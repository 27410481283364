<template>
<div class="d-flex align-items-center">
  <div class="clipboardWrapper input-group">
    <span class="title" v-html="$t('Reference number: ')"></span>
    <input type="text" :disabled="disabledInput" class="form-control" id="copied-tooltip" ref="target" :value="value" aria-label="Value">
    <svg @click="copy()" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.19 4H18V2.81C18 2.06474 17.7039 1.35001 17.177 0.82303C16.65 0.296053 15.9353 0 15.19 0L2.81 0C2.06474 0 1.35001 0.296053 0.82303 0.82303C0.296053 1.35001 0 2.06474 0 2.81L0 17.19C0 17.9353 0.296053 18.65 0.82303 19.177C1.35001 19.7039 2.06474 20 2.81 20H6V21.19C6 21.9353 6.29605 22.65 6.82303 23.177C7.35001 23.7039 8.06474 24 8.81 24H21.19C21.9353 24 22.65 23.7039 23.177 23.177C23.7039 22.65 24 21.9353 24 21.19V6.81C24 6.06474 23.7039 5.35001 23.177 4.82303C22.65 4.29605 21.9353 4 21.19 4ZM2 17.19V2.81C2 2.59517 2.08534 2.38915 2.23724 2.23724C2.38915 2.08534 2.59517 2 2.81 2H15.19C15.4048 2 15.6109 2.08534 15.7628 2.23724C15.9147 2.38915 16 2.59517 16 2.81V17.19C16 17.4048 15.9147 17.6109 15.7628 17.7628C15.6109 17.9147 15.4048 18 15.19 18H2.81C2.59517 18 2.38915 17.9147 2.23724 17.7628C2.08534 17.6109 2 17.4048 2 17.19ZM22 21.19C22 21.4048 21.9147 21.6109 21.7628 21.7628C21.6109 21.9147 21.4048 22 21.19 22H8.81C8.59517 22 8.38915 21.9147 8.23724 21.7628C8.08534 21.6109 8 21.4048 8 21.19V20H15.19C15.9353 20 16.65 19.7039 17.177 19.177C17.7039 18.65 18 17.9353 18 17.19V6H21.19C21.2964 6 21.4017 6.02095 21.5 6.06166C21.5982 6.10236 21.6875 6.16203 21.7628 6.23724C21.838 6.31246 21.8976 6.40175 21.9383 6.50003C21.979 6.5983 22 6.70363 22 6.81V21.19Z" fill="var(--color-7)"/>
    </svg>

  </div>
  <b-tooltip ref="tooltip" target="copied-tooltip" noninteractive>
    {{$t('Copied to clipboard')}}
  </b-tooltip>
</div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "clipboard",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    variables: Object,
    disabledInput: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    console.log("disabled: ", this.variables, this.disabledInput, this.$store.state.referenceNumber)
  },
  computed: {
    value () {
      let result = this.$t(this.text);
      console.log(result)
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state.formData[variable];
          if(variable === "referenceNumber") {
            value = this.$store.getters.getReferenceNumber
          }
          result = result.replace(new RegExp(`{${variable}}`, "g"), value || 'no-ref-num');
        }
      }
      return result;
    }
  },
  methods: {
    copy () {
      var copyText = this.$refs.target;
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */
      navigator.clipboard.writeText(copyText.value);
      this.$refs.tooltip.$emit('open')
      setTimeout(() => {
        this.$refs.tooltip.$emit('close')
      }, 2000)
    },
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000)
  }
};
</script>

<style lang="scss" scoped>
.clipboardWrapper {
  border: 1px solid var(--input-border-color);
  background-color: #fff;
  border-radius: 16px;
  height: 60px !important;
  padding: 5px 20px 5px 20px;
  align-items: center;
  color: var(--color-7);
  input {
    text-align: center;
    color: var(--color-7);
  }
  @media (max-width: 767px) {
    padding: 5px 10px;
    .b-icon {
      font-size: 20px !important;
    }
  }
}


.title {
  font: normal normal bold 16px/26px 'Plus Jakarta Sans';
  @media (max-width: 767px) {
    font-size: 12px;
  }
}
input {
  font: normal normal bold 16px/26px 'Plus Jakarta Sans';
  border: none;
  background: transparent;
  padding: 5px;
  &:disabled {
    background: transparent;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }
}

</style>
