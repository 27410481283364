<template>
<span></span>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "doi",
  props: {
    apiUrl: {
      type: String,
      required: true
    },
    title: String,
    text: String,
    button: String
  },
  data: () => {
    return {
      verification_code: "",
      loading: true,
      sent: false
    };
  },
  computed: {
    ...mapGetters(["customerToken", "campaignId", "receiptToken"])
  },
  methods: {
    checkDoi () {
      return axios
        .post(this.apiUrl, {
          verification_code: this.verification_code,
          campaignId: this.campaignId
        })
        .then(res => res.data);
    }
  },
  mounted () {
    this.verification_code = this.$route.query.verification_code;

    if (this.verification_code) {
      this.checkDoi()
        .then(res => {
          if (window.dataLayer) {
          // eslint-disable-next-line
          dataLayer.push({
              event_action: "CompleteRegistration"
            });
          }
          if (res.response) {
            this.sent = true;
          }
        })
        .catch(er => {
          console.log(er)
          location.href = "/"
        });
    } else {
      this.$router.replace("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.content_doi {
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: left bottom, right bottom, 22% 25%;
  background-size: 33%, 32%, 60%;
  padding-bottom: 270px;
  color: #1D1D1D !important;
}
.steps_container {
  max-width: 700px !important;
  margin: 0 auto;
}
.wrapper_d_title_dop {
  text-align: center;
}
.wrapper_d_title {
  margin-top: 81px;
  margin-bottom: 75px;
  text-align: center;
}
.btn {
  color: #FFFFFF !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 100px;
  transition: all 0.3s;
  box-sizing: border-box;
  font-family: "'Plus Jakarta Sans'", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  padding: 3px 20px;
  box-shadow: none !important;
  max-height: 40px;
  &.disabled {
    opacity: 0.3;
  }
  &-lg {
    max-width: 470px;
    width: 100%;
  }
  &-md {
    max-width: 226px;
    width: 100%;
  }
}
.btn_abs {
  position: absolute;
  bottom: 0;
  right: 0;
  line-height: 0;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.h1 {
  font-size: 32px;
  font-weight: 600;
  line-height: 39px;
  margin-top: 15px;
  margin-bottom: 32px;
}
.h4 {
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
}
@media (max-width: 1240px) {
  .text-center {
    margin-top: 50%;
  }
  .content_doi {
    background-position: 25% 70%, 75% 70%, center top;
    background-size: 35%, 35%, 80%;
    padding-bottom: 50px;
  }
}
@media (max-width: 550px) {
  .text-center {
    margin-top: 38%;
  }
  .content_doi {
    background-position: 22% 75%, 78% 75%, center top;
    background-size: 40%, 40%, 100%;
  }
}
</style>
