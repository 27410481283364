<template>
  <div :class="[{'row': !noRow}, className]" class="" :style="cssString">
    <template v-for="field in fields">
      <componentRenderer
        :class="`col-lg-${field.properties.grid.columns}`"
        :field="field"
        v-bind:key="field.id"
        v-on:component-updated="updated"
      />
    </template>
  </div>
</template>
<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "empty-bucket",
  components: { componentRenderer },
  data () {
    return {
      cssString: ""
    }
  },
  props: {
    fieldId: String,
    requires: Array,
    fields: {
      type: Array,
      required: true
    },
    grid: {
      type: Object
    },
    noRow: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    className: {
      type: String
    }
  },
  methods: {
    updated (field, properties) {
      let updatedField = this._.find(this.fields, function (fld) {
        return fld.fieldId === field.fieldId;
      });

      updatedField.properties = { ...updatedField.properties, ...properties };
      // let updatedField = __.findDeep(this.fields, fld => fld === field.fieldId);
      // console.log(
      //   "component renderer on updated::",
      //   field,
      //   "properties",
      //   properties,
      //   "FOUND",
      //   updatedField
      // );

      // TODO
      // some validation will go here
      // in case if all fields are valid we are happy to set truthy status
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
    }
  },

  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      }
    }
  }
};
</script>
<style scoped lang="scss">
.row {
  ::v-deep [disabled] {
    pointer-events: none;
  }
}
@media (max-width: 1024px) {
  .between-banner-space {
    height: 30px !important;
  }
}
@media (min-width: 992px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}
@media (max-width: 991px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

</style>
