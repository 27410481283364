<template>
  <span></span>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "checkReceiptToken",
  mounted () {
    const value = this.$route.query['token']
    this.check72Hours(value)
      .then(res => {
        if (!res.response) {
          location.href='expired'
        }
      })
  },
  methods: {
    ...mapActions(['check72Hours'])
  }
}
</script>