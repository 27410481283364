<template>
  <div>
    <p v-html="question"></p>
    <template v-for="(ans,idx) in answers">
      <div
        :key="idx"
        v-if="answerType==='checkbox'"
        class="custom-control custom-checkbox custom-checkbox-arrow"
      >
        <input
          :name="idx"
          :checked="selected"
          type="checkbox"
          class="custom-control-input"
          :id="`checkbox_${fieldId}_${idx}`"
          @change="test($event,idx)"
        />
        <label class="custom-control-label text_min" :for="`checkbox_${fieldId}_${idx}`" v-html="ans"></label>
      </div>
      <div
        v-if="answerType==='radio'"
        class="custom-control custom-radio radio-input"
        :key="idx"
      >
        <input
          v-model="selected"
          class="custom-control-input"
          type="radio"
          :value="ans"
          :id="`radio_${fieldId}_${idx}`"
        />
        <label class="custom-control-label" :for="`radio_${fieldId}_${idx}`">{{ans}}</label>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "question",
  props: {
    fieldId:String,
    question: String,
    answers: Array,
    answerType: {
      type: String,
      default: "checkbox"
    }
  },
  data: () => {
    return {
      results: [],
      selected: ""
    };
  },
  methods: {
    test ($event, idx) {
      // console.log(this.results, idx, this.result);
      this.results[idx] = !this.results[idx];
    }
  }
};
</script>


<style lang="scss" scoped>
.form-group {
  margin-bottom: 20px;
}
.form-field {
  margin-bottom: 20px;
}
.wrapper_input {
  position: relative;
}
.form-field__label {
  position: absolute;
  top: 50%;
  left: 10px;
  padding-left: 7px;
  padding-right: 7px;
  transition: transform 0.1s;
  pointer-events: none;
  transform-origin: 0 100%;
  transform: translateY(-50%);
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 16px;
}
.form-field__input {
  background: transparent !important;
  outline: 0;
  box-shadow: none;
  transition: border-color 0.15s;
  border-radius: 0 !important;
  border: 1px solid #808080 !important;
  box-sizing: border-box !important;
  padding-left: 15px !important;
  height: 48px !important;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
}
.form-field__input:focus + .form-field__label,
.form-field__input.visited + .form-field__label {
  transform: translateY(-140%) scale(0.7);
}
.wrapper-i {
  cursor: pointer;
}
.wrapper_weit {
  display: flex;
  align-items: center;
}
.information-img {
  width: 18px;
}
.add-img {
  width: 30px;
  margin-right: 20px;
}
.is-invalid {
  .form-field__input {
    border-color: #ff5a5a !important;
  }
  .invalid-feedback {
    display: block;
  }
}
.wrapper_best {
  display: flex;
  align-items: center;
  position: relative;
  .custom-control {
    padding-left: 35px;
  }
  .custom-checkbox .custom-control-label::before,
  .custom-checkbox .custom-control-label::after {
    left: -35px;
  }
}
.down-arrow {
  width: 16px;
  transition: all 0.3s;
  margin-left: 10px;
  margin-top: -8px;
}
.down-arrow[aria-expanded="true"] {
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.card-body-dop {
  border: none;
  padding-left: 35px;
}
.custom-radio {
  padding-left: 35px;
  margin-right: 20px;
}
.custom-control-label::after,
.custom-checkbox .custom-control-label::before {
  width: 20px;
  height: 20px;
  top: 0;
  left: -35px;
}
.custom-checkbox-dop {
  position: absolute;
  bottom: 5px;
  right: 10px;
  .custom-control-label {
    &:after,
    &:before {
      left: -20px;
    }
  }
}
.custom-control-label::after,
.custom-checkbox .custom-control-label::before,
.custom-control-input[type="radio"] {
  background-color: transparent !important;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 0;
}
.custom-checkbox .custom-control-label::before {
  background-image: url("../../assets/icons/form/unchecked_checkbox.svg") !important;
  background-repeat: no-repeat;
  box-shadow: none !important;
  background-size: 20px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  border: 1px solid var(--input-border-color);
  background-color: var(--color-7);
  box-sizing: border-box;
  border-radius: 2px;
}
.text_min {
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 16px;
}

.radioInputWrapper {
  .inputWrapper {
    display: flex;
    .radio-input {
      margin: 3px;
    }
  }
}
input[type="radio"] + label {
  // color: #C4C4C4;
}
.custom-radio {
  padding-left: 35px;
  margin-right: 20px;
}
input[type="radio"] + label:before {
  border: 2px solid #C4C4C4 !important;
  box-shadow: none !important;
  width: 20px;
  height: 20px;
  top: 0;
  left: -35px;
}
.custom-control-label::after, .custom-control-label::before {
  width: 20px;
  height: 20px;
  top: 0;
  left: -35px;
  margin-left: 10px;
  margin-top: 3px;
}
.custom-control-input[type="radio"]:checked~.custom-control-label::before {
  background: #C4C4C4 !important;
}
.custom-checkbox-dop {
  position: absolute;
  bottom: 5px;
  right: 10px;
  .custom-control-label {
    &:after,
    &:before {
      left: -20px;
    }
  }
}
.custom-control-label::after,
.custom-checkbox .custom-control-label::before,
.custom-control-input[type="radio"] {
  background-color: transparent !important;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 0;
}
</style>
