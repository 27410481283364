import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
import { localize } from 'vee-validate'
import { i18n } from '../translate'

const APIUrl = process.env.VUE_APP_API_URL
const defaultMsg = 'Oops. An error has occurred. Please try again later.'

export default {
  isPublic({ state }, pageName) {
    if (pageName.toLowerCase() === 'home') return true
    const page = state.pageProperties.pages.find(page => page.pageName === pageName)
    return page && Boolean(page.isPublic);
  },
  handleError: ({ commit, dispatch, state }, err) => {
    if (err.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
      err.code = err.response.status
      if (err.response.status == 501) {
        return location.href = '/sitedown'
      }
    }
    const page = state.pageProperties;
    let e = undefined
    const errors = page.errors ? page.errors[i18n.locale] : []
    if (errors) {
      if (err && err.cause)
        e = errors[err.cause]
      else
        e = errors['default']
    }
    // console.error(e)
    console.log(e, err, errors)
    if (err && err.message) {
      commit('setModalOptions', { type: 'error', isActive: true, message: err.message })
    } else {
      commit('setModalOptions', { type: 'error', isActive: true, message: e })
    }
  },

  setPageProperties: ({
    commit
  }, properties) => {
    commit('setPageProperties', properties);
  },

  setTemplateData: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      // console.log("SET TEMPLATE DADTA", APIUrl, data)
      axios.post(`${APIUrl}/savePage`, data).then(res => {
        commit('setPageProperties', res.data);
        return resolve(res.data)
      })
    })
  },

  refreshTempId: ({ dispatch, commit, getters }) => {
    return axios.post(`${APIUrl}/tempId`, { campaignId: getters.campaignId, jstc: 1 })
      .then(resp => {
        if (resp.data.response) {
          const data = {
            field: 'customerToken',
            value: resp.data.customerToken
          }
          if (!location.pathname.includes("/re-iban") && !location.pathname.includes("/survey")) commit('setField', data)
        }
      }).catch(err => {
        dispatch('handleError', err)
      })
  },

  setComponentStatus: ({ commit }, { component, status }) => {
    commit('setComponentStatus', { component, status });
  },
  setCampaignId: ({ commit }, campaignId) => {
    commit('setCampaignId', campaignId);
  },

  sendFormData({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {

      if (window.nsId) {
        data.formData.nsId = window.nsId;
      }

      axios.post(data.apiUrl, data.formData)
        .then(res => {
          if (data.triggers && data.triggers.neuStarTrigger) {
            // console.log("3. will send::: neutrigger", data.triggers)
            let a = window._agknEchoTag;
            if (a && data.n) {
              a.setBpId('firstp');
              a.addEchoKeyValue('event', 'submit');
              a.generateTag();
            }
          }
          if (res.data.response) {
            resolve(res.data)
          } else {
            if (res.data.invalid_fields && res.data.invalid_fields.length !== 0) {
              //janrain error
              dispatch('handleError', { message: res.data.invalid_fields[0] })
            } else {
              dispatch('handleError')
            }
            reject(res.data)
          }
        })
        .catch(ex => {
          dispatch('handleError')
          return { success: false, error: ex.message }
        })
    })
  },
  sendSurveyData({ commit, dispatch }, data) {
    console.log("data", data)
    return new Promise((resolve, reject) => {
      axios.post(data.apiUrl, data.formData)
        .then(res => {
          if (res.data.response) {
            resolve(res.data)
          } else {
            dispatch('handleError')
            reject(res.data)
          }
        })
        .catch(ex => {
          dispatch('handleError')
          reject(ex)
        })
    })
  },
  getReturnFormFields: ({ getters, commit, dispatch }, receiptToken) => {
    let data = {};
    data.receiptToken = receiptToken;
    data.campaignId = getters.campaignId;
    data.lang = localStorage.getItem("lang")
    return new Promise((resolve, reject) => {
      // console.log("SET TEMPLATE DADTA", APIUrl, data)
      axios.post(`${APIUrl}/returnForm`, data).then(res => {
        return resolve(res.data)
      }).catch(err => {
        return { success: false, error: err.message }
      })
    })
  },
  uploadProductPhotos({ getters, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      formData.append('productPhoto', data.image, 'productPhoto.png')
      formData.append('customerToken', getters.customerToken)
      formData.append('campaignId', getters.campaignId)
      formData.append('receiptToken', data.receiptToken)
      axios.post(data.apiUrl, formData)
        .then(res => {
          if (res.data.response) {
            if (res.data.referenceNumber)
              commit('setReferenceNumber', res.data.referenceNumber)
            resolve(res.data)
          } else {
            dispatch('handleError')
            reject(res.data)
          }
        })
        .catch(ex => {
          dispatch('handleError')
          reject(ex)
        })
    })
  },

  generateCode({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken
      axios.post(data.apiUrl, data.formData)
        .then(res => {
          if (res.data.response) {
            return resolve(res.data)
          } else {
            dispatch('handleError')
            return reject(res.data)
          }
        })
        .catch(ex => {
          dispatch('handleError')
          return { success: false, error: ex.message }
        })
    })
  },

  verifyCode({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      data.formData.receiptToken = state.receiptToken

      axios.post(data.apiUrl, data.formData)
        .then(res => {
          resolve(res.data)
        })
        .catch(ex => {
          dispatch('handleError')
          return { success: false, error: ex.message }
        })
    })
  },

  sendProducts({ commit, dispatch, state }, data) {
    return new Promise((resolve, reject) => {
      data.formData.customerToken = state.customerToken
      data.formData.campaignId = state.campaignId
      axios.post(data.apiUrl, data.formData)
        .then(res => {
          if (res.data.response)
            resolve(res.data)
          else
            dispatch('handleError')
        })
        .catch(ex => {
          dispatch('handleError')
          return { success: false, error: ex.message }
        })
    })
  },

  uploadReceipt({ getters, commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      const formData = new FormData()
      if (data.receiptToken) {
        formData.append('receiptToken', data.receiptToken)

      }
      formData.append('userToken', data.customerToken)
      formData.append('receipt', data.image, 'receipt.png')
      formData.append('subCampaignId', getters.campaignId)
      formData.append('campaignId', data.campaignId)

      if (data.token) {
        formData.append('token', data.token)
      }
      return axios.post(`${data.apiUrl}`, formData)
        .then(resp => {
          if (resp.data.response !== 'ERR') {
            commit('setReceiptToken', resp.data.receiptToken)
            if (resp.data.referenceNumber)
              commit('setReferenceNumber', resp.data.referenceNumber)
            return resolve(resp.data.receiptToken);
          } else {
            dispatch('handleError', resp.data.error)
          }
        })
        .catch(resp => {
          dispatch('uploadError')
          reject(resp)
        })
    })
  },

  onReceiptUploadSuccess({ commit }, data) {
    commit('setReceiptImage', data)
  },
  uploadBarcode($, data) {
    // Make a put request to add the barcode data with the given receipt token
    console.log("Actions", data)
    const apiUrl = 'https://national-cashback.justsnap.eu/api/v2/dashboard/receipt'
    axios.put(apiUrl, data, { headers: { "Content-Type": "application/json" } })
      .then(res => console.log("ACTIONS", res))
      .catch(err => console.log("ACTIONS", err))
  },
  uploadFile({ commit }, file) {
    if (file) commit('setFile', file);
  },
  setImgList({ commit }, list) {
    commit('setImgList', list);
  },
  getBarCode({ commit }, code) {
    commit('setBarCode', code)
  },
  handleProductCode({ commit }, value) {
    commit('setProductCode', value)
  },
  updateSearchStatus({ commit }, status) {
    commit('setSearchStatus', status)
  },
  updateScanner({ commit }, status) {
    commit('setScannerStatus', status)
  },
  findProducts({ commit }, array) {
    commit('setProducts', array)
  },
  removeProduct({ commit }, productId) {
    commit('deleteProduct', productId)
  },
  editQuantityProduct({ commit }, { product, action, maxProductCount }) {
    commit('setQuantityProduct', { product, action, maxProductCount })
  },
  editInputQuantityProduct({ commit }, { productId, count, maxProductCount }) {
    if (count === '0') {
      commit('deleteProduct', productId)
    } else {
      if (maxProductCount && count > maxProductCount) {
        commit('setModalOptions', { type: 'error', isActive: true, message: `Sie können nicht mehr als ${maxProductCount} Produkte auswählen.` });
        commit('setInputQuantityProduct', { productId, count: maxProductCount });
        return
      }
      commit('setInputQuantityProduct', { productId, count })
    }
  },
  searchProductByBarcode({ state, dispatch, commit }, { barcode, maxProductCount }) {
    let apiUrl = 'https://template-v2.justsnap.eu/api/searchBarcode';
    const isAllowed = maxProductCount ? maxProductCount > state.scannedProducts.length : true;

    // If barcode exists , exit method
    if (state.scannedProducts.find(item => item.ean === (barcode))) return;

    if (/^[0-9]+$/.test(barcode) && isAllowed) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${apiUrl}`, {
            campaignId: state.campaignId,
            barcode: barcode
          })
          .then(res => {
            let status = res.data.result.length > 0 ? 'success' : 'not_found';
            dispatch("updateSearchStatus", status);

            // Check if the product has selected a valid item according to the selected product list
            const findSameCategory = state.scannedProducts.find(item => item.category === (res.data.result[0]).category)
            if (!findSameCategory)
              dispatch("findProducts", res.data.result);
            else {
              commit('setModalOptions', { type: 'error', isActive: true, message: i18n.t(`You cannot enter more than one product.`) });
              dispatch("updateSearchStatus", null);
            }

            if (status === 'not_found') {
              // The barcode is not P&G
              dispatch('updateScannerErrorMessage', 'Der Barcode scheint nicht zu einem P&G Produkt zu gehören. Glauben Sie, dass es sich um einen Fehler handelt?')
              dispatch('setErrorActivate', true)
            } else {
              dispatch('setErrorActivate', false)
              console.log(res)
            }
            resolve(res.data);
          })
      });
    } else {
      if (!isAllowed) {
        commit('setModalOptions', { type: 'error', isActive: true, message: `Sie können nicht mehr als ${maxProductCount} Produkte auswählen.` });
        dispatch("updateSearchStatus", null);
      } else {
        dispatch("updateSearchStatus", 'not_found');
        // The barcode is invalid
        dispatch('updateScannerErrorMessage', 'Der Barcode ist ungültig. Bitte versuchen Sie erneut, den Barcode einzugeben oder zu scannen. Glauben Sie, dass es sich um einen Fehler handelt?')
        dispatch('setErrorActivate', true)
      }
    }
  },
  updateScannerErrorMessage({ commit }, error) {
    commit('setScannerError', error);
  },
  setErrorImages({ commit }, data) {
    console.log(data.posNum)
    commit('setErrorImages', data)
  },
  setErrorActivate({ commit }, data) {
    commit('setErrorActivate', data)
  },
  setReceiptBarcode({ commit }, data) {
    commit('setReceiptBarcode', data)
  },
  setIsReceiptImageChanged({ commit }, data) {
    commit('setIsReceiptImageChanged', data)
  },
  setConvertedImagePointsData({ commit }, data) {
    commit('setConvertedImagePointsData', data)
  },
  setFirstTimeUploading({ commit }, data) {
    commit('setFirstTimeUploading', data)
  },
  isUpdateIban: ({ commit }, data) => {
    return new Promise((resolve, reject) => {
      axios.get(`https://template-v2.justsnap.eu/api/ibanUpdateCheck/${data.customerToken}?campaignId=${data.campaignId}`)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  setCustomerToken: ({ commit }, data) => {
    commit('setCustomerToken', data)
  },
  check72Hours({ getters }, data) {
    const formData = {}
    formData.receiptToken = data
    formData.campaignId = getters.campaignId
    return new Promise((resolve, reject) => {
      axios.post(`${APIUrl}/check72Hours`, formData)
        .then(resp => {
          resolve(resp.data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  uploadError({ commit }) {
    commit('setModalOptions', { type: 'error', isActive: true, message: 'Ein Fehler ist aufgetreten, weil der Beleg zu oft hochgeladen wurde.' })

    setTimeout(() => {
      location.href = ''
    }, 3 * 1000)
  },
  checkGeoLocation({ getters }) {
    return new Promise((resolve, reject) => {
      return axios.post(`${APIUrl}/checkGeoLocation`, { campaignId: getters.campaignId })
        .then(res => resolve(res.data.countryCode))
        .catch(er => reject(er))
    })
  }
}

export function updateFavicon(url) {
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = url;
  document.getElementsByTagName("head")[0].appendChild(link);
}
