<template>
  <div @click="pressed" class="gray_block">
    <img src="../../assets/icons/barcodeScannerInfo.svg" alt="">
    <div class="inner_gray_block">
      <div class="text color-black mb-30" v-html="computedText"></div>
    </div>
    <div class="row align-items-center wrapper-steps-example ">
      <div class="col-3">
      </div>
      <div class="col-5 pr-0 receipt-example" >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "barcode-information",
  props: {
    descriptionText: {
      required: false,
      type: String,
      default: "Bitte geben Sie die Barcode-Nummer Ihres Braun Zahncreme Produktes ein, machen Sie ein Foto Ihres Aktionsproduktes oder laden Sie es per Drag&Drop hoch. Bitte stellen Sie sicher, dass die Barcode-Informationen Ihres Aktionsproduktes gut sichtbar sind."
    },
    desktop: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      clicked: false
    }
  },
  computed: {
    computedText () {
      return (this.clicked || this.desktop) ? this.descriptionText : `${this.descriptionText.substring(0 , 40)}...`;
    }
  },
  methods: {
    pressed () {
      this.clicked = !this.clicked;
    }
  }
};
</script>
<style lang="scss" scoped>
  .gray_block {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 25px 40px;
    display: flex;

    @media (max-width: 991px) {
      padding: 16px;
    }
    .inner_gray_block {
      text-align: left;
      font: normal normal 300 16px/22px 'Plus Jakarta Sans';
      letter-spacing: 0px;
      color: #1B1B1B;
      opacity: 1;

      padding: 0px 30px;
    }
  }
  .wraper_punkt {
    li {
      margin-bottom: 30px;
      font-size: 16px;
      letter-spacing: 0.16px;
      line-height: 24px;
      @media (max-width: 991px) {
        margin-bottom: 15px;
      }
      .item {
        width: 25px;
        height: 25px;
        display: inline-block;
        background: #8B8B8B;
        color: #ffffff;
        border-radius: 100px;
        text-transform: uppercase;
        margin-right: 20px;
        text-align: center;
        padding: 3px 8px;
      }
      .item_text {
        color: #2E0606;
        display: inline-block;
      }
    }
  }
  .checkMark {
    position: relative;
    float: left;
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
    .checkMark_img {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -27px;
      @media (max-width: 767px) {
        max-width: 30px;
        margin-left: -15px;
      }
    }
    .cross_red_img {
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -18px;
      @media (max-width: 767px) {
        max-width: 24px;
        margin-left: -12px;
      }
    }
  }
  .wrapper_check_block {
    padding-top: 90px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 991px) {
      padding-top: 20px;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .receipt-example {
    width: 100%;
  }
  .wrapper-steps-example {
    margin-bottom: 30px;
  }
</style>
