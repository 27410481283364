export const translateError = e => {
  const errors = {
    "Email address is already in use.": "Sie haben ein ForMe -Konto mit dieser E-Mail registriert. Bitte gehen Sie zur Anmeldeseite.",
    "IBAN already used": "IBAN bereits verwendet",
    "IBAN is alreaedy in use": "Die soeben angegebene IBAN hat im Rahmen unserer Aktion bereits erfolgreich teilgenommen. Bitte verwenden Sie eine andere IBAN!",
    "Incorrect username or password. Please try again.": "Falscher Benutzername oder falsches Passwort. Bitte versuche es erneut."
  }
  return errors[e]
}
export default {
  methods: {
    goToPage: (pageName, self) => {
      // console.log("gotopage::->", pageName)
      self.$eventHub.$emit('goToPage-event', pageName);

      // change url when moving to another page
      // if (location.pathname.split('/').join('') !== pageName) {
      // Add country code to url when changing page
      history.replaceState({}, null, '/' + localStorage.getItem('lang') + '/' + pageName)
      // }
    },
    goToPageWithData: (pageName , self) => {
      console.log(pageName , self)
    },
    sendProductPhotos: (params, buttonComponent) => {
      let fieldId = params;
      // console.log("1. will SEND products:::", fieldId, buttonComponent)

      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));

      buttonComponent.$eventHub.$emit('sendProductPhotos-event', fieldId);
    },
    sendForm: (params, buttonComponent) => {
      if (params.indexOf(',') > -1) {
        params = params.split(',')
      }
      let formId = params;
      let neuStarTrigger = false
      if (self._.isArray(params)) {
        formId = params[0]
        neuStarTrigger = params.filter(param => param === 'neuStarTrigger')
        // console.log("1. will SEND FORM:::", formId, 'neuStarTrigger', neuStarTrigger.length > 0)
      }
      const triggers = {
        neuStarTrigger: neuStarTrigger.length > 0
      }
      // console.log("1. will SEND FORM:::", formId, "NO TRIGGER")
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      // console.log("2. will SEND FORM:::", formId, buttonComponent)

      buttonComponent.$eventHub.$emit('getFormData-event', formId, triggers);
    },
    sendProducts: (formId, buttonComponent) => {
      // console.log("SEND PRODUCTS:::", formId)
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('sendProducts-event', formId);
    },
    sendSurvey: (formId, buttonComponent) => {
      // console.log("SEND PRODUCTS:::", formId)
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('getSurveyData-event', formId);
    },
    printForm: (formId, buttonComponent) => {
      // console.log("SEND PRODUCTS:::", formId)
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('done-event-trigger',
        (parent) => buttonComponent.doneTrigger(buttonComponent, parent));
      buttonComponent.$eventHub.$emit('printForm-event', formId);
    },
    getCustomerToken: (params, self) => {
      // console.log("getCustomerToken", self)
      self.$store.dispatch('retrieveUserToken')
    },
    doneTrigger: (component, eventResult) => {
      component.$eventHub.$off('done-event-trigger');
      component.loading = false
      // console.log('1. will Done Trigger', eventResult)
      // console.log('event Result',eventResult)
      // if (eventResult.status) {
      //     eventResult.parent.clear()
      // }
      if (!eventResult.status && eventResult.message) {
        // console.log("EVENT RESULT>>>", eventResult)
        // const lang = "en"

        // Oops. Something went wrong, please try again later.
        // const defaultErrorMessage = "Hoppla. Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."
        // if (eventResult.invalid_fields && eventResult.invalid_fields.length) {
        //     const localErr = translateErrpr(eventResult.invalid_fields[0], lang)
        //     if (localErr) component.showModal(localErr)
        //     else component.showModal(defaultErrorMessage)
        //     return
        // }
        component.showModal(eventResult.message)
      }

    },
    sendReceipt: (component, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$emit('sendReceipt-event')
    },
    sendEmail: (data, buttonComponent) => {
      buttonComponent.loading = true;
      buttonComponent.$eventHub.$on('email-sent-trigger',
        (parent) => buttonComponent.emailSentTrigger(buttonComponent, parent));

      buttonComponent.$eventHub.$emit('sendmail-event', data);
    },
    emailSentTrigger: (component, eventResult) => {
      component.$eventHub.$off('email-sent-trigger');
      component.loading = false

      // Email was sent
      if (eventResult.success) {
        component.showModal(eventResult.message, 'success')
        eventResult.parent.clear()

        // Clear The product state
        component.$store.commit('resetProducts');
        component.$store.commit('setProductCode', '');
        component.$store.commit('setSearchStatus', null);

        // Move back to home page
        component.goToPage('homepage', component)
      }
      if (!eventResult.status && eventResult.message) component.showModal(eventResult.message)


    },
    isMobileOrTablet ( currentScreenSize ) {
      return currentScreenSize < 992 ? true : false
    },
    showModalSuccess (message) {
      this.showModal(message, 'success')
    },
    displaySuccess () {
      this.$eventHub.$emit('display-success');
    },
    receiptUploaderToProductUploader ( _ , self) {

      const sp = self.$store.getters.scannedProducts;
      // console.log()

      if (sp.every(item => item.isFeRequired === 0)) {
        self.goToPage('product-upload-be', self)
      } else {
        self.goToPage('product-upload-fe', self)
      }
    }
  }
}
