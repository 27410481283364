<template>
  <div class="wrapper">
    <canvas
      :width="width"
      :height="height"
      ref="canvas"
      class="canvas"
      @mousedown="handleMouseDown"
      @mouseup="handleMouseUp"
      @mouseleave="handleMouseLeave"
      @mousemove="handleMouseMove"
      @touchstart="handleMouseDown"
      @touchmove="handleMouseMove"
      @touchend="handleMouseLeave"
    ></canvas>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'erazer',
  props: {
    height: Number,
    width: Number,
    imagePosition: Object,
    layer: Object,
    color: String,
    size: Number
  },
  data () {
    return {
      history: [],
      ctx: null,
      mouseX: 0,
      mouseY: 0,
      moveHistory: {},
      scale: 1
    }
  },

  watch: {
    layer (layer) {
      if (layer) {
        this.history.forEach((item, i) => {
          this.drawToLayer(item, i)
        })
        this.$emit('input', {
          ctx: this.layer.ctx
        })
      }
    },

    imagePosition: function (newPosition, currentPosition) {
      if (!newPosition) {
        this.ctx.clearRect(0, 0, this.width, this.height)
        this.history = []
      }
      if (currentPosition && newPosition) {
        let currentWidth = +(currentPosition.x1 - currentPosition.x0).toFixed(5)
        let newWidth = +(newPosition.x1 - newPosition.x0).toFixed(5)
        let ratio = +(newWidth / currentWidth).toFixed(5)

        let leftDelta = +(newPosition.x0 - currentPosition.x0).toFixed(5)
        let topDelta = +(newPosition.y0 - currentPosition.y0).toFixed(5)

        this.ctx.clearRect(0, 0, this.width, this.height)
        this.history.forEach((item, i) => {
          if (ratio === 1) {
            item.x = +(item.x + leftDelta).toFixed(5)
            item.y = +(item.y + topDelta).toFixed(5)
          } else {
            let totalShift = this.getFinalShift()

            // temporary move dots coordination to the initial position (without shift)
            // to have the same move acceleration as image
            if (totalShift.x) {
              item.x = +(item.x - totalShift.x).toFixed(5)
            }
            if (totalShift.y) {
              item.y = +(item.y - totalShift.y).toFixed(5)
            }

            // scale
            item.x = +(item.x * ratio).toFixed(5)
            item.y = +(item.y * ratio).toFixed(5)
            item.r = +(item.r * ratio).toFixed(5)

            // after scale
            // return back to the actual position
            if (totalShift.x) {
              item.x = item.x + totalShift.x
            }
            if (totalShift.y) {
              item.y = item.y + totalShift.y
            }
          }

          this.draw(item, i)
        })

        // remember shifts to be able to restore initial position
        this.scale = parseFloat((this.scale * ratio).toFixed(2))

        if (ratio === 1) {
          if (!this.moveHistory[this.scale]) {
            this.moveHistory[this.scale] = [0, 0]
          }
          this.moveHistory[this.scale][0] = +(this.moveHistory[this.scale][0] + leftDelta).toFixed(5)
          this.moveHistory[this.scale][1] = +(this.moveHistory[this.scale][1] + topDelta).toFixed(5)
        }
      }
    }
  },

  methods: {
    getFinalShift () {
      let totalShift = {
        x: 0,
        y: 0
      }
      for (let scale in this.moveHistory) {
        let shift = this.moveHistory[scale]
        totalShift.x = +(totalShift.x + shift[0]).toFixed(2)
        totalShift.y = +(totalShift.y + shift[1]).toFixed(2)
      }
      return totalShift
    },

    handleMouseDown (e) {
      e.preventDefault()
      this.mouseDown = true
      if (window.TouchEvent && (e instanceof TouchEvent)) {
        const rect = e.target.getBoundingClientRect()
        this.mouseX = e.touches[0].clientX - rect.x
        this.mouseY = e.touches[0].clientY - rect.y
      } else {
        this.mouseX = e.offsetX
        this.mouseY = e.offsetY
      }
      this.setDummyPoint()
    },

    handleMouseUp () {
      if (this.mouseDown) {
        this.setDummyPoint()
      }
      this.mouseDown = false
    },

    handleMouseLeave () {
      if (this.mouseDown) {
        this.setDummyPoint()
      }
      this.mouseDown = false
    },

    handleMouseMove (e) {
      e.preventDefault()
      if (this.mouseDown) {
        if (window.TouchEvent && (e instanceof TouchEvent)) {
          const rect = e.target.getBoundingClientRect()
          this.mouseX = e.touches[0].clientX - rect.x
          this.mouseY = e.touches[0].clientY - rect.y
        } else {
          this.mouseX = e.offsetX
          this.mouseY = e.offsetY
        }

        let item = {
          isDummy: false,
          x: this.mouseX,
          y: this.mouseY,
          c: this.color,
          r: this.size
        }

        this.history.push(item)
        this.draw(item, this.history.length)
        this.drawToLayer(item, this.history.length)
        this.emitLayer()
      }
    },

    getDummyItem () {
      let lastPoint = this.history[this.history.length - 1]

      return {
        isDummy: true,
        x: lastPoint ? lastPoint.x : this.mouseX,
        y: lastPoint ? lastPoint.y : this.mouseY,
        c: null,
        r: null
      }
    },

    setDummyPoint () {
      let item = this.getDummyItem()
      this.history.push(item)
      this.draw(item, this.history.length)
      this.drawToLayer(item, this.history.length)
    },

    draw (item, i) {
      this.ctx.lineCap = 'round'
      this.ctx.lineJoin = 'round'

      let prevItem = this.history[i - 2]

      if (i < 2) {
        return false
      }

      if (!item.isDummy && !this.history[i - 1].isDummy && !prevItem.isDummy) {
        this.ctx.strokeStyle = item.c
        this.ctx.lineWidth = item.r

        this.ctx.beginPath()
        this.ctx.moveTo(prevItem.x, prevItem.y)
        this.ctx.lineTo(item.x, item.y)
        this.ctx.stroke()
        this.ctx.closePath()
      } else if (!item.isDummy) {
        this.ctx.strokeStyle = item.c
        this.ctx.lineWidth = item.r

        this.ctx.beginPath()
        this.ctx.moveTo(item.x, item.y)
        this.ctx.lineTo(item.x, item.y)
        this.ctx.stroke()
        this.ctx.closePath()
      }
    },

    drawToLayer (item, i) {
      let ctx = this.layer.ctx
      let ratio = this.layer.ratio
      ctx.lineCap = 'round'
      ctx.lineJoin = 'round'

      let prevItem = this.history[i - 2]

      if (i < 2) {
        return false
      }

      if (!item.isDummy && !this.history[i - 1].isDummy && !prevItem.isDummy) {
        ctx.strokeStyle = item.c
        ctx.lineWidth = item.r * ratio

        ctx.beginPath()
        ctx.moveTo(prevItem.x * ratio, prevItem.y * ratio)
        ctx.lineTo(item.x * ratio, item.y * ratio)
        ctx.stroke()
        ctx.closePath()
      } else if (!item.isDummy) {
        ctx.strokeStyle = item.c
        ctx.lineWidth = item.r * ratio

        ctx.beginPath()
        ctx.moveTo(item.x * ratio, item.y * ratio)
        ctx.lineTo(item.x * ratio, item.y * ratio)
        ctx.stroke()
        ctx.closePath()
      }
    },

    emitLayer: debounce(function () {
      this.$emit('input', {
        ctx: this.layer.ctx
      })
    }, 500)
  },

  mounted () {
    this.ctx = this.$refs.canvas.getContext('2d')
  }
}
</script>

<style scoped>
  .wrapper {
    position: absolute !important;
    top: 0;
    left: 0;

    cursor: crosshair;
  }
</style>
