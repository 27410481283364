<template>
  <div class="container">
    <div class="row">
      <div v-if="locale === 'dk'">
        <div class="page-title">Vilkår og Betingelser</div>
        <div class="page-title">"Braun Penge tilbage Garanti"</div>
        <br />
        <div class="text-center mb-5">
          <span style="font-style: italic"
            >Prøv vores produkt og få dine penge tilbage</span
          >
          <br />
          <hr />
          <br />
          <span style="font-style: italic"
            >Kan du ikke lide det produkt, du har købt? – Intet problem, tilmeld
            dig, returner produktet og få dine penge tilbage!</span
          >
        </div>
        <b>Kampagnen på et blik:</b>
        <div class="box-notice">
          <ul>
            <li>
              Køb Braun-produkter, der deltager i den deltagende periode (mellem
              <b>01.07.2022 og 30.06.2025</b>) hos en forhandler.
            </li>
            <li>
              Registrer dig på salgsfremmende webstedet, upload købsbeviset for
              salgsfremmende produktet og indsend registreringsformularen. Efter
              kontrol vil du modtage en e-mail med oplysninger om returnering af
              produktet. Når Braun produktet er modtaget sammen med alle
              nødvendige dokumenter, får du pengene tilbage for det returnerede
              produkt via bankoverførsel. Behold venligst det originale
              købsbevis!
            </li>
            <li>
              Deltagelse i kampagnen er kun mulig i eget navn og per person med
              <b>ét</b> købsbevis og <b>ét</b>
              deltagende product.
            </li>
            <li>
              Vigtigt: Du skal registrere din kvittering og dit produkt via
              vores websted
              <a
                href="https://www.braun-moneyback.com/dk"
                target="_blank"
                rel="noopener noreferrer"
                ><u>www.braun-moneyback.com</u></a
              >
              inden for den pågældende periode på
              <b>60 eller 120 dage efter købet</b>. Så snart din
              online-deltagelse er bekræftet, har du <b>10 dage</b> til at
              udskrive returformularen og sende den sammen med dit produkt til
              et af de P&G-servicecentre, der er anført i disse vilkår og
              betingelser.
            </li>
            <li>
              Fristen for indsendelse af den online registreringsformular og
              upload af købsbeviset er <b>01.11.2025</b>. Vores supportafdeling
              er tilgængelig indtil <b>15.12.2025</b>.
            </li>
          </ul>
        </div>
        <br />
        <div>
          Tak for din interesse i vores Braun Penge tilbage Garanti. Selv om vi
          har forsøgt at give dig alt det vigtigste på et øjeblik: Det er lidt
          mere kompliceret. Du finder derfor også det "småt skrevet" i disse
          vilkår og betingelser.
        </div>
        <ul ref="list" class="list">
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">Hvem er involveret i kampagnen?</div>
                <div class="list-item-details">
                  Kampagnen er organiseret for deltagere bosiddende i Norge,
                  Danmark, Sverige og Finland af Procter & Gamble International
                  Operations SA, Route de Saint-Georges 47, 1213 PETIT-LANCY,
                  Geneve.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">Hvad handler kampagnen om?</div>
                <div class="list-item-details">
                  Kampagnen giver dig mulighed for at afprøve og returnere visse
                  Braun-produkter inden for 60 eller 120 dage. Følgende
                  produkter ("<b>Salgsfremmende produkter</b>") deltager i denne
                  kampagne:
                  <br />
                  <br />
                  prøveperiode på højst <b>60 dage</b> efter købet:
                  <br />
                  <br/>Braun Silk-épil 9
                  <br/>Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX, BT9XXX)
                  <br/>Braun Series 7
                  <br/>Braun Series 8
                  <br/>Braun Series 9
                  <br/>King C Gillette Stylemaster & Stylemaster eCom
                  <br />
                  <br />
                  prøveperiode på højst <b>120 dage</b> efter købet:
                  <br />Braun Skin i·Expert Pro 7
                  <br />Braun Silk-expert Pro 5
                  <br />Braun Silk-expert Pro 3
                  <br /><br />
                  <b>BEMÆRK</b>: Du skal registrere din kvittering og dit
                  produkt via vores websted
                  <a href="https://www.braun-moneyback.com/dk" target="_blank"
                    >www.braun-moneyback.com</a
                  >
                  inden for den pågældende periode. Efter bekræftelse af online-
                  registreringen har du <b>10 dage</b> til at sende dit produkt
                  sammen med den returformular, der er vedhæftet din
                  bekræftelsesmail, til et af P&G Service Centrene, der er
                  anført nedenfor.
                  <br />
                  <br />
                  Tilbagebetalingen svarer til den faktisk betalte købspris.
                  <br />
                  <br />
                  Kampagnen vedrører
                  <ul>
                    <li>
                      til køb af salgsfremmende produkter mellem
                      <b>01.07.2022</b> og <b>30.06.2025</b>
                      ("<b>Kampagneperiode</b>"), datoen for købsbeviset er
                      relevant;
                    </li>
                    <li>
                      til køb - herunder onlinekøb - hos en detailhandler
                      ("<b>Kampagneforhandlere</b>");
                    </li>
                    <li>
                      udtrykkeligt ikke på "private køb" af salgsfremmende
                      produkter, herunder online / auktionsplatforme.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">Hvem kan deltage i kampagnen?</div>
                <div class="list-item-details">
                  Du kan deltage i kampagnen, hvis du
                  <br />
                  <ul>
                    <li>
                      er slutbruger, er over 18 år og bosiddende i Norge,
                      Danmark, Sverige eller Finland,
                    </li>
                    <li>
                      har en bankkonto med et IBAN tildelt Danmark, Finland, Norge eller Sverige,
                    </li>
                    <li>have en e-mail-adresse og</li>
                    <li>
                      kan få adgang til kampagnens websted på
                      <a
                        href="https://www.braun-moneyback.com/dk"
                        target="_blank"
                        >www.braun-moneyback.com</a
                      >.
                    </li>
                  </ul>
                  <br />
                  Hvert IBAN og hver e-mail-adresse kan kun deltage i kampagnen
                  én gang.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">Hvordan kan du deltage i kampagnen?</div>
                <div class="list-item-details">
                  For at deltage i kampagnen skal du
                  <br />
                  <ul>
                    <li>
                      have købt et deltagende produkt i detailhandlen i
                      kampagneperioden samt være i besiddelse af det købte
                      deltagende produkt og købsbeviset,
                    </li>
                    <li>
                      åbne online-formularen
                      <a
                        href="https://www.braun-moneyback.com/dk"
                        target="_blank"
                        >www.braun-moneyback.com</a
                      >,
                    </li>
                    <li>
                      oplyse stregkodenummeret på salgsfremmende produktet eller
                      uploade et foto af stregkoden på dit salgsfremmende
                      produkt,
                    </li>
                    <li>uploade et foto af dit købsbevis,</li>
                    <li>uploade et foto af produktsiden med stregkoden,</li>
                    <li>
                      oplyse dine kontooplysninger (IBAN, BIC, for- og efternavn
                      på kontohaveren),
                    </li>
                    <li>
                      registrere dig ved at oplyse din e-mail-adresse og andre
                      personlige oplysninger og
                    </li>
                    <li>
                      gennemføre de trin til verifikation og forebyggelse af
                      misbrug/svindel, der er vist på kampagnens websted (se
                      nedenfor). Ved at klikke på knappen for at fuldføre din
                      deltagelse accepterer du også disse vilkår og betingelser.
                    </li>
                  </ul>
                  <br />
                  Den vellykkede afslutning af online-deltagelsesprocessen og
                  referencenummeret for din deltagelse vil blive vist på
                  kampagnens websted.
                  <br />
                  <br />
                  Derefter skal du sende produktet sammen med den tilsvarende
                  kupon til servicecentret inden for <b>10 dage</b>. Du vil
                  modtage din refusion inden for 6 uger efter, at pakken er
                  ankommet og bekræftet i servicecentret.
                  <br />
                  <br />
                  Online-deltagelsesprocessen skal være afsluttet inden for den
                  testperiode, der er angivet for hver produktkategori.
                  Deltagelsesprocessen kan være afsluttet senest den
                  <b>01.11.2025 ("Afslutningsdato")</b>.
                  <br />
                  <br />
                  Kun registrerede pakker med gyldigt trackingnummer kan
                  accepteres. Portoudgifterne refunderes ikke.
                  <br />
                  <br />
                  <b>P&G Servicecentrets</b> adresser i dit land er:
                  <br />
                  <br />
                  <b>Norge</b>
                  <br />
                  L.G. Elektro A/S
                  <br />
                  Kolstien 4
                  <br />
                  5097 Bergen
                  <br />
                  Norge
                  <br />
                  <br />
                  <b>Sverige</b>
                  <br />
                  El & Digital Service AB
                  <br />
                  Folkungagatan 144
                  <br />
                  116 30 Stockholm
                  <br />
                  Sverige
                  <br />
                  <br />
                  <b>Finland</b>
                  <br />
                  AIDE Europe Oy
                  <br />
                  Puusepänkatu 2D
                  <br />
                  00880 Helsinki
                  <br />
                  Suomi
                  <br />
                  <br />
                  <b>Danmark</b>
                  <br />
                  CJ Hvidevareservice
                  <br />
                  Thorndahlsvej 11
                  <br />
                  9200 Aalborg SV
                  <br />
                  Danmark
                  <br />
                  <br />
                  Vær opmærksom på følgende med hensyn til stregkoden på det
                  kampagneprodukt, der kræves for at deltage, og dit købsbevis:
                  <br />
                  <ul>
                    <li>
                      Sørg for, at stregkoden (dvs. den sædvanlige stregkode
                      bestående af sorte streger med hvide mellemrum) er
                      tydeligt synlige på de tilsvarende produktfotos.
                    </li>
                    <li>
                      Som købsbevis i henhold til disse vilkår og betingelser er
                      kun kvitteringen eller fakturaen fra den forhandler, der
                      har gennemført kampagnen, gyldig. Ordrebekræftelser,
                      følgesedler eller lignende dokumenter er ikke
                      tilstrækkelige.
                    </li>
                    <li>
                      På fotoet af købsbeviset skal det tydeligt fremgå, hvem
                      forhandleren er, kvitteringens ID (ikke bankens
                      transaktions-ID), dato og klokkeslæt for kvitteringen,
                      salgsfremmende produktet, salgsfremmende produktets pris
                      og det samlede beløb på kvitteringen, den angivne moms og
                      eventuelle rabatter.
                    </li>
                    <li>
                      Fotoet af købsbeviset må ikke være overbelyst, sløret
                      eller for mørkt. Scannede kvitteringer er ikke
                      tilstrækkelige for deltagelse, der kræves detaljerede
                      fotos.
                    </li>
                    <li>
                      Kun købsbeviset må vises på fotoet af købsbeviset; det
                      købte salgsfremmende produkt må ikke fotograferes sammen
                      med købsbeviset.
                    </li>
                  </ul>
                  <br />
                  Når deltagelsesprocessen er afsluttet med succes - dvs. når
                  alle de oplysninger, du har givet eller uploadet, er modtaget
                  - samt referencenummeret for din deltagelse vil blive vist på
                  kampagnens websted; du vil også modtage en e-mail til den
                  e-mailadresse, du har oplyst.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">Hvad er der ellers at overveje?</div>
                <div class="list-item-details">
                  <u>Testkampagne:</u> Denne kampagne giver dig mulighed for at
                  teste Braun salgsfremmende produkterne. På denne baggrund (og
                  på baggrund af erfaringer med lignende aktioner) er vi nødt
                  til at definere nogle spilleregler med hensyn til kampagnens
                  mål:
                  <ul>
                    <li>
                      Deltagelse i kampagnen er kun mulig i eget navn og pr.
                      person med ét købsbevis og ét salgsfremmende produkt.
                    </li>
                    <li>
                      Hvert købsbevis kan kun bruges én gang til at deltage i
                      kampagnen; dette gælder også, hvis der er anført mere end
                      ét salgsfremmende produkt på et købsbevis.
                    </li>
                    <li>
                      Hvis du indløser en rabatkupon til salgsfremmende
                      produktet på samme tid, vil du kun modtage en refusion for
                      det faktisk betalte beløb.
                    </li>
                    <li>
                      Et produkt, der refunderes i denne kampagne, kan ikke
                      bruges i en anden kampagne fra Procter & Gamble, og et
                      produkt, der allerede har deltaget i en anden kampagne,
                      kan ikke refunderes i denne kampagne.
                    </li>
                    <li>
                      Desuden kan hvert IBAN og hver e-mail-adresse kun deltage
                      i kampagnen én gang.
                    </li>
                    <li>
                      Betalinger kan foretages til en konto, der er tildelt et deltagende land og kun i landets
officielle valuta (DKK for deltagere fra Danmark, EUR for deltagere fra Finland, NOK for
deltagere fra Norge og SEK for deltagere fra Sverige). Bankkonti fra andre lande og/eller i
en anden valuta kan ikke accepteres til deltagelse.
                    </li>
                    <li>
                      Der påtages intet ansvar for forkert angivelse af
                      IBAN-nummeret. Der er en engangs mulighed for at ændre et
                      ugyldigt IBAN-nummer, hvorefter deltagelsen bliver
                      ugyldig. Hvis du vil ændre dine bankoplysninger, kan du
                      kontakte vores supporttjeneste, som udelukkende er
                      oprettet til IBAN-ændringer (via e-mail:
                      <a href="mailto:support@braun-moneyback.com"
                        ><u>support@braun-moneyback.com</u></a
                      >
                      ) indtil 15.12.2025. (Hvis du har spørgsmål, der ikke
                      vedrører IBAN-ændringen, bedes du kontakte os ved hjælp af
                      de kontaktmuligheder, der er angivet i afsnit I).
                    </li>
                    <li>
                      Ufuldstændige, ukorrekte eller for sent indsendte
                      ansøgninger accepteres ikke. Afsenderbevis vil ikke blive
                      godkendt som bevis for modtagelse. Vi er ikke ansvarlige
                      for formularer og/eller deres indhold, der er forsvundet
                      eller beskadiget eller forsinket.
                    </li>
                    <li>
                      I tilfælde af forkert indsendt pakkeindhold har
                      forbrugeren en engangs mulighed for at sende de nødvendige
                      dokumenter på ny. Hvis der ved det andet forsøg mangler
                      noget indhold eller er forkert igen, bliver deltagelsen
                      ugyldig.
                    </li>
                  </ul>
                  <br />
                  <u>Forebyggelse af misbrug og svig:</u> Vores hidtidige
                  erfaringer viser desværre, at sådanne kampagner desværre
                  jævnligt fører til forsøg på misbrug og svig, nogle gange i
                  betydeligt omfang. Derfor skal vi også i denne kampagne træffe
                  forskellige foranstaltninger for at forhindre og opdage
                  sådanne forsøg. Vi har forståelse for, at dette kan medføre
                  ekstra arbejde for Dem. Vi har dog forsøgt at gøre vores
                  nødvendige foranstaltninger så ukomplicerede som muligt for
                  dig som deltager:
                  <ul>
                    <li>
                      Ved hjælp af den kontrol, der er forklaret ovenfor i
                      afsnit D som en del af deltagelsesprocessen, kontrollerer
                      vi, om de oplysninger, som en deltager har givet (IBAN og
                      e-mailadresse), allerede er blevet brugt til deltagelse i
                      kampagnen; i så fald er yderligere deltagelse ikke længere
                      mulig.
                    </li>
                    <li>
                      Under den videre behandling vil deltageroplysningerne også
                      blive kontrolleret, navnlig de stregkoder og købsbeviser,
                      som deltagerne har uploadet.
                    </li>
                  </ul>
                  <br />

                  <u>Opbevaring af købsbeviset:</u> Bemærk venligst, at du skal
                  opbevare
                  <u
                    >det originale købsbevis, der blev brugt til deltagelse,
                    indtil du har modtaget tilbagebetalingsbeløbet.</u
                  >
                  I enkelte tilfælde skal du sende det med posten til kontrol,
                  før der kan udstedes en tilbagebetaling. I dette tilfælde skal
                  du sende det originale købsbevis i en kuvert med tilstrækkelig
                  porto til den angivne adresse. Ufrankerede varer accepteres
                  ikke, og det originale købsbevis kan ikke returneres, efter at
                  det er blevet kontrolleret (lav om nødvendigt en kopi til dit
                  eget brug); intet ansvar for tab med posten.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">Hvad sker der efter din deltagelse?</div>
                <div class="list-item-details">
                  I denne forbindelse
                  <ul>
                    <li>
                      Der vil blive foretaget en kontrol for at forhindre
                      misbrug og svindel. I den forbindelse kan det originale
                      købsbevis i enkelte tilfælde blive krævet af dig;
                    </li>
                    <li>
                      Der vil blive sendt en e-mail for at bekræfte dit
                      pengekrav til den e-mailadresse, du har oplyst; dette sker
                      normalt inden for to arbejdsdage efter modtagelsen af
                      oplysningerne;
                    </li>
                    <li>
                      P&G Service Center bekræfter pakken med det deltagende
                      produkt;
                    </li>
                    <li>
                      Tilbagebetalingsbeløbet vil blive udbetalt til den konto,
                      som du har angivet via IBAN; dette sker normalt inden for
                      seks uger efter eksamen er afsluttet.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      Justsnap giver P&G de væsentlige deltagelsesdata (for- og
                      efternavn, adresse, e- mailadresse, købsbevisdata og
                      digital kopi af købsbeviset, køn og fødselsdato);
                    </li>
                    <li>
                      Justsnap foretager kontrollen for at forhindre misbrug og
                      svindel (nærmere oplysninger herom i afsnit G). I denne
                      forbindelse kan Justsnap også anmode om det originale
                      købsbevis fra dig i individuelle tilfælde;
                    </li>
                    <li>
                      Justsnap sender dig en e-mail med en bekræftelse af dit
                      pengekrav til den e-mailadresse, du har angivet; dette
                      sker normalt inden for to arbejdsdage efter modtagelsen af
                      oplysningerne;
                    </li>
                    <li>
                      P&G Service Center bekræfter pakken med det deltagende
                      produkt;
                    </li>
                    <li>
                      Justsnap udbetaler cashback-beløbet til den konto, som du
                      har angivet via IBAN; dette sker normalt inden for seks
                      uger efter, at eksamen er gennemført.
                    </li>
                  </ul>
                  <br />
                  Vi forbeholder os retten til at udelukke enhver deltager fra
                  kampagnen, hvis der er mistanke om, at der er afgivet falske
                  oplysninger ved deltagelsen, at der er forsøgt at manipulere
                  kampagnen, eller at disse vilkår og betingelser er blevet
                  overtrådt på anden vis. I dette tilfælde vil der ikke blive
                  sendt nogen bekræftelsesmail til dig. Du kan bevise over for
                  os, at der ikke var nogen overtrædelse af vilkår og
                  betingelser i dit tilfælde.
                  <br />
                  <br />
                  Vi forbeholder os også retten til at ændre eller tilpasse
                  disse vilkår og betingelser og til at afbryde, forlænge eller
                  afslutte kampagnen til enhver tid uden forudgående varsel.
                  <br />
                  <br />
                  For deltagere fra Norge gælder norsk lov, for deltagere fra
                  Sverige gælder svensk lov, for deltagere fra Finland gælder
                  finsk lov og for deltagere fra Danmark gælder dansk lov.
                  <br />
                  Dommernes afgørelse er endelig.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvem kan jeg kontakte, hvis jeg har spørgsmål om kampagnen?
                </div>
                <div class="list-item-details">
                  Hvis du har spørgsmål om kampagnen, er den hurtigste og eneste
                  måde at kontakte os på via
                  <a href="mailto:contact@braun-moneyback.com"
                    ><u>contact@braun-moneyback.com</u></a
                  >. Denne mulighed eksisterer indtil <b>15.12.2025</b>.
                  <br />
                  <br />
                  Du kan også kontakte Procter & Gamble International Operations
                  SA pr. e-mail
                  <a href="mailto:contactCH.im@pg.com"
                    ><u>contactCH.im@pg.com</u></a
                  >
                  og pr. post (Route de Saint-Georges 47 1213 PETIT-LANCY,
                  Geneve).
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="locale === 'fi'">
        <div class="page-title">Ehdot ja edellytykset</div>
        <div class="page-title">“Braun Moneyback -takuu”</div>
        <br />
        <div class="text-center mb-5">
          <span style="font-style: italic"
            >Kokeile tuotettamme ja saat rahasi takaisin</span
          >
          <br />
          <hr />
          <br />
          <span style="font-style: italic"
            >Etkö pidä ostamastasi tuotteesta? - Ei hätää, rekisteröidy, palauta
            tuote ja saat rahasi takaisin!</span
          >
        </div>
        <b>Kampanja yhdellä silmäyksellä:</b>
        <div class="box-notice">
          <ul>
            <li>
              Osta Braun:n osallistuvia tuotteita osallistumisjakson aikana (<b
                >01.07.2022 - 30.06.2025</b
              >) jälleenmyyjältä.
            </li>
            <li>
              Rekisteröidy myynninedistämissivustolla, lataa ostotodistus
              myynninedistämistuotteesta ja lähetä rekisteröintilomake.
              Tarkistuksen jälkeen saat sähköpostiviestin, jossa on tuotteen
              palautuksen yksityiskohdat. Kun tuote on vastaanotettu yhdessä
              kaikkien tarvittavien asiakirjojen kanssa, saat palautetun
              tuotteen hinnan takaisin pankkisiirrolla. Säilytä alkuperäinen
              ostotodistus!
            </li>
            <li>
              Kampanjaan voi osallistua vain omilla nimilläsi ja henkilöä kohden
              yhdellä ostotositilla ja yhdellä osallistuvalla tuotteella.
            </li>
            <li>
              <b>Tärkeää</b>: Sinun on rekisteröitävä kuittisi ja tuotteesi
              verkkosivustollamme
              <a href="https://www.braun-moneyback.com/fi" target="_blank"
                >www.braun-moneyback.com</a
              >
              kautta <b>60 tai 120 päivän</b> kuluessa ostosta. Heti kun
              osallistumisesi verkossa on vahvistettu, sinulla on
              <b>10 päivää</b> aikaa tulostaa palautuslomake ja lähettää se
              yhdessä tuotteen kanssa johonkin näissä ehdot ja edellytykset
              luetelluista P&G:n palvelukeskuksista.
            </li>
            <li>
              Määräaika sähköisen rekisteröintilomakkeen lähettämiselle ja
              ostokuitin lataamiselle on <b>01.11.2025</b>. Tukiosastomme on
              käytettävissä <b>15.12.2025</b> asti.
            </li>
          </ul>
        </div>
        <br />
        <div>
          Kiitos kiinnostuksestasi Braun Moneyback -takuu. Vaikka yritimme antaa
          sinulle kaiken olennaisen yhdellä silmäyksellä: Se on hieman
          monimutkaisempaa. Siksi löydät myös "pienellä painettua tekstiä"
          näissä ehdot ja edellytykset.
        </div>
        <ul ref="list" class="list">
          <li class="list-item-wrapper">
            <div class="number">Ketkä osallistuvat kampanjaan?</div>
            <div class="list-item-details">
              Kampanjan järjestää Norjassa, Tanskassa, Ruotsissa ja Suomessa
              asuville osallistujille Procter & Gamble International Operations
              SA, Route de Saint-Georges 47, 1213 PETIT-LANCY, Geneve.
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Mistä kampanjassa on kyse?</div>
            <div class="list-item-details">
              <p>
                Kampanja antaa sinulle mahdollisuuden kokeilla ja palauttaa
                tietyt Braun-tuotteet 60 tai 120 päivän kuluessa. Seuraavat
                tuotteet ("<strong>Myynninedistämistuotteet</strong>")
                osallistuvat tähän kampanjaan:<br /><br />kokeiluaika enintään
                <b>60 päivää</b> oston jälkee:<br /><br/>Braun Silk-épil 9
                <br/>Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX, BT9XXX)
                <br/>Braun Series 7
                <br/>Braun Series 8
                <br/>Braun Series 9
                <br/>King C Gillette Stylemaster & Stylemaster eCom
                <br /><br />kokeiluaika enintään <b>120 päivää</b> oston
                jälkee:<br />
                <br />Braun Skin i·Expert Pro 7
                <br />Braun Silk-expert Pro 5
                <br />Braun Silk-expert Pro 3
                <br /><br /><strong
                  >HUOMAUTUS</strong
                >: Sinun on rekisteröitävä kuittisi ja tuotteesi
                verkkosivustollamme
                <a href="https://www.braun-moneyback.com/fi" target="_blank"
                  >www.braun-moneyback.com</a
                >
                vastaavan ajan kuluessa. Kun olet saanut vahvistuksen
                online-rekisteröinnistä, sinulla on
                <strong>10 päivää</strong> aikaa lähettää tuotteesi yhdessä
                vahvistussähköpostisi liitteenä olevan palautuslomakkeen kanssa
                johonkin alla luetelluista P&amp;G:n palvelukeskuksista.
              </p>
              <p>Palautus vastaa tosiasiallisesti maksettua ostohintaa.</p>
              <p>Kampanja koskee</p>
              <ul>
                <li>
                  kampanjatuotteiden ostoihin <strong>01.07.2022</strong> ja
                  <strong>30.06.2025</strong> välisenä aikana.
                  ("<strong>kampanja-aika</strong>"), ostotodistuksen päivämäärä
                  on merkityksellinen;<br /><br />
                </li>
                <li>
                  ostoksiin - myös verkko-ostoksiin - vähittäismyyjältä
                  ("<strong>kampanjan jälleenmyyjät</strong>");<br /><br />
                </li>
                <li>
                  ei nimenomaisesti koske kampanjatuotteiden "yksityisostoja",
                  mukaan lukien verkossa/ huutokauppapaikat.<br /><br />
                </li>
              </ul>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Kuka voi osallistua kampanjaan?</div>
            <div class="list-item-details">
              <p>Voit osallistua kampanjaan, jos</p>
              <ul>
                <li>
                  olet loppukäyttäjä ja yli 18-vuotias ja asut Norjassa,
                  Tanskassa, Ruotsissa tai Suomessa,
                </li>
                <li>
                  sinulla on Tanskaan, Suomeen, Norjaan tai Ruotsiin määritetty pankkitili IBAN-numerolla,
                </li>
                <li>sinulla on oltava sähköpostiosoite ja</li>
                <li>
                  sinulla on pääsy kampanjan verkkosivustolle osoitteessa
                  <a href="https://www.braun-moneyback.com/fi"
                    >www.braun-moneyback.com</a
                  >.
                </li>
              </ul>
              <p>
                Kukin IBAN-tilinumero ja sähköpostiosoite voi osallistua
                kampanjaan vain kerran.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Miten voit osallistua kampanjaan?</div>
            <div class="list-item-details">
              <p>
                Osallistuaksesi kampanjaan sinun on täytettävä seuraavat
                vaatimukset
              </p>
              <ul>
                <li>
                  ostanut osallistuvan tuotteen vähittäiskaupasta
                  kampanja-aikana sekä omistettava ostettu osallistuva tuote ja
                  ostotodistus,
                </li>
                <li>
                  avaa verkkolomake
                  <a href="https://www.braun-moneyback.com/fi"
                    >www.braun-moneyback.com</a
                  >,
                </li>
                <li>
                  Ilmoita kampanjatuotteen viivakoodin numero tai lataa kuva
                  kampanjatuotteen viivakoodista,
                </li>
                <li>lataa valokuva ostotodistuksesta,</li>
                <li>lataa valokuva tuotteen kyljestä, jossa on viivakoodi,</li>
                <li>
                  anna tilitietosi (IBAN, BIC, tilinomistajan etu- ja sukunimi),
                </li>
                <li>
                  rekisteröidy antamalla sähköpostiosoitteesi ja muut
                  henkilökohtaiset tietosi sekä
                </li>
                <li>
                  täytä kampanjasivustolla (ks. jäljempänä) esitetyt todentamis-
                  ja väärinkäytösten/petosten estämisvaiheet. Klikkaamalla
                  painiketta osallistumisen loppuunsaattamiseksi hyväksyt myös
                  nämä ehdot ja edellytykset.<br /><br />
                </li>
              </ul>
              <p>
                Online-osallistumisprosessin onnistunut loppuunsaattaminen ja
                osallistumisesi viitenumero näytetään kampanjasivustolla.<br /><br />Tämän
                jälkeen sinun on lähetettävä tuote yhdessä vastaavan kupongin
                kanssa palvelukeskukseen <strong>10 päivän</strong> kuluessa.
                Saat hyvityksesi 6 viikon kuluessa siitä, kun paketti on
                saapunut ja vahvistettu P&amp;G palvelukeskuksessa.
              </p>
              <p>
                Sähköinen online-osallistumisprosessi on saatettava päätökseen
                kullekin tuoteluokalle määritellyn testausajan kuluessa.
                Osallistumisprosessi voidaan saattaa
                <b>01.11.2025</b> ("<strong>Päättymispäivä</strong>") mennessä.
              </p>
              <p>
                Vain rekisteröityjä paketteja, joissa on voimassa oleva
                seurantanumero, voidaan hyväksyä. Postikuluja ei palauteta.
              </p>
              <br />
              <p>
                <strong>P&amp;G Palvelukeskuksen</strong> osoitteet maassasi
                ovat:
              </p>
              <br />
              <p><strong>Norja</strong></p>
              <p>L.G. Elektro A/S</p>
              <p>Kolstien 4</p>
              <p>5097 Bergen</p>
              <p>Norge</p>
              <p></p>
              <br/>
              <p><strong>Ruotsi</strong></p>
              <p>
                El &amp; Digital Service AB<br />Folkungagatan 144<br />116 30
                Stockholm<br />Sverige
              </p>
              <p></p>
              <br/>
              <p><strong>Suomi</strong></p>
              <p>AIDE Europe Oy</p>
              <p>Puusepänkatu 2D</p>
              <p>00880 Helsinki</p>
              <p>Suomi</p>
              <br/>
              <p>
                <strong>Tanska</strong><strong><br /></strong>CJ
                Hvidevareservice<br />Thorndahlsvej 11<br />9200 Aalborg SV<br />Danmark
              </p>
              <br />
              <p>
                Huomioi seuraavat tiedot osallistumiseen vaadittavan
                kampanjatuotteen viivakoodin ja ostotodistuksen osalta:
              </p>
              <ul>
                <li>
                  Varmista, että viivakoodi (eli tavallinen mustista viivoista
                  ja valkoisista väleistä koostuva viivakoodi) näkyvät selvästi
                  vastaavissa tuotekuvissa.
                </li>
                <li>
                  Näissä ehdot ja edellytykset tarkoitettuna ostotodistuksena
                  kelpaa ainoastaan kampanjaan osallistuvan jälleenmyyjän kuitti
                  tai lasku. Tilausvahvistukset, toimituskirjat tai vastaavat
                  asiakirjat eivät riitä.
                </li>
                <li>
                  Ostotodisteen kuvassa on oltava selvästi tunnistettavissa
                  jälleenmyyjän tiedot, kuitin tunnus (ei pankkitapahtuman
                  tunnus), kuitin päivämäärä ja kellonaika,
                  myynninedistämistuote, myynninedistämistuotteen hinta ja
                  kuitin kokonaissumma, ilmoitettu arvonlisävero ja mahdolliset
                  myönnetyt alennukset.
                </li>
                <li>
                  Ostotositteen kuva ei saa olla ylivalottunut, epätarkka tai
                  liian tumma. Skannatut kuitit eivät riitä osallistumiseen,
                  vaan niistä vaaditaan yksityiskohtaiset valokuvat.
                </li>
                <li>
                  Ostotodistuksen valokuvassa saa esittää vain ostotodistuksen;
                  ostettua kampanjatuotetta ei saa kuvata yhdessä
                  ostotodistuksen kanssa.
                </li>
              </ul>
              <p>
                Osallistumisprosessin onnistunut loppuunsaattaminen - eli
                kaikkien antamiesi tai lataamiesi tietojen vastaanottaminen -
                sekä osallistumisesi viitenumero näkyvät kampanjasivustolla;
                saat myös sähköpostiviestin antamaasi sähköpostiosoitteeseen.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Mitä muuta on otettava huomioon?</div>
            <div class="list-item-details">
              <p>
                <u>Testikampanja:</u> Tämän kampanjan avulla voit testata
                Braun-tuotteita. Tätä taustaa vasten (ja vastaavista toimista
                saatujen kokemusten perusteella) meidän on määriteltävä joitakin
                pelisääntöjä kampanjan tavoitteen osalta:
              </p>
              <ul>
                <li>
                  Kampanjaan osallistuminen on mahdollista vain omissa nimissäsi
                  ja henkilöä kohden yhdellä ostotodistuksella ja yhdellä
                  mainostuotteella.
                </li>
                <li>
                  Kutakin ostotodistusta voi käyttää vain kerran kampanjaan
                  osallistumiseen; tämä pätee myös, jos ostotodistuksessa on
                  useampi kuin yksi mainostuote.
                </li>
                <li>
                  Jos lunastat samalla kampanjatuotteen alennuskupongin, saat
                  hyvityksen vain todellisuudessa maksetusta summasta.
                </li>
                <li>
                  Tässä kampanjassa hyvitettävää tuotetta ei voi käyttää missään
                  muussa Procter &amp; Gamble -kampanjassa, eikä tuotetta, joka
                  on jo osallistunut toiseen kampanjaan, voi hyvittää tässä
                  kampanjassa.
                </li>
                <li>
                  Lisäksi kukin IBAN ja sähköpostiosoite voi osallistua
                  kampanjaan vain kerran.
                </li>
                <li>
                  Maksut voidaan suorittaa osallistujamaalle osoitetulle tilille ja vain maan virallisessa
valuutassa (DKK osallistujille Tanskasta, EUR osallistujille Suomesta, NOK osallitujille Norjasta
ja SEK osallistujille Ruotsista). Pankkitilejä muista maista ja/tai muussa valuutassa ei voida
hyväksyä osallistumiseen.
                </li>
                <li>
                  IBAN-numeron virheellisestä ilmoittamisesta ei oteta vastuuta.
                  Virheellisen IBAN-tilinumeron voi muuttaa kerran, minkä
                  jälkeen osallistuminen raukeaa. Jos haluat muuttaa
                  pankkitietojasi, voit ottaa yhteyttä tukipalveluumme, joka on
                  perustettu yksinomaan IBAN-muutoksia varten (sähköpostitse:
                  <a href="mailto:support@braun-moneyback.com"
                    >support@braun-moneyback.com</a
                  >) <strong>15.12.2025 </strong>asti. (Jos sinulla on
                  kysymyksiä, jotka eivät liity IBAN-tilinumeron muuttamiseen,
                  ota meihin yhteyttä kohdassa I annettujen yhteystietojen
                  avulla).
                </li>
                <li>
                  Puutteellisia, virheellisiä tai myöhästyneitä hakemuksia ei
                  voi palauttaa. Lähettäjätodistusta ei hyväksytä
                  vastaanottotodistukseksi. Emme ole vastuussa lomakkeista
                  ja/tai niiden sisällöstä, jotka ovat kadonneet,
                  vahingoittuneet tai viivästyneet.
                </li>
                <li>
                  Jos pakkauksen sisältö on lähetetty väärin, kuluttajalla on
                  kerran mahdollisuus lähettää tarvittavat asiakirjat uudelleen.
                  Jos toisella yrittämällä jokin sisältö puuttuu tai on jälleen
                  virheellinen, osallistuminen mitätöityy.
                </li>
              </ul>
              <p>
                <u>Väärinkäytösten ja petosten ehkäiseminen:</u> Tähänastisen
                kokemuksemme mukaan tällaiset kampanjat johtavat valitettavasti
                säännöllisesti väärinkäyttö- ja petosyrityksiin, joskus
                huomattavassa määrin. Tästä syystä meidän on myös tässä
                kampanjassa ryhdyttävä erilaisiin toimenpiteisiin tällaisten
                yritysten estämiseksi ja havaitsemiseksi. Ymmärrämme, että tämä
                saattaa aiheuttaa teille lisätyötä. Olemme kuitenkin pyrkineet
                tekemään tarvittavista toimistamme mahdollisimman mutkattomia
                sinulle osallistujana:
              </p>
              <ul>
                <li>
                  Tarkistamme edellä kohdassa D selostetun tarkistuksen avulla
                  osana osallistumisprosessia, onko osallistujan antamia tietoja
                  (IBAN ja sähköpostiosoite) jo käytetty kampanjaan
                  osallistumiseen; tässä tapauksessa osallistuminen ei ole enää
                  mahdollista.
                </li>
                <li>
                  Jatkokäsittelyn aikana tarkistetaan myös osallistujan tiedot,
                  erityisesti osallistujien lataamat viivakoodit ja
                  ostotodisteet.
                </li>
              </ul>
              <p>
                <u>Ostotodistuksen säilyttäminen:</u> Huomaa, että sinun on
                <u
                  >säilytettävä osallistumiseen käytetty alkuperäinen
                  ostotodistus siihen asti, kunnes palautus on saatu.</u
                >
                Yksittäistapauksissa sinun on lähetettävä se postitse
                tarkistettavaksi, ennen kuin palautus voidaan myöntää. Tässä
                tapauksessa lähetä alkuperäinen ostotodistus kirjekuoressa,
                jossa on riittävä postimaksu, annettuun osoitteeseen.
                Postimerkitsemättömiä lähetyksiä ei hyväksytä, eikä alkuperäistä
                ostotodistusta voida palauttaa sen tarkistamisen jälkeen (ota
                tarvittaessa kopio omiin tarpeisiisi); emme vastaa postitse
                tapahtuvasta katoamisesta.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Mitä tapahtuu osallistumisen jälkeen?</div>
            <div class="list-item-details">
              <p>Tässä yhteydessä</p>
              <ul>
                <li>
                  Tarkastus suoritetaan väärinkäytösten ja petosten estämiseksi.
                  Tässä yhteydessä sinulta voidaan yksittäistapauksissa pyytää
                  alkuperäinen ostotodistus;
                </li>
                <li>
                  Rahasaatavasi vahvistamiseksi lähetetään sähköposti antamaasi
                  sähköpostiosoitteeseen; tämä tapahtuu yleensä kahden työpäivän
                  kuluessa tietojen vastaanottamisesta;
                </li>
                <li>
                  P&G:n palvelukeskus vahvistaa paketin, jossa on osallistuva
                  tuote ja
                </li>
                <li>
                  Palautuksen määrä maksetaan IBAN-tilin kautta ilmoittamallesi
                  tilille; tämä tapahtuu yleensä kuuden viikon kuluessa kokeen
                  suorittamisesta.
                </li>
              </ul>
              <p>
                Pidätämme oikeuden sulkea minkä tahansa osallistujan pois
                kampanjasta, jos epäillään, että osallistumisen yhteydessä on
                annettu vääriä tietoja, kampanjaa on yritetty manipuloida tai
                näitä ehtoja on muutoin rikottu. Tässä tapauksessa sinulle ei
                lähetetä vahvistussähköpostia. Voit todistaa meille, että sinun
                tapauksessasi ei ole rikottu ehdot ja edellytykset.
              </p>
              <p>
                Pidätämme myös oikeuden muuttaa tai mukauttaa näitä käyttöehtoja
                ja keskeyttää, pidentää tai lopettaa kampanjan milloin tahansa
                ilman ennakkoilmoitusta.
              </p>
              <p>
                Norjasta tuleviin osallistujiin sovelletaan Norjan lakia,
                Ruotsista Ruotsin lakia, Suomesta Suomen lakia ja Tanskasta
                Tanskan lakia. Tuomareiden päätös on lopullinen.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              Keneen voin ottaa yhteyttä, jos minulla on kysyttävää kampanjasta?
            </div>
            <div class="list-item-details">
              <p>
                Jos sinulla on kysyttävää kampanjasta, nopein ja ainoa tapa
                ottaa meihin yhteyttä on
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >. Tämä mahdollisuus on olemassa
                <strong>15.12.2025 </strong>asti.
              </p>
              <p>
                Voit ottaa yhteyttä Procter &amp; Gamble International
                Operations SA:han myös sähköpostitse
                <a href="mailto:contactCH.im@pg.com">contactCH.im@pg.com</a> ja
                postitse (Route de Saint-Georges 47 1213 PETIT-LANCY, Geneve).
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="locale === 'no'">
        <div class="page-title">Vilkår og betingelser</div>
        <div class="page-title">“Braun Penge tilbage Garanti”</div>
        <br />
        <div class="text-center mb-5">
          <span style="font-style: italic"
            >Prøv produktet vårt og få pengene tilbake</span
          >
          <br />
          <hr />
          <br />
          <span style="font-style: italic"
            >Liker du ikke produktet du kjøpte? - Ikke noe problem, registrer
            deg, returner produktet og få pengene tilbake!</span
          >
        </div>
        <b>Kampanjen på et øyeblikk:</b>
        <div class="box-notice">
          <ul>
            <li>
              Kjøp Braun deltakende produkter i løpet av deltakerperioden
              (mellom <b>01.07.2022</b> og <b>30.06.2025</b>) hos en forhandler.
            </li>
            <li>
              Registrer deg på salgsfremmende nettstedet, last opp kjøpsbeviset
              for salgsfremmende produktet og send inn registreringsskjemaet.
              Etter å ha sjekket, vil du motta en e-post med detaljer for
              produktretur. Etter at produktet ble mottatt sammen med alle
              nødvendige dokumenter, vil du få refusjon for det returnerte
              produktet via bankoverføring. Ta vare på det opprinnelige
              kjøpsbeviset!
            </li>
            <li>
              Deltakelse i kampanjen er kun mulig i ditt eget navn og per person
              med ett kjøpsbevis og ett kampanjeprodukt.
            </li>
            <li>
              <b>Viktig</b>: Du må registrere kvitteringen og produktet via
              nettstedet vårt
              <a
                href="https://www.braun-moneyback.com/no"
                target="_blank"
                rel="noopener noreferrer"
                >www.braun-moneyback.com</a
              >
              innen tilsvarende periode på <b>60</b> eller
              <b>120 dager</b> etter kjøpet. Så snart din online deltakelse er
              bekreftet, vil du ha <b>10 dager</b> på deg til å skrive ut
              returskjemaet og sende det sammen med produktet ditt til et av
              P&G-servicesentrene som er oppført i disse vilkårene og
              betingelsene.
            </li>
            <li>
              Fristen for å sende inn det elektroniske registreringsskjemaet og
              laste opp kjøpskvitteringen er <b>01.11.2025</b>. Vår
              supportavdeling er tilgjengelig frem til <b>15.12.2025</b>.
            </li>
          </ul>
        </div>
        <br />
        <div>
          Takk for din interesse for vårt Braun Penge tilbage Garanti. Selv om
          vi prøvde å gi deg alt det viktigste på et øyeblikk: Det er litt mer
          komplisert. Du vil derfor også finne "liten skrift" i disse vilkårene
          og betingelsene.
        </div>
        <ul ref="list" class="list">
          <li class="list-item-wrapper">
            <div class="number">Hvem er involvert i kampanjen?</div>
            <div class="list-item-details">
              Kampanjen er organisert for deltakere bosatt i Norge, Danmark,
              Sverige og Finland av Procter &Gamble International Operations SA,
              Route de Saint-Georges 47, 1213 PETIT-LANCY, Geneve.
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Hva handler kampanjen om?</div>
            <div class="list-item-details">
              <p>
                Kampanjen gir deg muligheten til å prøve å returnere visse
                Braun-produkter innen 60 eller 120 dager. Følgende produkter
                ("<strong>Salgsfremmende produkter</strong>") deltar i denne
                kampanjen:<br /><br />prøveperiode på maksimalt
                <strong>60 dager</strong> etter kjøpet:
                <br /><br/>Braun Silk-épil 9
                <br/>Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX, BT9XXX)
                <br/>Braun Series 7
                <br/>Braun Series 8
                <br/>Braun Series 9
                <br/>King C Gillette Stylemaster & Stylemaster eCom
                <br />
                <br />
              </p>
              <p>
                prøveperiode på maksimalt <strong>120 dager</strong> etter
                kjøpet:<br />
                <br />Braun Skin i·Expert Pro 7
                <br />Braun Silk-expert Pro 5
                <br />Braun Silk-expert Pro 3
                <br /><br />
              </p>
              <p>
                <strong>MERK</strong>: Du må registrere kvitteringen og
                produktet via nettstedet vårt
                <a
                  href="https://www.braun-moneyback.com/no"
                  target="_blank"
                  rel="noopener noreferrer"
                  >www.braun-moneyback.com</a
                >
                innen tilsvarende periode. Etter bekreftelse av den elektroniske
                registreringen, vil du ha <strong>10 dager</strong> på deg til å
                sende produktet sammen med returskjemaet vedlagt
                bekreftelses-e-posten din til et av P&amp;G-servicesentrene som
                er oppført nedenfor.
              </p>
              <p>Refusjonen tilsvarer kjøpesummen som faktisk er betalt.</p>
              <p>Kampanjen er relatert til</p>
              <ul>
                <li>
                  til kjøp av kampanjeprodukter mellom
                  <strong>01.07.2022</strong> og
                  <strong>30.06.2025</strong>
                  („<strong>Kampanjeperiode</strong>“), er kjøpsdatoen
                  relevant;<br /><br />
                </li>
                <li>
                  til kjøp - inkludert online kjøp - fra en forhandler
                  („<strong>Kampanjeforhandlere</strong>“);<br /><br />
                </li>
                <li>
                  eksplisitt ikke til "private kjøp" av salgsfremmende
                  produkter, inkludert online / auksjon plattforme.<br /><br />
                </li>
              </ul>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Hvem kan delta i kampanjen?</div>
            <div class="list-item-details">
              <p>Du kan delta i kampanjen hvis du</p>
              <ul>
                <li>
                  er sluttbruker og over 18 år og bor i Norge, Danmark, Sverige
                  eller Finland,
                </li>
                <li>
                  har en bankkonto tilknyttet Danmark, Finland, Norge eller Sverige,
                </li>
                <li>har en e-postadresse og</li>
                <li>
                  kan få tilgang til kampanjenettstedet på
                  <a href="https://www.braun-moneyback.com/no"
                    >www.braun-moneyback.com</a
                  >
                </li>
              </ul>
              <p>
                Hver IBAN og e-postadresse kan bare delta i kampanjen én gang.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Hvordan kan du delta i kampanjen?</div>
            <div class="list-item-details">
              <p>For å delta i kampanjen må du</p>
              <ul>
                <li>
                  har kjøpt et deltakende produkt i detaljhandelen i løpet av
                  kampanjeperioden, samt besittet det kjøpte deltakende
                  produktet og kjøpsbeviset,
                </li>
                <li>
                  åpne det elektroniske skjemaet
                  <a href="https://www.braun-moneyback.com/no"
                    >www.braun-moneyback.com</a
                  >,
                </li>
                <li>
                  oppgi strekkodenummeret til salgsfremmende produktet eller
                  laste opp et bilde av strekkoden til salgsfremmende produktet
                  ditt,
                </li>
                <li>laste opp et bilde av kjøpsbeviset ditt,</li>
                <li>laste opp et bilde av produktsiden med strekkoden,</li>
                <li>
                  oppgi kontoinformasjonen din (IBAN, BIC, for- og etternavn til
                  kontoinnehaveren),
                </li>
                <li>
                  registrere deg ved å oppgi e-postadresse og annen personlig
                  informasjon og
                </li>
                <li>
                  fullfør trinnene for verifisering og misbruk / forebygging av
                  svindel som vises på kampanjenettstedet (se nedenfor). Ved å
                  klikke på knappen for å fullføre deltakelsen din, godtar du
                  også disse vilkårene og betingelsene.<br /><br />
                </li>
              </ul>
              <p>
                Den vellykkede fullføringen av den elektroniske
                deltakelsesprosessen og referansenummeret for deltakelsen din
                vises på kampanjenettstedet. <br /><br />Etter det må du sende
                produktet sammen med den tilsvarende kupongen til
                servicesenteret innen <strong>10 dager</strong>. Du vil motta
                refusjonen innen 6 uker etter at pakken ankommer og er bekreftet
                i P&amp;G servicesenteret.
              </p>
              <p>
                Den elektroniske deltakelsesprosessen må fullføres innen
                testperioden som er angitt for hver produktkategori.
                Deltakelsesprosessen kan fullføres av
                <b>01.11.2025</b> ("<strong>Avslutningsdato</strong>").
              </p>
              <p>
                Kun registrerede pakker med gyldigt trackingnummer kan
                accepteres. Portoudgifterne refunderes ikke.
              </p>
              <p>
                <strong>P&amp;G Servicesenter</strong> adresser i ditt land er:
              </p>
              <br/>
              <p><strong>Norge</strong></p>
              <p>L.G. Elektro A/S</p>
              <p>Kolstien 4</p>
              <p>5097 Bergen</p>
              <p>Norge</p>
              <p></p>
              <br/>
              <p><strong>Sverige</strong></p>
              <p>
                El &amp; Digital Service AB<br />Folkungagatan 144<br />116 30
                Stockholm<br />Sverige
              </p>
              <p></p>
              <br/>
              <p><strong>Finland</strong></p>
              <p>AIDE Europe Oy</p>
              <p>Puusepänkatu 2D</p>
              <p>00880 Helsinki</p>
              <p>Suomi</p>
              <br/>
              <p>
                <strong>Danmark</strong><strong><br /></strong>CJ
                Hvidevareservice<br />Thorndahlsvej 11<br />9200 Aalborg SV<br />Danmark
              </p>
              <br/>
              <p>
                Vær oppmerksom på følgende med hensyn til strekkoden til
                salgsfremmende produktet som kreves for deltakelse og
                kjøpsbevis:
              </p>
              <ul>
                <li>
                  Forsikre deg om at strekkoden (dvs. den vanlige strekkoden som
                  består av svarte stolper med hvite mellomrom) er tydelig
                  synlig på de tilsvarende produktbildene.
                </li>
                <li>
                  Som kjøpsbevis i betydningen av disse vilkår og betingelser,
                  er bare kvitteringen eller fakturaen fra kampanjeforhandleren
                  gyldig. Ordrebekreftelser, leveringsmerknader eller
                  sammenlignbare dokumenter er ikke tilstrekkelige.<br /><br />
                </li>
                <li>
                  På bildet av kjøpsbeviset må detaljene til forhandleren,
                  kvitterings-ID-en (ikke banktransaksjons-ID), dato og
                  klokkeslett for kvitteringen, kampanjeproduktet, prisen på
                  kampanjeproduktet og det totale beløpet for kvitteringen, den
                  oppgitte mva og eventuelle rabatter som gis være tydelig
                  gjenkjennelige.<br /><br />
                </li>
                <li>
                  Bildet av kjøpsbeviset må ikke være overeksponert, uskarpt
                  eller for mørkt. Skannede kvitteringer er ikke tilstrekkelig
                  for deltakelse, detaljerte bilder er nødvendig.<br /><br />
                </li>
                <li>
                  Bare kjøpsbeviset kan vises på bildet av kjøpsbeviset; det
                  kjøpte salgsfremmende produktet kan ikke fotograferes sammen
                  med kjøpsbeviset.
                </li>
              </ul>
              <p>
                Vellykket gjennomføring av deltakelsesprosessen - det vil si
                fordring av all informasjon du har oppgitt eller lastet opp -
                samt referansenummeret til deltakelsen din vil bli vist på
                kampanjenettstedet; Du vil da også motta en e-post til
                e-postadressen du oppga.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Hva annet er det å vurdere?</div>
            <div class="list-item-details">
              <p>
                <u>Testkampanje:</u> Denne kampanjen vil gjøre det mulig for deg
                å teste Braun-kampanjeproduktene. På denne bakgrunn (og basert
                på erfaring med sammenlignbare handlinger) må vi definere noen
                regler i spillet med hensyn til målet for kampanjen:
              </p>
              <ul>
                <li>
                  Deltakelse i kampanjen er bare mulig i ditt eget navn og per
                  person med ett kjøpsbevis og ett salgsfremmende produkt.
                </li>
                <li>
                  Hvert kjøpsbevis kan bare brukes én gang for å delta i
                  kampanjen. Dette gjelder også hvis mer enn ett salgsfremmende
                  produkt vises på et kjøpsbevis.
                </li>
                <li>
                  Hvis du løser inn en rabattkupong for salgsfremmende produktet
                  samtidig, vil du bare motta refusjon for beløpet som faktisk
                  er betalt.
                </li>
                <li>
                  Et produkt som refunderes i denne kampanjen, kan ikke brukes i
                  noen annen Procter &amp;Gamble-kampanje, og et produkt som
                  allerede har deltatt i en annen kampanje, kan ikke refunderes
                  for denne kampanjen.
                </li>
                <li>
                  I tillegg kan hver IBAN og e-postadresse bare delta i
                  kampanjen én gang.
                </li>
                <li>
                  Betalinger kan gjennomføres til kontoer tilknyttet et deltakende land, kun i den offisielle
valutaen (DKK for deltakere fra Danmark, EUR for deltakere fra Finland, NOK for deltakere
fra Norge og SEK for deltakere fra Sverige). Bankkontoer fra andre land og/eller i andre
valutaer vil ikke godkjennes for deltakelse.
                </li>
                <li>
                  Det pågår ikke noe ansvar for å feilaktig angi IBAN-nummeret.
                  Det er en engangsmulighet til å endre en ugyldig IBAN,
                  hvoretter deltakelsen blir ugyldig. For å endre
                  bankopplysningene dine, kan du kontakte vår supporttjeneste,
                  som utelukkende er satt opp for IBAN-endringer (via e-post:
                  <a href="mailto:support@braun-moneyback.com"
                    >support@braun-moneyback.com</a
                  >
                  ) til <strong>15.12.2025</strong>. (Hvis du har spørsmål som
                  ikke er relatert til IBAN-endringen, kan du kontakte oss ved
                  hjelp av kontaktalternativene gitt i avsnitt I.)
                </li>
                <li>
                  Ufuldstændige, ukorrekte eller for sent indsendte ansøgninger
                  accepteres ikke. Afsenderbevis vil ikke blive godkendt som
                  bevis for modtagelse. Vi er ikke ansvarlige for formularer
                  og/eller deres indhold, der er forsvundet eller beskadiget
                  eller forsinket.
                </li>
                <li>
                  Ved feil innsendt pakkeinnhold har en forbruker en
                  engangsmulighet til å sende de nødvendige dokumentene på nytt.
                  Hvis noe innhold mangler eller er feil ved andre forsøk igjen,
                  blir deltakelsen ugyldig.
                </li>
              </ul>
              <p>
                <u>Forebygging av misbruk og svindel:</u> Etter vår erfaring så
                langt fører kampanjer som disse dessverre regelmessig til forsøk
                på misbruk og svindel, noen ganger i betydelig grad. Av denne
                grunn må vi også ta ulike tiltak for å forhindre og oppdage
                slike forsøk i denne kampanjen. Vi forstår at dette kan føre til
                ytterligere arbeid for deg. Vi har imidlertid forsøkt å gjøre
                våre nødvendige tiltak så ukompliserte som mulig for deg som
                deltaker:
              </p>
              <ul>
                <li>
                  Gjennom verifikasjonen som er forklart ovenfor i del D som en
                  del av deltakelsesprosessen, sjekker vi om informasjonen gitt
                  av en deltaker (IBAN og e-postadresse) allerede er brukt til
                  deltakelse i kampanjen; I dette tilfellet er videre deltakelse
                  ikke lenger mulig.
                </li>
                <li>
                  Under videre behandling vil deltakerinformasjonen også bli
                  sjekket, spesielt strekkodene og kjøpsbeviset lastet opp av
                  deltakerne.
                </li>
              </ul>
              <p>
                <u>Oppbevaring av kjøpsbeviset</u>: Vær oppmerksom på at du må
                beholde
                <u
                  >det opprinnelige kjøpsbeviset som brukes til deltakelse til
                  refusjonsbeløpet er mottatt</u
                >. I enkelte tilfeller må du sende dette til en tilsvarende
                e-postforespørsel per post for gjennomgang før Justsnap kan
                utstede refusjon. I dette tilfellet må du sende det opprinnelige
                kjøpsbeviset i en konvolutt med tilstrekkelig porto til den
                oppgitte adressen. Ustemplede varer vil ikke bli akseptert, og
                det originale kjøpsbeviset kan ikke returneres etter at det er
                kontrollert (vennligst ta en kopi for dine egne formål om
                nødvendig); intet ansvar for tap per post.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Hva skjer etter din deltakelse?</div>
            <div class="list-item-details">
              <p>I denne sammenhengen</p>
              <ul>
                <li>
                  Det vil bli gjennomført en sjekk for å forhindre misbruk og
                  svindel. I denne sammenhengen kan du be om det opprinnelige
                  kjøpsbeviset fra deg i enkelttilfeller;
                </li>
                <li>
                  En e-post vil bli sendt for å bekrefte pengekravet ditt til
                  e-postadressen du oppga; dette gjøres vanligvis innen to
                  virkedager etter fordring av informasjonen;
                </li>
                <li>
                  P&G Servicesenter bekrefter pakken med deltakende produkt;
                </li>
                <li>
                  Tilbagebetalingsbeløbet vil blive udbetalt til den konto, som
                  du har angivet via IBAN; dette sker normalt inden for seks
                  uger efter eksamen er afsluttet.
                </li>
              </ul>
              <p>
                Vi forbeholder oss retten til å ekskludere enhver deltaker fra
                kampanjen hvis det er mistanke om at falsk informasjon ble gitt
                når vi deltok, det ble gjort forsøk på å manipulere kampanjen
                eller disse vilkårene og betingelsene ble brutt på annen måte. I
                dette tilfellet vil ingen bekreftelses-e-post bli sendt til deg.
                Du kan bevise for oss at det ikke var noe brudd på vilkårene i
                saken din.
              </p>
              <p>
                Vi forbeholder oss også retten til å endre eller tilpasse disse
                vilkårene og betingelsene og til å avbryte, utvide eller
                avslutte kampanjen når som helst uten forvarsel.
              </p>
              <p>
                For deltakere fra Norge gjelder norsk lov, for deltakere fra
                Sverige gjelder svensk lov, for deltakere fra Finland gjelder
                finsk lov og for deltakere fra Danmark gjelder dansk lov.
                Dommernes avgjørelse er endelig.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              Hvem kan jeg kontakte hvis jeg har spørsmål om kampanjen?
            </div>
            <div class="list-item-details">
              <p>
                Hvis du har spørsmål om kampanjen, er den raskeste og eneste
                måten å kontakte oss på via
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >. Denne muligheten finnes til <strong>15.12.2025.</strong>
              </p>
              <p>
                Du kan også kontakte Procter &amp;Gamble International
                Operations SA via e-post
                <a href="mailto:contactCH.im@pg.com">contactCH.im@pg.com</a> og
                per post (Route de Saint-Georges 47 1213 PETIT-LANCY, Geneve).
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="locale === 'se'">
        <div class="page-title">Villkor</div>
        <div class="page-title">“Braun pengarna tillbaka-garanti”</div>
        <br />
        <div class="text-center mb-5">
          <span style="font-style: italic"
            >Prova vår produkt och få pengarna tillbaka</span
          >
          <br />
          <hr />
          <br />
          <span style="font-style: italic"
            >Gillar du inte produkten du köpte? - Inga problem, registrera dig,
            returnera produkten och få pengarna tillbaka!</span
          >
        </div>
        <b>Kampanjen i en blick</b>
        <div class="box-notice">
          <ul>
            <li>
              Köp Braun-produkter som deltar under deltagarperioden (mellan
              <b>01.07.2022</b> och <b>30.06.2025</b>) hos en återförsäljare.
            </li>
            <li>
              Registrera dig på reklamwebbplatsen, ladda upp beviset på köpet av
              reklamprodukten och skicka in registreringsformuläret. Efter
              kontroll får du ett e-postmeddelande med uppgifter om återlämnande
              av produkten. När produkten har mottagits tillsammans med alla
              nödvändiga dokument får du pengarna tillbaka för den returnerade
              produkten via banköverföring. Behåll det ursprungliga
              inköpsbeviset!
            </li>
            <li>
              Deltagande i kampanjen är endast möjligt i ditt eget namn och per
              person med ett köpbevis och en deltagande produkt.
            </li>
            <li>
              <b>Viktigt</b>: Du måste registrera ditt kvitto och din produkt
              via vår webbplats
              <a href="https://www.braun-moneyback.com/se" target="_blank"
                >www.braun-moneyback.com</a
              >
              inom motsvarande period på <b>60 eller 120 dagar</b> efter köpet.
              Så snart ditt deltagande online har bekräftats har du
              <b>10 dagar</b> på dig att skriva ut returformuläret och skicka
              det tillsammans med din produkt till ett av de P&G Servicecenter
              som anges i dessa villkor.
            </li>
            <li>
              Tidsfristen för att skicka in den elektroniska
              registreringsblanketten och ladda upp köpekvittot är
              <b>01.11.2025</b>. Vår supportavdelning är tillgänglig fram till
              <b>15.12.2025</b>.
            </li>
          </ul>
        </div>
        <br />
        <div>
          Tack för ditt intresse för vår Braun pengarna tillbaka-garanti. Även
          om vi försökte ge dig allt väsentligt på en gång: Det är lite mer
          komplicerat. Därför hittar du också det "finstilta" i dessa villkor.
        </div>
        <ul ref="list" class="list">
          <li class="list-item-wrapper">
            <div class="number">Vem deltar i kampanjen?</div>
            <div class="list-item-details">
              Kampanjen organiseras för deltagare bosatta i Norge, Danmark,
              Sverige och Finland av Procter & Gamble International Operations
              SA, Route de Saint-Georges 47, 1213 PETIT-LANCY, Geneve.
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Vad handlar kampanjen om?</div>
            <div class="list-item-details">
              <p>
                Kampanjen ger dig möjlighet att prova och returnera vissa
                Braun-produkter inom 60 eller 120 dagar. Följande produkter
                ("<strong>reklamprodukter</strong>") deltar i denna kampanj:<br /><br />provperiod
                på högst <strong>60 dagar</strong> efter köpet:
              </p>
              <p><br/>Braun Silk-épil 9
                <br/>Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX, BT9XXX)
                <br/>Braun Series 7
                <br/>Braun Series 8
                <br/>Braun Series 9
                <br/>King C Gillette Stylemaster & Stylemaster eCom
                <br /><br />provperiod på högst <strong>120 dagar</strong> efter
                köpet:
              </p>
              <p>
                <br />Braun Skin i·Expert Pro 7
                <br />Braun Silk-expert Pro 5
                <br />Braun Silk-expert Pro 3
                <br /><br />
              </p>
              <p>
                <br /><strong>OBS</strong>: Du måste registrera ditt kvitto och
                din produkt via vår webbplats
                <a href="https://www.braun-moneyback.com/se" target="_blank"
                  >www.braun-moneyback.com</a
                >
                inom motsvarande period. Efter bekräftelse av
                online-registreringen har du <strong>10 dagar</strong> på dig
                att skicka din produkt tillsammans med den returblankett som
                bifogats i ditt bekräftelsemail till ett av P&amp;G:s
                servicecenter som anges nedan.
              </p>
              <p></p>
              <p>
                Återbetalningen motsvarar det faktiskt betalda inköpspriset.
              </p>
              <p>Kampanjen avser</p>
              <ul>
                <li>
                  - köp av reklamprodukter mellan
                  <strong>01.07.2022</strong> och
                  <strong>30.06.2025</strong>
                  (<strong>''kampanjperiod''</strong>), datumet för
                  inköpsbeviset är relevant;<br /><br />
                </li>
                <li>
                  köp - inklusive online-köp - från en återförsäljare (<strong
                    >''kampanjen återförsäljare''</strong
                  >);<br /><br />
                </li>
                <li>
                  uttryckligen inte på 'privata köp' av kampanjprodukter,
                  inklusive online / auktionsplattformar.<br /><br />
                </li>
              </ul>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Vem kan delta i kampanjen?</div>
            <div class="list-item-details">
              <p>Du kan delta i kampanjen om du</p>
              <ul>
                <li>
                  är en slutanvändare som är över 18 år och bosatt i Norge,
                  Danmark, Sverige eller Finland,
                </li>
                <li>
                  har ett bankkonto med ett IBAN-nummer som tilldelats Danmark, Finland, Norge eller
Sverige,
                </li>
                <li>ha en e-postadress och</li>
                <li>
                  har tillgång till kampanjens webbplats på
                  <a href="https://www.braun-moneyback.com/se"
                    >www.braun-moneyback.com</a
                  >.
                </li>
              </ul>
              <p>
                Varje IBAN och e-postadress kan endast delta i kampanjen en
                gång.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Hur kan du delta i kampanjen?</div>
            <div class="list-item-details">
              <p>För att delta i kampanjen måste du</p>
              <ul>
                <li>
                  ha köpt en deltagande produkt i detaljhandeln under
                  kampanjperioden samt inneha den köpta deltagande produkten och
                  inköpsbeviset,
                </li>
                <li>
                  öppna onlineformuläret
                  <a href="https://www.braun-moneyback.com/se"
                    >www.braun-moneyback.com</a
                  >,
                </li>
                <li>
                  ange reklamproduktens streckkod eller ladda upp ett foto av
                  reklamproduktens streckkod,
                </li>
                <li>ladda upp ett foto av ditt inköpsbevis,</li>
                <li>ladda upp ett foto av produktsidan med streckkod,</li>
                <li>
                  ange dina kontouppgifter (IBAN, BIC, kontoinnehavarens för-
                  och efternamn),
                </li>
                <li>
                  registrera dig genom att ange din e-postadress och andra
                  personuppgifter och
                </li>
                <li>
                  genomföra de steg för verifiering och förebyggande av
                  missbruk/bedrägeri som visas på kampanjens webbplats (se
                  nedan). Genom att klicka på knappen för att slutföra ditt
                  deltagande godkänner du också dessa villkor.<br /><br />
                </li>
              </ul>
              <p>
                Det framgångsrika slutförandet av online-deltagandeprocessen och
                referensnumret för ditt deltagande kommer att visas på
                kampanjwebbplatsen. <br /><br />Därefter måste du skicka
                produkten tillsammans med motsvarande kupong till servicecentret
                inom <strong>10 dagar</strong>. Du får din återbetalning inom 6
                veckor efter att paketet anländer och bekräftas i P&amp;G
                servicecentret.
              </p>
              <p>
                <br />Deltagandet online måste slutföras inom den testperiod som
                anges för varje produktkategori. Deltagandet kan avslutas senast
                <b>01.11.2025</b> ("<strong>Slutdatum</strong>").
              </p>
              <p>
                Endast registrerade paket med giltigt spårningsnummer kan
                accepteras. Portokostnaden återbetalas inte.
              </p>
              <p>
                <strong>P&amp;G Servicecentrets</strong> adresser i ditt land
                är:
              </p>
              <br/>
              <p><strong>Norge</strong></p>
              <p>L.G. Elektro A/S</p>
              <p>Kolstien 4</p>
              <p>5097 Bergen</p>
              <p>Norge</p>
              <p></p>
              <br/>
              <p><strong>Sverige</strong></p>
              <p>
                El &amp; Digital Service AB<br />Folkungagatan 144<br />116 30
                Stockholm<br />Sverige
              </p>
              <p></p>
              <br/>
              <p><strong>Finland</strong></p>
              <p>AIDE Europe Oy</p>
              <p>Puusepänkatu 2D</p>
              <p>00880 Helsinki</p>
              <p>Suomi</p>
              <br/>
              <p>
                <strong>Danmark</strong><strong><br /></strong>CJ
                Hvidevareservice<br />Thorndahlsvej 11<br />9200 Aalborg SV<br />Danmark
              </p>
              <br/>
              <p>
                Observera följande när det gäller streckkoden på den
                reklamprodukt som krävs för deltagande och ditt inköpsbevis:
              </p>
              <ul>
                <li>
                  Se till att streckkoden (dvs. den vanliga streckkoden som
                  består av svarta streck med vita mellanslag) syns tydligt på
                  motsvarande produktfoton.
                </li>
                <li>
                  Som inköpsbevis i den mening som avses i dessa villkor gäller
                  endast kvittot eller fakturan från återförsäljaren av
                  kampanjen. Orderbekräftelser, leveranssedlar eller jämförbara
                  dokument är inte tillräckliga.
                </li>
                <li>
                  På fotot av inköpsbeviset måste uppgifterna om
                  återförsäljaren, kvittots ID (inte bankens transaktions-ID),
                  datum och klockslag för kvittot, den salgsfremmende produkten,
                  priset på den salgsfremmende produkten och kvittots
                  totalbelopp, den angivna momsen och eventuella rabatter som
                  beviljats tydligt framgå.
                </li>
                <li>
                  Fotot av inköpsbeviset får inte vara överexponerat, suddigt
                  eller för mörkt. Skannade kvitton räcker inte för deltagande,
                  utan det krävs detaljerade foton.
                </li>
                <li>
                  På fotot av inköpsbeviset får endast inköpsbeviset visas; den
                  köpta reklamprodukten får inte fotograferas tillsammans med
                  inköpsbeviset.
                </li>
              </ul>
              <p>
                När deltagandet har slutförts med framgång - dvs. när all
                information som du har lämnat eller laddat upp har mottagits -
                samt referensnumret för ditt deltagande kommer att visas på
                kampanjwebbplatsen; du kommer då också att få ett
                e-postmeddelande till den e-postadress som du har angett.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Vad finns det mer att tänka på?</div>
            <div class="list-item-details">
              <p>
                <u>Testkampanj:</u> Med denna kampanj vill vi att du ska kunna
                testa Braun reklamprodukterna. Mot denna bakgrund (och baserat
                på erfarenhet av jämförbara åtgärder) måste vi definiera några
                spelregler med avseende på kampanjens mål:
              </p>
              <ul>
                <li>
                  Deltagande i kampanjen är endast möjligt i ditt eget namn och
                  per person med ett inköpsbevis och en reklamprodukt.
                </li>
                <li>
                  Varje inköpsbevis kan endast användas en gång för att delta i
                  kampanjen; detta gäller även om mer än en reklamprodukt anges
                  på ett inköpsbevis.
                </li>
                <li>
                  Om du samtidigt löser in en rabattkupong för reklamprodukten
                  får du endast en återbetalning för det belopp som faktiskt
                  betalats.
                </li>
                <li>
                  En produkt som återbetalas i denna kampanj kan inte användas i
                  någon annan kampanj från Procter &amp; Gamble, och en produkt
                  som redan har deltagit i en annan kampanj kan inte återbetalas
                  i denna kampanj.
                </li>
                <li>
                  Dessutom kan varje IBAN och e-postadress endast delta i
                  kampanjen en gång.
                </li>
                <li>
                  Betalningar kan endast göras till ett konto som tilldelats ett deltagande land och endast i
landets officiella valuta (DKK för deltagare från Danmark, EUR för deltagare från Finland,
NOK för deltagare från Norge och SEK för deltagare från Sverige). Bankkonton från andra
länder och/eller i annan valuta accepteras inte för deltagande.
                </li>
                <li>
                  Inget ansvar tas på sig om IBAN-numret anges felaktigt. Det
                  finns en engångsmöjlighet att ändra ett ogiltigt IBAN-nummer,
                  varefter deltagandet blir ogiltigt. För att ändra dina
                  bankuppgifter kan du kontakta vår supporttjänst, som är
                  inrättad enbart för IBAN-ändringar (via e-post:
                  <a href="mailto:support@braun-moneyback.com"
                    >support@braun-moneyback.com</a
                  >) fram till <strong>15.12.2025.</strong> (Om du har några
                  frågor som inte har med IBAN-ändringen att göra, vänligen
                  kontakta oss via de kontaktalternativ som anges i avsnitt I).
                </li>
                <li>
                  Ofullständiga, felaktiga eller sena ansökningar kommer inte
                  att godkännas. Avsändarbevis kommer inte att godkännas som
                  mottagningsbevis. Vi ansvarar inte för blanketter och/eller
                  deras innehåll som har försvunnit eller skadats eller
                  försenats.
                </li>
                <li>
                  Om paketinnehållet har skickats in felaktigt har konsumenten
                  en möjlighet att skicka in de nödvändiga dokumenten på nytt.
                  Om det vid det andra försöket återigen saknas innehåll eller
                  är fel, blir deltagandet ogiltigt.
                </li>
              </ul>
              <p>
                <u>Förebyggande av missbruk och bedrägeri:</u> Enligt vår
                erfarenhet hittills leder kampanjer som dessa tyvärr regelbundet
                till försök till missbruk och bedrägeri, ibland i betydande
                omfattning. Därför måste vi också vidta olika åtgärder för att
                förhindra och upptäcka sådana försök i denna kampanj. Vi förstår
                att detta kan leda till merarbete för er. Vi har dock försökt
                att göra våra nödvändiga åtgärder så okomplicerade som möjligt
                för dig som deltagare:
              </p>
              <ul>
                <li>
                  Genom den kontroll som beskrivs ovan i avsnitt D som en del av
                  deltagandet kontrollerar vi om de uppgifter som deltagaren
                  lämnat (IBAN och e-postadress) redan har använts för att delta
                  i kampanjen; i så fall är ytterligare deltagande inte längre
                  möjligt.
                </li>
                <li>
                  Under den fortsatta behandlingen kommer deltagarinformationen
                  också att kontrolleras, i synnerhet de streckkoder och
                  inköpsbevis som deltagarna laddat upp.
                </li>
              </ul>
              <p>
                <u>Behållning av inköpsbeviset:</u> Observera att du måste
                behålla
                <u
                  >det ursprungliga inköpsbeviset som användes för deltagandet
                  tills återbetalningen har mottagits.</u
                >
                I enskilda fall måste du skicka detta till en motsvarande
                e-postförfrågan per post för granskning innan Justsnap kan
                utfärda en återbetalning. I detta fall ska du skicka det
                ursprungliga inköpsbeviset i ett kuvert med tillräckligt porto
                till den angivna adressen. Ostämplade varor kommer inte att
                accepteras och det ursprungliga köpbeviset kan inte returneras
                efter att det har kontrollerats (vänligen gör en kopia för dina
                egna ändamål om det behövs); inget ansvar för förluster via
                post.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">Vad händer efter ditt deltagande?</div>
            <div class="list-item-details">
              <p>I detta sammanhang</p>
              <ul>
                <li>
                  En kontroll kommer att genomföras för att förhindra missbruk
                  och bedrägeri. I detta sammanhang kan det ursprungliga
                  inköpsbeviset i enskilda fall komma att begäras av dig;
                </li>
                <li>
                  Ett e-postmeddelande kommer att skickas för att bekräfta ditt
                  krav på pengar till den e-postadress du angett, detta sker
                  vanligtvis inom två arbetsdagar efter det att informationen
                  har mottagits;
                </li>
                <li>
                  P&G Service Center bekräftar paketet med deltagande produkt
                  och
                </li>
                <li>
                  Återbetalningsbeloppet kommer att betalas till det konto som
                  du har identifierat via IBAN; detta görs vanligtvis inom sex
                  veckor efter att provet har avslutats.
                </li>
              </ul>
              <p>
                Vi förbehåller oss rätten att utesluta deltagare från kampanjen
                om det finns misstankar om att falsk information lämnats vid
                deltagandet, försök till manipulation av kampanjen eller att
                dessa villkor på något annat sätt har överträtts. I detta fall
                kommer inget bekräftelsemail att skickas till dig. Du kan bevisa
                för oss att det inte förekom något brott mot villkoren i ditt
                fall.
              </p>
              <p>
                Vi förbehåller oss också rätten att ändra eller anpassa dessa
                villkor och att avbryta, förlänga eller avsluta kampanjen när
                som helst utan föregående meddelande.
              </p>
              <p>
                För deltagare från Norge gäller norsk lag, för deltagare från
                Sverige gäller svensk lag, för deltagare från Finland gäller
                finsk lag och för deltagare från Danmark gäller dansk lag.
                Domarnas beslut är slutgiltigt.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              Vem kan jag kontakta om jag har några frågor om kampanjen?
            </div>
            <div class="list-item-details">
              <p>
                Om du har några frågor om kampanjen är det snabbaste och enda
                sättet att kontakta oss via
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >. Denna möjlighet finns fram till <strong>15.12.2025.</strong>
              </p>
              <p>
                Du kan också kontakta Procter &amp; Gamble International
                Operations SA via e-post
                <a href="mailto:contactCH.im@pg.com">contactCH.im@pg.com</a> och
                per post (Route de Saint-Georges 47 1213 PETIT-LANCY, Geneve).
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "conditions"
};
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 5px;
}
.page-title {
  width: 100%;
  text-align: center;
  color: var(--text-color-3);
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
  margin: 30px;
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.box-notice {
  background-color: #dfdfdf;
  border: 1px solid black;
  padding: 15px;
  margin: 5px;
  color: #3d3d41;
}
.list {
  list-style-type: upper-latin;
  word-break: break-all;
  margin: 10px 4px;
  li::marker {
    font-size: 26px;
    font-weight: bold;
    color: var(--color5);
  }
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  padding-left: 20px;
  padding-right: 20px;
}
.number {
  color: var(--color5);
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  display: inline;
  margin-right: 5px;
  p {
    display: inline;
  }
}
p {
  margin-bottom: 0;
}
.list-item-wrapper {
  margin-top: 24px;
  margin-right: 5px;
  white-space: initial;
  color: var(--color5);
  .list-item {
    font-weight: normal;
    white-space: initial;
    font-size: 16px;
    line-height: 24px;
  }
  .list-item-details {
    margin-top: 10px;
    text-justify: auto;
    a {
      text-decoration: underline;
    }
  }
}
</style>
