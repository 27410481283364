<template>
  <form-wizard @on-change="handleTabChange" :color="tabsWizard.color">
    <h2 slot="title">{{tabsWizard.title}}</h2>
    <template v-for="tab in tabsWizard.tabs">
      <tab-content :title="tab.title" :icon="tab.icon" :key="tab.tabId">
        <div class="row d-flex justify-content-around" :key="tab.tabId">
          <template v-for="field in tab.fields">
            <componentRenderer :field="field" :key="field.fieldId" />
          </template>
        </div>
      </tab-content>
    </template>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from "@/components/renderers/FormWizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import componentRenderer from "@/components/component-renderer";

export default {
  name: "steps-component",
  components: {
    FormWizard,
    TabContent,
    componentRenderer
  },
  props: {
    tabsWizard: {
      type: Object,
      required: true
    }
  },
  methods: {
    nextStep () {
      //   console.log("NEXT:::", this);
    },
    handleTabChange: function (prevIndex, nextIndex) {
      // console.log("step Change", nextIndex, this.tabsWizard.tabs[nextIndex]);
    }
  }
};
</script>