<template>
  <div class="gray_block">
    <div class="inner_gray_block">
      <div class="description">{{ $t(descriptionText) }}</div>
      <div class="subDescription mt-2">{{ $t(descriptionSubText) }}</div>
    </div>
    <div class="row align-items-center wrapper-steps-example">
      <div class="col-5">
        <img class="img-fluid receipt-example" src="/assets/receipts/receipt.png" alt="Receipt image">
      </div>
      <div class="col-7">
        <ul class="wraper_punkt">
          <li class="media" :key="step.label" v-for="step in steps">
            <div class="item">{{$t(step.label)}}</div>
            <div class="media-body item_text">{{ $t(step.text)}}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "receipt-information",
  props: {
    descriptionText: {
      required: false,
      type: String,
      default: ""
    },
    descriptionSubText: {
      required: false,
      type: String,
      default: ""
    },
    steps: {
      required: false,
      type: [Array, Object],
      default: () => []
    }
  }
};
</script>
<style lang="scss" scoped>
  .gray_block {
    padding: 30px;
    border-radius: 8px;
    @media (max-width: 991px) {
      padding: 40px 60px;
    }
    @media (max-width: 767px) {
      padding: 40px 15px;
    }
    .inner_gray_block {
      text-align: left;
      font: normal normal 400 16px/22px 'Plus Jakarta Sans';
      letter-spacing: 0px;
      color: #3D3D41;
      opacity: 1;
      margin-bottom: 50px;
    }
  }
  .wraper_punkt {
    li {
      margin-bottom: 25px;
      font-size: 14px;
      letter-spacing: 0.16px;
      @media (max-width: 991px) {
        margin-bottom: 15px;
      }
      @media (max-width: 575px) {
        margin-bottom: 4px;
      }
      .item {
        color: var(--color-4) !important;
        width: 30px;
        font-weight: 700;
        display: inline-block;
        color: #3D3D41;
        text-transform: uppercase;
        margin-right: 10px;
        text-align: center;
      }
      .item_text {
        font: normal normal 400 16px/22px 'Plus Jakarta Sans';
        letter-spacing: 0px;
        color: #3D3D41;
        opacity: 1;
        display: inline-block;
        @media (max-width: 575px) {
          font: normal normal 400 12px/16px 'Plus Jakarta Sans';
        }
      }
    }
  }
  .checkMark {
    position: relative;
    float: left;
    margin-left: 15px;
    &:first-child {
      margin-left: 0;
    }
    .checkMark_img {
      position: absolute;
      bottom: 25%;
      left: 50%;
      margin-left: -27px;
      @media (max-width: 767px) {
        max-width: 30px;
        margin-left: -15px;
      }
    }
    .cross_red_img {
      position: absolute;
      bottom: 25%;
      left: 50%;
      margin-left: -18px;
      @media (max-width: 767px) {
        max-width: 24px;
        margin-left: -12px;
      }
    }
  }
  .wrapper_check_block {
    padding-top: 90px;
    display: flex;
    justify-content: space-between;
    @media (max-width: 991px) {
      padding-top: 20px;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .receipt-example {
    padding-top: 12px;
    height: 100%;
    background: #fff 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    max-height: 320px;
  }
  .wrapper-steps-example {
    margin-bottom: 30px;
    @media (max-width: 575px) {
      >div {
        padding: 0px;
      }
    }
  }

  .description {
    margin-left: 3rem;
    @media (max-width: 575px) {
      margin: 0px;
    }
  }

  .subDescription {
    margin-left: 3rem;
    @media (max-width: 575px) {
      margin: 0px;
    }
  }
</style>
