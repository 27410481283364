<template>
  <div class="container justify-content-center">
    <div class="row">
      <h2 class="pageTitle w-100 mt-5 mb-5 heading" v-html="title"></h2>
      <ul style="list-style-type: none; width: 100%;" v-if="ordered">
        <template v-for="(item,idx) in computedText">
          <li :key="idx">
            <div class="list-item-wrapper d-flex flex-row align-items-start">
              <div class="number"><span :style="`color: black`">{{idx+1}}.</span></div>
              <div class="pr-2"><span class="list-item" v-html="item"></span></div>
            </div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
export default {
  name: "text-list",
  props: {
    items: {
      type: Array,
      default: () => ["item 1", "item 2", "item 3"]
    },
    title: String,
    ordered: {
      type: Boolean,
      default: true
    },
    grid: {
      type: Object,
      default: () => ({
        backgroundColor: "#fff"
      })
    },
    actions: {
      type: Array
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    }
  },
  data () {
    return {};
  },
  computed: {
    computedText () {
      return this.items.map(element => {
        return element.replace(/\t/g, "&nbsp;&nbsp;&nbsp;&nbsp;")
      })
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  outline: 0;
}
ul {
  padding: 0;
  margin: 0;
  .number {

    font: normal normal bold 22px/24px 'Plus Jakarta Sans', Bold;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;

    @media (max-width: 991px) {
      font: normal normal bold 16px/20px 'Plus Jakarta Sans' !important;
      padding-left: 10px
    }
  }
  .list-item-wrapper {
    margin-top: 24px;
    white-space: initial;
    .list-item {
      margin-left: 30px;
      text-align: left;
      font: normal normal 500 20px/24px 'Plus Jakarta Sans', Medium;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      word-wrap: break-word;

      @media (max-width: 991px) {
        font: normal normal normal 16px/20px 'Plus Jakarta Sans' !important;
        margin-left: 2px;
      }
    }
  }
}

@media (max-width: 425px) {
  h2 {
    font-size: 23px !important;
  }
}

.heading {
  color: var(--color2);
  text-align: left;
  font: normal normal 600 32px/34px 'Plus Jakarta Sans', SemiBold;
  letter-spacing: 0px;
  opacity: 1;
}

</style>
