import { render, staticRenderFns } from "./footer-component.vue?vue&type=template&id=5269c8ac&scoped=true"
import script from "./footer-component.vue?vue&type=script&lang=js"
export * from "./footer-component.vue?vue&type=script&lang=js"
import style0 from "./footer-component.vue?vue&type=style&index=0&id=5269c8ac&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5269c8ac",
  null
  
)

export default component.exports