import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './languages'

Vue.use(VueI18n);
const lang = window.localStorage.getItem('lang')
let locale = 'en'
if (lang) locale = lang

// Create VueI18n instance with options
export const i18n = new VueI18n({
  locale,
  messages
});
