import heading from "@/components/renderers/heading";
import paragraph from "@/components/renderers/paragraph";
import squareImage from "@/components/renderers/square-image";
import alert from "@/components/renderers/alert";
import bucket from "@/components/renderers/bucket";
import container from "@/components/renderers/container";
import flexContainer from "@/components/renderers/flex-container";
import card from "@/components/renderers/card";
import keyVisual from "@/components/renderers/key-visual";
import navbar from "@/components/renderers/navbar";
import anchor from "@/components/renderers/anchor";
import receiptProcessor from "@/components/renderers/receipt-processor"
import productUpload from "@/components/renderers/product-upload"
import formComponent from "@/components/renderers/form-component"
import textInput from "@/components/renderers/text-input"
import stepCard from "@/components/renderers/step-card"
import stepsComponent from "@/components/renderers/steps-component"
import selectInput from "@/components/renderers/select-input"
import radioInput from "@/components/renderers/radio-input"
import dateInput from "@/components/renderers/date-input"
import buttonComponent from "@/components/renderers/button-component"
import productSelection from "@/components/renderers/ProductSelection/index.vue"
import emptyBucket from "@/components/renderers/empty-bucket"
import receiptInformation from "@/components/renderers/receipt-information"
import barcodeInformation from "@/components/renderers/barcode-information"
import smsVerification from "@/components/renderers/SmsVerification/sms-verification"
import checkbox from "@/components/renderers/checkbox"
import gtinComponent from "@/components/renderers/gtin-component"
import stepCounter from "@/components/renderers/step-counter"
import footerComponent from "@/components/renderers/footer-component"
import scrollButton from "@/components/renderers/scroll-button"
import stepsHorizontal from "@/components/renderers/steps-horizontal"
import question from "@/components/renderers/question"
import textList from "@/components/renderers/text-list"
import reclaimBucket from "@/components/renderers/reclaim-bucket"
import injectTag from "@/components/renderers/inject-tag"
import dataLayerEvent from "@/components/renderers/datalayer-event"
import refreshToken from "@/components/renderers/refresh-token"
import segmentaCookieHandler from "@/components/renderers/segmenta-cookieHandler"
import background from "@/components/renderers/background"
import doi from "@/components/renderers/doi"
import queryStringToState from "@/components/renderers/query-string-to-state"
import barcodeScanner from "@/components/renderers/BarcodeScanner"
import barcodeError from "@/components/renderers/BarcodeError"
import secondBlock from "@/components/renderers/second-block"
import faq from "@/components/renderers/faq"
import conditions from "@/components/renderers/conditions"
import participation from '@/components/renderers/participation'
import fivePoints from '@/components/renderers/fivePoints'
import noLogo from '@/components/renderers/noLogo'
import ibanReupload from "@/components/renderers/iban-reupload"
import checkReceiptToken from "@/components/renderers/checkReceiptToken"
import survey from "@/components/renderers/survey"
import returnFormComponent from "@/components/renderers/return-form-component"

import barcodeScannerOralB from "@/components/renderers/BarcodeScannerOralB"
import stepCardOralB from "@/components/renderers/OralB-components/step-card-oralb"
import progressBar from "@/components/renderers/OralB-components/progress-bar"
import receiptExamples from "@/components/renderers/OralB-components/receipt-examples"
import cardBucket from "@/components/renderers/OralB-components/card-bucket"
import clipboard from "@/components/renderers/OralB-components/clipboard"

export default [
  queryStringToState,
  segmentaCookieHandler,
  refreshToken,
  dataLayerEvent,
  injectTag,
  reclaimBucket,
  ibanReupload,
  textList,
  question,
  stepCounter,
  receiptInformation,
  barcodeInformation,
  smsVerification,
  heading,
  paragraph,
  squareImage,
  alert,
  bucket,
  emptyBucket,
  container,
  flexContainer,
  card,
  keyVisual,
  navbar,
  anchor,
  receiptProcessor,
  productUpload,
  formComponent,
  textInput,
  stepCard,
  stepsComponent,
  selectInput,
  radioInput,
  dateInput,
  buttonComponent,
  productSelection,
  checkbox,
  gtinComponent,
  footerComponent,
  background,
  doi,
  stepsHorizontal,
  scrollButton,
  barcodeScanner,
  barcodeError,
  secondBlock,
  faq,
  conditions,
  participation,
  fivePoints,
  noLogo,
  checkReceiptToken,
  stepCardOralB,
  progressBar,
  barcodeScannerOralB,
  receiptExamples,
  cardBucket,
  clipboard,
  survey,
  returnFormComponent
];
