<template>
  <div class="">
    <ValidationProvider v-if="validation" :rules="validation" v-slot="{ errors }" :name="$t(label)">
      <div :style="styles" class="selectInputWrapper col-12">
        <label v-if="labelOnTop" :style="labelOnTopStyle" class="input-label">
          {{$t(label)}}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>{{$t('(optional)')}}</span>
        </label>
        <select
          v-model="localSelected"
          required
          ref="select"
          :name="name"
          :id="field.fieldId"
          class="form-control"
          @change="updateComponent"
          :class="{'is-invalid': errors.length}"
          :placeholder="$t(placeholder)"
          :aria-label="$t(label)"
        >
          <!-- <option value disabled :selected="!selectedValue" hidden></option> -->
          <option
            v-for="option in filteredOptions"
            :key="option.value"
            :selected="option.value == selectedValue"
            :disabled="option.disabled"
            :value="option.value"
          >{{ $t(option.text) }}</option>
        </select>
        <!-- <img
          v-if="errors.length"
          class="error_min inputError"
          src="../../assets/icons/risk-icon.svg"
          height="18px"
          width="18px"
          alt
        /> -->
        <!-- <span v-if="!labelOnTop" class="floating-label">{{ $t(placeholder) }}</span> -->
        <!-- <span v-else-if="!localSelected" class="placeholder">{{$t(placeholder)}}</span> -->
      </div>
      <div class="validation-error" v-if="errors.length">{{errors[0]}}</div>
    </ValidationProvider>
    <template v-else>
      <div class="selectInputWrapper col-12">
        <label v-if="labelOnTop" :style="labelOnTopStyle" class="input-label">
          {{$t(label)}}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>{{$t('(optional)')}}</span>
        </label>
        <select
          class="form-control"
          v-model="localSelected"
          required
          ref="select"
          :name="name"
          :id="field.fieldId"
          @change="updateComponent"
          :placeholder="$t(placeholder)"
        >
          <option value disabled :selected="!selectedValue" hidden></option>
          <option
            v-for="option in filteredOptions"
            :key="option.value"
            :selected="option.value == selectedValue"
            :disabled="option.disabled"
            :value="option.value"
          >{{option.text}}</option>
        </select>
        <!-- <span v-if="placeholder" class="placeholder">{{placeholder}}</span>
        <span v-else-if="!localSelected" class="placeholder">{{$t(placeholder)}}</span> -->
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "select-input",
  data () {
    return {
      localSelected: null,
      selectedValue: null
    };
  },
  props: {
    field: {
      required: false,
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    selectedValue: {
      type: String,
      required: false,
      default: ""
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select Something"
    },
    validation: {
      type: String,
      required: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    labelOnTop: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    },
    forCountry: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed : {
    filteredOptions () {
      if(this.forCountry){
        return this.options.filter((option) => option.code === this.locale )
      }else{
        return this.options
      }
    }
  },
  created() {
    this.localSelected = this.filteredOptions[0].value;
    this.selectedValue = this.filteredOptions[0].value;
  },
  methods: {
    updateComponent (event) {
      this.$refs.select.style.color = "#000";
      this.$emit("component-updated", {
        text: event.target.value
      });
      this.$emit("input", event.target.value);
      this.$eventHub.$emit("checkCountryForPLZ", event.target.value);
    }
  }
};
</script>

<style scoped lang="scss">

.selectInputWrapper {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #fff url("../../assets/icons/form/down-arrow.svg") no-repeat !important;
    background-size: 20px !important;
    background-position: right 10px center !important;
  }
  position: relative;
  .error_min {
    width: 16px;
  }
  .inputError {
    position: absolute;
    top: 12px;
    right: 10px;
  }
}

select {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  outline: 0;
  box-shadow: none;
  transition: border-color 0.15s;
  border-radius: var(--field-radius);
  border: 1px solid #707070;
  box-sizing: border-box !important;
  height: 44px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  color: #000;
  &.is-invalid {
    border-color: #dc3545 !important;
  }
}

.floating-label {
  position: absolute;
  top: 50%;
  left: 12px;
  transition: transform 0.1s;
  pointer-events: none;
  transform-origin: 0 100%;
  transform: translateY(-50%);

  font: normal normal 300 16px/22px 'Plus Jakarta Sans';
  letter-spacing: 0px;
  color: #1B1B1B;
  opacity: 0.5;

}

select:focus + .floating-label,
select.visited + .floating-label,
select:not(:focus):valid ~ .floating-label {
  transform: translateY(-145%) scale(0.7);
}

</style>
