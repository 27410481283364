<template>
  <div>
    <div v-if="list.length !== 0" class="barcode-scanner__products-list" :style="styles">
      <div class="barcode-scanner__products-list_item d-flex"
           v-for="item in list"
           :key="item.id"
      >
        <div class="d-flex" style="width: 100%">
          <div class="col-lg-10 my-2 p-0 col-10">
            <div class="d-flex">
              <div class="photoContainer">
                <img class="photo" :src="item.photoUrl || '/assets/braun_image.png'" alt="Braun Logo">
              </div>
              <div class="item-content d-flex">
                <div class="name">{{item.name}}</div>
                <div class="ean">EAN: {{item.ean}}</div>
              </div>
            </div>
          </div>
          <div class="col-lg-2 my-2 p-0 col-2">
            <b-input-group>

              <template #append>
                <b-button
                  size="sm"
                  @click="removeProduct(item.id)"
                  variant="custom"
                >
                  <b-icon icon="x" />
                </b-button>
              </template>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
    <div  v-if="showReturnBackButton && list.length === 0" :style="grid" style="justify-content: center; display:flex">
      <buttonComponent
        addClass="btn next-btn"
        @click.native="goToSelectProducts()"
        :textColor="white"
        text="Produkte hinzufügen"
        backgroundColor="var(--color2)"
        borderColor="var(--color2)"
        :borderRadius='"50rem"'
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import buttonComponent from "@/components/renderers/button-component.vue";

export default {
  name: 'productsList',
  components: {
    buttonComponent
  },
  props: {
    styles: {
      type: Object,
      required: false
    },
    showEditButton: {
      type: Boolean,
      default: false
    },
    showReturnBackButton: {
      type: Boolean,
      default: false
    },
    maxProductCount: Number
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      list: state => state.scannedProducts
    })
  },
  methods: {
    ...mapActions(["removeProduct", "editQuantityProduct", "editInputQuantityProduct"]),
    editProduct (product, action) {
      let data = { product, action };
      this.editQuantityProduct(data);
      if (!product.count) {
        this.removeProduct(product.id);
      }
    },
    goToSelectProducts () {
      this.goToPage({
        pageName: 'productSelection',
        shouldBack: true
      }, this)
    }
  }
}
</script>

<style scoped lang="scss">
.barcode-scanner__products-list {
  width: 100%;

  @media (max-width: 767px) {
    padding: 0;
  }
  &_item {
    background: #FFFFFF;
    border: 1px solid #6BB1FF;
    box-sizing: border-box;
    border-radius: 16px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 32px;
    @media (max-width: 991px) {
      padding: 16px !important;
    }
    div {
      align-items: center;
    }
    .photoContainer {
      margin-right: 20px;

      &::after {
        content: '';
        position: absolute;
        left: 8px;
        bottom: -5px;
        width: 40px;
        height: 5px;
        background: rgba(196, 196, 196, 0.51);
        filter: blur(5px);
      }
    }
    .photo {
      max-width: 56px;
      max-height: 56px;
    }

    .item-content {
      flex-direction: column;
      align-items: flex-start;

      letter-spacing: 0px;
      color: #000000;


      .name {
        font: normal normal 600 14px/18px 'Plus Jakarta Sans';
        margin-bottom: 5px;
      }

      .ean {
        font: normal normal 500 14px/18px 'Plus Jakarta Sans';
      }
    }
  }
  .circle {
    display: flex;
    width: 44px;
    height: 44px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: #9ADFE6;
    border-radius: 2em;
  }
}

.select-none {
  user-select: none;
}

.short-logo {
  width: 36px;
}

.btn-info {
  width: 44px;
  height: 44px;
  border-radius: 100% !important;
  font-size: 0;
  line-height: 1;
  background-color: var(--color9);
  border: 0;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }

  .input-group-prepend &{
    background-image: url("../../assets/icons/minus.svg");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .input-group-append &{
    background-image: url("../../assets/icons/plus.svg");
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.btn-danger {
  background: 0;
  border: 0;
  border-radius: 0;
  color: #6F6F6F;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }
}
.btn-edit {
  background: 0;
  border: 0;
  &:focus {
    outline: 0 !important;
    box-shadow: none;
  }
  img {
    width: 70%;
  }
}

.input-group {
  justify-content: flex-end;
  .form-control {
    border: 0;
    padding: 0;
    margin: 0 5px;
    text-align: center;
    flex: 0 1 26%;
    &:focus {
      outline: 0 !important;
      box-shadow: none;
    }
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
</style>
