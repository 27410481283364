<template>
  <div  class="" >
    <ValidationProvider :rules="validation" v-slot="{ errors }" :name="$t(label)">
      <div :style="styles" class="dateInputWrapper form-group col-12">
        <label v-if="labelOnTop" :style="labelOnTopStyle" class="input-label">
          {{$t(label)}}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>{{$t('(optional)')}}</span>
        </label>
        <datepicker
          :value="value"
          :placeholder="$t(placeholder)"
          :class="{'is-invalid': errors.length}"
          @selected="updateValue"
          @opened="floatLabel = true"
          @closed="floatLabel = false"
          ref="input"
          :name="label"
          :id="name"
          language="en"
          v-bind="datepickerParams"
          required
          :tabindex="tabIndex"
        ></datepicker>
        <!-- <img v-if="errors.length" class="error_min inputError" src="../../assets/icons/risk-icon.svg" height="18px" width="18px" alt=""> -->
        <span
          v-if="!labelOnTop"
          :class="{floatLabel: floatLabel || value}"
          class="floating-label"
        >{{label}}</span>
        <!-- <span v-else-if="!value" class="placeholder">{{$t(placeholder)}}</span> -->
      </div>
      <div class="validation-error" v-if="errors.length">
        {{errors[0]}}
      </div>
    </ValidationProvider>
    <!-- <div class="m-0 p-0" v-else>
      <div class="dateInputWrapper form-group">
        <datepicker
          :value="value"
          :style="styles"
          :class="{'is-invalid': errors.length}"
          @selected="updateValue"
          @opened="floatLabel = true"
          @closed="floatLabel = false"
          ref="input"
          :name="label"
          :id="name"
          v-bind="datepickerParams"
          required
          :placeholder="$t(placeholder)"
          :tabindex="tabIndex"
        ></datepicker>
        <span
          v-if="!labelOnTop"
          :class="{floatLabel: floatLabel}"
          class="floating-label"
        >{{label}}</span>
      </div>
    </div> -->
  </div>
</template>

<script>

import Datepicker from "@hokify/vuejs-datepicker";
import "@hokify/vuejs-datepicker/dist/vuejs-datepicker.css"

export default {
  name: "date-input",
  components: {
    Datepicker
  },
  props: {
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    value: {
      type: Date
    },
    validation: {
      type: String,
      required: false
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    params: {
      type: Object,
      required: false
    },
    labelOnTop: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    },
    tabIndex: {
      type: [Number, String],
      default: ""
    }
  },
  computed: {
    datepickerParams () {
      const params = { ...this.params }
      if (params.disabledDates) {
        if (params.disabledDates.to) {
          params.disabledDates.to = this.convertToDate(params.disabledDates.to)
        }
        if (params.disabledDates.from) {
          params.disabledDates.from = this.convertToDate(params.disabledDates.from)
          params.openDate = params.disabledDates.from
        }
      }
      return params
    }
  },
  data () {
    return {
      floatLabel: false
    }
  },
  methods: {
    updateValue (value) {
      this.$emit("component-updated", {
        value
      });
    },
    convertToDate (date) {
      if (date === "today") {
        return new Date()
      } else if (date === "18years") {
        const date = new Date()
        date.setFullYear(date.getFullYear() - 18)
        return date
      } else {
        return new Date(date)
      }
    }
  },
  mounted () {
    document.addEventListener('keydown', (e) => {
      if (e.target == this.$refs.input.$el && (e.code == 'Space' || e.code == 'Enter')) {
        e.preventDefault()
        this.$refs.input.showCalendar()
      }
    })
  }
};
</script>

<style scoped lang="scss">

  .inputError {
    position: absolute;
    right: 10px;
    top: 12px;
  }
.dateInputWrapper {
  position: relative;

  ::v-deep input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    outline: 0;
    box-shadow: none;
    transition: border-color .15s;
    border: 1px solid #707070;
    box-sizing: border-box !important;
    padding-left: 12px !important;
    height: 48px !important;
    cursor: pointer;
    width: 100%;
    font-size: 14px !important;
    color: #000;
    border-radius: var(--field-radius);
  }
    ::v-deep .vdp-datepicker__calendar {
      width: 100%;
      border-radius: 8px;
      border: 1px solid var(--input-border-color) !important;
    }
    ::v-deep .vdp-datepicker.is-invalid {
      ::v-deep div>input {
        border-color: #dc3545;
        background-color: #fff0f1;
      }
    }

  .floating-label {
    position: absolute;
    top: 50%;
    left: 12px;
    transition: transform .1s;
    pointer-events: none;
    transform-origin: 0 100%;
    transform: translateY(-50%);

    font: normal normal 300 16px/22px 'Plus Jakarta Sans';
    letter-spacing: 0px;
    color: #1B1B1B;
    opacity: 0.5;

    &.floatLabel {
      transform: translateY(-145%) scale(0.7);
    }
  }
}
</style>
