
<template>

 <div class="div">
     <iframe v-show="false" id="iframe" ref="iframe"></iframe>
     <div class="container">
       <div class="row justify-content-center">
         <div class="col-lg-8">
          <div class="print-container" >
            <div id="printMe" ref="print">
              <div v-for="field in fields" :key="field.name">
                <div class="label">
                  {{capitalize($t(field.name))}}
                </div>
                <div v-if="Array.isArray(field.value)">
                  <div class="data" :key="p.groupingId" :class="{'mb-2': 0 !== 2}" v-for="(p) in field.value">
                    <b>EAN:</b> {{p.groupingId}}<br>
                    <b>Amount:</b> {{p.amount}}<br>
                    <b>Name:</b> {{p.name}}<br>
                    <b>Operations Number:</b> {{p.operationsNumber}}<br>
                  </div>
                </div>
                <div v-else class="data">
                  {{field.value}}
                </div>
              </div>
            </div>
          </div>
         </div>
       </div>
     </div>
    <!-- <button class="btn  action-button" @click="print"><span class="glyphicon glyphicon-print"></span>{{$t('Print')}}</button> -->
 </div>
</template>
<script>
import Swal from 'sweetalert2'
import { jsPDF } from "jspdf";
import * as html2canvas from "html2canvas"

export default {
  data: () => {
    return { fields: [], receiptImageURL: "" };
  },
  name: "return-form-component",
  props: {
    fieldId: String,
    apiUrl: {
      type: String,
      required: false
    }
  },
  async created () {
    let receiptToken = this.$route.query['receiptToken'];
    const res = await this.$store.dispatch('getReturnFormFields', receiptToken);
    const formFields = res.formFields;
    const receiptImageURL = res.receiptImageURL;
    const productsArr = formFields.filter(p => p.name.toLowerCase() === "products")
    const filteredArr = formFields.filter(p => p.name.toLowerCase() !== "products");

    this.receiptImageURL = receiptImageURL;
    this.fields = [...productsArr, ...filteredArr]; // put products on top
    if (this.fields) {
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
    }
  },
  mounted () {
    this.$eventHub.$on('printForm-event', this.print)
  },
  beforeDestroy () {
    this.$eventHub.$off('printForm-event', this.print)
  },
  methods: {
    capitalize (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    generateData () {
      var result = [];
      this.fields.forEach(i => {
        var data = {
          Title: i.name,
          Value: i.value
        };
        result.push(Object.assign({}, data));
      })
      return result;
    },
    createHeaders (keys) {
      var result = [];
      for (var i = 0; i < keys.length; i += 1) {
        result.push({
          id: keys[i],
          name: keys[i],
          prompt: keys[i],
          width: 65,
          align: "center",
          padding: 0
        });
      }
      return result;
    },
    async print () {
      Swal.fire({
        text: this.$t('Please hide your personal data (name, surname, phone nr., address, etc.) on the receipt/invoice if you will have it before sending your package to Service Center.'),
        confirmButtonText: this.$t('Next')
      }).then(() => {
        let pdfRef = this.$refs.print;
        html2canvas(pdfRef).then(canvas => {
          let pdfImage = canvas.toDataURL();
          let pdf = new jsPDF();

          var width = pdf.internal.pageSize.getWidth();
          var height = pdf.internal.pageSize.getHeight();
          pdf.addImage(pdfImage, 'PNG', 20, 20);
          pdf.addPage()
          pdf.addImage(this.receiptImageURL, 'PNG', 0, 0, width, height);
          pdf.save("return-form.pdf");
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
        }).catch(err => {
          console.log("print catch", err)
        })
      });
    }
  }
};
</script>
<style scoped lang="scss">
.print-container {
  background: #FFFFFF;
  box-shadow: 0px 16px 32px -3px #EBEBEB;
  border-radius: 16px;
  padding: 32px;
  @media (max-width: 767px) {
    padding: 16px;
  }
  .label {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: #2E2E2E;
    margin: 10px 0;
  }
  .data {
    background: #FFFFFF;
    border: 1px solid #D1E7FF;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 13px;
  }
}
#printMe {
  text-align: initial;
  display: flex;
  flex-direction: column;
  // padding-left: 200px;
  background: #F5FAFF;
  border-radius: 8px;
  padding: 48px;
  @media (max-width: 767px) {
    padding: 24px;
  }
}
.tr {
  display: flex;
  justify-content: center;
  align-items: center;
}
.td {
    min-width: 100%;
    padding-left: 30px;
    font-weight: bold;
    font-size: 2em;
    height: 100px;
    vertical-align: middle;

    display: grid;
    align-content: center;

    border: 3px solid black;
    border-radius: 10px;
}
.td-value {
    min-width:50vw;
    padding: 0px 30px;
    font-weight: bold;
    font-size: 2em;
    height: 100px;
    vertical-align: middle;

    display: grid;
    align-content: center;

    border: 3px solid black;
    border-radius: 10px;
}
table, th, td {
  border: 3px solid black;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.action-button {
  color: #fff;
  margin-top: 40px;
  outline: none !important;
  background-color: #1B578E;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  // width: 100%;
}
.div {
    text-align: center;
}
@media print {
  body * {
    visibility: hidden;
  }
  #printMe, #printMe * {
    visibility: visible;
  }
  #printMe {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@media (max-width: 991px) {
  .td {
    font-size: 16px;
    min-width: 100px;
    width: 200px;
    height: 80px;
    padding: 0px;
    text-align: center;
  }

  .td-value {
    font-size: 16px;
    height: 80px;
    padding: 0px;
    text-align: center;
  }
}

@media (max-width: 500px) {
  .td {
    padding: 0px 10px;
  }

  .td-value {
    padding: 0px 10px;
    word-break: break-word;
  }
}
</style>
