<template>
  <div class="barcode-scanner center">

    <!-- Add barcode scanning options -->
    <div class="col-lg-12 p-0 barcodeWrapper">

      <div class="d-flex whiteCard fileUploaderWrapper fileUploaderWrapper1">
        <div class="dashedContainer">
          <file-uploader
              :file-uploader-border-color="colorSchema"
              :file-uploader-background-color="colorSchema"
              :description="$t(texts.receiptUploadDescription)"
              :subDescription="$t(texts.receiptUploadSubDescription)"
              :btn-text="$t(texts.receiptUpload)"
              :isBarcodeUploader="true"
              @file-picked="onFilePicked"
              :texts="{
                wrongFormat: 'Das Dateiformat wird nicht unterstützt. Es werden nur .jpeg, .png und .pdf Dateienakzeptiert.',
                fileSizeLimit: 'Die Datei ist zu groß. Es werden nur Dateien bis 16 MB unterstützt.'
                }"
              :btnShape="tabletView"
            />
        </div>
      </div>
      <span class="or">{{$t('or')}}</span>
      <div class="d-flex whiteCard fileUploaderWrapper fileUploaderWrapper2">
          <div class="dashedContainer" @click="start" v-if="!scannerActive && !imgList.length && !stayActive">
            <div class="dashedContainerInner">
              <div class="w-100 h-100 position-relative d-flex align-items-center flex-column">
                <p class="description" v-html="$t(texts.barcodeScanDescription)"/>
                <p class="subDescription" v-html="$t(texts.barcodeScanSubDescription)"/>
                <buttonComponent
                  @click.native="start"
                  addClass="secondaryButton absolute bottom customColor"
                  :text="$t(texts.barcodeScan)"
                  :grid= "{columns: 6}"
                />
              </div>
            </div>
          </div>
          <div class="dashedContainer" v-else>
            <div class="dashedContainerInner">
              <div class="w-100 h-100 position-relative d-flex align-items-center flex-column">
                <select v-if="videoDevices.length > 1 && scannerActive && !isIOS" v-model="selectedDeviceId" @change="switchCamera">
                  <option v-for="(device, index) in videoDevices" :value="device.deviceId" :key="device.label">
                    {{ device.label.includes('front') ? `Vordere Kamera ${index}` : device.label.includes('back') ? `Rückseitige Kamera ${index}` : `Kamera ${index}` }}
                  </option>
                </select>
                <div id="videoWindow" class="video" :class="{'active': scannerActive && cameraIsFound || stayActive}">
                  <video id="video" src=""></video>
                  <div v-if="!scannerActive && !!imgList.length" class="video-icon">
                    <b-icon icon="check-circle" style="width: 120px; height: 120px;" variant="success"></b-icon>
                  </div>
                </div>
                <buttonComponent
                  v-if="scannerActive || stayActive"
                  @click.native="stop"
                  addClass="secondaryButton absolute bottom customColor"
                  :text="$t(texts.stop)"
                  :fullWidth="true"
                  :grid= "{columns: 6}"
                />
              </div>
            </div>
          </div>
      </div>
      <span class="or or2">{{$t('or')}}</span>
      <div class="d-flex whiteCard fileUploaderWrapper fileUploaderWrapper3">
        <div class="notDashedContainer">
          <div class="notDashedContainerInner">
            <div class="w-100 h-100 position-relative d-flex align-items-center flex-column">
              <p class="description" v-html="$t(texts.barcodeAddDescription)"/>
              <search-module v-on:some-event="redirect($event)" :apiUrl="apiUrl" :maxProductCount="maxProductCount" :texts="texts"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="list.length" class="productListContainer">
      <productsList :fullSize="true" :maxProductCount="maxProductCount" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { BrowserBarcodeReader } from '@zxing/library';
import { promiseTimeout } from './promise-timeout'
import FileUploader from '../file-uploader'
import SearchModule from './SearchModule.vue';
import ProductsList from '../ProductsList'
import buttonComponent from "@/components/renderers/button-component.vue"

const codeReader = new BrowserBarcodeReader();

export default {
  components: { FileUploader, SearchModule, ProductsList, buttonComponent },
  data () {
    return {
      selectedFile: null,
      uploadSectionHover: false,
      errorTextVisible: false,
      selectedDeviceId: null,
      url: null,
      stopPressed: false,
      errorPicDisplay: false,
      cameraIsFound: true,
      videoDevices: [],
      hideCross: false,
      stayActive: false,

      tabList: [
        "Laden Sie das Barcode-Bild des Waschmittels hoch",
        "Laden Sie das Barcode-Bild des Weichspülers hoch"
      ],
      activeTab: 0
    }
  },
  props: {
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    colorSchema: {
      required: false,
      type: String,
      default: "var(--color2)"
    },
    cameraTimeOut: {
      type: Number
    },
    tabletView: {
      type: Boolean
    },
    maxProductCount: Number,
    apiUrl: String
  },
  computed: {
    ...mapState({
      barcode: state => state.barcode,
      types: state => state.types,
      file: state => state.file,
      scannerActive: state => state.scannerActive,
      imgList: state => state.imgList,
      list: state => state.scannedProducts,
      status: state => state.searchStatus,
      inputStatus () {
        let state;
        switch(this.status) {
        case 'success':
          state = true;
          break;
        case 'not_found':
          state = false;
          break;
        case null:
          state = null;
          break;
        default:
          break;
        }
        return state
      },
      resultSearch () {
        return this.status !== 'success'
      }
    }),
    isIOS () {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }
  },
  mounted () {
    codeReader
      .listVideoInputDevices()
      .then(videoInputDevices => {
        if (videoInputDevices[1] && videoInputDevices[1].deviceId) {
          this.videoDevices = videoInputDevices;
          this.selectedDeviceId = videoInputDevices[1].deviceId;
        } else if (videoInputDevices[0] && videoInputDevices[0].deviceId) {
          this.videoDevices = videoInputDevices;
          this.selectedDeviceId = videoInputDevices[0].deviceId;
        }
      })
      .catch(err => console.error(err));
  },
  methods: {
    ...mapActions(["getBarCode", "updateScanner", "uploadFile", "setImgList", "searchProductByBarcode", "updateSearchStatus", "handleProductCode", "updateScannerErrorMessage" , "setErrorActivate"]),
    onFilePicked (file) {
      this.url = URL.createObjectURL(file);
      this.selectedFile = file;
      this.decode();
      this.errorTextVisible = this.errorPicDisplay = false;
      this.$emit('input', file);
      this.setErrorActivate(false);
    },
    decode () {
      this.errorTextVisible = this.errorPicDisplay = false;
      this.setErrorActivate(false);
      this.updateSearchStatus(null);
      codeReader.decodeFromImage(undefined, this.url)
        .then(result => {
          const foundResult = result.text;
          this.getBarCode(foundResult);
          // Barcode is found but need to check if it exists in this campaign
          this.searchProductByBarcode({ barcode: this.barcode, maxProductCount: this.maxProductCount }).then(res => {
            res = res.result
            console.log(res)
            if(res.length === 0) {
              this.updateScannerErrorMessage(this.$t(this.texts.notParticipating))
              this.searchProductByBarcode({ barcode: this.barcode, maxProductCount: this.maxProductCount })
            }
          });
        })
        .catch(err => {
          console.log("over here ")
          console.error(err);
          this.getBarCode('');
          this.updateScannerErrorMessage(this.$t(this.texts.invalidBarCode))
          this.searchProductByBarcode({ barcode: this.barcode, maxProductCount: this.maxProductCount })
        })
    },
    start () {
      this.errorTextVisible = false;
      this.setErrorActivate(false)
      this.stopPressed = false;
      this.getBarCode(null);
      this.updateSearchStatus(null);
      this.updateScanner(true); // Update scanner if no error message

      if (!this.cameraIsFound) {
        navigator.getUserMedia = (
          navigator.getUserMedia ||
          navigator.webkitGetUserMedia ||
          navigator.mozGetUserMedia ||
          navigator.msGetUserMedia
        );

        if (typeof navigator.mediaDevices.getUserMedia !== 'undefined') {
          navigator.mediaDevices.getUserMedia({
            video: true
          })
            .then(res => {

              // Camera is on and can be restarted then
              this.errorPicDisplay = false
              this.cameraIsFound = true
              this.start()
              console.log("CAMERA ON")
            }).
            catch(err => {
              console.log("CAMERA OFF")
              console.log(err)
            });
        }
      }


      // Run the actual code reader if scannerActive is true
      if (this.scannerActive) {
        console.log("Chacking barcode")
        let codeReaderPromise = promiseTimeout(this.cameraTimeOut , codeReader.decodeOnceFromVideoDevice(this.selectedDeviceId, 'video'))
        codeReaderPromise.then((result) => {
          this.updateScanner(true);
          const foundResult = result.text;
          this.getBarCode(foundResult);
          if(this.barcode) {
            this.playSound();
            this.searchProductByBarcode({ barcode: this.barcode, maxProductCount: this.maxProductCount });
          }
        }).catch((err) => {
          console.error(err);

          // Error Message When Camera not found
          if (err?.name == "NotAllowedError") {
            this.updateScannerErrorMessage(this.$t(this.texts.cameraNotFound))
            console.log(this.texts.cameraNotFound)

            // When camera is not found, turn camer is found to false
            this.cameraIsFound = false;
          }

          // The promise timed out
          if (err === "time-out") {
            this.updateScannerErrorMessage(this.$t(this.texts.timedOut))
            this.errorPicDisplay = true
          }

          this.searchProductByBarcode(this.barcode)
          this.errorPicDisplay = this.stopPressed === true ? false : true;

        }).finally(() => {
          // With a completion of a Scan or Failure, in both cases the Video will be closedss
          this.stop();
        })
      }
    },
    stop () {
      this.updateScanner(false);
      codeReader.reset();
      this.stopPressed = true;
    },
    playSound () {
      let sound = 'http://soundbible.com/mp3/Elevator Ding-SoundBible.com-685385892.mp3';
      if(sound) {
        let audio = new Audio(sound);
        audio.play();
      }
    },
    unsetFile () {
      this.setImgList([]);
      this.handleProductCode('');
      this.errorTextVisible = this.errorPicDisplay = false;
      this.setErrorActivate(false);
    },
    switchCamera (e) {
      this.stayActive = true;
      this.selectedDeviceId = e.target.value;
      delete this.$store.state.errorActivate;
      this.hideCross = true;
      codeReader.reset();
      setTimeout(() => {
        this.start();
        this.stayActive = false;
        this.$store.state['errorActivate'] = false;
        this.hideCross = false;
      }, 100)
    }
  }
}
</script>

<style scoped lang="scss">
#videoWindow {
  height: 0;
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0px auto;
  &.active {
    height: auto;
    width: 100%;
  }
}
video {
  width: 100%;
}
canvas {
  max-width: 100px;
  max-height: 100px;
}
.video-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.barcode-scanner {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}
.gray_block {
  margin-top: 30px;
}
.barcode-scanner {
  &_actions {
    // margin: 20px 0px;
    // width: 100%;
    @media (max-width: 450px) { padding: 0px 10px ;}
  }
  &_icons {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
    padding: 0 10px;
    flex-direction: row;
    justify-content: space-between;
  }
  ul, li {
    list-style: none;
  }
  &__divider {
      width: 100%;
      margin-top: 25px;
      margin-bottom: 25px;
      hr {
        border: 1px solid #939393;
      }
      .col-6 {
        font-size: 20px;
        line-height: 32px;
      }
    }
}
.drag-drop {
    &_container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        font-weight: 200;
      }
      div {
        font-family: ''Plus Jakarta Sans'', sans-serif
      }
    }
    &_btn {
      width: 75%;
      height: 40px;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      color: var(--color2);
      border: 1px solid var(--color2);
      border-radius: 40px;
  }
}
#result_strip {
  ul {
    width: 100%;
    margin-top: 1rem;
  }
  .img-container {
    position: relative;
    width: 100%;
    padding-bottom: 62.5%;
    .img-bg-container {
      background-size: cover;
      background-position: center center;
    }
    .img-cross-container, .img-bg-container {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
    .img-cross-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: bold;
      color: red;
      cursor: pointer;
    }
  }
}
.uploadInputSection {
  @media (max-width: 425px) {
    width: 100%;
  }
}
.uploadDescription {
  font-size: 12px;
  line-height: 22px;
}
.text-center {
  text-align: center !important;
}
.file_upload {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  border: 1px dashed #6F6F6F;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  margin: 0;
  &:hover {
    & .file_upload_background {
      opacity: 0.2;
      transition: 0.4s ease-in-out;
    }
  }
  & .file_upload_background {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: -1;
    background-color: #009639;
    opacity: 0.06;
    transition: 0.4s;
  }
}
.file_upload input[type="file"] {
  display: none;
}
.hidden {
  display: none;
}
.wrapper_square_img {
  position: relative;
  display: inline-block;
  width: 89%;
}
.inputfile {
  display: none;
}
.error-text {
  color: red;
}

#redCross {
  color: red;
  height: 150px;
  width: 150px;

  margin: 30px auto;
}

.inputOptionsWrapper {
  justify-content: center;
  flex-direction: column;

  &1 {
    grid-column: 2;
    margin-right: 5px;
    @media (max-width: 991px) {margin-right: 0px;}
  }

  &2 {
    margin-left: 5px;
    @media (max-width: 991px) {margin-left: 0px;}
  }

  p {
    text-align: center;
    margin: 0px;
    padding: 30px 0px;

    font: normal normal 300 16px/22px 'Plus Jakarta Sans';
    letter-spacing: 0px;
    color: #3D3D41;
    opacity: 1;

    @media (max-width: 450px) { font: normal normal 300 16px/22px 'Plus Jakarta Sans'; }
  }
}

.scanDesign {
  background: #0024581A 0% 0% no-repeat padding-box;
  border: 2px dashed #8CC6FF;
  border-radius: 20px;
  opacity: 1;

  width: 355px;
  height: 166px;

  @media (max-width: 450px) {width: 100%;}

  margin: 0px auto;
  cursor: pointer;

  &center {
    display: flex;
    justify-content: center;
    margin: 20px auto;
  }

  &Wrapper {
    margin: 20px auto;
    @media (max-width: 450px) { padding: 0px 10px ;}
  }

  &Inner {
    display: flex;
    flex-direction: column;
    justify-content: center;

    max-width: calc(100% - 9rem);

    padding: auto auto;

    img {
      width: 55px;
      margin: 0px auto;
      padding-bottom: 15px;
    }

    p {
      padding: 0px;
    }
  }
}

.barcodeWrapper {
  display: grid;
  grid-template-columns: 1fr 0fr 1fr 0fr 1fr;
  gap: 15px;

  @media (max-width: 1121px) {
    grid-template-columns: 1fr 0fr 1fr;
  }
}
.whiteBg {
  background: #FFFFFF 0% 0% no-repeat padding-box;
}

.fileUploaderWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 362px !important;
  justify-self: stretch;
  align-self: stretch;
}

.productListContainer {
  margin-top: 80px;
  width: 100%;
}

@media (max-width: 1121px) {
  .fileUploaderWrapper {
    &3 {
      grid-column: 1 / 4;
    }
  }
  .or {
    &2 {
      grid-column: 1 / 4;
    }
  }
}

@media (max-width: 748px) {
  .barcodeWrapper {
    grid-template-columns: 1fr;
  }
  .or {
    &2 {
      grid-column: 1 / 1;
    }
  }
  .fileUploaderWrapper {
    &1, &2, &3 {
      grid-column: 1 / 1;
    }
  }
}

.or {
 display: flex;
 justify-content: center;
 align-items: center;
}
</style>
