import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
function loadPage (pagePath) {
  return () => import(`@/${pagePath}`);
}


const goTo = (to, from, next) => {
  console.log("ROUTE", this, from, next)
}
const routes = [
  {
    path: '/sitedown',
    name: 'Sitedown',
    component: loadPage('views/Maintenance.vue')  
  },
  {
    path: '/',
    name: 'Home',
    component: loadPage('views/Home.vue')  
  },
  // {
  //   path: '/:lang?/resetPassword',
  //   name: 'PasswordReset',
  //   component: loadPage('views/Password-Reset.vue')
  // },
  // {
  //   path: '/pageBuilder',
  //   name: 'PageBuilder',
  //   component: PageBuilder
  // },
  // {
  //   path: '/:lang?/doi',
  //   name: 'doi',
  //   component: loadPage('views/DOI.vue')
  // },
  // {
  //   path: '/geoLimit',
  //   name: 'geo',
  //   component: loadPage('views/GeoLimit.vue')
  // },
  // {
  //   path: '/404',
  //   name: '404',
  //   component: loadPage('views/404.vue')
  // },
  {
    path: '/*',
    name: 'anyRootPath',
    component: loadPage('views/Home.vue')  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  routes
})

const VUE_APP_SITE_DOMAIN = process.env.VUE_APP_SITE_DOMAIN
let toLoop = true;
router.beforeEach((to, from, next) => {
  // if the user is not authenticated, `next` is called twice
  if(to.name === "security" && toLoop) {
    toLoop = false;
    // router.push(".wellknown/security.txt")
    window.location.href = `${VUE_APP_SITE_DOMAIN}.wellknown/security.txt`;
  }else{
    next()
  }
})

export default router