/*eslint comma-dangle: [2, "always"]*/
export default {
  // Header
  'Terms and Conditions': 'Terms and Conditions',
  'FAQ': 'FAQ',

  // Footer
  'Footer Support Text': 'Our support department is available until 15.12.2025: <a href="mailto:contact@braun-moneyback.com" ><span><b>contact@braun-moneyback.com</b></span></a>',
  'Privacy': 'Privacy',
  'Impressum': 'Impressum',
  'Sitemap': 'Page Overview',

  // Home Screen
  'START REFUND': 'START REFUND',
  'Step 1': 'Step 1',
  'Step 2': 'Step 2',
  'Step 3': 'Step 3',
  'Step 4': 'Step 4',
  'Select your product': 'Select your product',
  'Upload a receipt': 'Upload a receipt',
  'Register': 'Register',
  'Get your money back': 'Get your money back',

  // Thank you page
  'referenceNumberText' : `<center>Your invoice and screenshot have been successfully sent to us and will now be verified.
  <br/>Reference number:<br/> <b>{referenceNumber}</b>
  <br/><br/>As soon as we have verified your data, you will either receive a confirmation email, or an email with further questions (if your data was incomplete or your invoice was not uploaded correctly).
  <br/>
  We ask for your patience as this can take up to 36 hours. After successfully verifying the invoice and other data, we will transfer the refund amount to the account you specified within 4 to 6 weeks. In the event that we have further questions, we will contact you by email
  </center>`,

  // Progress Bar

  'Select<br>product': 'Select<br>product',
  'Upload<br>a receipt': 'Upload<br>a receipt',
  'Get your<br>money back': 'Get your<br>money back',
  'Return form': '',
  'Print': 'Print',
  'Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation.': 'Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation.',

}
