<template>
  <div class="dropDownMainContainer">
    <div class="dropDownWrapper" @click="toggleDropdown" @keydown.enter="toggleDropdown" tabindex="0">
      <img :src="selectedFlag" alt="Flag image"/>
      <img src="/assets/svg/down-arrow.svg" alt="Down Arrow" role="button" class="transition" :style="isOpen ? 'transform: rotate(180deg)' : ''"/>
    </div>
    <div v-if="!hidden" ref="dropDownMenu" class="dropDownMenu transition">
      <div class="menuItem" :key="lang.code" v-for="lang in languages" @click="selectLanguage(lang)" @keydown.enter="selectLanguage(lang)" tabindex="0">
        <img class="flagImage" :src="lang.flag"/>
        <span
          class="languageText"
          v-html="selectedLanguage === lang.code ? `<b>${lang.text}</b>` : lang.text"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "language-dropdown",
  props: {
    languages: {
      type: Array,
      required: true,
      default: () => [{ text: "English", code: "en", flag: "/assets/flags/england.svg" }]
    }
  },
  data: () => {
    return {
      selectedLanguage: "dk",
      isOpen: false,
      disabled: true, // to prevent misclicks while in the animation
      hidden: true
    }
  },
  computed: {
    selectedFlag () {
      return this.languages.find(el => el.code === this.selectedLanguage).flag
    }
  },
  beforeMount () {
    let lang = localStorage.getItem("lang");
    if (lang) this.selectedLanguage = lang
    else lang = this.selectedLanguage

    localStorage.setItem('lang', lang)
    this.$root.$i18n.locale = lang
    history.replaceState({}, null, '/' + lang + '/')
    this.$store.commit("setCampaignId", `braun-moneyback-${lang}`)
  },
  methods: {
    toggleDropdown () {
      let dropDownMenu = this.$refs['dropDownMenu'];
      if (this.isOpen) { // if dd is open
        dropDownMenu.style.transform = "translate(0px, -70px)"
        dropDownMenu.style.opacity = "0"
        this.disabled = true;
        setTimeout(() => {
          this.hidden = true;
        }, 333);
      } else { // if dd is closed
        this.hidden = false;
        this.disabled = false;
        setTimeout(() => {
          dropDownMenu = this.$refs['dropDownMenu'];
          dropDownMenu.style.transform = "translate(0px, 0px)"
          dropDownMenu.style.opacity = "1"
        }, 0);
      }
      this.isOpen = !this.isOpen
    },
    selectLanguage (lang) {
      if (!this.disabled) {
        const acceptedCountryValues = {
        'se': {
          ConsentOverlayId: 'htmllang/0e5be564-d319-403b-ba88-7d317efbf9ab',
          lang: 'sv-SE',
          langCode: 'SE',
          marketingProgramNbr: '410',
          sourceID: '14297'
        },
        'fi': {
          ConsentOverlayId: 'htmllang/84101c15-422d-4ad8-a723-243b521f9ea2',
          lang: 'fi-FI',
          langCode: 'FI',
          marketingProgramNbr: '517',
          sourceID: '14300'
        },
        'dk': {
          ConsentOverlayId: "htmllang/d0eb68ab-bc82-42b2-b244-71aa7411579b",
          lang: 'da-DK',
          langCode: 'DK',
          marketingProgramNbr: '516',
          sourceID: '14298'
        },
        'no': {
          ConsentOverlayId: "htmllang/d5f3e3cb-0b40-4aa1-a64e-be487eb1b2eb",
          lang: 'no-NO',
          langCode: 'NO',
          marketingProgramNbr: '515',
          sourceID: '14299'
        }
      }
      const currentCountry = acceptedCountryValues[lang.code]
        window.PGdataLayer.GTM["SiteLocale"] = currentCountry.lang
        window.PGdataLayer.GTM["SiteCountry"] = lang.code.toUpperCase()
        window.PGdataLayer.GTM["SiteLanguage"] = currentCountry.langCode
        window.PGdataLayer.GTM["ConsentOverlayID"] = currentCountry.ConsentOverlayId
        window.PGdataLayer.user = { "id": "", "marketingProgramNbr": currentCountry.marketingProgramNbr, "sourceID": currentCountry.sourceID };
        this.selectedLanguage = lang.code
        localStorage.setItem("lang", this.selectedLanguage)
        this.$root.$i18n.locale = this.selectedLanguage
        history.replaceState({}, null, '/' + lang.code + '/')
        this.$store.commit("setCampaignId", `braun-moneyback-${lang.code}`)
        this.toggleDropdown()
        window.location.reload()
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/dropdown-oralb.scss";
</style>
