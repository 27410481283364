<template>
  <div class="container">
    <div class="row">
      <div v-if="locale === 'dk'">
        <div class="page-title">Ofte Stillede Spørgsmål</div>
        <ul ref="list" class="list">
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvilke betingelser skal jeg opfylde for at kunne deltage i
                  Braun penge-tilbage-garanti?
                </div>
                <div class="list-item-details">
                  <ul style="list-style-type: disc">
                    <li>Minimumsalder: Du skal være mindst 18 år gammel.</li>
                    <li>
                      Bopæl: Du skal have bopæl i Norge, Danmark, Sverige og
                      Finland.
                    </li>
                    <li>
                      Bankoplysninger: Du skal have et IBAN-nummer tildelt Danmark, Finland, Norge eller Sverige.
                    </li>
                    <li>
                      E-mailadresse: Du kan kun deltage med din egen
                      e-mail-adresse.
                    </li>
                  </ul>
                  <br />
                  Bemærk: Hvert IBAN og hver e-mail-adresse kun deltage i
                  kampagnen én gang.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvordan kan jeg deltage i Braun penge-tilbage-garanti?
                </div>
                <div class="list-item-details">
                  Du kan kun deltage online - via kampagnens websted
                  <a
                    href="https://www.braun-moneyback.com/dk"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><u>www.braun-moneyback.com</u></a
                  >. Processen kan beskrives som følger:
                  <ul>
                    <li>angive salgsfremmende produktet,</li>
                    <li>lav et foto af dit købsbevis og upload det,</li>
                    <li>
                      upload et foto af dit Braun produkt (du skal sørge for, at
                      stregkoden på dit salgsfremmende produkt er tydeligt
                      synlig),
                    </li>
                    <li>
                      indtaste bankoplysninger (IBAN), e-mail-adresse og andre
                      personlige oplysninger,
                    </li>
                    <li>
                      acceptere vilkår for deltagelse + privatlivspolitik.
                    </li>
                  </ul>
                  <br />
                  Når du har modtaget en bekræftelse på din online-registrering,
                  skal du sende produktet sammen med den tilsvarende kupon til
                  servicecentret inden for <b>10 dage</b>. Du vil modtage din
                  refusion inden for 6 uger efter, at pakken er ankommet og
                  bekræftet i servicecentret.
                  <br />
                  <br />
                  Bemærk: Det er ikke muligt at bruge forskellige
                  e-mail-adresser (brugerkonti) pr. person.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvor skal jeg sende pakken med produktet hen?
                </div>
                <div class="list-item-details">
                  Send venligst dit produkt sammen med returformularen og en
                  kopi af dit købsbevis til et af følgende servicecentre:
                  <br/><br/>
                  <b>Norge</b>
                  <br />
                  L.G. Elektro A/S
                  <br />
                  Kolstien 4
                  <br />
                  5097 Bergen
                  <br />
                  Norge
                  <br />
                  <br />
                  <b>Sverige</b>
                  <br />
                  El & Digital Service AB
                  <br />
                  Folkungagatan 144
                  <br />
                  116 30 Stockholm
                  <br />
                  Sverige
                  <br />
                  <br />
                  <b>Finland</b>
                  <br />
                  AIDE Europe Oy
                  <br />
                  Puusepänkatu 2D
                  <br />
                  00880 Helsinki
                  <br />
                  Suomi
                  <br />
                  <br />
                  <b>Danmark</b>
                  <br />
                  CJ Hvidevareservice
                  <br />
                  Thorndahlsvej 11
                  <br />
                  9200 Aalborg SV
                  <br />
                  Danmark
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvem skal jeg kontakte, hvis jeg har spørgsmål om Braun
                  penge-tilbage- garanti?
                </div>
                <div class="list-item-details">
                  Kontakt venligst Braun penge-tilbage-garanti kundeservice via
                  <a href="mailto:contact@braun-moneyback.com"
                    ><u>contact@braun-moneyback.com</u></a
                  >.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvilke produkter skal jeg købe for at kunne deltage i Braun
                  penge-tilbage-garanti?
                </div>
                <div class="list-item-details">
                  Følgende produkter deltager i Braun penge-tilbage-garanti:
                  <br />
                  <br />
                  prøveperiode på højst <b>60 dage</b> efter købet:
                  <br /><br/>Braun Silk-épil 9
                  <br/>Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX)
                  <br/>Braun Series 7
                  <br/>Braun Series 8
                  <br/>Braun Series 9
                  <br/>King C Gillette Stylemaster & Stylemaster eCom
                  <br />
                  <br />
                  prøveperiode på højst <b>120</b> dage efter købet:
                  <br />
                  <br />Braun Skin i·Expert Pro 7
                  <br />Braun Silk-expert Pro 5
                  <br />Braun Silk-expert Pro 3
                  <br />
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvor kan jeg købe de produkter, der deltager i Braun
                  penge-tilbage-garanti?
                </div>
                <div class="list-item-details">
                  Du kan købe salgsfremmende produkterne hos alle nordiske og
                  internationale forhandlere, der opererer i Norge, Danmark,
                  Sverige og Finland. Bemærk venligst, at vi ikke kan acceptere
                  private køb og køb på auktioner.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">Hvor meget vil jeg få refunderet?</div>
                <div class="list-item-details">
                  Du vil få refunderet 100% af den FAKTISKE købspris (minus
                  eventuelle rabatter) for det indsendte deltagende produkt.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvornår kan jeg deltage i Braun penge-tilbage-garanti?
                </div>
                <div class="list-item-details">
                  Perioden for køb af salgsfremmende produkter er fra
                  <b>01.07.2022</b> til og med <b>30.06.2025</b> (datoen for
                  købsbeviset er relevant her).
                  <br />
                  <br />
                  Du skal registrere din kvittering online:
                  <br />
                  <br />
                  <ul>
                    <li>
                      for Braun Silk-épil 9,
                      Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX),
                      Braun Series 7,
                      Braun Series 8,
                      Braun Series 9,
                      King C Gillette Stylemaster & Stylemaster eCom
                     - senest 60 dage efter
                      købet,
                    </li>
                    <li>
                      for Braun Silk-expert Pro 5,
                      Braun Silk-expert Pro 3
                       - senest 120 dage
                      efter købet.
                    </li>
                  </ul>
                  <br />
                  Når din online-registrering er blevet bekræftet, har du 10
                  dage til at sende produktet sammen med returformularen og en
                  kopi af dit købsbevis til et af vores servicecentre.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvor ofte kan jeg deltage i Braun penge-tilbage-garanti?
                </div>
                <div class="list-item-details">
                  Hver person (brugerkonto: kombination af IBAN og
                  e-mailadresse) kan deltage i Braun penge- tilbage-garanti med
                  højst ét købsbevis og ét salgsfremmende produkt.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvor mange produkter kan jeg deltage i Braun
                  penge-tilbage-garanti med?
                </div>
                <div class="list-item-details">
                  Der kan udstedes en tilbagebetaling for ét salgsfremmende
                  produkt pr person.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvor længe skal jeg opbevare købsbeviset?
                </div>
                <div class="list-item-details">
                  Du bedes opbevare købsbeviset, indtil du modtager
                  tilbagebetalingen.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvad er IBAN, og hvor kan jeg finde det?
                </div>
                <div class="list-item-details">
                  IBAN står for International Bankkontonummer. Det er en
                  internationalt godkendt kode bestående af op til 34 bogstaver
                  og tal, som hjælper bankerne med at behandle overførsler i
                  hele verden. Du kan normalt finde dit IBAN-nummer øverst til
                  højre på dit kontoudtog eller i din netbank. Hvis du ikke kan
                  finde dit IBAN-nummer, bør du kunne generere det online via
                  din banks netbank eller ved at bruge et IBAN-beregnerværktøj.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvad er BIC, og hvor kan jeg finde det?
                </div>
                <div class="list-item-details">
                  BIC står for Bankidentifikationskode. For at sende eller
                  modtage penge skal enhver finansiel institution vide, hvor
                  pengene skal sendes hen, og BIC er præcis det, der viser det.
                  <br />
                  Du kan normalt finde din banks BIC på dine kontoudtog. Hvis du
                  bruger netbank, kan du logge ind på din digitale bankkonto for
                  nemt at se dit kontoudtog eller kontakte din bank, så de kan
                  give dig din BIC.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Min partner og jeg har en fælles IBAN-konto. Kan vi begge
                  deltage separat med denne konto i Braun penge-tilbage-garanti?
                </div>
                <div class="list-item-details">
                  Hvert IBAN kan kun bruges af én bruger i hele kampagneperioden
                  (i en fast kombination med e- mail-adresse). En fælles brug
                  sammen med en anden deltager er ikke mulig uanset en eventuel
                  personlig forbindelse.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvornår kan jeg forvente at få min tilbagebetaling?
                </div>
                <div class="list-item-details">
                  Efter en positiv kontrol af din deltagelse vil
                  refunderingsbeløbet blive overført til den bankkonto (IBAN),
                  du har angivet, inden for højst 6 uger.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Kan jeg deltage i andre kampagner med det samme købsbevis?
                </div>
                <div class="list-item-details">
                  Hvis du allerede har deltaget i en parallel kampagne med dit
                  købsbevis og allerede har modtaget en bekræftelse på
                  tilbagebetaling for dit Braun produkt, er det ikke muligt at
                  deltage i Braun penge-tilbage-garanti med dette produkt og
                  dette købsbevis.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Mit købsbevis indeholder mere end 1 Braun salgsfremmende
                  produkt. Kan to eller flere personer bruge dette købsbevis?
                </div>
                <div class="list-item-details">
                  Generelt kan et købsbevis kun deltage i vores Braun
                  penge-tilbage-garanti én gang. Hverken omfanget af de
                  salgsfremmende produkter, der er anført på købsbeviset, kan
                  tages i betragtning, eller et salgsfremmende produkt kan
                  overdrages til en anden person.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Jeg har ikke modtaget en bekræftelsesmail efter at have
                  gennemført deltagelsesprocessen, hvad nu?
                </div>
                <div class="list-item-details">
                  Efter en vellykket registrering har du modtaget en
                  bekræftelsesmail med et 10-cifret referencenummer. Tjek også
                  din SPAM- eller JUNK-mappe. Hvis du ikke har modtaget et
                  10-cifret referencenummer, bedes du deltage igen via
                  kampagnens websted
                  <a
                    href="https://www.braun-moneyback.com/dk"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><u>www.braun-moneyback.com</u></a
                  >.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Når jeg gennemgår deltagelsesprocessen, går den ned på et
                  bestemt tidspunkt. Hvad skal jeg gøre?
                </div>
                <div class="list-item-details">
                  Generelt kan et købsbevis kun deltage i vores Braun
                  penge-tilbage-garanti én gang. Hverken omfanget af de
                  salgsfremmende produkter, der er anført på købsbeviset, kan
                  tages i betragtning, eller et salgsfremmende produkt kan
                  overdrages til en anden person.Braun Money Back - Nordics Hvis
                  du går i stå under deltagelsesprocessen af tekniske årsager,
                  anbefales det - hvis det er muligt - at bruge en anden enhed.
                  Du kan f.eks. skifte fra din mobiltelefon til din pc. Hvis
                  dette ikke virker, kan du prøve at bruge en anden browser.
                  Hvis disse små tips ikke hjælper dig, kan du sende en e- mail
                  til vores supportafdeling:
                  <a href="mailto:contact@braun-moneyback.com"
                    ><u>contact@braun-moneyback.com</u></a
                  >. Giv venligst en præcis beskrivelse af problemet i din
                  e-mail - et skærmbillede af det punkt, hvor det ikke længere
                  kan gå, ville være perfekt. Andre nyttige oplysninger er,
                  hvilken enhed, hvilket operativsystem og hvilken browser du
                  bruger. Vores supportteam vil derefter stå til rådighed for
                  dig.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvordan kan jeg se, om jeg har gennemført upload-processen for
                  købsbeviset?
                </div>
                <div class="list-item-details">
                  Så snart du har uploadet dit købsbevis, indtastet dine
                  personlige oplysninger og bankoplysninger og bekræftet dine
                  oplysninger via en sms-kode, vises en meddelelse på skærmen,
                  der bekræfter, at dit forsøg på at deltage er lykkedes, og som
                  viser dit personlige referencenummer. Du vil også straks
                  modtage en e-mail med en bekræftelse eller et afslag til den
                  e-mailadresse, du har angivet.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvor længe er supportafdelingen tilgængelig?
                </div>
                <div class="list-item-details">
                  Vores supportafdeling er tilgængelig indtil 15.12.2025.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Min deltagelse er blevet bekræftet, men
                  tilbagebetalingsbeløbet er forkert. Hvad kan jeg gøre?
                </div>
                <div class="list-item-details">
                  I din bekræftelses-e-mail finder du en knap, som du kan bruge
                  til at anmode om en ny vurdering af din sag, som dog bliver
                  ugyldig efter 72 timer. I dette tilfælde skal du kontakte
                  vores supportteam via
                  <a href="mailto:contact@braun-moneyback.com"
                    ><u>contact@braun-moneyback.com</u></a
                  >
                  og beskrive din sag i en e-mail. De vil tage sig af dine
                  bekymringer der.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvor længe har jeg mulighed for at anmode om en ny vurdering
                  af min sag?
                </div>
                <div class="list-item-details">
                  En automatisk revurdering ved hjælp af de sendte links kan
                  anmodes om inden for 72 timer efter, at e-mailen med
                  bekræftelse eller afvisning er sendt. Herefter kan du kun
                  anmode om, at din sag behandles igen via vores supportteam (<a
                    href="mailto:contact@braun-moneyback.com"
                    ><u>contact@braun-moneyback.com</u></a
                  >). Angiv venligst altid dit referencenummer!
                  <br />
                  <br />
                  Bemærk venligst, at denne mulighed også kun er tilgængelig i
                  begrænset omfang. Dine data bliver slettet efter
                  tilbagebetaling-kampagnen er udløbet, behandling er derfor kun
                  mulig op til 7 uger efter fristen - dvs. indtil 15.12.2025.
                  Herefter lukkes supportafdelingen, og dine henvendelser kan
                  ikke længere behandles.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Mit købsbevis blev afvist, fordi ikke alle data kan ses.
                  Hvilke data er der tale om?
                </div>
                <div class="list-item-details">
                  På købsbeviset skal forhandleren (filialen), salgsfremmende
                  produktet, salgsfremmende produktets pris, købsbevisets
                  samlede beløb, købsbevisets ID-nummer (ikke bankens
                  transaktionsnummer-ID), momsen, eventuelle rabatter samt dato
                  og klokkeslæt fremgå tydeligt.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Mit købsbevis er meget langt og bliver ikke læsbart, når jeg
                  tager et billede. Hvordan kan jeg komme videre?
                </div>
                <div class="list-item-details">
                  Send venligst købsbeviset i ét billede, når du registrerer dig
                  på vores websted. Hvis du modtager en e-mail med besked om, at
                  det ikke er læsbart, skal du sende dit købsbevis opdelt i
                  flere billeder til supportteamet
                  <a href="mailto:contact@braun-moneyback.com"
                    ><u>contact@braun-moneyback.com</u></a
                  >. Du skal sørge for, at bunden af hvert billede er lig med
                  toppen af det næste billede. Hele købsbeviset skal således
                  sendes i stykker.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvorfor blev min deltagelse afvist, selv om jeg har købt de
                  korrekte produkter?
                </div>
                <div class="list-item-details">
                  Det kan f.eks. være en teknisk fejl, eller det kan være, at
                  dit købsbevis ikke er skarpt nok eller overeksponeret.
                  <br />
                  I den nederste tredjedel af din e-mail med afslag finder du en
                  knap, hvor du kan uploade dit købsbevis igen. Klik på denne
                  knap, og du vil automatisk blive omdirigeret til genindlæsning
                  af købsbeviset. Du bedes tage et nyt foto af dit købsbevis og
                  uploade det igen inden for 72 timer. Bemærk venligst, at
                  knappen mister sin funktionalitet efter 72 timer.
                  <br />
                  Hvis din deltagelse afvises igen, bedes du kontakte os via
                  support-e-mailadressen, som du også kan finde i din e-mail med
                  afvisning (<a href="mailto:contact@braun-moneyback.com"
                    ><u>contact@braun-moneyback.com</u></a
                  >).
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Mit købsbevis blev ikke accepteret eller blev endda betegnet
                  som svindel. Hvordan kan det være?
                </div>
                <div class="list-item-details">
                  Hvis dit købsbevis er for sløret eller for mørkt eller
                  overbelyst, så dataene ikke kan ses tydeligt, er der en
                  mulighed for, at du modtager en e-mail med afvisning, hvori
                  købsbeviset beskrives som falsk, eller at du bliver bedt om at
                  uploade et billede af bedre kvalitet af dit købsbevis. Hjælp
                  os med at acceptere din deltagelse hurtigt og uden
                  komplikationer ved at uploade et foto af god kvalitet (ikke en
                  scanning!) af dit købsbevis, som viser, at det er en original
                  kvittering, og hvor alle de nødvendige data tydeligt kan ses.
                  Du sparer en masse tid og besvær for både dig selv og os.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Kan jeg også sende mit købsbevis pr. e-mail?
                </div>
                <div class="list-item-details">
                  Du kan kun gennemføre online deltagelsesprocessen på vores
                  salgsfremmende websted
                  <a
                    href="https://www.braun-moneyback.com/dk"
                    target="_blank"
                    rel="noopener noreferrer"
                    ><u>www.braun-moneyback.com</u></a
                  >. Genindsendelse af købsbeviset kan kun ske via en knap til
                  genupload, som du finder i din afvisningsmail. Hvis du ikke
                  har modtaget en afvisningsmail eller en bekræftelsesmail, er
                  det et tegn på, at din deltagelse ikke lykkedes, og at du skal
                  gennemgå deltagelsesprocessen igen.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Jeg indtastede et forkert IBAN ved registreringen. Hvad sker
                  der i dette tilfælde?
                </div>
                <div class="list-item-details">
                  Hvis du har indtastet et forkert IBAN-nummer, så overførslen
                  ikke kan gennemføres, har du én gang mulighed for at ændre
                  det. Du kan kontakte den relevante afdeling pr. e-mail inden
                  15.12.2025:
                  <a href="mailto:support@braun-moneyback.com"
                    >support@braun-moneyback.com</a
                  >. Hvis en vellykket overførsel heller ikke kan gennemføres
                  med dette IBAN, bortfalder retten til tilbagebetaling.
                  Kontroller venligst, at dette IBAN endnu ikke er blevet brugt
                  til en anden tilbagebetaling som en del af vores Braun
                  penge-tilbage-garanti.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Hvad sker der med mine oplysninger, som jeg har indtastet på
                  kampagnens websted?
                </div>
                <div class="list-item-details">
                  Behandlingen af kampagnedata er baseret med henblik på
                  gennemførelse af kampagnen. I det omfang du får mulighed for
                  at give dit samtykke (især til at modtage oplysninger om
                  Procter & Gamble-mærker pr. e-mail) i forbindelse med
                  kampagnen på kampagnens websted eller på anden vis, er dette
                  samtykke frivilligt og har ingen indvirkning på deltagelsen i
                  kampagnen. Du kan til enhver tid tilbagekalde dit samtykke
                  helt eller delvist. Kampagnedataene opbevares i en periode på
                  tre måneder efter fristen for den operationelle behandling af
                  kampagnen.
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="list-item-wrapper">
              <div class="list-item">
                <div class="number">
                  Jeg har et generelt spørgsmål om Procter & Gamble
                  International Operations SA. Hvem skal jeg tale med?
                </div>
                <div class="list-item-details">
                  Du bedes kontakte Procter & Gamble International Operations SA
                  via
                  <a href="mailto:contactCH.im@pg.com"
                    ><u>contactCH.im@pg.com</u></a
                  >.
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="locale === 'fi'">
        <div class="page-title">FAQ</div>
        <ul ref="list" class="list">
          <li class="list-item-wrapper">
            <div class="number">
              Mitä ehtoja minun on täytettävä, jotta voin osallistua Braun Rahat
              takaisin -takuu?
            </div>
            <div class="list-item-details">
              <ul>
                <li>Vähimmäisikäraja: Sinun on oltava vähintään 18-vuotias.</li>
                <li>
                  Asuinpaikka: Asuinpaikan on oltava Norjassa, Tanskassa,
                  Ruotsissa tai Suomessa.
                </li>
                <li>
                  Pankkiyhteydet: Sinulla on Tanskaan, Suomeen, Norjaan tai Ruotsiin määritetty pankkitili
IBAN-numerolla.
                </li>
                <li>
                  Sähköpostiosoite: Voit osallistua vain omalla
                  sähköpostiosoitteellasi.
                </li>
              </ul>
              <p>
                Huomautus: Kukin IBAN ja sähköpostiosoite voi osallistua
                kampanjaan vain kerran.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              Miten voin osallistua Braun Rahat takaisin -takuu?
            </div>
            <div class="list-item-details">
              <p>
                Voit osallistua vain verkossa - kampanjan verkkosivuston
                <a href="https://www.braun-moneyback.com/fi"
                  >www.braun-moneyback.com</a
                >
                kautta. Prosessi voidaan kuvata seuraavasti:<br /><br />-
                määritä kampanjatuote,<br />- ota kuva ostotodistuksestasi ja
                lataa se,<br />- lataa kuva Braun-tuotteen (sinun on
                varmistettava, että kampanjatuotteen viivakoodi näkyy selvästi),
                <br />- anna pankkitiedot (IBAN), sähköpostiosoite ja muita
                henkilökohtaisia tietoja,<br />- hyväksy osallistumisehdot ja
                tietosuojaselosteet.
              </p>
              <p>
                <br />Kun olet saanut vahvistuksen online-rekisteröinnistäsi,
                sinun on lähetettävä tuote ja sitä vastaava kuponki
                palvelukeskukseen 10 päivän kuluessa. Saat hyvityksesi 6 viikon
                kuluessa siitä, kun paketti on saapunut ja vahvistettu
                palvelukeskuksessa.
              </p>
              <p>
                <br />Huomautus: Ei ole mahdollista käyttää eri
                sähköpostiosoitteita (käyttäjätilit) per henkilö.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Minne minun pitäisi lähettää paketti tuotteineen?</p>
            </div>
            <div class="list-item-details">
              <p>
                Lähetä tuote yhdessä palautuslomakkeen ja kopion ostotositteesta
                kanssa johonkin P&amp;G seuraavista palvelukeskuksista:<br /><br /><strong
                  >Norja</strong
                >
              </p>
              <p>L.G. Elektro A/S</p>
              <p>Kolstien 4</p>
              <p>5097 Bergen</p>
              <p>Norge</p>
              <p>
                <br /><strong>Ruotsi</strong><br />El &amp; Digital Service
                AB<br />Folkungagatan 144<br />116 30 Stockholm<br />Sverige<br /><br /><strong
                  >Suomi</strong
                ><br />AIDE Europe Oy
              </p>
              <p>Puusepänkatu 2D</p>
              <p>00880 Helsinki</p>
              <p>Suomi<br /><br /><strong>Tanska</strong></p>
              <p>
                CJ Hvidevareservice<br />Thorndahlsvej 11<br />9200 Aalborg
                SV<br />Danmark
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Keneen otan yhteyttä, jos minulla on kysyttävää Braun Rahat
                takaisin -takuu?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Ota yhteyttä
                <em>Braun Rahat takaisin -takuu</em> asiakaspalveluun
                <a href="mailto:contact@braun-moneyback.com"
                  ><u>contact@braun-moneyback.com</u></a
                >.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Mitä tuotteita minun on ostettava voidakseni osallistua Braun
                Rahat takaisin -takuu?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Seuraavat tuotteet osallistuvat
                <em>Braun Rahat takaisin -takuu</em>:
              </p>
              <p>
                <br />kokeiluaika enintään <strong>60 päivää</strong> oston
                jälkee:
                <br /><br/>Braun Silk-épil 9
                <br/>Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX)
                <br/>Braun Series 7
                <br/>Braun Series 8
                <br/>Braun Series 9
                <br/>King C Gillette Stylemaster & Stylemaster eCom
                <br />
                <br />
                kokeiluaika enintään
                <strong>120 päivää</strong> oston jälkee:
                <br />Braun Skin i·Expert Pro 7
                <br />Braun Silk-expert Pro 5
                <br />Braun Silk-expert Pro 3
                <br />
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Mistä voin ostaa Braun Rahat takaisin -takuu osallistuvia
                tuotteita?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Voit ostaa kampanjatuotteita kaikista pohjoismaisista ja
                kansainvälisistä jälleenmyyjistä, jotka toimivat Norjassa,
                Tanskassa, Ruotsissa ja Suomessa. Huomaathan, että emme voi
                hyväksyä yksityisostoksia tai huutokaupoista tehtyjä ostoksia.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Kuinka paljon saan korvausta?</p>
            </div>
            <div class="list-item-details">
              <p>
                Sinulle korvataan 100% toimitetun osallistuvan tuotteen
                TODELLISESTA ostohinnasta (vähennettynä mahdollisilla
                alennuksilla).
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Milloin voin osallistua Braun Rahat takaisin -takuu?</p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Myynninedistämistuotteiden ostoaika on
                <strong>01.07.2022</strong> ja
                <strong>30.06.2025</strong> välisenä aikana (ostotodistuksen
                päivämäärä on tässä yhteydessä merkityksellinen).
              </p>
              <p>
                Sinun tulisi rekisteröidä kuittisi verkossa:<br /><br />- 
                Braun Silk-épil 9,
                Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX),
                Braun Series 7,
                Braun Series 8,
                Braun Series 9,
                King C Gillette Stylemaster & Stylemaster eCom
                
                
                osalta - <strong>60 päivän</strong> kuluessa ostosta,
              </p>
              <p>
                <br/>- 
                Braun Silk-expert Pro 5,
                Braun Silk-expert Pro 3 osalta -
                <strong>120 päivän</strong> kuluessa ostosta.
              </p>
              <p>
                <br />Kun online-rekisteröinti on vahvistettu, sinulla on
                <strong>10 päivää</strong> aikaa lähettää tuote yhdessä
                palautuslomakkeen ja kopion ostotositteesta kanssa johonkin
                palvelukeskuksistamme.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Kuinka usein voin osallistua Braun Rahat takaisin -takuu?</p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Kukin henkilö (käyttäjätili: IBANin ja sähköpostiosoitteen
                yhdistelmä) voi osallistua
                <em>Braun Rahat takaisin -takuu</em> enintään yhdellä
                ostotodistuksella ja yhdellä kampanjatuotteella.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Kuinka monella tuotteella voin osallistua Braun Rahat takaisin
                -takuu?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Palautus voidaan myöntää yhdestä
                myynninedistämistuotteesta henkilöä kohden.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Kuinka kauan minun on säilytettävä ostotodistus?</p>
            </div>
            <div class="list-item-details">
              <p>Säilytä ostotodistus, kunnes saat palautuksen.</p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Mikä on IBAN ja mistä löydän sen?</p>
            </div>
            <div class="list-item-details">
              <p>
                IBAN tarkoittaa kansainvälistä tilinumeroa. Se on enintään 34
                kirjaimesta ja numerosta koostuva kansainvälisesti sovittu
                koodi, joka auttaa pankkeja käsittelemään tilisiirtoja
                kaikkialla maailmassa. <br />IBAN-numerosi löydät yleensä
                pankkitiliotteesi oikeasta yläkulmasta tai verkkopankissasi. Jos
                et löydä IBAN-numeroasi, sinun pitäisi pystyä luomaan se
                verkossa pankkisi verkkopankkipalvelun kautta tai käyttämällä
                IBAN-laskentatyökalua.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Mikä on BIC ja mistä löydän sen?</p>
            </div>
            <div class="list-item-details">
              <p>
                BIC on lyhenne sanoista Bank Identifier Code. Rahan lähettämistä
                tai vastaanottamista varten minkä tahansa rahoituslaitoksen on
                tiedettävä, minne rahat lähetetään, ja BIC on juuri se, mikä sen
                osoittaa. <br />Löydät pankkisi BIC-koodin yleensä
                tiliotteistasi. Jos käytät verkkopankkia, kirjaudu
                digitaaliselle pankkitilillesi nähdäksesi helposti tiliotteen
                tai ota yhteyttä pankkiisi, jotta se antaa sinulle BIC-koodisi.
              </p>
              <p>0</p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Minulla ja kumppanillani on yhteinen IBAN-tili. Voimmeko
                molemmat osallistua erikseen tällä tilillä Braun Rahat takaisin
                -takuu?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Kutakin IBAN-tilinumeroa voi käyttää vain yksi käyttäjä
                koko kampanjan ajan (kiinteässä yhdistelmässä
                sähköpostiosoitteen kanssa). Yhteiskäyttö toisen osallistujan
                kanssa ei ole mahdollista henkilökohtaisesta yhteydestä
                riippumatta.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Milloin voin odottaa hyvitystä?</p>
            </div>
            <div class="list-item-details">
              <p>
                Kun osallistumisesi on tarkastettu, palautusmäärä siirretään
                ilmoittamallesi pankkitilille (IBAN) enintään 6 viikon kuluessa.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Voinko osallistua muihin kampanjoihin samalla ostotodistuksella?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Jos olet jo osallistunut rinnakkaiseen kampanjaan
                ostotodistuksellasi ja olet jo saanut vahvistuksen
                Braun-tuotteen hyvityksestä, et voi osallistua
                <em>Braun Rahat takaisin -takuu</em> tällä tuotteella ja tällä
                ostotodistuksella.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Ostotodistukseni sisältää enemmän kuin 1 Braun kampanjatuotetta.
                Voiko tätä ostotodistusta käyttää kaksi tai useampi henkilö?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Yleensä ostotositteella voi osallistua
                <em>Braun Rahat takaisin -takuu</em> vain kerran.
                Ostotodistuksessa lueteltujen kampanjatuotteiden laajuutta ei
                voida ottaa huomioon, eikä kampanjatuotetta voi siirtää toiselle
                henkilölle.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                En ole saanut vahvistussähköpostia osallistumisprosessin
                suorittamisen jälkeen, mitä nyt?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Onnistuneen rekisteröinnin jälkeen olet saanut
                vahvistussähköpostin, jossa on 10-numeroinen viitenumero.
                Tarkista myös SPAM- tai JUNK-kansiosi. Jos et ole saanut
                10-numeroista viitenumeroa, osallistu uudelleen kampanjan
                verkkosivuston
                <a href="https://www.braun-moneyback.com/fi"
                  >www.braun-moneyback.com</a
                >
                kautta.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Osallistumisprosessia läpikäydessä se kaatuu tietyssä vaiheessa.
                Mitä minun pitäisi tehdä?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Jos osallistumisprosessi jumiutuu teknisistä syistä, on
                suositeltavaa - jos mahdollista - käyttää toista laitetta.
                Vaihda esimerkiksi matkapuhelimesta tietokoneeseen. Jos tämä ei
                onnistu, voit kokeilla käyttää toista selainta. Jos nämä pienet
                vinkit eivät auta sinua, lähetä sähköpostia tukiosastollemme:
                <a href="mailto:contact@braun-moneyback.com"
                  ><u>contact@braun-moneyback.com</u></a
                >. Anna sähköpostissasi tarkka kuvaus ongelmasta - kuvakaappaus
                kohdasta, jossa ei enää voi mennä, olisi täydellinen. Muita
                hyödyllisiä tietoja ovat laite, käyttöjärjestelmä ja selain,
                jota käytät. Tukitiimimme on sitten tukenasi.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Mistä tiedän, olenko suorittanut ostotodisteen latausprosessin
                onnistuneesti?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Heti kun olet ladannut ostotodistuksen, syöttänyt henkilö- ja
                pankkitietosi ja vahvistanut tietosi tekstiviestikoodilla,
                näytölle ilmestyy viesti, jossa vahvistetaan
                osallistumisyrityksesi onnistuminen ja näytetään
                henkilökohtainen viitenumerosi. Saat myös viipymättä vahvistus-
                tai hylkäyssähköpostin antamaasi sähköpostiosoitteeseen.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Kuinka kauan tukipalvelu on käytettävissä?</p>
            </div>
            <div class="list-item-details">
              <p>Tukiosastomme on tavoitettavissa 15.12.2025 asti.</p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Osallistumiseni on vahvistettu, mutta palautuksen määrä on
                virheellinen. Mitä voin tehdä?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Vahvistussähköpostissasi on painike, jonka avulla voit pyytää
                tapauksesi uudelleenarviointia, joka kuitenkin raukeaa 72 tunnin
                kuluttua. Tässä tapauksessa ota yhteyttä tukitiimiimme
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >
                ja kuvaile tapauksesi sähköpostitse. He hoitavat huolesi siellä.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Kuinka kauan minulla on mahdollisuus pyytää tapaukseni
                uudelleenarviointia?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Automaattista uudelleenarviointia lähetettyjen linkkien avulla
                voi pyytää 72 tunnin kuluessa vahvistus- tai hylkäyssähköpostin
                lähettämisestä. Tämän jälkeen voit pyytää tapauksesi
                uudelleenkäsittelyä vain tukitiimimme kautta (<a
                  href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >). Ilmoita aina viitenumerosi!<br />Huomaa, että tämäkin
                vaihtoehto on käytettävissä vain rajoitetusti. Tietosi
                poistetaan rahapalautuskampanjan päättymisen jälkeen, joten
                käsittely on mahdollista vain 7 viikkoon asti määräajan
                päättymisen jälkeen - eli 15.12.2025 asti. Tämän jälkeen tuki
                suljetaan, eikä tiedustelujasi voida enää käsitellä.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Ostotodistukseni hylättiin, koska kaikki tiedot eivät näy. Mitä
                tietoja tarkoitetaan?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Ostotodistuksessa on oltava selvästi näkyvissä jälleenmyyjä
                (konttori), kampanjatuote, kampanjatuotteen hinta,
                ostotodistuksen loppusumma, ostotodistuksen ID-numero (ei
                pankkitapahtuman numerotunnus), arvonlisävero, mahdolliset
                alennukset sekä päivämäärä ja kellonaika.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Ostotodistukseni on hyvin pitkä, eikä sitä voi lukea, kun otan
                siitä kuvan.<br />Miten voin edetä?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Lähetä ostotodistus yhdessä kuvassa, kun rekisteröidyt
                verkkosivustollamme. Jos saat sähköpostiviestin, jossa
                kerrotaan, että sitä ei voi lukea, lähetä ostotodistus useampaan
                kuvaan jaettuna tukitiimille
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >. Varmista, että jokaisen kuvan alareuna vastaa seuraavan kuvan
                yläreunaa. Näin ollen koko ostotodistus on lähetettävä osissa.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Miksi osallistumiseni hylättiin, vaikka ostin oikeat tuotteet?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Kyse voi olla esimerkiksi teknisestä virheestä tai siitä, että
                ostotositteesi ei ole tarpeeksi terävä tai ylivalottunut.<br />Hylkäävän
                sähköpostiviestin alemmassa kolmanneksessa on painike, jolla
                voit ladata ostotodistuksen uudelleen. Napsauta tätä painiketta,
                niin sinut ohjataan automaattisesti ostotodistuksen
                uudelleenladatamiseen. Ota uusi kuva ostotodistuksestasi ja
                ladata se uudelleen 72 tunnin kuluessa. Huomaa, että painike
                menettää toimintakykynsä 72 tunnin jälkeen.<br />Jos
                osallistumisesi hylätään uudelleen, ota meihin yhteyttä
                tukisähköpostiosoitteessa, joka löytyy myös
                hylkäyssähköpostiviestistäsi (<a
                  href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >).
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Ostotodistustani ei hyväksytty tai se jopa leimattiin
                petokseksi. Miten se voi olla mahdollista?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Jos ostotodistus on liian epätarkka tai liian tumma tai
                ylivalottunut niin, että tiedot eivät näy selvästi, on
                mahdollista, että saat hylkäävän sähköpostiviestin, jossa
                ostotodistus kuvataan väärennökseksi tai sinua pyydetään
                lataamaan ostotodistuksestasi parempilaatuinen kuva. Auta meitä
                hyväksymään osallistumisesi nopeasti ja mutkattomasti lataamalla
                hyvälaatuinen kuva (ei skannaus!) ostotodistuksestasi, josta käy
                ilmi, että se on alkuperäinen kuitti ja josta kaikki tarvittavat
                tiedot näkyvät selvästi. Säästät paljon aikaa ja vaivaa sekä
                itsellesi että meille.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Voinko lähettää ostotodistukseni myös sähköpostitse?</p>
            </div>
            <div class="list-item-details">
              <p>
                Voit suorittaa online-osallistumisprosessin vain
                mainossivustollamme
                <a href="https://www.braun-moneyback.com/fi"
                  >www.braun-moneyback.com</a
                >. Ostotodistuksen voi lähettää uudelleen vain uudelleen ladata
                painikkeella, jonka löydät hylkäävästä sähköpostiviestistäsi.
                Jos et ole saanut hylkäyssähköpostia tai vahvistussähköpostia,
                tämä on merkki siitä, että osallistumisesi ei onnistunut ja
                sinun on käytävä osallistumisprosessi läpi uudelleen.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Syötin väärän IBAN-tilinumeron rekisteröitymisen yhteydessä.
                Mitä tässä tapauksessa tapahtuu?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Jos olet syöttänyt väärän IBAN-tilinumeron, joten siirtoa ei
                voida suorittaa, sinulla on kerran mahdollisuus muuttaa se. Voit
                ottaa yhteyttä asianomaiseen osastoon sähköpostitse 15.12.2025
                mennessä:
                <a href="mailto:support@braun-moneyback.com"
                  >support@braun-moneyback.com</a
                >. Jos siirtoa ei voida tehdä onnistuneesti myöskään tällä
                IBAN-tilinumerolla, oikeus palautukseen raukeaa. Varmista, että
                tätä IBAN-tilinumeroa ei ole vielä käytetty toiseen palautukseen
                osana <em>Braun Rahat takaisin -takuu</em>.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Mitä tapahtuu tiedoilleni, jotka annoin kampanjasivustolla?</p>
            </div>
            <div class="list-item-details">
              <p>
                Kampanjatietojen käsittely perustuu kampanjan toteuttamiseksi.
                Siltä osin kuin sinulle annetaan kampanjan yhteydessä
                kampanjasivustolla tai muulla tavoin mahdollisuus antaa
                suostumuksesi (erityisesti saada sähköpostitse tietoa Procter
                &amp; Gamble -brändeistä), tämä suostumus on vapaaehtoinen eikä
                sillä ole vaikutusta kampanjaan osallistumiseen. Voit peruuttaa
                suostumuksesi kokonaan tai osittain milloin tahansa. Kampanjan
                tietoja säilytetään kolmen kuukauden ajan kampanjan
                operatiivisen käsittelyn määräajan päättymisestä.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Minulla on yleinen kysymys Procter &amp; Gamble International
                Operations. SA. Kenen kanssa minun pitäisi keskustella?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Ota yhteyttä Procter &amp; Gamble International Operations
                SA:han
                <a href="mailto:contactCH.im@pg.com">contactCH.im@pg.com</a>.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="locale === 'no'">
        <div class="page-title">FAQ</div>
        <ul ref="list" class="list">
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hvilke betingelser må jeg oppfylle for å delta i Braun pengene
                tilbake-garanti?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <ul>
                <li>Minimumsalder: Du må være minst 18 år gammel.</li>
                <li>
                  Bosted: Bosted må være i Norge, Danmark, Sverige og Finland.
                </li>
                <li>
                  Bankdetaljer: Du må ha et tilknyttet Danmark, Finland, Norge eller Sverige.
                </li>
                <li>
                  E-postadresse: Du kan bare delta med din egen e-postadresse.
                </li>
              </ul>
              <p>
                MERK: Hver IBAN og e-postadresse bare delta i kampanjen én gang.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hvordan kan jeg delta i Braun pengene tilbake-garanti?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Du kan delta online - via kampanjenettstedet
                <a href="https://www.braun-moneyback.com/no"
                  >www.braun-moneyback.com</a
                >. Prosessen kan beskrives som følger:<br /><br />
              </p>
              <ul>
                <li>spesifiser salgsfremmende produktet,</li>
                <li>lage et bilde av kjøpsbeviset ditt og laste det opp,</li>
                <li>
                  last opp et bilde av ditt Braun produkt (du må sørge for at
                  strekkoden til salgsfremmende produktet ditt er tydelig
                  synlig),
                </li>
                <li>
                  angi bankdetaljer (IBAN), e-postadresse, ytterligere
                  personopplysninger,
                </li>
                <li>
                  godta vilkår for deltakelse + personvernregler.<br /><br />
                </li>
              </ul>
              <p>
                Etter kundebekreftelsen for din online registrering, må du sende
                produktet sammen med tilsvarende bilag til servicesenteret innen
                <strong>10 dager</strong>. Du vil motta refusjonen innen 6 uker
                etter at pakken ankommer og er bekreftet i servicesenteret.
              </p>
              <p>
                <br />Merk: Det er ikke mulig å bruke forskjellige
                e-postadresser (brukerkontoer) per person.
              </p>
              <p>0</p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hvor skal jeg sende pakken med produktet til?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Vennligst send produktet sammen med returskjemaet og en kopi av
                kjøpsbeviset ditt til et av følgende P&amp;G servicesentre:<br /><br /><strong
                  >Norge</strong
                ><br />L.G. Elektro A/S
              </p>
              <p>Kolstien 4</p>
              <p>5097 Bergen</p>
              <p>
                Norge<br /><br /><strong>Sverige</strong><br />El &amp; Digital
                Service AB<br />Folkungagatan 144<br />116 30 Stockholm<br />Sverige
              </p>
              <p><br /><strong>Finland</strong></p>
              <p>AIDE Europe Oy</p>
              <p>Puusepänkatu 2D</p>
              <p>00880 Helsinki</p>
              <p>
                Suomi<br /><br /><strong>Danmark</strong><br />CJ
                Hvidevareservice<br />Thorndahlsvej 11<br />9200 Aalborg SV<br />Danmark
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hvem kontakter jeg hvis jeg har spørsmål om Braun pengene
                tilbake-garanti?
              </p>
              <p></p>
              <p>
                Kontakt <em>Braun pengene tilbake-garanti</em> kundeservice via
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >.
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Hvilke produkter må jeg kjøpe for å delta i Braun pengene
                tilbake-garanti?
              </p>
              <p>
                <br />Følgende produkter deltar i
                <em>Braun pengene tilbake-garanti</em>:<br /><br />
              </p>
              <p>
                prøveperiode på maksimalt <strong>60 dager</strong> etter
                kjøpet:
                <br /><br/>Braun Silk-épil 9
                <br/>Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX)
                <br/>Braun Series 7
                <br/>Braun Series 8
                <br/>Braun Series 9
                <br/>King C Gillette Stylemaster & Stylemaster eCom
                <br />
                <br />
              </p>
              <p>
                prøveperiode på maksimalt <strong>120 dager</strong> etter
                kjøpet:
                <br />Braun Skin i·Expert Pro 7
                <br />Braun Silk-expert Pro 5
                <br />Braun Silk-expert Pro 3
                <br /><br />
              </p>
              <p><br /></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hvor kan jeg kjøpe produktene som deltar i Braun pengene
                tilbake-garanti?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Du kan kjøpe salgsfremmende produkter fra alle nordiske og
                internasjonale forhandlere som opererer i Norge, Danmark,
                Sverige og Finland. Vær oppmerksom på at vi ikke kan akseptere
                private kjøp og kjøp gjort på auksjoner.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hvor mye får jeg refundert?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Du får refundert 100% av den FAKTISKE kjøpesummen som er betalt
                (minus eventuelle rabatter) for det deltakende produktet som er
                sendt inn.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Når kan jeg delta i Braun pengene tilbake-garanti?</p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Perioden for kjøp av salgsfremmende produkter er fra
                <strong>01.07.2022</strong> til og med
                <strong>30.06.2025</strong> (kjøpsdatoen er relevant her).
                <br /><br />
              </p>
              <p>
                Du bør registrere kvitteringen din på nettet:<br /><br />- for
                Braun Silk-épil 9,
                Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX),
                Braun Series 7,
                Braun Series 8,
                Braun Series 9,
                King C Gillette Stylemaster & Stylemaster eCom - innen <strong>60 dager</strong> etter kjøpet,<br />-
                for 
                Braun Silk-expert Pro 5,
                Braun Silk-expert Pro 3 - innen
                <strong>120 dager</strong> etter kjøpet. <br /><br />Etter at
                din online registrering ble bekreftet, vil du ha
                <strong>10 dager</strong> til å sende produktet sammen med
                returskjemaet og en kopi av kjøpsbeviset ditt til et av våre
                servicesentre.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hvor ofte kan jeg delta i Braun pengene tilbake-garanti?</p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Hver person (brukerkonto: kombinasjon av IBAN og
                e-postadresse) kan delta i
                <em>Braun pengene tilbake-garanti</em> med maksimalt ett
                kjøpsbevis og ett salgsfremmende produkt.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hvor mange produkter kan jeg delta i Braun pengene
                tilbake-garanti med?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Refusjon kan utstedes for ett salgsfremmende produkt per person.
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hvor lenge må jeg beholde kjøpsbeviset?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>Vennligst behold kjøpsbeviset til fordring av refusjonen.</p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hva er IBAN, og hvor finner jeg det?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                IBAN står for Internasjonalt Bankkontonummer. Det er en
                internasjonalt avtalt kode bestående av opptil 34 bokstaver og
                tall som hjelper banker med å behandle overføringer over hele
                verden. <br />Du kan vanligvis finne IBAN-nummeret ditt øverst
                til høyre på kontoutskriften din eller i nettbanken din. Hvis du
                ikke finner IBAN-en din, bør du kunne generere den på nettet via
                bankens nettbanktjeneste eller ved å bruke et
                IBAN-kalkulatorverktøy.<br /><br />
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hva er BIC og hvor finner jeg det?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                BIC står for Bankens Identifiseringskode. For å sende eller
                motta penger, må enhver finansinstitusjon vite hvor pengene skal
                sendes, og BIC er akkurat det som viser det.
              </p>
              <p>
                Du kan vanligvis finne bankens BIC i kontoutskriftene dine. Hvis
                du bruker nettbank, kan du logge inn på din digitale bankkonto
                for enkelt å se kontoutskriften din eller kontakte banken din
                slik at de gir deg din BIC.
              </p>
              <p>0</p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Partneren min og jeg har en felles IBAN-konto. Kan vi begge
                delta separat med denne kontoen i Braun pengene tilbake-garanti?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Hver IBAN kan bare brukes av én bruker i hele
                kampanjeperioden (i en fast kombinasjon med e-postadresse). En
                felles bruk med en annen deltaker er ikke mulig uavhengig av
                personlig tilkobling.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Når kan jeg forvente refusjonen min?</p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Etter en positiv sjekk av deltakelsen din, vil
                refusjonsbeløpet bli overført til bankkontoen (IBAN) du
                spesifiserte innen maksimalt 6 uker.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Kan jeg delta i andre kampanjer med samme kjøpsbevis?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Hvis du allerede har deltatt i en parallell kampanje med
                kjøpsbeviset og allerede har mottatt bekreftelse på refusjon for
                Braun-produktet, er det ikke mulig å delta i
                <em>Braun pengene tilbake-garanti</em> med dette produktet og
                dette kjøpsbeviset.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Kjøpsbeviset inneholder mer enn 1 Braun salgsfremmende produkt.
                Kan to eller flere personer bruke dette kjøpsbeviset?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Vanligvis kan et kjøpsbevis bare delta i
                <em>Braun pengene tilbake-garanti</em> én gang. Verken omfanget
                av salgsfremmende produktene som er oppført på kjøpskvitteringen
                kan tas i betraktning, og heller ikke et salgsfremmende produkt
                kan overføres til en annen person.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Jeg har ikke mottatt en e-postbekreftelse etter å ha fullført
                deltakelsesprosessen, hva nå?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Etter en vellykket registrering vil du ha mottatt en
                bekreftelses-e-post med et 10-sifret referansenummer. Sjekk også
                SPAM- eller JUNK-mappen din. Hvis du ikke har mottatt et
                10-sifret referansenummer, kan du delta på nytt via
                kampanjenettstedet
                <a href="https://www.braun-moneyback.com/no"
                  >www.braun-moneyback.com</a
                >.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Når du går gjennom deltakelsesprosessen, krasjer den på et
                bestemt tidspunkt. Hva skal jeg gjøre?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Hvis du blir sittende fast under deltakelsesprosessen av
                tekniske årsaker, anbefales det - om mulig - å bruke en annen
                enhet. Du kan for eksempel bytte fra mobiltelefonen til PCen.
                Hvis dette ikke fungerer, kan du prøve å bruke en annen
                nettleser. Hvis disse små tipsene ikke hjelper deg, kan du sende
                en e-post til vår supportavdeling:
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >. Vennligst gi en presis beskrivelse av problemet i e-posten
                din - et skjermbilde av punktet der det ikke lenger kan gå,
                ville være perfekt. Andre nyttige data er hvilken enhet, hvilket
                operativsystem og hvilken nettleser du bruker. Vårt supportteam
                vil da være ved din side.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hvordan vet jeg om jeg har fullført opplastingsprosessen for
                kjøpsbevis?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Så snart du har lastet opp kjøpsbeviset ditt og skrevet inn dine
                personlige opplysninger, vises en melding på skjermen som
                bekrefter hvor vellykket forsøket ditt er på å delta og vise
                ditt personlige referansenummer. Du vil også umiddelbart motta
                en bekreftelses- eller avvisnings-e-post til e-postadressen du
                oppga.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hvor lenge er støtteavdelingen tilgjengelig?</p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Vår støtteavdelingen er tilgjengelig frem til
                15.12.2025.<br /><br />
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Min deltakelse er bekreftet, men refusjonsbeløpet er feil. Hva
                kan jeg gjøre?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                I bekreftelses-e-posten finner du en knapp som du kan be om en
                ny vurdering av saken din med, som imidlertid blir ugyldig etter
                72 timer. I dette tilfellet kan du kontakte supportteamet vårt
                via
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >
                og beskrive saken din i en e-post. De vil ta seg av dine
                bekymringer der.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hvor lenge har jeg mulighet til å be om en ny vurdering av saken
                min?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                En automatisk reevaluering ved hjelp av lenkene som sendes kan
                bes om innen 72 timer etter at bekreftelsen eller
                avvisnings-e-posten er sendt. Etter det kan du bare be om at
                saken din behandles på nytt via supportteamet vårt (<a
                  href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >). Oppgi alltid referansenummeret ditt!<br />Vær oppmerksom på
                at dette alternativet også bare er tilgjengelig i begrenset
                grad. Dataene dine vil bli slettet etter at refusjon-kampanjen
                er utløpt, behandlingen er derfor bare mulig til 15.12.2025.
                Støtteavdelingen vil da bli stengt og dine henvendelser kan ikke
                lenger behandles.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Kjøpsbeviset mitt ble avvist fordi ikke alle dataene kan ses.
                Hvilke data er ment?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                På kjøpsbeviset, forhandleren (grenen), kampanjeproduktet,
                prisen på kampanjeproduktet, totalen av kjøpsbeviset, bevis på
                kjøps-ID-nummer (ikke banktransaksjonsnummer-ID), mva,
                eventuelle rabatter som kan gjelde, samt dato og klokkeslett må
                være tydelig synlig.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Kjøpsbeviset mitt er veldig langt og blir ikke lesbart når jeg
                tar et bilde.<br />Hvordan kan jeg fortsette?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Vennligst send kjøpsbeviset på ett bilde når du registrerer deg
                på vår hjemmeside. Hvis du mottar en e-postmelding om at den
                ikke er lesbar, sender du kjøpsbeviset atskilt med flere bilder
                til kundestøtteteamet
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >. Du må kontrollere at bunnen av hvert bilde er lik toppen av
                neste bilde. Dermed må hele kjøpsbeviset sendes i stykker.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hvorfor ble min deltakelse avvist selv om jeg kjøpte de riktige
                produktene?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Det kan for eksempel være en teknisk feil, eller det kan være at
                kjøpsbeviset ditt ikke er skarpt nok eller overeksponert.
                <br />I den nedre tredjedelen av avvisnings-e-posten finner du
                en knapp for å laste opp kjøpsbeviset på nytt. Klikk på denne
                knappen, så blir du automatisk omdirigert til re-opplasting av
                kjøpsbeviset. Ta et nytt bilde av kjøpsbeviset og last det opp
                på nytt innen 72 timer. Vær oppmerksom på at knappen mister
                funksjonaliteten etter 72 timer. <br />Hvis deltakelsen din blir
                avvist på nytt, kan du kontakte oss via e-postadressen for
                kundestøtte, som også finnes i avvisnings-e-posten din (<a
                  href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >).
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Kjøpsbeviset mitt ble ikke akseptert eller ble til og med merket
                som svindel. Hvordan kan det være?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Hvis kjøpsbeviset ditt er for uskarpt eller for mørkt eller
                overeksponert slik at dataene ikke kan sees tydelig, er det en
                mulighet for at du vil motta en avvisnings-e-post der
                kjøpsbeviset beskrives som en falsk, eller du blir bedt om å
                laste opp et bedre kvalitetsbilde av kjøpsbeviset ditt. Hjelp
                oss med å akseptere din deltakelse raskt og uten komplikasjoner
                ved å laste opp et bilde av god kvalitet (ikke en skanning!) av
                kjøpsbeviset ditt, som viser at det er en original kvittering og
                som alle nødvendige data tydelig kan ses på. Du sparer mye tid
                og problemer for både deg selv og oss.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Kan jeg også sende inn kjøpsbeviset mitt via e-post?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Du kan bare fullføre online deltakelsesprosessen på
                kampanjenettstedet vårt
                <a href="https://www.braun-moneyback.com/no"
                  >www.braun-moneyback.com</a
                >. Gjeninnsending av kjøpsbevis kan kun gjøres via en
                reuploadknapp, som du finner i avslags-e-posten. Hvis du ikke
                har mottatt en avvisnings-e-post eller en bekreftelses-e-post,
                er dette en indikasjon på at din deltakelse var mislykket og at
                du må gå gjennom deltakelsesprosessen på nytt.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Jeg skrev inn feil IBAN ved registrering. Hva skjer i dette
                tilfellet?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Hvis du har angitt feil IBAN slik at overføringen ikke kan
                utføres, har du én gang mulighet til å endre den. Du kan
                kontakte den relevante avdelingen via e-post ved å 15.12.2025:
                <a href="mailto:support@braun-moneyback.com"
                  >support@braun-moneyback.com</a
                >. Hvis en vellykket overføring heller ikke kan gjøres med denne
                IBAN-en, utløper retten til refusjon. Kontroller at denne
                IBAN-en ennå ikke er brukt til en ny refusjon som en del av
                Braun pengene tilbake-garanti.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hva skjer med dataene mine som jeg skrev inn på
                kampanjenettstedet?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Dataene dine behandles for å gjennomføre kampanjen. I den grad
                du får muligheten til å gi samtykke (spesielt for å motta
                informasjon om Procter &amp;Gamble-merker via e-post) i
                forbindelse med kampanjen på kampanjenettstedet eller på annen
                måte, er dette samtykket frivillig og har ingen innvirkning på
                deltakelsen i kampanjen. Du kan når som helst trekke tilbake
                samtykket ditt helt eller delvis. Kampanjedataene lagres i en
                periode på tre måneder etter fristen for operativ behandling av
                kampanjen.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Jeg har et generelt spørsmål om Procter &amp; Gamble
                International Operations SA. Hvem skal jeg snakke med?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Ta kontakt med Procter &amp;Gamble International Operations SA
                via
                <a href="mailto:contactCH.im@pg.com">contactCH.im@pg.com</a>.
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div v-if="locale === 'se'">
        <div class="page-title">Vanliga Frågor</div>
        <ul ref="list" class="list">
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Vilka villkor måste jag uppfylla för att delta i Braun
                pengarna-tillbaka-garanti?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <ul>
                <li>Minimiålder: Du måste vara minst 18 år.</li>
                <li>
                  Bosättningsort: Du måste vara bosatt i Norge, Danmark, Sverige
                  eller Finland.
                </li>
                <li>
                  Bankuppgifter: Du måste ha ett IBAN-nummer som tilldelats Danmark, Finland, Norge eller
Sverige.
                </li>
                <li>
                  E-postadress: Du kan endast delta med din egen e-postadress.
                </li>
              </ul>
              <p>
                Observera: Kan varje IBAN och e-postadress endast delta i
                kampanjen en gång.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hur kan jag delta i Braun pengarna-tillbaka-garanti?<br /><br />
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Du kan endast delta online - via kampanjens webbplats
                <a href="https://www.braun-moneyback.com/se"
                  >www.braun-moneyback.com</a
                >. Processen kan beskrivas på följande sätt:<br /><br />- ange
                reklamprodukten,<br />- ta ett foto av ditt inköpsbevis och
                ladda upp det,<br />- ladda upp ett foto av din Braun produkt
                (du måste se till att streckkoden på din reklamprodukt syns
                tydligt), <br />- ange bankuppgifter (IBAN), e-postadress och
                andra personuppgifter,<br />- godkänna villkoren för deltagande
                + sekretesspolicy.
              </p>
              <p>
                <br />När du har fått en bekräftelse på din online-registrering
                måste du skicka produkten tillsammans med motsvarande kupong
                till servicecentret inom <strong>10 dagar</strong>.Du får din
                återbetalning inom 6 veckor efter det att paketet har anlänt och
                bekräftats i servicecentret.
              </p>
              <p>
                <br />Observera: Det är inte möjligt att använda olika
                e-postadresser (användarkonton) per person.
              </p>
              <p>0</p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Vart ska jag skicka paketet med produkten?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Skicka din produkt tillsammans med returformuläret och en kopia
                av ditt inköpsbevis till ett av följande P&amp;G
                servicecenter:<br /><br /><strong>Norge</strong>
              </p>
              <p>L.G. Elektro A/S</p>
              <p>Kolstien 4</p>
              <p>5097 Bergen</p>
              <p>Norge</p>
              <p></p>
              <br/>
              <p><strong>Sverige</strong></p>
              <p>
                El &amp; Digital Service AB<br />Folkungagatan 144<br />116 30
                Stockholm<br />Sverige
              </p>
              <p></p>
              <br/>
              <p><strong>Finland</strong></p>
              <p>AIDE Europe Oy</p>
              <p>Puusepänkatu 2D</p>
              <p>00880 Helsinki</p>
              <p>Suomi<br /><br /></p>
              <p>
                <strong>Danmark</strong><br />CJ Hvidevareservice<br />Thorndahlsvej
                11<br />9200 Aalborg SV<br />Danmark
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Vem ska jag kontakta om jag har frågor om Braun
                pengarna-tillbaka-garanti?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Vänligen kontakta Braun pengarna-tillbaka-garanti
                kundtjänst via
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Vilka produkter måste jag köpa för att delta i Braun
                pengarna-tillbaka-garanti?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                Följande produkter deltar i Braun pengarna-tillbaka-garanti:<br /><br />provperiod
                på högst <strong>60 dagar</strong> efter köpet:
                <br /><br/>Braun Silk-épil 9
                <br/>Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX)
                <br/>Braun Series 7
                <br/>Braun Series 8
                <br/>Braun Series 9
                <br/>King C Gillette Stylemaster & Stylemaster eCom
                <br />
                <br />
              </p>
              <p>
                provperiod på högst <strong>120 dagar</strong> efter köpet:
                <br />Braun Skin i·Expert Pro 7
                <br />Braun Silk-expert Pro 5
                <br />Braun Silk-expert Pro 3
                <br /><br />
              </p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Var kan jag köpa de produkter som deltar i Braun
                pengarna-tillbaka-garanti?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Du kan köpa kampanjprodukterna från alla nordiska och
                internationella återförsäljare som är verksamma i Norge,
                Danmark, Sverige och Finland. Observera att vi inte kan
                acceptera privata köp och köp på auktioner.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hur mycket kommer jag att få tillbaka?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Du får 100% av det FAKTISKA inköpspriset (minus eventuella
                rabatter) för den inlämnade deltagande produkten.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>När kan jag delta i Braun pengarna-tillbaka-garanti?</p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Perioden för köp av reklamprodukter är från
                <strong>01.07.2022</strong> till och med
                <strong>30.06.2025</strong> (datumet för inköpsbeviset är
                relevant här).
              </p>
              <p>
                Du bör registrera ditt kvitto online:<br /><br />- för 
                Braun Silk-épil 9,
                Braun Premium Styling (MGK7XXX, BT7XXX, XT5XXX, XT3XXX),
                Braun Series 7,
                Braun Series 8,
                Braun Series 9,
                King C Gillette Stylemaster & Stylemaster eCom
                 - inom
                <strong>60 dagar</strong> efter köpet,<br />- för 
                Braun Silk-expert Pro 5,
                Braun Silk-expert Pro 3
                 - inom <strong>120 dagar</strong> efter köpet.
                <br /><br />När din online-registrering har bekräftats har du
                <strong>10 dagar</strong> på dig att skicka produkten
                tillsammans med returformuläret och en kopia av ditt inköpsbevis
                till ett av våra servicecenter.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hur ofta kan jag delta i Braun pengarna-tillbaka-garanti?</p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Varje person (användarkonto: kombination av IBAN och
                e-postadress) kan delta i Braun pengarna-tillbaka-garanti med
                högst ett inköpsbevis och en reklamprodukt.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hur många produkter kan jag delta i Braun
                pengarna-tillbaka-garanti med?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                En återbetalning kan utfärdas för en reklamprodukt per person.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hur länge måste jag behålla inköpsbeviset?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>Behåll inköpsbeviset tills återbetalningen har mottagits.</p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Vad är IBAN och var hittar jag det?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                IBAN står för Internationellt Bankkontonummer. Det är en
                internationellt överenskommen kod som består av upp till 34
                bokstäver och siffror och som hjälper bankerna att hantera
                överföringar över hela världen. <br />Du hittar vanligtvis ditt
                IBAN-nummer uppe till höger på ditt kontoutdrag eller i din
                internetbank. Om du inte kan hitta ditt IBAN-nummer bör du kunna
                generera det online via din banks internetbank eller genom att
                använda ett IBAN-kalkylatorverktyg.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Vad är BIC och var hittar jag det?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                BIC står för Bankidentifieringskod. För att skicka eller ta emot
                pengar måste alla finansinstitut veta vart pengarna ska skickas
                och BIC är precis vad som visar det. <br />Du kan vanligtvis
                hitta din banks BIC i dina kontoutdrag. Om du använder
                internetbank kan du logga in på ditt digitala bankkonto för att
                enkelt se ditt kontoutdrag eller kontakta din bank så att de ger
                dig din BIC.
              </p>
              <p>0</p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Min partner och jag har ett gemensamt IBAN-konto. Kan vi båda
                delta separat med detta konto i Braun pengarna-tillbaka-garanti?
              </p>
            </div>
            <div class="list-item-details">
              <p>
                <br />Varje IBAN kan endast användas av en användare under hela
                kampanjperioden (i en fast kombination med e-postadress). En
                gemensam användning med en annan deltagare är inte möjlig
                oavsett eventuell personlig koppling.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>När kan jag förvänta mig min återbetalning?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Efter en positiv kontroll av ditt deltagande kommer
                återbetalningsbeloppet att överföras till det bankkonto (IBAN)
                du angett inom högst 6 veckor.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Kan jag delta i andra kampanjer med samma inköpsbevis?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Om du redan har deltagit i en parallell kampanj med ditt
                inköpsbevis och redan har fått en bekräftelse på återbetalning
                för din produkt Braun, är det inte möjligt att delta i Braun
                pengarna-tillbaka-garanti med denna produkt och detta
                inköpsbevis.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Mitt inköpsbevis innehåller mer än 1 Braun reklamprodukt. Kan
                två eller fler personer använda detta köpebevis?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Generellt sett kan ett inköpsbevis bara delta i vår Braun
                pengarna-tillbaka-garanti en gång. Varken omfattningen av de
                reklamprodukter som anges på inköpskvittot kan beaktas eller så
                kan en reklamprodukt överföras till en annan person.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Jag har inte fått något bekräftelsemail efter att ha slutfört
                deltagandet, vad händer nu?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Efter en lyckad registrering har du fått ett bekräftelsemail med
                ett 10-siffrigt referensnummer. Kontrollera även din SPAM- eller
                JUNK-mapp. Om du inte har fått något 10-siffrigt referensnummer
                kan du delta igen via kampanjens webbplats
                <a href="https://www.braun-moneyback.com/se"
                  >www.braun-moneyback.com</a
                >.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                När jag går igenom deltagandeprocessen kraschar den vid ett
                visst tillfälle. Vad ska jag göra?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Om du av tekniska skäl fastnar under deltagandet rekommenderas
                det att du - om möjligt - använder en annan enhet. Byt till
                exempel från din mobiltelefon till din dator. Om detta inte
                fungerar kan du försöka använda en annan webbläsare. Om dessa
                små tips inte hjälper dig kan du skicka ett e-postmeddelande
                till vår supportavdelning:
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >. Ge en exakt beskrivning av problemet i ditt e-postmeddelande
                - en skärmdump av den punkt där det inte längre går skulle vara
                perfekt. Andra användbara uppgifter är vilken enhet, vilket
                operativsystem och vilken webbläsare du använder. Vårt
                supportteam kommer sedan att stå vid din sida.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hur kan jag se om jag har slutfört uppladdningen av
                inköpsbeviset?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Så snart du har laddat upp ditt inköpsbevis, angett dina person-
                och bankuppgifter och bekräftat dina uppgifter via en sms-kod
                kommer ett meddelande att visas på skärmen som bekräftar att
                ditt försök att delta lyckats och som visar ditt personliga
                referensnummer. Du kommer också omedelbart att få ett
                e-postmeddelande om bekräftelse eller avslag till den
                e-postadress du angett.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Hur länge är supportavdelningen tillgänglig?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>Vår supportavdelning är tillgänglig till 15.12.2025.</p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Mitt deltagande har bekräftats, men återbetalningsbeloppet är
                felaktigt. Vad kan jag göra?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                I ditt e-postmeddelande finns en knapp med vilken du kan begära
                en ny bedömning av ditt ärende, som dock blir ogiltig efter 72
                timmar. Kontakta i så fall vårt supportteam via
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >
                och beskriv ditt ärende i ett e-postmeddelande. De kommer att ta
                hand om dina problem där.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Hur länge har jag möjlighet att begära en ny bedömning av mitt
                ärende?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                En automatisk omprövning med hjälp av de skickade länkarna kan
                begäras inom 72 timmar efter det att e-postmeddelandet om
                bekräftelse eller avslag har skickats. Därefter kan du endast
                begära att ditt ärende behandlas på nytt via vårt supportteam
                (<a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >). Ange alltid ditt referensnummer!<br />Observera att även
                detta alternativ endast är tillgängligt i begränsad omfattning.
                Dina uppgifter kommer att raderas efter att återbetalning
                kampanjen har löpt ut, behandling är därför endast möjlig upp
                till 7 veckor efter tidsfristen - dvs. fram till 15.12.2025. Då
                kommer supportavdelningen att stängas och dina förfrågningar kan
                inte längre behandlas.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Mitt inköpsbevis avvisades eftersom alla uppgifter inte kan ses.
                Vilka uppgifter avses?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                På inköpsbeviset måste återförsäljaren (filialen),
                reklamprodukten, priset på reklamprodukten, summan av
                inköpsbeviset, ID-nummer för inköpsbeviset (inte ID-nummer för
                banktransaktion), momsen, eventuella rabatter samt datum och
                klockslag tydligt framgå.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Mitt inköpsbevis är mycket långt och blir inte läsbart när jag
                tar en bild.<br />Hur kan jag gå vidare?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Skicka in köpebeviset i en enda bild när du registrerar dig på
                vår webbplats. Om du får ett e-postmeddelande om att det inte
                går att läsa, skicka ditt inköpsbevis i flera bilder till
                supportteamet
                <a href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >. Du måste se till att botten av varje bild motsvarar toppen av
                nästa bild. Hela inköpsbeviset måste alltså skickas i bitar.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Varför avvisades mitt deltagande trots att jag köpte rätt
                produkter?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Det kan till exempel handla om ett tekniskt fel, eller så kan
                det vara så att ditt inköpsbevis inte är tillräckligt skarpt
                eller överexponerat.<br />I den nedre tredjedelen av ditt
                e-postmeddelande om avslag hittar du en knapp för att ladda upp
                ditt inköpsbevis på nytt. Om du klickar på denna knapp kommer du
                automatiskt att omdirigeras till uppladdningen av köpebeviset.
                Ta ett nytt foto av ditt inköpsbevis och ladda upp det igen inom
                72 timmar. Observera att knappen förlorar sin funktionalitet
                efter 72 timmar.<br />Om ditt deltagande avvisas igen, vänligen
                kontakta oss via supportens e-postadress, som också finns i ditt
                e-postmeddelande om avvisning (<a
                  href="mailto:contact@braun-moneyback.com"
                  >contact@braun-moneyback.com</a
                >).
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Mitt inköpsbevis godkändes inte eller betecknades till och med
                som bedrägeri. Hur kan det vara möjligt?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Om ditt inköpsbevis är för suddigt, för mörkt eller
                överexponerat så att uppgifterna inte kan ses tydligt finns det
                en möjlighet att du får ett avvisningsmeddelande där
                inköpsbeviset beskrivs som en förfalskning eller att du ombeds
                att ladda upp en bild av bättre kvalitet på ditt inköpsbevis.
                Hjälp oss att godkänna ditt deltagande snabbt och utan
                komplikationer genom att ladda upp ett foto av god kvalitet
                (inte en skanning!) av ditt inköpsbevis, som visar att det är
                ett originalkvitto och där alla nödvändiga uppgifter tydligt kan
                ses. Du sparar mycket tid och problem för både dig själv och
                oss.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>Kan jag också skicka in mitt inköpsbevis via e-post?</p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Du kan endast slutföra online-deltagandeprocessen på vår
                reklamwebbplats
                <a href="https://www.braun-moneyback.com/se"
                  >www.braun-moneyback.com</a
                >. Du kan bara skicka in beviset på köpet på nytt via knappen
                för att ladda upp det på nytt, som du hittar i ditt
                e-postmeddelande om avslag. Om du inte har fått något
                e-postmeddelande om avslag eller bekräftelse är detta ett tecken
                på att ditt deltagande inte lyckades och att du måste gå igenom
                deltagandet igen.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Jag angav fel IBAN vid registreringen. Vad händer i detta fall?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Om du har angett ett felaktigt IBAN-nummer så att överföringen
                inte kan genomföras har du en enda möjlighet att ändra det. Du
                kan kontakta den berörda avdelningen via e-post senast
                15.12.2025:
                <a href="mailto:support@braun-moneyback.com"
                  >support@braun-moneyback.com</a
                >. Om en lyckad överföring inte heller kan genomföras med detta
                IBAN upphör rätten till återbetalning. Kontrollera att detta
                IBAN ännu inte har använts för en annan återbetalning som en del
                av vår Braun pengarna-tillbaka-garanti.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Vad händer med mina uppgifter som jag angav på
                kampanjwebbplatsen?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Behandlingen av kampanjdata grundar för att genomföra kampanjen.
                I den mån du ges möjlighet att ge ditt samtycke (särskilt för
                att få information om Procter &amp; Gambles varumärken via
                e-post) i samband med kampanjen på kampanjens webbplats eller på
                annat sätt, är detta samtycke frivilligt och har ingen inverkan
                på deltagandet i kampanjen. Du kan när som helst återkalla ditt
                samtycke helt eller delvis. Uppgifterna om kampanjen lagras
                under en period av tre månader efter tidsfristen för den
                operativa behandlingen av kampanjen.
              </p>
              <p></p>
            </div>
          </li>
          <li class="list-item-wrapper">
            <div class="number">
              <p>
                Jag har en allmän fråga om Procter &amp; Gamble International
                Operations SA. Vem ska jag prata med?
              </p>
              <p></p>
            </div>
            <div class="list-item-details">
              <p>
                Kontakta Procter &amp; Gamble International Operations SA via
                <a href="mailto:contactCH.im@pg.com">contactCH.im@pg.com</a>.
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "faq"
};
</script>

<style lang="scss" scoped>
.container {
  margin: 10px 5px;
}
.page-title {
  width: 100%;
  text-align: center;
  color: var(--text-color-3);
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
  margin: 30px;
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 28px;
  }
}
.list {
  list-style-type: decimal;
  word-break: break-word;
  margin: 10px 4px;
  > li::marker {
    font-size: 26px;
    font-weight: bold;
    color: var(--color5);
  }
  li::marker {
    font-weight: bold;
  }
  li {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
table,
th,
td {
  border: 1px solid black;
  border-collapse: collapse;
}
th,
td {
  padding-left: 20px;
  padding-right: 20px;
}
.number {
  color: var(--color5);
  font-weight: bold;
  font-size: 24px;
  line-height: 20px;
  display: inline;
  margin-right: 5px;
  p {
    display: inline;
  }
}
p {
  margin-bottom: 0;
}
.list-item-wrapper {
  margin-top: 24px;
  margin-right: 5px;
  white-space: initial;
  color: var(--color5);
  .list-item-details {
    margin-top: 10px;
    text-justify: auto;
    a {
      text-decoration: underline;
    }
  }
}
</style>
