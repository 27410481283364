<template>
  <div class="m-0 checkboxWrapper">
    <ValidationProvider
      :rules="validation"
      v-slot="{ errors }"
    >
      <div class="wrapper_best" :style="styles">
        <div class="custom-control custom-checkbox custom-checkbox-arrow pt-1" ref="checkboxWrapper" :tabIndex="tabIndex">
          <input
            v-model="localChecked"
            :name="name"
            :checked="checked"
            type="checkbox"
            class="custom-control-input"
            :id="id"
            @input="clickCheckbox"
            ref="checkBox1"
            required
          />
          <label
            :class="`${clickable ? 'custom-control-label' : ''}`"
            :for="id"
            v-html="$t(label)"
          ></label>
        </div>
        <div
          v-if="expandable"
          role="button"
          id="down-arrow-btn"
          :aria-expanded="expand"
          aria-controls="expandedDiv"
        >
          <img
            class="down-arrow"
            @click="checkBoxClicked"
            src="../../assets/icons/form/down-arrow.svg"
            alt="Down Arrow"
          />
        </div>
      </div>
      <div
        class="validation-error"
        style="margin-left: 5px"
        v-if="errors.length"
      >
        <img class="error_min" src="https://jolt-assets.s3.eu-central-1.amazonaws.com/template/islos/error.svg" alt="Error" >
        {{ validationMessage ? $t(`validation.${validationMessage}`): errors[0]}}
      </div>
    </ValidationProvider>
    <div v-if="expandable" class="expandedDiv" ref='expandedDiv' id="expandedDiv">
      <div class="card card-body card-body-dop">
        <div v-html="$t('Why do we collect this data?')"></div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../../store";
export default {
  name: "checkbox",
  data () {
    return {
      localChecked: this.checked,
      localCheckedExpanded: this.checked,
      expand: false
    };
  },
  props: {
    clickable: {
      type: Boolean,
      default: true
    },
    name: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    validationName: {
      type: String,
      default: ""
    },
    validationMessage: {
      required: false,
      type: String,
      default: ""
    },
    name2: {
      type: String,
      default: ""
    },
    id2: {
      type: String,
      default: ""
    },
    validationName2: {
      type: String,
      default: ""
    },
    checked: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "Empty Link Text"
    },
    expandable: {
      required: false,
      type: Boolean,
      default: false
    },
    expandableText: {
      required: false,
      type: String,
      default: ""
    },
    validation: {
      type: [String, Object],
      required: false,
      default: ""
    },
    checkOnClick: {
      required: false,
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    tabIndex: {
      type: [Number, String],
      default: ""
    }
  },
  mounted () {
    document.querySelectorAll('.modalOpener').forEach(x => {
      x.addEventListener('click', function () {
        store.commit('setModalOptions', {
          type: 'custom',
          message: this.dataset.text,
          isActive: true,
          closeButton: true
        })
      })
    })
    this.$emit("component-updated", {
      checked: !!this.checked
    });
    this.$emit("input", !!this.checked);

    document.addEventListener('keydown', (e) => {
      if (e.target == this.$refs.checkboxWrapper && (e.code == 'Space' || e.code == 'Enter')) {
        e.preventDefault()
        this.$refs.checkBox1.click()
      }
    })
  },
  updated () {
    document.querySelectorAll('.modalOpener').forEach(x => {
      x.addEventListener('click', function () {
        store.commit('setModalOptions', {
          type: 'custom',
          message: this.dataset.text,
          isActive: true,
          closeButton: true
        })
      })
    })
  },
  watch: {
    localChecked (value) {
      this.$emit("component-updated", {
        checked: value
      });
      this.$emit("input", value);
    },
    localCheckedExpanded (value) {
      this.$emit("component-updated", {
        checked2: value
      });
      this.$emit("input", value);
    }
  },
  methods: {
    clickCheckbox (value) {
      const firstCheckBox = this.$refs['checkBox1'];
      const secondCheckBox = this.$refs['checkBox2'];

      if (!this.expand && secondCheckBox)
        this.checkBoxClicked();
      if (firstCheckBox && secondCheckBox) {
        secondCheckBox.checked = firstCheckBox.checked ? value.target.checked : false;
        this.localCheckedExpanded = secondCheckBox.checked
      }

      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: value.target.checked
      });
    },
    checkBoxClicked () {
      this.expand = !this.expand;
      const expandedDiv = this.$refs['expandedDiv'];
      expandedDiv.style.maxHeight = expandedDiv.style.maxHeight ? null : expandedDiv.scrollHeight + "px";
    }
  }
};
</script>

<style lang="scss" scoped>
$medium: 991px;
.validation-error {
  @media only screen and (max-width: $medium) {
    margin-left: 15px !important;
  }
}
.checkboxWrapper {
  font-family: "Helvetica";
  padding: 5px;
}
.form-group {
  margin-bottom: 20px;
}
.form-field {
  margin-bottom: 20px;
}
.wrapper_input {
  position: relative;
}
.form-field__label {
  position: absolute;
  top: 50%;
  left: 10px;
  padding-left: 7px;
  padding-right: 7px;
  transition: transform 0.1s;
  pointer-events: none;
  transform-origin: 0 100%;
  transform: translateY(-50%);
  font-size: 14px;
  letter-spacing: 0.14px;
  line-height: 16px;
}
.form-field__input {
  background: transparent !important;
  outline: 0;
  box-shadow: none;
  transition: border-color 0.15s;
  border-radius: 0px !important;
  border: 1px solid #808080 !important;
  box-sizing: border-box !important;
  padding-left: 15px !important;
  height: 48px !important;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
}
.form-field__input:focus + .form-field__label,
.form-field__input.visited + .form-field__label {
  transform: translateY(-140%) scale(0.7);
}
.wrapper-i {
  cursor: pointer;
}
.wrapper_weit {
  display: flex;
  align-items: center;
}
.information-img {
  width: 18px;
}
.add-img {
  width: 30px;
  margin-right: 20px;
}
.is-invalid {
  .form-field__input {
    border-color: #ff5a5a !important;
  }
  .invalid-feedback {
    display: block;
  }
}
.wrapper_best {
  display: flex;
  position: relative;
  @media only screen and (max-width: $medium) {
    margin-left: 10px;
    margin-right: 10px;
  }
  .custom-control {
    padding-left: 35px;
  }
  .custom-checkbox .custom-control-label::before,
  .custom-checkbox .custom-control-label::after {
    left: -35px;
  }
}
.down-arrow {
  width: 16px;
  transition: all 0.3s;
  margin-left: 10px;
  margin-top: -8px;
}
[aria-expanded="true"] {
  .down-arrow {
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.card-body-dop {
  border: none;
  padding-left: 35px;
  background-color: transparent;
}
.custom-control-label::after,
.custom-checkbox .custom-control-label::before {
  width: 20px;
  height: 20px;
  top: 0px;
  left: -35px;
}
.custom-checkbox-dop {
  position: absolute;
  bottom: 5px;
  right: 10px;
  .custom-control-label {
    &:after,
    &:before {
      left: -20px;
    }
  }
}
.custom-control-label::after,
.custom-checkbox .custom-control-label::before,
.custom-control-input[type="radio"] {
  background-color: transparent !important;
  width: 20px;
  height: 20px;
  border: none;
  border-radius: 0px;
}
.custom-checkbox .custom-control-label::before {
  background-image: url("https://jolt-assets.s3.eu-central-1.amazonaws.com/template/islos/unchecked_checkbox.svg") !important;
  background-repeat: no-repeat;
  box-shadow: none !important;
  background-size: 20px;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("https://jolt-assets.s3.eu-central-1.amazonaws.com/template/islos/checked_checkbox.svg") !important;
  background-repeat: no-repeat;
  background-size: 20px;
}
.expandedDiv {

  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  -moz-transition: height 0.4s ease-out;; /* Firefox 4 */
  -webkit-transition: height 0.4s ease-out;;  /* Safari and Chrome */
  -o-transition: height 0.4s ease-out;;  /* Opera */

  &Image {
    -webkit-transform: rotate(180deg); /* Safari and Chrome */
    -moz-transform: rotate(180deg);   /* Firefox */
    -ms-transform: rotate(180deg);   /* IE 9 */
    -o-transform: rotate(180deg);   /* Opera */
    transform: rotate(180deg);
  }
}
</style>
