<template>
    <div class="row col-md-12 flex no-gutters p-0 m-0 barcode-encoder">
        <div class="poz_rel overflow-hidden col-xs-12 col-md-6 col-lg-6" style="margin-bottom: 30px;" >
            <div
            class="wrapper_file_upload wrapper_file_upload_left"
            :class="{ highlight: highlight1 }"
            :style="{'padding-left': selectedFile1 ? '0px' : '70px' , 'padding-right': selectedFile1 ? '0px' : '70px'}"
            @dragover.prevent
            @drop.stop.prevent="handleDrop"
            @dragenter.stop.prevent="onDragEnter"
            @dragleave.stop.prevent="onDragLeave"
            id="upload1"
            >
            <div v-show="!!selectedFile1" class="receipt-widget">
                <img class="img" :src="getImageUrl('btn1')"/>
                <div style="display: flex">
                    <button
                        class="btn btn-select btn_abs"
                        @click.prevent="selectAnother"
                        id="btn1"
                    >{{ this.texts.receiptReUpload }}</button>
                </div>
            </div>
            <label class="file_upload" :style="{ display: selectedFile1 ? 'none' : 'block'}">
                <input
                :disabled="hasUnresolvedDependencies"
                accept="image/*, application/pdf"
                id="uploaded-file1"
                class="uploaded-file"
                type="file"
                name="file"
                ref="image1"
                @change="onFilePicked"
                />
                <div class="text" v-html="this.texts.barcodePhotoText"></div>
                <button
                    class="btn btn-select btn-absolute"
                    @click.prevent="selectAnother"
                    id="btn1"
                >{{ this.texts.receiptUpload }}</button>
            </label>
            </div>
        </div>

        <div class="poz_rel overflow-hidden col-xs-12 col-md-6 col-lg-6" style="margin-bottom: 30px;" >
            <div
            class="wrapper_file_upload wrapper_file_upload_right"
            :class="{ highlight: highlight2 }"
            :style="{'padding-left': selectedFile2 ? '0px' : '70px' , 'padding-right': selectedFile2 ? '0px' : '70px'}"
            @dragover.prevent
            @drop.stop.prevent="handleDrop"
            @dragenter.stop.prevent="onDragEnter"
            @dragleave.stop.prevent="onDragLeave"
            id="upload2"
            >
            <div v-show="!!selectedFile2" class="receipt-widget">
                <img class="img" :src="getImageUrl('btn2')"/>
                 <button
                    class="btn btn-select btn_abs"
                    @click.prevent="selectAnother"
                    id="btn2"
                >{{ this.texts.receiptReUpload }}</button>
            </div>
            <label class="file_upload" :style="{ display: selectedFile2 ? 'none' : 'block'}">
                <input
                :disabled="hasUnresolvedDependencies"
                accept="image/*, application/pdf"
                id="uploaded-file2"
                class="uploaded-file"
                type="file"
                name="file"
                ref="image2"
                @change="onFilePicked"
                />
                <div class="text" v-html="this.texts.productPhotoText"></div>
                <button
                    class="btn btn-select btn-absolute"
                    @click.prevent="selectAnother"
                    id="btn2"
                >{{ this.texts.receiptUpload }}</button>
            </label>
            </div>
        </div>

    </div>
</template>

<script>
import "@/assets/styles/receipt-editor.css";
import { mapActions } from "vuex"

export default {
  setBarcodeErrorDone ( { commit } , data) {
    commit('setBarcodeErrorDone', data)
  },
  name: "barcode-error",
  data () {
    return {
      selectedFile1: null,
      selectedFile2: null,
      uploadSectionHover1: false,
      uploadSectionHover2: false,
      highlight1: false,
      highlight2: false,
      url1: null,
      url2: null
    };
  },
  props: {
    fieldId: String,
    requires: {
      type: Array
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    }
  },
  watch: {
    selectedFile1 (value) {
      this.selectedFile1 = !!value;
    },
    selectedFile2 (value) {
      this.selectedFile2 = !!value;
    }
  },
  mounted () {
    this.$eventHub.$on("sendReceipt-event", this.send);
  },
  beforeDestroy () {
    this.$eventHub.$off("sendReceipt-event");
    this.setErrorImages({ img: "" , posNum: 1 });
    this.setErrorImages({ img: "" , posNum: 2 });
  },
  computed: {
    hasUnresolvedDependencies () {
      return this.$store.getters.hasUnresolvedDependencies(this.requires);
    },
    getImages () {
      return this.$store.getters.getErrorImageList
    }
  },
  methods: {
    ...mapActions(["setErrorImages"]),
    onFilePicked (event) {
      let uploadedBy = event.srcElement.id
      const files = event.target.files || event.dataTransfer.files;
      this.handleFiles(files , uploadedBy);
    },
    handleDrop (e) {

      let uploadedBy = e.srcElement.id
      this.highlight1 = this.highlight2 = false
      let dt = e.dataTransfer;
      let files = dt.files;
      this.handleFiles(files , uploadedBy);
    },
    handleFiles (files , id) {
      const maxfilesize = 1024 * 1024 * 16; // 1 Mb
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: false
      });

      if (files && files[0]) {
        let filename = files[0].name;

        if (filename && filename.lastIndexOf(".") <= 0) {
          return;
        }

        if (files[0].size > maxfilesize) {
          this.showModal(this.texts.fileSizeLimit);
          return;
        }
        const extension = files[0].name.split(".").pop();
        const found = ["png", "jpg", "jpe", "jpeg", "tiff", "pdf"].filter(
          ext => ext === extension.toLowerCase()
        );
        if (!found.length) {
          this.showModal(this.texts.wrongFormat);
          return;
        }
        this.$nextTick().then(() => {
          let selectedFile = files[0];
          if (id === "uploaded-file1" || id === "upload1") {
            this.selectedFile1 = selectedFile
            this.url1 = URL.createObjectURL(this.selectedFile1)
            this.setErrorImages({ img: this.url1 , posNum: 1 })
          }
          if (id === "uploaded-file2" || id === "upload2") {
            this.selectedFile2 = selectedFile
            this.url2 = URL.createObjectURL(this.selectedFile2)
            this.setErrorImages({ img: this.url2 , posNum: 2 })
          }
          console.log(this.getImages)

        });
      }
    },
    onDragEnter (e) {
      if (e.target.id === "upload1") this.highlight1 = true;
      if (e.target.id === "upload2") this.highlight2 = true
    },
    onDragLeave (e) {
      if (e.target.id === "upload1") this.highlight1 = false;
      if (e.target.id === "upload2") this.highlight2 = false;
    },
    selectAnother (event) {
      if (event.srcElement.id === "btn1") this.$refs.image1.click();
      if (event.srcElement.id === "btn2") this.$refs.image2.click();
    },
    getImageUrl (e) {
      if (this.selectedFile1 && e === "btn1") return this.url1
      if (this.selectedFile2 && e === "btn2") return this.url2
    }
  }
};
</script>

<style scoped lang="scss">
.barcode-encoder {
  height: 530px;
  @media (max-width: 767px) {
    height: auto;
  }
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.4s;
}
.uploadIcon {
  position: absolute;
  top: 0;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.file_upload input[type="file"] {
  display: none;
}
.hidden {
  display: none;
}
.receipt-uploader {
  overflow: hidden;
}

.receipt-scanner ::v-deep {
  width: 100%;
  .layout > .container {
    border: 0;
    padding: 0;
  }
  .canvas-container {
    > div {
      display: flex;
      flex-direction: column;

      .controls {
        background: #4193d3;
        order: -1;

        .btn-group {
          display: flex;
          width: 100%;

          @media (max-width: 767px) {
            width: 60%;
          }

          label {
            padding: 0;
            flex-grow: 1;
          }

          .btn {
            background: none;
            border: none;
            border-right: 1px solid;
            text-transform: uppercase;
            cursor: pointer;

            &:last-child {
              border: none;
            }

            span {
              padding-bottom: 16px;
            }

            &:hover span,
            &.active span {
              color: white;

              &:after {
                content: "";
                position: absolute;
                width: 40%;
                border: 2px solid white;
                bottom: -10px;
                left: 0;
                right: 0;
                margin: auto;
              }
            }
          }
        }
      }

      .scanner-bar {
        background: #4f4f4f;
        box-shadow: 0 0 10px #4f4f4f;
      }

      .text {

        transform: translate(-50%, 0%);
        letter-spacing: var(--unnamed-character-spacing-0);
        text-align: center;
        font: normal normal 300 20px/32px 'Plus Jakarta Sans', sans-serif;
        letter-spacing: 0;
        color: #3D3D41;
        opacity: 1;

      }
    }
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.poz_rel {
  position: relative;
}
.receipt-widget {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 70% ;
  width: 70%  ;

  .btn_abs {
    z-index: 1000;
  }

  .img {
    width:  100%;
    height: 100%;
    max-height: 430px;
    align-self: center;
  }
}
.wrapper_file_upload {
  position: relative;
  height: 100%;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px dashed black;
  padding-bottom: 54px;
  transition: background-color 300ms;

  &_left {
    margin-left: auto;
    margin-right: 10px;
    @media (max-width: 767px) {
      margin-right: auto;
    }
  }

    &_right {
    margin-right: auto;
    margin-left: 10px;
    @media (max-width: 767px) {
      margin-left: auto;
    }
  }

  @media (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  &_prog {
    border-style: solid;
  }
  .file_upload {
    text-align: center;
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 20px;
    .text {

        text-align: center;
        font: normal normal 300 20px/32px "'Plus Jakarta Sans'", sans-serif;
        letter-spacing: -1px;
        color: #3D3D41;
        opacity: 1;
        padding-bottom: 20px;
    }
  }
}
.upload_img {
  width: 44px;
  margin-bottom: 30px;
}
.file_upload input[type="file"] {
  display: none;
}
.btn_abs {
  position: absolute;
  bottom: 0;
  left: 30%;
  right: 0;
  line-height: 0;
  border-radius: 0 0 4px 4px;
  display: flex;
  justify-content: center;
  align-items: center;;
}
.btn {

  text-align: center;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  transition: all 0.3s;
  box-sizing: border-box;
  font-weight: 500;
  padding: 0 15px;
  box-shadow: none !important;
  min-height: 8%;

  text-align: center;
  font: normal normal normal 13px/16px 'Plus Jakarta Sans';
  letter-spacing: 0;
  color: var(--color2);
  opacity: 1;
  &.disabled {
    opacity: 0.3;
  }
  &-lg {
    max-width: 470px;
    width: 100%;
  }
  &-md {
    max-width: 226px;
    width: 100%;
  }
  &-select {
  border: 1px solid var(--color2);
  border-radius: 40px;
  opacity: 0.9;
  }
  &-absolute {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 20%;
    width: 50%;
  }
}
.highlight {
  background-color: #c4f3f3;
}

.overflow-hidden {
  overflow: hidden;
}
</style>
