<template>
  <div class="radioInputWrapper" :style="styles">
    <ValidationProvider :rules="validation" v-slot="{ errors }" :name="$t(label)">
      <label v-if="labelOnTop" :style="labelOnTopStyle" class="input-label">
          {{$t(label)}}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>{{$t('(optional)')}}</span>
        </label>
      <div class="inputWrapper">
        <div
          class="custom-control custom-radio radio-input"
          v-for="option in options"
          :key="option.name"
          :tabindex="option.tabIndex"
        >
          <input
            v-model="localSelected"
            class="custom-control-input"
            type="radio"
            :name="fieldId"
            :id="option.name"
            :value="option.value"
            checked
            :disabled="option.disabled"
          />
          <label class="custom-control-label" :for="option.name">{{$t(option.text)}}</label>
        </div>
      </div>
      <div class="validation-error" v-if="errors.length">
        <!-- <img class="error_min" src="../../assets/icons/form/error.svg" alt="" /> -->
        {{errors[0]}}
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
export default {
  name: "radio-input",
  data () {
    return {
      localSelected: this.selectedValue || ""
    };
  },
  props: {
    field: {
      required: false,
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      required: true
    },
    fieldId: {
      type: String,
      required: true
    },
    selectedValue: {
      type: String,
      required: false,
      default: ""
    },
    validation: {
      type: String,
      required: false
    },
    direction: {
      type: String,
      required: false,
      default: "row"
    },
    color: {
      type: String,
      default: "#000"
    },
    styles:{
      type: Object,
      default: ()=>{}
    },
    label: {
      type: String,
      required: false
    },
    labelOnTop: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    localSelected (value) {
      this.$emit("component-updated", {
        selectedValue: value
      });
      this.$emit("input", value);
    }
  }
};
</script>

<style lang="scss" scoped>
$medium: 800px;
.radioInputWrapper {
  @media only screen and (max-width: $medium) {
    // margin-left: 30px;
    // margin-right: 20px;
  }
  .inputWrapper {
    display: flex;
    background: #FFFFFF;
    border: 1px solid var(--input-border-color);
    box-sizing: border-box;
    border-radius: 8px;
    height: 44px;
  }
}
input[type="radio"] + label {
  // color: #C4C4C4;
}
.custom-radio {
  padding-left: 35px;
  &:nth-child(1) {
    border-right: 1px solid var(--input-border-color);;
  }


&:focus-visible {
  border: 1px solid red;
}
}
input[type="radio"] + label:before {
  border: none;
  box-shadow: none !important;
  width: 14px;
  height: 14px;
  top: 3px;
  left: -32px;
}

.custom-control {
  display: flex;
  align-items: center;
  flex: 1;
}

.custom-control-label {
  text-align: center;
  font: normal normal 300 16px/22px 'Plus Jakarta Sans';
  letter-spacing: 0px;
  color: #1B1B1B;
  opacity: 1;
}

.custom-control-label::after,
.custom-control-label::before {
  width: 14px;
  height: 14px;
  top: 0;
  left: -35px;
  margin-left: 10px;
  margin-top: 2px;
}
.custom-control-label::after {
  background-image: none !important;
  background-color: var(--color5) !important;
}
.custom-control-input[type="radio"]:checked ~ .custom-control-label::before {
  background: var(--color5);
  padding: 5px;
  border: 1px solid var(--color5) !important;
}
.custom-control-input[type="radio"]:active ~ .custom-control-label::before {
  background-color: var(--color5);
  opacity: 0.4;
  border: 1px solid var(--color5);
}
.custom-checkbox-dop {
  position: absolute;
  bottom: 5px;
  right: 10px;
  .custom-control-label {
    &:after,
    &:before {
      left: -20px;
    }
  }
}
.custom-control-label::after,
.custom-checkbox .custom-control-label::before,
.custom-control-input[type="radio"] {
  background-color: transparent !important;
  width: 20px;
  height: 20px;
  border: 1px solid var(--color5);
  border-radius: 50%;
}
</style>
