<template>
  <div class="keyVisualWrapper" :style="styles">
    <div :class="{'container': !noContainer}">
      <div :class="{'row':addRow}">
        <img class="desktop_img img-fluid w-100" :src="`/assets/banner/${this.locale}/banner_desktop.jpg`" :alt="this.altText ? $t(this.altText) : 'Banner'" aria-describedby="imageDescription" >
        <img class="tablet_img img-fluid w-100" :src="`/assets/banner/${this.locale}/banner_tablet.jpg`" :alt="this.altText ? $t(this.altText) : 'Banner'" :style="tabletImgStyles" aria-describedby="imageDescription" >
        <img class="mobile_img img-fluid w-100" :src="`/assets/banner/${this.locale}/banner_mobile.jpg`" :alt="this.altText ? $t(this.altText) : 'Banner'" :style="mobileImgStyles" aria-describedby="imageDescription" >
        <p v-if="altText" id="imageDescription" class="visually-hidden">{{$t(this.altText)}}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "key-visual",
  props: {
    desktopImageUrl: {
      type: String
    },
    mobileImageUrl: {
      type: String
    },
    tabletImageUrl: {
      type: String
    },
    addRow: {
      type: Boolean
    },
    noContainer: {
      type: Boolean
    },
    mobileImgStyles: {
      type: Object,
      default: () => {}
    },
    tabletImgStyles: {
      type: Object,
      default: () => {}
    },
    styles: {
      type: Object,
      default: () => {}
    },
    altText: {
      type: String,
      default: ""
    }
  },
};
</script>

<style scoped lang="scss">
  .keyVisualWrapper {
    position: relative;
  }

.desktop_img {
  @media (max-width: 768px) {
    display: none;
  }
}

.tablet_img {
  @media (max-width: 435px), (min-width: 769px) {
    display: none;
  }
}

.mobile_img {
  @media (min-width: 436px) {
    display: none;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

</style>
