<template>
  <div class="dropDownMainContainer">
    <div class="dropDownWrapper" @click="toggleDropdown">
      <img :src="selectedFlag"/>
      <img src="/assets/svg/down-arrow.svg" alt="Down Arrow" class="transition" :style="isOpen ? 'transform: rotate(180deg)' : ''"/>
    </div>
    <div v-if="!hidden" ref="dropDownMenu" class="dropDownMenu transition">
      <div class="menuItem" :key="country.phoneCode" v-for="country in countries" @click="selectCountry(country)">
        <img class="flagImage" :src="country.flag"/>
        <span
          class="languageText"
          v-html="selectedCountry === country.phoneCode ? `<b>${country.phoneCode}</b>` : country.phoneCode"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "sms-dropdown",
  props: {
    countries: {
      type: Array,
      required: true,
      default: () => [{ countryCode: "en", phoneCode: "+44", flag: "/assets/flags/england.svg" }]
    }
  },
  data: () => {
    return {
      selectedCountry: '',
      isOpen: false,
      disabled: true, // to prevent misclicks while in the animation
      hidden: true
    }
  },
  computed: {
    selectedFlag () {
      return this.countries.find(el => el.phoneCode === this.selectedCountry).flag
    }
  },
  beforeMount () {
    let lang = localStorage.getItem("lang");
    this.selectedCountry = this.countries.find(c => c.countryCode === lang).phoneCode;
    this.$emit('input', this.selectedCountry)
  },
  methods: {
    toggleDropdown () {
      let dropDownMenu = this.$refs['dropDownMenu'];
      if (this.isOpen) { // if dd is open
        dropDownMenu.style.transform = "translate(0px, -70px)"
        dropDownMenu.style.opacity = "0"
        this.disabled = true;
        setTimeout(() => {
          this.hidden = true;
        }, 333);
      } else { // if dd is closed
        this.hidden = false;
        this.disabled = false;
        setTimeout(() => {
          dropDownMenu = this.$refs['dropDownMenu'];
          dropDownMenu.style.transform = "translate(0px, 0px)"
          dropDownMenu.style.opacity = "1"
        }, 0);
      }
      this.isOpen = !this.isOpen
    },
    selectCountry (country) {
      if (!this.disabled) {
        this.selectedCountry = country.phoneCode
        this.toggleDropdown()
        this.$emit('input', this.selectedCountry)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/dropdown-oralb.scss";
.dropDownMenu {
  left: 0;
}
</style>
