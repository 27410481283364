<template>
  <div>
    <div v-if="showDisplayFull" class="row">
      <div class="pb-3">
        <barcodeInformation :desktop="true"/>
      </div>
      <div class="col-md-8">
        <barcodeScanner :texts="texts" :height="readerHeight" :width="readerWidth" :cameraTimeOut="cameraTimeOut" :maxProductCount="maxProductCount" />
      </div>
      <div class="col-md-4" style="background: #FFFFFF 0% 0% no-repeat padding-box;">
        <p class="searchModuleHeading" >Barcode hinzufügen</p>
        <SearchModule v-on:some-event="redirect($event)" :apiUrl="apiUrl" :maxProductCount="maxProductCount"/>
        <productsList v-if="list.length" :fullSize="true" :maxProductCount="maxProductCount" />
      </div>
    </div>
    <div v-else class="row">
      <div class="col-md-12">
        <div class="pb-3">
          <barcodeInformation :desktop="false" />
        </div>
        <barcodeScanner
          :texts="texts"
          :height="readerHeight"
          :width="readerWidth"
          :cameraTimeOut="cameraTimeOut"
          :tabletView="true"
          :list="list"
          :maxProductCount="maxProductCount"
        />
        <div class="col-md-12 pt-5" style="background: #FFFFFF 0% 0% no-repeat padding-box;">
          <p class="searchModuleHeading" >Barcode hinzufügen</p>
          <SearchModule v-on:some-event="redirect($event)" :apiUrl="apiUrl" :maxProductCount="maxProductCount"/>
          <productsList v-if="list.length" :fullSize="true" :maxProductCount="maxProductCount" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center row">
      <div class="col-lg-3" style="padding-top: 40px">
        <button
          v-if="pageToBack !== 'reviewProducts'"
          type="button"
          class="btn w-100 next-btn"
          :class="{'disabled': nextButtonDisabled}"
          style="height: 50px;"
          :disabled="nextButtonDisabled"
          @click="checkIfOnlyOneProductSelected"
        >
          Weiter
        </button>
        <button
          v-if="pageToBack === 'reviewProducts'"
          type="button"
          class="btn w-100 next-btn"
          :class="{'disabled': nextButtonDisabled}"
          :disabled="nextButtonDisabled"
          @click="backToProductsOverview"
        >
          Zurück zur Produktübersicht
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import BarcodeScanner from './BarcodeScanner'
import SearchModule from './SearchModule'
import ProductsList from '../ProductsList'
import BarcodeInformation from '../barcode-information'
export default {
  name: 'barcode-scanner',
  components: {
    BarcodeScanner,
    SearchModule,
    ProductsList,
    BarcodeInformation
  },
  data () {
    return {
      tabValue: ["DETERGENT" , "SOFTENERS" , "BEADS"],
      popUpSeen: false
    }
  },
  computed: {
    ...mapState({
      list: state => state.scannedProducts,
      nextButtonDisabled: state => !state.scannedProducts.length,
      status: state => state.searchStatus,
      barcode: state => state.productCode
    }),
    showDisplayFull () {
      return !this.isMobileOrTablet(screen.width)
    },
    pageToBack () {
      return this.$store.getters.pageToBack;
    }
  },
  props: {
    readerHeight: {
      type: Number
    },
    readerWidth: {
      type: Number
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    apiUrl: {
      type: String
    },
    successAction: {
      type: [Object, Array]
    },
    errorPageName: {
      type: String
    },
    cameraTimeOut: {
      type: Number
    },
    maxProductCount: Number
  },
  methods: {
    ...mapActions(["getBarCode", "searchProductByBarcode", "setErrorActivate" , "updateSearchStatus"]),
    sendProducts () {
      const apiUrl = "https://template-v2.justsnap.eu/api/submitProducts";

      this.list.forEach(item => {
        item.count = 1;
      })

      const formData = {
        selectedProducts: this.list
      };

      this.$store.dispatch("sendProducts", { formData, apiUrl }).then(r => {
        if (r.response) {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
          return;
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
    },

    backToProductsOverview () {
      this.$eventHub.$emit('backToPage-event')
    },

    checkIfOnlyOneProductSelected () {
      console.log(this.list)

      // if (this.list && this.list.length == 1 && !this.popUpSeen) {
      //   this.showModal("Sind Sie sicher, dass Sie nur mit einem Produkt teilnehmen möchten? Alle einzulösenden Lenor Produkte müssen auf einem gemeinsamen Bild abgebildet sein." , "custom")
      //   this.popUpSeen = true
      //   return
      // }

      this.sendProducts()
    }

  },
  destroyed () {
    // Set the error message to false
    this.setErrorActivate(false)
    this.$store.dispatch("findProducts", []);
    this.getBarCode('')
    this.updateSearchStatus(null)
  },
  mounted () {
    // Check if the search status error is true
    if (this.status === "not_found")
      this.setErrorActivate(true)
    else
      this.setErrorActivate(false)
  }
}
</script>

<style scoped lang="scss">
.product_list-relative_container {
  position: relative;
  height: 100%;
}
.product_list-absolute_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
@media (max-width: 768px) {
  .product_list-absolute_container {
    position: static;
  }
}
.scroll {
  overflow-y: scroll;
}
.next-btn {
    background: var(--color2) 0% 0% no-repeat padding-box;
    border-radius: var(--button-radius);

    margin-bottom: 40px;

    font: normal normal 500 20px/24px 'Plus Jakarta Sans';
    letter-spacing: 0px;
    color: #FBFBFB;
    opacity: 1;
}
.next-btn.disabled {
    background: #E3E3E3 !important;
    border-color: #E3E3E3 !important;
    color: #6F6F6F;
}
.confirm-msg {
    background: #FFFFFF;
    border: 1px solid #DDDFE1;
    box-sizing: border-box;
    border-radius: 20px;


    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
}
@media screen and (max-width: 425px) {
  .next-btn,
  .next-btn.disabled {
    height: 48px;
    width: 100%;
  }
}

.searchModuleHeading {
  text-align: center;
  font: normal normal 500 20px/24px 'Plus Jakarta Sans', Medium;
  letter-spacing: 0px;
  color: #3D3D41;
  opacity: 1;
}
</style>
