<template>
  <div class="smsVerificationWrapper">
    <template v-if="active===0">
      <paragraph :text="$t(heading)" :classes="['heading']"/>
      <paragraph :text="$t(subHeading1)" :classes="['subHeading']" :styles="{'margin-bottom': '35px;'}"/>
      <paragraph :text="$t(description)" :classes="['subHeading']" :styles="{'font-size': '16px;'}"/>
      <ValidationObserver ref="numberForm">
        <div class="inputWrapper">
          <div class="d-flex whiteCard whiteCard-fit col-lg-5 col-md-7 col-sm-12">
            <div class="notDashedContainer">
              <div class="notDashedContainerInner">
                <label class="input-label">
                  {{$t(title1)}}
                </label>
                <div class="smsInputWrapper">
                  <div style="border-right: 1px solid #D1E7FF; margin: 0 10px;">
                    <sms-dropdown
                      v-model="phoneCode"
                      :countries="items"
                      :menuStyle="'left: 0;'"
                    />
                  </div>
                  <span v-html="phoneCode"></span>
                  <textInput
                    name="phoneNumber"
                    v-model="tel"
                    class="phoneNumber col-md-7 col-sm-5 mr-1 desktop"
                    label="Mobilrufnummer *"
                    validation="required|integer"
                    :styles="inputStyles"
                    :slideUp="tel != ''"
                    :labelOnTop="true"
                    :showLabel="false"
                    :placeholder="$t(placeholder)"
                  />
                  <textInput
                    name="phoneNumber"
                    v-model="tel"
                    class="phoneNumber col-md-7 mr-1 mobile"
                    label="Mobilrufnummer *"
                    validation="required|integer"
                    :styles="inputStyles"
                    :slideUp="tel != ''"
                    :labelOnTop="true"
                    :showLabel="false"
                    :placeholder="$t(placeholderMobile)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div class="d-flex buttonContainer col-lg-12 row">
        <buttonComponent
          addClass="backButton absolute left"
          @click.native="backButton"
          :text="$t('Back')"
          :grid= "{columns: 3}"
        />
        <buttonComponent
          addClass="primaryButton"
          :class="{'disabled': isCodeSendBtnDisabled}"
          :disabled="isCodeSendBtnDisabled"
          @click.native="goto(1)"
          :text="textBtn1"
          :grid= "{columns: 3}"
        />
      </div>
    </template>
    <template v-if="active===1">
      <paragraph :text="$t(heading)" :classes="['heading']"/>
      <paragraph :text="$t(subHeading2)" :classes="['subHeading']" :styles="{'margin-bottom': '35px;'}"/>
      <ValidationObserver ref="codeInput">
        <div class="inputWrapper">
          <div class="codeInputWrapper col-lg-5 col-md-7 col-sm-12">
            <div class="d-flex whiteCard whiteCard-fit col-sm-12">
              <div class="notDashedContainer">
                <div class="notDashedContainerInner">
                  <label class="input-label">
                    {{$t(title2)}}
                  </label>
                  <v-otp-input
                    inputClasses="otp-input"
                    :numInputs="4"
                    separator="-"
                    :shouldAutoFocus="true"
                    @on-complete="handleOnComplete"
                    @on-change="handleOnChange"
                  />
                </div>
              </div>
            </div>
            <div style="margin-top: 20px;">
              <span class="" style="font: normal normal normal 12px/15px 'Plus Jakarta Sans'; cursor: pointer;" @click="active = 0"><u>{{$t('Change mobile number')}}</u></span>
            </div>
          </div>
        </div>
      </ValidationObserver>
      <div class="d-flex buttonContainer col-lg-12 row">
        <buttonComponent
          addClass="backButton absolute left"
          @click.native="backButton"
          :text="$t('Back')"
          :grid= "{columns: 3}"
        />
        <buttonComponent
          addClass="primaryButton"
          :class="{'disabled': isNextBtnDisabled}"
          :disabled="isNextBtnDisabled"
          @click.native="verify"
          :text="textBtn1"
          :grid= "{columns: 3}"
        />
      </div>
    </template>
  </div>
</template>

<script>
import buttonComponent from "@/components/renderers/button-component.vue";
import textInput from "@/components/renderers/text-input.vue";
import Paragraph from '../paragraph.vue';
import SmsDropdown from '../OralB-components/sms-dropdown.vue';

export default {
  name: "sms-verification",
  components: {
    buttonComponent,
    textInput,
    Paragraph,
    SmsDropdown
  },
  props: {
    textBtn1: {
      required: false,
      type: String,
      default: "Button"
    },
    textBtn2: {
      required: false,
      type: String,
      default: "Button"
    },
    generateUrl: String,
    authUrl: String,
    onSuccess: {
      type: Object
    },
    inputStyles:{
      type:Object,
      required:false,
      default:()=>{}
    },
    heading: {
      type: String
    },
    subHeading1: {
      type: String
    },
    subHeading2: {
      type: String
    },
    description: {
      type: String
    },
    placeholder: {
      type: String
    },
    placeholderMobile: {
      type: String
    },
    title1: {
      type: String
    },
    title2: {
      type: String
    }
  },
  data () {
    return {
      phoneCode: "",
      active: 0,
      tel: "",
      code: "",
      items: [
        { countryCode: 'dk', phoneCode: '+45', flag: '/assets/flags/denmark.svg' },
        { countryCode: 'no', phoneCode: '+47', flag: '/assets/flags/norway.svg' },
        { countryCode: 'fi', phoneCode: '+358', flag: '/assets/flags/finland.svg' },
        { countryCode: 'se', phoneCode: '+46', flag: '/assets/flags/sweden.svg' }
      ]
    };
  },
  methods: {
    handleOnComplete (code) {
      // Maybe we can auto send here?
    },
    handleOnChange (code) {
      this.code = code
    },
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    backButton () {
      this.goToPage('register' , this);
    },
    async goto (page) {
      const isValid = await this.$refs.numberForm.validate();
      // const isPhoneNumber = /\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*\d\W*(\d{1,2})$/.test(`${this.countryCode}${this.tel}`)
      if(!isValid) return;
      // if(!isPhoneNumber){
      //  return this.$store.commit('setModalOptions', { type: 'error', isActive: true, message: "Die Telefonnummer ist nicht gültig." })
      // }

      const formData = {
        mobile: `${this.phoneCode}${this.tel}`
      };
      const apiUrl = this.generateUrl;

      this.$store.dispatch("generateCode", { formData, apiUrl }).then(r => {
        this.active = page;
        this.scrollTo('AcIfie');
      });
    },
    async verify () {
      const isValid = await this.$refs.codeInput.validate();
      if (!isValid) return;
      // console.log("VERiFY CODE");
      //do the verification... then ->
      const formData = {
        mobile: `${this.phoneCode}${this.tel}`,
        code: this.code
      };

      const apiUrl = this.authUrl;
      this.$store.dispatch("verifyCode", { formData, apiUrl }).then(r => {
        if (r.response) {
          const fn = this.onSuccess.fn;
          const params = this.onSuccess.params;
          this[fn](params, this);
        } else {
          this.showModal('Der Bestätigungscode ist nicht korrekt.')
        }
      });
    }
  },
  computed: {
    isCodeSendBtnDisabled () {
      if (!/^[0-9]*$/.test(this.tel)) return true; // Numeric
      if (this.tel.substring(0,1) == '5' && this.tel.length == 10 ) return false; // Mobile auth
      return true; // Else condition
    },
    isNextBtnDisabled () {
      console.log(this.code)
      if (/^(\d{4})$/.test(this.code)) return false; // Sms Code Length
      return true; // Else condition
    }
  }
};
</script>
<style>
.smsVerificationWrapper.text-center.col-lg-12.component-wrapper > span > div > div.m-0.p-0.countryCode > span > div > span {
    font-size: 10px !important;
}
</style>
<style lang="scss" scoped>
.sms-button {
  background-color: #000;
}

.smsVerificationWrapper {
  font-family: inherit;
  max-width: 1440px;
  .description {
    font-size: 14px;
    &.bigger {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .sendCodeButton {
    margin-top: 30px;
  }
}

.title {
  text-align: left;
}

.codeInputWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  height: fit-content;

  > div > div > .notDashedContainerInner > div {
  justify-content: center;
  ::v-deep span {
    color: var(--input-border-color);
  }
}

}

.inputWrapper {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.smsInputWrapper {
  background: #FFFFFF;
  border: 1px solid var(--input-border-color);
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: 44px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  @media (max-width: 991px) {
    justify-content: flex-start !important;
  }
  ::v-deep input {
    width: 100% !important;
    padding: 0;
    height: 40px !important;
    border: none !important;

    @media (max-width: 991px) {
      width: 90% !important;
      padding: 5px;
    }

    @media (max-width: 400px) {
      width: 80% !important;
    }
  }
  ::v-deep input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
  }

  ::v-deep span {
    font: normal normal normal 14px/25px 'Plus Jakarta Sans';
  }

  ::v-deep .validation-error {
    position: absolute;
  }
}

.country-flag {
  width: 30px;
}

::v-deep .IZ-select {
  justify-content: center;
  width: 100%;
  border-radius: 100px;
  .validation-error {
    margin-left: 0;
  }

  .IZ-select__input {
    border: none;
    box-shadow: none;
    width: 100px;
    padding-left: 0;
  }

  .IZ-select__menu {
    width: 200px !important;
  }

  .IZ-select__input--selection-slot {
    & > div {
      flex: 1 1 auto;
    }
  }

  input {
    display: none;

    &.custom-input {
      display: block;
    }
  }
}

::v-deep .hide .IZ-select__menu {
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
}

.country-selected-item {
  height: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  width: 100%;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #808080;
  cursor: pointer;
  margin-top: 5px;
  border-radius: var(--field-radius);
  &.is-invalid {
    border-color: #dc3545;
  }

  .country-code {
    font-weight: 400;
    padding-left: 5px;
  }
  .custom-input-wrap {
    padding-left: .75rem;
    display: inline-flex;
    align-items: center;

    .custom-input {
      padding-left: 4px;
      padding-right: 28px;
    }
    .custom-input-warn {
      position: absolute;
      right: 15px;
      width: 16px;
    }
  }
}

.country-item {
  display: flex;
  align-items: center;

  .country-code {
    margin-left: 10px;
  }
}

.zurechBtn {
  border: 1px solid #FF4D56;
  background-color: white;
  color: var(--color1);
  border-radius: 6px;
  opacity: 1;

  font: normal normal 500 20px/24px 'Plus Jakarta Sans', Medium;
  letter-spacing: 0px;
  color: #FF4D56;
  opacity: 1;

  max-height: 50px;
}

.codeBtn {
  background: var(--color4) 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;

  font: normal normal 500 20px/24px 'Plus Jakarta Sans', Medium;
  letter-spacing: 0px;
  color: #FBFBFB;
  opacity: 1;

  &Wrapper {
    padding-bottom: 15px;
  }
}

@media (max-width: 991px) {

  .heading { font: normal normal 600 24px/34px 'Plus Jakarta Sans' !important; }
  .subHeading { font: normal normal normal 16px/20px 'Plus Jakarta Sans' !important; }

  .btnWrapper {
    flex-direction: column;
  }

}

@media (min-width: 992px) {
  .codeBtnWrapper { display: none; }
}


::v-deep .otp-input {
  width: 50px;
  height: 44px;
  padding: 5px;
  font-size: 20px;
  background: #FFFFFF;
  border: 1px solid var(--input-border-color);
  box-sizing: border-box;
  border-radius: 8px;
  text-align: center;

  @media (max-width: 1148px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 1057px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 767px) {
    width: 40px;
    height: 40px;
    font-size: 14px;
  }
  @media (max-width: 400px) {
    width: 30px;
    height: 30px;
  }
}

.notDashedContainerInner > div {
  @media (max-width: 400px) {
    justify-content: flex-start;
  }
  ::v-deep span {
    margin: 0 10px;
    @media (max-width: 1148px) {
      margin: 0 8px;
    }
    @media (max-width: 1057px) {
      margin: 0 6px;
    }
    @media (max-width: 767px) {
      margin: 0 4px;
    }
    @media (max-width: 400px) {
      margin: 0 1px;
    }
  }
}

@media (min-width: 991px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}
@media (max-width: 991px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

.buttonContainer {
  justify-content: center;
  padding: 140px 0;
  @media (max-width: 991px) {
    padding: 80px 0;
    justify-content: flex-end;
  }
 }
</style>
