<template>
  <div class="poz_rel whiteCard overflow-hidden" >
    <div class="dashedContainer">
      <div
        class="wrapper_file_upload"
        :style="{ display: selectedFile ? 'none' : 'flex'}"
        :class="{ highlight: highlight }"
        @dragover.prevent
        @drop.stop.prevent="handleDrop"
        @dragenter.stop.prevent="onDragEnter"
        @dragleave.stop.prevent="onDragLeave"
      >
        <label class="file_upload">
          <input
            :disabled="hasUnresolvedDependencies"
            accept="image/*, application/pdf"
            id="uploaded-file"
            class="uploaded-file"
            type="file"
            name="file"
            ref="image"
            @change="onFilePicked"
          />
          <!-- <img class="upload_img" src="../../assets/images/svg/fileUpload.svg" alt="upload" /> -->
          <div class="text1" v-html="$t(this.texts.receiptUpload1)"></div>
          <div class="text2" v-html="$t(this.texts.receiptUpload2)"></div>
        </label>
        <a
          href="#"
          class="btn upload-btn btn_abs secondaryButton customColor"
          @click.prevent="selectAnother"
          tabindex="0"
        >{{$t(selectImageTitle)}}</a>
      </div>

      <div v-show="!!selectedFile" class="receipt-widget">
        <receipt-scanner
          :key="attempts"
          v-model="receipt"
          class="receipt-scanner"
          :apiUrl="apiUrl"
          ref="receiptScanner"
          :file="selectedFile"
          :corner-detection-phrases="cornerDetectionPhrases"
          :phrases="phrases"
          @input="onInput($event)"
          @uploadError="handleUploadError"
          v-bind="$props"
          :show="!!selectedFile"
          controlsBackground="transparent"
          :cropColor="colorSchema"
        >
          <template slot="crop">
            <span :title="$t(cropTitle)" v-b-tooltip>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0057B8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-crop"
              >
                <path d="M6.13 1L6 16a2 2 0 0 0 2 2h15" />
                <path d="M1 6.13L16 6a2 2 0 0 1 2 2v15" />
              </svg>
            </span>
          </template>
          <template slot="drag">
            <span :title="$t(dragTitle)" v-b-tooltip>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0057B8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-move"
              >
                <polyline points="5 9 2 12 5 15" />
                <polyline points="9 5 12 2 15 5" />
                <polyline points="15 19 12 22 9 19" />
                <polyline points="19 9 22 12 19 15" />
                <line x1="2" y1="12" x2="22" y2="12" />
                <line x1="12" y1="2" x2="12" y2="22" />
              </svg>
            </span>
          </template>
          <template slot="erase">
            <span :title="$t(eraseTitle)" v-b-tooltip>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0057B8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-edit-2"
              >
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
              </svg>
            </span>
          </template>
          <template slot="zoomIn">
            <span :title="$t(zoomInTitle)" v-b-tooltip>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0057B8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-zoom-in"
              >
                <line x1="11" y1="3" x2="11" y2="19" />
                <line x1="3" y1="11" x2="19" y2="11" />
              </svg>
            </span>
          </template>
          <template slot="zoomOut">
            <span :title="$t(zoomOutTitle)" v-b-tooltip>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0057B8"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-zoom-out"
              >
                <line x1="3" y1="11" x2="19" y2="11" />
              </svg>
            </span>
          </template>
        </receipt-scanner>
        <a
          href="#"
          v-if="selectedFile"
          @click.prevent="selectAnother"
          class="btn upload-btn btn_abs secondaryButton customColor"
        >{{$t(selectAnotherTitle)}}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { ReceiptScanner } from "../receipt-editor";
import "@/assets/styles/receipt-editor.css";
import { mapActions } from 'vuex';

export default {
  name: "receipt-processor",
  components: {
    ReceiptScanner
  },
  data () {
    return {
      receipt: null,
      apiUrl: "https://receipt.justsnap.eu", // api that will be used to retrieve "image points"
      selectedFile: null,
      processedFile: null,
      uploadSectionHover: false,
      highlight: false,
      attempts: 0,
      selectedValidate: false,
      processedValidate: false
    };
  },
  props: {
    successAction: Object,
    backgroundColorBtn: {
      required: false,
      type: String,
      default: "#000"
    },
    borderColorBtn: {
      required: false,
      type: String,
      default: ""
    },
    textColorBtn: {
      required: false,
      type: String,
      default: "#fff"
    },
    textBtn: {
      required: false,
      type: String,
      default: "WEITER"
    },
    borderRadiusBtn: {
      required: false,
      type: String,
      default: ""
    },
    fieldId: String,
    requires: {
      type: Array
    },
    phrases: {
      type: Array
    },
    cornerDetectionPhrases: {
      type: Array
    },
    hideEraser: {
      type: Boolean
    },
    hideZoomer: {
      type: Boolean
    },
    skipCornerDetection: {
      type: Boolean
    },
    eraseColor: {
      type: String
    },
    eraseSize: {
      type: Number
    },
    cropColor: {
      type: String
    },
    cropBackground: {
      type: String
    },
    cropTitle: {
      type: String
    },
    eraseTitle: {
      type: String
    },
    dragTitle: {
      type: String
    },
    selectImageTitle: {
      type: String
    },
    selectAnotherTitle: {
      type: String
    },
    zoomInTitle: {
      type: String
    },
    zoomOutTitle: {
      type: String
    },
    controlsBackground: {
      type: String
    },
    postBillUrl: {
      type: String,
      default: "https://receipt.justsnap.eu/postBill"
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    tokenName: {
      required: false,
      type: String,
      default: "token"
    },
    reUpload: {
      required: false,
      type: Boolean
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    addClass: String,
    selected: [Blob,File],
    internalButton: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  watch: {
    processedFile (value) {
      this.processedValidate = !!value;
    },
    selectedFile (value) {
      this.selectedValidate = !!value;
    },
    isImageCorrect (val) {
      this.$eventHub.$emit('canProceedNext', val)
    }
  },
  mounted () {
    this.selectedFile = this.selected || null
    this.$eventHub.$on("sendReceipt-event", this.send);
  },
  beforeDestroy () {
    this.$eventHub.$off("sendReceipt-event");
  },
  computed: {
    ...mapActions(['uploadError']),
    isImageCorrect () {
      return (this.processedValidate && this.selectedValidate);
    },
    hasUnresolvedDependencies () {
      return this.$store.getters.hasUnresolvedDependencies(this.requires);
    }
  },
  methods: {
    handleUploadError (e) {
      this.uploadError()
    },
    send () {
      //send receipt then ->
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: false
      });
      const data = {
        image: this.receipt,
        apiUrl: this.postBillUrl,
        customerToken: this.$store.getters.customerToken,
        campaignId: "pg-template"
      }; // receipt info

      if (this.reUpload) {
        data["receiptToken"] = this.$route.query[this.tokenName];
        data["customerToken"] = 1;
        data["campaignId"] = "pg-template-re";
      }

      this.$store.dispatch("uploadReceipt", data).then(r => {
        if (this.internalButton) this.$refs.sendButton.loading = false;
        const fn = this.successAction.fn;
        const params = this.successAction.params;
        this[fn](params, this);
      });
    },
    onFilePicked (event) {
      const files = event.target.files || event.dataTransfer.files;
      this.handleFiles(files);
    },
    handleDrop (e) {
      let dt = e.dataTransfer;
      let files = dt.files;
      this.handleFiles(files);
    },
    handleFiles (files) {
      const maxfilesize = 1024 * 1024 * 16; // 1 Mb
      // this.$store.dispatch("setComponentStatus", {
      //   component: this.fieldId,
      //   status: false
      // });

      if (files && files[0]) {
        let filename = files[0].name;

        if (filename && filename.lastIndexOf(".") <= 0) {
          return;
        }

        if (files[0].size > maxfilesize) {
          this.showModal(this.$t(this.texts.fileSizeLimit));
          this.$refs.image.type = '';
          this.$refs.image.type = 'file';
          return;
        }
        const extension = files[0].name.split(".").pop();
        const found = ["png", "jpg", "jpe", "jpeg", "tiff", "pdf"].filter(
          ext => ext === extension.toLowerCase()
        );
        if (!found.length) {
          this.showModal(this.$t(this.texts.wrongFormat));
          this.$refs.image.type = '';
          this.$refs.image.type = 'file';
          return;
        }
        this.attempts++;
        this.$nextTick().then(() => {
          this.selectedFile = files[0];
          this.processedFile = null;
        });
      }
    },
    onDragEnter () {
      this.highlight = true;
    },
    onDragLeave () {
      this.highlight = false;
    },
    onInput (data) {
      // this.$emit("component-updated", data);
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
      this.processedFile = data;
      this.$emit("component-updated", {
        selected: data
      });
    },
    selectAnother () {
      this.$refs.image.click();
    }
  }
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.4s;
}
.uploadIcon {
  position: absolute;
  top: 0;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.file_upload input[type="file"] {
  display: none;
}
.hidden {
  display: none;
}
.receipt-uploader {
  overflow: hidden;
}

.receipt-scanner ::v-deep {
  width: 100%;
  .layout > .container {
    border: 0;
    padding: 0;
  }
  .canvas-container {
    > div {
      display: flex;
      flex-direction: column;

      .controls {
        background: #4193d3;
        order: -1;

        .btn-group {
          display: flex;
          width: 90%;

          @media (max-width: 767px) {
            width: 100%;
          }

          label {
            padding: 0;
            flex-grow: 1;
          }

          .btn {
            background: none;
            border: none;
            text-transform: uppercase;
            cursor: pointer;

            &:last-child {
              border: none;
            }

            span {
              padding: 10px 15px;
              background: #FFFFFF;
              box-shadow: 0px 4px 8px #EBF5FF;
              border-radius: 2px;
            }

            &:hover span,
            &.active span {
              color: white;

              // &:after {
              //   content: "";
              //   position: absolute;
              //   width: 60%;
              //   border: 2px solid white;
              //   bottom: -10px;
              //   left: 0;
              //   right: 0;
              //   margin: auto;
              // }
            }
          }
        }
      }

      .scanner-bar {
        background: #4f4f4f;
        box-shadow: 0 0 10px #4f4f4f;
      }

      .text {
        p {
          transform: translate(-50%, 0%);
        }
      }
    }
  }
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.poz_rel {
  position: relative;
}
.receipt-widget {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;

  .btn_abs {
    z-index: 1000;
  }
}
.wrapper_file_upload {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 54px;
  transition: background-color 300ms;
  box-sizing: border-box;

  @media (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  &_prog {
    border-style: solid;
  }
  .file_upload {
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 20px;
    .text {
      &1 {
        font: normal normal bold 24px/30px 'Plus Jakarta Sans';
        letter-spacing: 0px;
        color: var(--text-color-2);
        opacity: 1;
        padding-bottom: 15px;
      }

      &2 {
        font: normal normal normal 16px/25px 'Plus Jakarta Sans';
        letter-spacing: 0px;
        color: var(--text-color-3);
        opacity: 1;
      }
    //   line-height: 48px;
    //   @media (max-width: 767px) {
    //     line-height: 32px;
    //   }
    }
  }
}
.upload_img {
  width: 129px;
  margin-bottom: 30px;
}
.file_upload input[type="file"] {
  display: none;
}
.btn_abs {
  border-width: 0px !important;
  position: absolute;
  margin: 0 auto;
  bottom: 30px;
  line-height: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal bold 16px/20px 'Plus Jakarta Sans';
  color: #FFFFFF;
  &:active {
    border-width: 1px !important;
  }
}
.upload-btn {
  background-color: #5A5E5F;

  &:focus,
  &:active {
    outline: 1px solid #000 !important;
    box-shadow: none;
  }
}
.btn {
  color: #1d1d1d;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  transition: all 0.3s;
  box-sizing: border-box;
  font: normal normal bold 20px/24px 'Plus Jakarta Sans', Medium;
  padding: 10px 30px;
  box-shadow: none !important;
  min-height: 54px;
  &.disabled {
    opacity: 0.3;
  }
  &-lg {
    max-width: 470px;
    width: 100%;
  }
  &-md {
    max-width: 226px;
    width: 100%;
  }
  &-yellow {
    border-color: red;
    background-color: red;
    &:hover,
    &:focus {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: red;
    }
    &-min {
      @media (max-width: 574px) {
        font-size: 17px;
      }
    }
  }
  &-white {
    font-size: 16px;
    border-color: #ffffff;
    background-color: #ffffff;
    color: #1d1d1d !important;
    &:hover,
    &:focus {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: #1d1d1d;
    }
  }
  &-green {
    border-color: var(--brand-color);
    background-color: var(--brand-color);
    color: #ffffff !important;
    &:hover,
    &:focus {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: #028c86;
    }
  }
  &-light-blue {
    border-color: #beebe4;
    background-color: #beebe4;
    &:hover,
    &:focus {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16) !important;
    }
    &:active {
      border-color: #02b1aa;
    }
  }
  &-transparent {
    max-width: 350px;
    border-color: #424242;
    color: #424242;
    &:hover,
    &focus {
      background-color: #424242;
      color: #ffffff;
    }
  }
}
.highlight {
  background-color: #c4f3f3;
}
.text.color-black {
  font-size: 13px;
}
.overflow-hidden {
  overflow: hidden;
}

.customColor{
  color: #000 !important;
  background: #ea9e1c !important;
}
</style>
