<template>
  <div class="stepCardWrapper" :style="`--title-color: ${titleColor}; --border-color: ${borderColor}`">
    <div class="stepImage">
      <img :src="imageSource" :alt="stepTitle">
    </div>
    <div class="stepCardContent">
      <p class="stepTitle">{{$t(stepTitle)}}</p>
      <p class="stepDescription">{{$t(stepDescription)}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "step-card-oralb",
  props: {
    fields: {
      required: false,
      type: Array,
      default: () => []
    },
    imageSource: {
      required: true,
      type: String
    },
    stepTitle: {
      required: true,
      type: String,
      default: "Step Title"
    },
    stepDescription: {
      required: true,
      type: String,
      default: "Step Description"
    },
    titleColor: {
      type: String,
      default: "black"
    },
    borderColor: {
      type: String,
      default: "black"
    }
  }
};
</script>

<style lang="scss" scoped>
.stepCardWrapper {
  background: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 0 0 32px;
  border-radius: 16px;
  border: 1px solid var(--border-color);
  max-width: 305px;
  height: 134px;
  margin: 28px;

  .stepImage img {
    height: 65px;
    width: 65px;
    margin-right: 16px;
  }

  .stepTitle {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-weight: 800;
    color: var(--title-color);
  }
  .stepDescription {
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: var(--text-color-3)
  }
}
@media (max-width: 375px) {
  .stepCardWrapper {
    max-width: 242px !important;
    height: 93px !important;
  }
  .stepImage img {
    width: 45px !important;
    height: 45px !important;
    margin-right: 10px !important;
  }
  .stepTitle {
    font-size: 16px !important;
  }
  .stepDescription {
    font-size: 12px !important;
  }
}

</style>
