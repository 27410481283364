<template>
  <div class="bubble_block m-0 p-0">

    <div
      id="bubble_block"
      class="bubble_block desktop_img"
      :style="{backgroundImage: `url(${imageSource})`}"
    >
      <div :style="backgroundStyles">
        <component-renderer
          v-for="field in fields"
          v-bind:key="field.id"
          :field="field"
        />
      </div>
    </div>
    <div
      id="bubble_block"
      class="bubble_block mobile_img"
      :style="{backgroundImage: `url(${mobileImageSource ? mobileImageSource : imageSource})`}"
    >
      <div :style="backgroundStyles">
        <component-renderer
          v-for="field in fields"
          v-bind:key="field.id"
          :field="field"
        />
      </div>
    </div>
  </div>
</template>

<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "background",
  components: { componentRenderer },
  props: {
    fields: {
      required: false,
      type: Array,
      default: () => []
    },
    imageSource: {
      required: false,
      type: String
    },
    mobileImageSource: {
      required: false,
      type: String
    },
    backgroundStyles: {
      required: false,
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.bubble_block {
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

.desktop_img {
  @media (max-width: 1000px) {
    display: none;
  }
}

.mobile_img {
  @media (min-width: 1001px) {
    display: none;
  }
}
</style>
