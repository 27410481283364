<template>
  <div class="gray_block">
    <div class="wrapper_check_block clearfix">
      <div class="checkMark">
          <img class="img-fluid" src="/assets/receipts/good example.png" alt="Receipt example">
      </div>
      <div class="checkMark">
          <img class="img-fluid" src="/assets/receipts/wrong example 1.png" alt="Receipt example">
      </div>
      <div class="checkMark">
          <img class="img-fluid" src="/assets/receipts/wrong example 2.png" alt="Receipt example">
      </div>
      <div class="checkMark">
          <img class="img-fluid" src="/assets/receipts/wrong example 3.png" alt="Receipt example">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "receipt-examples",
  props: {
  },
  methods: {
    pull () {
      this.pullDown = !this.shouldDisplayFull ? !this.pullDown : false
    }
  }
};
</script>
<style lang="scss" scoped>
  .gray_block {
    background-color: transparent;
    padding: 30px;
    border-radius: 4px;

    @media (max-width: 991px) {
      padding: 40px 60px;
    }
    @media (max-width: 767px) {
      padding: 10px 15px;
    }
    .inner_gray_block {
      text-align: center;
      margin-bottom: 50px;
    }
  }
  .checkMark {
    display: flex;
    justify-content: center;

    @media (max-width: 991px) {
      padding-top: 0;
      margin: 0px;
    }
  }
  .wrapper_check_block {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 25px;
    @media (max-width: 991px) {
      grid-template-columns: 1fr 1fr;
      padding-top: 0;
      margin-top: 0px;
    }
  }

</style>
