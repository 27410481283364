<template>
  <a v-if="url" :target="target" :href='url'>
    <img :src="imageSource" class="img-fluid" :class="className" :alt="alternateText" :style="styles"/>
  </a>
  <img v-else :src="imageSource" class="img-fluid" :class="className" :alt="alternateText" :style="styles"/>

</template>

<script>
export default {
  name: "square-image",
  props: {
    target:String,
    imageSource: {
      type: String,
      default: "@/assets/logo.png"
    },
    alternateText: {
      type: String,
      default: "Sample Image"
    },
    url:{
      type:String
    },
    styles:{
      type: Object,
      default:()=>{}
    },
    className: String
  }
};
</script>

<style>
img[data-field-type="square-image"] {
  object-fit: cover
}
</style>
