/*eslint comma-dangle: [2, "always"]*/
export default {
  // General
  'Next': 'Seuraava',
  'NEXT': 'SEURAAVA',
  'SEND': 'LÄHETTÄÄ',
  'Back to the homepage': 'Takaisin etusivulle',
  'Back to the Braun website': 'Palaa Braun:n verkkosivuille',
  'Back': 'Takaisin',
  'LOG IN': 'Kirjaudu sisään',
  'SIGN UP': 'LISÄÄNTYMINEN', // Missing
  '(optional)': '(valinnainen)', // Missing
  'Cookie Text': 'Evästesuostumus',
  'or': 'tai',

  // Header
  'Skip to Main Content': 'Siirtyä Pääsisältöön',
  'Terms and Conditions': 'Käyttöehdot',
  'FAQ': 'FAQ', // Missing

  // Footer
  'Footer Support Text': 'Tukiosastomme on käytettävissä 15.12.2025 asti: <a href="mailto:contact@braun-moneyback.com" ><span><b>contact@braun-moneyback.com</b></span></a>',
  'terms': 'Käyttöehdot',
  'Privacy': 'Yksityisyys',
  'Preference': 'Omat Tiedot',
  'Impressum': 'Jälki',
  'Sitemap': 'Sivun yleiskatsaus',

  // Home Screen
  'START REFUND': 'Aloita palautus',
  'Step 1': 'Vaihe 1',
  'Step 2': 'Vaihe 2',
  'Step 3': 'Vaihe 3',
  'Step 4': 'Vaihe 4',
  'Select your product': 'Valitse tuote',
  'Upload a receipt': 'Lataa kuittisi',
  'Register': 'Rekisteröi',
  'Get your money back': 'Saat rahasi takaisin',
  'bannerAltText': 'Täysi tyytyväisyys tai rahat takaisin!* Ehdot ovat voimassa',

  // Progress Bar

  'Select<br>product': 'Valitse<br>tuote',
  'Upload<br>a receipt': 'Lataa<br>kuittisi',
  'Get your<br>money back': 'Saat rahasi<br>takaisin',

  // Product Select Page
  'Scan the product': 'Skannaa tuote',
  'Type in or scan the barcode and serial number': 'Kirjoita tai skannaa viivakoodi',
  'Please enter the serial number and barcode number of your Braun products, take a photo of your promotional product or upload it using <b>Drag & Drop</b>. Please make sure that the barcode information of your promotional product is clearly visible.': 'Ole kiltti anna Braun-tuotteesi viivakoodinumero, ota valokuva kampanjatuotteestasi tai lataa se käyttämällä <b>raahaa ja pudota</b>. Varmista, että mainostuotteesi viivakooditiedot näkyvät selvästi.',
  'Upload': 'Ladata',
  'Upload<br>barcode image': 'Lataa<br>viivakoodin kuva',
  'Drag & Drop': 'Raahaa ja Pudota',
  'Scan': 'Skannaa',
  'Stop': 'Lopettaa', // Missing
  'Scan<br>barcode image': 'Skannaa<br>viivakoodin kuva',
  '(the easiest)': '(helpointa)',
  'Add': 'Lisää',
  'Add<br>barcode number': 'Lisää viivakoodi',
  'Enter Barcode number': 'Syötä Viivakoodinumero',
  'Serial number': 'Sarjanumero',
  'The file format is not supported. It will only .jpeg, .png and .pdf file accepted.': 'Tätä tiedostomuotoa ei tueta. Vain .jpeg-, .png-, .pdf- ja .tiff-tiedostot hyväksytään.',
  'The file is too large. Only files up to 16 MB are supported.': 'Tiedosto on liian suuri. Vain enintään 16 Mt:n tiedostoja tuetaan.',
  'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.': 'Valitettavasti kelvollista viivakoodia ei voida tunnistaa. Yritä syöttää viivakoodi yllä olevaan kenttään.',
  'Please turn on your camera': 'Kytke kamera päälle',
  'Correct Barcode Number': 'Viivakoodin numero on kelvollinen',
  'Wrong Barcode Number': 'Viivakoodin numero on virheellinen',
  'You cannot enter more than one product.': 'Et voi syöttää enempää kuin yhden tuotteen.',

  // Receipt Upload Page

  'Upload receipt': 'Ladata kuitti',
  'Upload a picture of your proof of purchase in this step.': 'Ladata kuva ostotodistuksestasi tässä vaiheessa.',
  'Upload your receipt photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.': 'Ladata kuitin kuva käyttämällä raahaa ja pudota. Vain JPEG, PDF ja PNG ovat tuettuja. Tiedoston enimmäiskoko: 16MB.',
  'Please photograph your proof of purchase!': 'Ole hyvä ja valokuvaa ostotodisteesi!',
  'Make sure that the following information is clearly visible:': 'Varmista, että seuraavat tiedot näkyvät selvästi:',
  'Dealer / Market name': 'Jälleenmyyjä / markkina',
  'Promotional product and purchase price': 'Myynninedistämistuote ja ostohinta',
  'Total of the proof of purchase and VAT': 'Ostotodisteen loppusumma ja arvonlisävero',
  'Date and time of purchase': 'Ostopäivämäärä ja -aika',
  'Proof of purchase ID': 'Ostotodisteen ID',
  'Upload your receipt photo': 'Ladata kuitin kuva',
  'Drag & Drop or click here': 'Raahaa ja pudota tai napsauttamalla tästä',
  'Choose a File': 'Valitse tiedosto',
  'Choose Another': 'Valitse toinen', // Missing
  'Crop': 'Rajaa',
  'Erase': 'Pyyhi pois',
  'Move': 'Siirry', // Missing
  'Zoom In': 'Lähentää',
  'Zoom Out': 'Loitonnetaan',
  'Please drag your receipt into the gray rectangle.': 'Ole kiltti vedä kuittisi harmaaseen suorakulmioon.',
  'You can erase private information with the razor tool.': 'Voit poistaa yksityisiä tietoja razor-työkalulla.',
  'If you get an error message, please take a better version of the receipt and upload it again.': 'Jos saat virheilmoituksen, ota parempi versio kuitista ja lataa se uudelleen.',
  'The receipt is processed automatically.This can take a minute.Please be patient.': 'Kuitti käsitellään automaattisesti.Tämä voi kestää minuutin.Ole kärsivällinen.',
  'Your receipt will now be uploaded and processed.': 'Kuittisi on nyt ladattu ja käsitelty.',

  // Product upload page

  'Upload product photo': 'Ladata tuotekuva',
  'Please upload a photo of the product side on which the EAN barcode is clearly visible.': 'Ole kiltti ladata kuva tuotteen sivusta, jossa EAN-viivakoodi näkyy selvästi.',
  'Upload your product photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.': 'Ladata tuotekuvan käyttämällä raahaa ja pudota. Vain JPEG, PDF ja PNG ovat tuettuja. Tiedoston enimmäiskoko: 16MB.',
  'Upload your product photo': 'Ladata tuotekuvan',

  // Banking page
  'What is IBAN and where do I find it?': 'Mikä on IBAN ja mistä löydän sen?',
  "IBAN stands for International Bank Account Number. It's an internationally-agreed code made up of up to 34 letters and numbers that helps banks to process transfers around the world. You can usually find your IBAN number on the top right- hand side of your bank statement or in your online banking.If you can't locate your IBAN, you should be able to generate it online via your bank's internet banking service or by using an IBAN calculator tool.": `IBAN on lyhenne sanoista Kansainvälinen Pankkitilinumero.Se on 34 kirjaimesta ja numerosta koostuva kansainvälisesti sovittu koodi, joka auttaa pankkeja käsittelemään tilisiirtoja kaikkialla maailmassa.
  <br>
  <br>
  Löydät IBAN- numerosi yleensä pankkitilisi tiliotteen oikeasta yläkulmasta tai verkkopankissasi.Jos et löydä IBAN - numeroasi, voit luoda sen verkossa pankkisi verkkopankkipalvelun kautta tai käyttämällä IBAN - laskentatyökalua.`,
  'What is BIC and where do I find it?': 'Mikä on BIC ja mistä löydän sen ?',
  "BIC stands for Bank Identifier Code. To send or receive money, any financial institution must know where to send the money and BIC is exactly what shows it. You can usually find your bank's BIC in your bank account statements.If you're using online banking, log into your digital bank account to easily view your bank statement or contact your bank so that they provide you your BIC.": `BIC on lyhenne sanoista Pankin Tunnus Koodi.Rahan lähettämistä tai vastaanottamista varten minkä tahansa rahoituslaitoksen on tiedettävä, minne rahat lähetetään, ja BIC - koodi on juuri se, joka osoittaa sen.
  <br>
  <br>
  Löydät pankkisi BIC - koodin yleensä pankkitiliotteestasi.Jos käytät verkkopankkia, kirjaudu digitaaliselle pankkitilillesi nähdäksesi tiliotteen helposti tai ota yhteyttä pankkiisi, jotta se antaa sinulle BIC - koodisi.`,
  'Enter bank details': 'Anna pankkitiedot',
  'Please enter the first and last name of the account holder exactly as they were given at the bank.': 'Ole kiltti kirjoita tilinomistajan etu- ja sukunimi täsmälleen sellaisina kuin ne on annettu pankissa.',
  'Avoid changing the umlauts: <b>Müller or Mueller</b>; Avoid adding or leaving out hyphens: <b>Marie Luise or Marie-Luise</b>; Only enter the middle name if the account is also managed with it.': 'Vältä umlauttien muuttamista: <b>Müller or Mueller</b>; Vältä väliviivojen lisäämistä tai pois jättämistä: <b>Marie Luise or Marie-Luise</b>; Kirjoita keskimmäinen nimi vain, jos tiliä hoidetaan myös sillä.',
  'Enter your IBAN': 'Syötä IBAN',
  'Enter your BIC': 'Syötä BIC',
  'Enter your first name': 'Syötä etunimi',
  'Enter your last name': 'Syötä sukunimi',
  'Account holder first name': 'Tilinomistajan etunimi',
  'Account holder last name': 'Tilinhaltijan sukunimi',
  'If the data does not exactly match the account details, in most cases the bank will charge back and make the process of an uncomplicated and quick refund more difficult.': 'Jos tiedot eivät täsmälleen vastaa tilin tietoja, pankki veloittaa useimmissa tapauksissa takaisin ja vaikeuttaa mutkatonta ja nopeaa palautusprosessia.',

  // Address page
  'Your address': 'Osoitteesi',
  'Streetname': 'Kadunnimi',
  'House number': 'Numero',
  'Postal code': 'Postinumero',
  'City': 'Kaupunki',
  'Country': 'Maa',
  'Enter your streetname': 'Syötä kadunnimi',
  'Enter the number of your house': 'Syötä talosi numero',
  'Enter your postal code': 'Syötä postinumero',
  'Enter a city you live': 'Kirjoita kaupunki, jossa asut', // Missing
  'Choose a country': 'Valitse maa', // Missing

  // Login page

  'Log in with an Braun account': 'Kirjaudu sisään Braun-tilillä',
  'Log in with<br>Braun account': 'Kirjaudu sisään<br>Braun-tilillä',
  'Email address': 'Sähköpostiosoite',
  'Enter your email address': 'Syötä sähköpostiosoite',
  'Password': 'Salasana',
  'Enter your password': 'Syötä salasana',
  'Forgot password?': 'Unohditko salasanan?',
  "Don't have<br>an account yet?": "Sinulla ei ole<br>vielä Braun-tiliä?",
  'Continue<br>as a guest': 'Jatka<br>vieraana',

  // Registration page

  'Registration form': 'Rekisteröintilomake',
  "Register now and don't miss a thing.": "Rekisteröidy nyt, niin et jää mistään paitsi.",
  'Braun informs you regularly about further "free test" campaigns and also offers exclusive free product tests every week.': 'Tarvitsemme sinulta seuraavat tiedot, jotta voimme käsitellä maksun. Niitä käytetään vain Braun Rahat Takaisin kampanjan käsittelyyn.',
  'Personal information': 'Henkilökohtaiset tiedot', // Missing
  'Title': 'Otsikko',
  'Mrs.': 'Rouva',
  'Mr.': 'Herra',
  'First name': 'Etunimi',
  'Last name': 'Sukunimi',
  'Date of birth': 'Syntymäaika',
  'dd/mm/yyyy': 'pp/kk/vvvv', // Missing
  'Account information': 'Tilitiedot', // Missing
  'E-mail': 'Sähköpostiosoite',
  'Enter your email': 'Syötä sähköpostiosoite',
  'Create a strong password': 'Luo vahva salasana', // Missing
  'I confirm that I am over 18 years old. I confirm the terms and conditions, privacy policy & Justsnap privacy policy.': 'Vahvistan, että olen yli 18-vuotias. Vahvistan <a href="https://termsandconditions.pg.com/fi-fi/" target="_blank"><u>käyttöehdot</u></a>, <a href="https://privacypolicy.pg.com/fi-FI" target="_blank"><u>tietosuojakäytännön</u></a> ja <a href="https://justsnap.de/Datenschutz/Data_Policy_DE.pdf" target="_blank"><u>Justsnap tietosuojakäytännön</u></a>.',
  'I have read the Conditions of participation for this promotion and accept them.': 'Olen lukenut <a href="https://braun-moneyback.com/fi/terms" target="_blank"><u>osallistumisehdot</u></a> tätä ylennystä varten ja hyväksynyt ne.',
  'Always up-to-date with our newsletters. I want to receive emails, offers and coupons from Oral-B - the community for power women and super moms - and other trusted P&G brands.': `<strong>Pidä meihin yhteyttä!</strong> Suostun vastaanottamaan henkilökohtaista viestintää koskien tarjouksia, uutisia ja muita kampanjoita Braun ja muilta <a href='https://us.pg.com/brands/' target='_blank'><strong><u>P&G-tuotemerkeiltä</u></strong></a> sähköpostitse ja verkkokanavien kautta. Voin <a href='https://preferencecenter.pg.com/fi-fi/datarequests/' target='blank'><strong><u>peruuttaa</u></strong></a> viestien vastaanottamisen milloin tahansa.`,
  'Registration Notice': `<div style="font-size: 16px; font-family: 'Helvetica'; padding-left: 25px;">Procter & Gamble, rekisterinpitäjä, käsittelee henkilötietojasi, jotta voit rekisteröityä tälle sivustolle ja olla vuorovaikutuksessa sen palveluiden kanssa, ja suostumuksestasi riippuen lähettää sinulle asiaankuuluvaa kaupallista viestintää, mukaan lukien henkilökohtaiset mainokset verkkomediassa.
    Lue <p class='modalOpener' data-text="<div>*Lue lisää
    <br>Merkitykselliset mainostiedot ja personoidut verkkomainokset
    <br>Tavoitteenamme on tehdä kokemuksestasi meistä ja P&G-perheen brändeistä mielenkiintoinen, miellyttävä ja henkilökohtainen. Teemme sen yrittämällä suunnitella kaupallisen viestintämme sen mukaan, mistä pidät eniten, välttämällä lähettämästä sinulle ehdotuksia, jotka eivät vastaa makuasi, tai näyttämästä sinulle mainoksia, joita olet jo katsonut monta kertaa. Miten me teemme sen?
    <ul>
      <br><li>Yhdistämällä tiedot, jotta voimme mukauttaa viestintämme paremmin kiinnostuksesi mukaan. Voimme oikeutettujen etujemme perusteella rikastaa rekisteröintitietojasi ja tietoja, jotka annat käyttäessäsi palveluitamme (brändimieltymykset, leikatut kupongit jne.), ominaisuuksien, kiinnostuksen kohteiden ja demografisten tietojen avulla, jotka saadaan kaupallisista lähteistä tai muilta kolmansilta osapuolilta. Jos olet vastaavasti hyväksynyt kohdennetun mainonnan evästeet tai vastaavat tekniikat P&G:n tai joissakin tapauksissa kolmansien osapuolten verkkosivustoilla tai sovelluksissa, voimme täydentää kohdennetun mainonnan evästeiden avulla saatuja tietoja muilla tiedoilla, jotka annat meille luodessasi tilin tai käyttäessäsi palveluitamme.</li>
    </ul>
    <br>Käsittelemme näitä tietoja myös automaattisesti sisältömme ja tarjoustemme mukauttamiseen sähköpostitse, verkossa (esimerkiksi kolmannen osapuolen alustoilla, mukaan lukien sosiaalisen median alustoilla) ja verkon ulkopuolella (esimerkiksi paperipostina), jos olet suostunut vastaanottamaan kaupallista viestintää meiltä.
    <ul>
      <br><li>Samankaltaisuuteen perustuva mainonta. Lailliseen etuumme perustuen analysoimme tietojasi myös löytääksemme uusia yhteyshenkilöitä, jotka voivat olla kiinnostuneita kuulemaan meistä, koska heillä on samanlainen profiili kuin sinulla. Me ja P&G:n tytäryhtiöt voimme esimerkiksi ladata salatun sähköpostiosoitteesi Facebookiin ja pyytää Facebookia näyttämään mainoksiamme ihmisille, joilla on samankaltaisia kiinnostuksen kohteita kuin sinulla, myös muissa maissa, perustuen tietoihin, joita sillä on sinusta ja muista ihmisistä.</li>
      <br><li>Henkilötietojesi käyttäminen ottaaksemme sinuun yhteyttä verkkomediassa. Jos olet suostunut vastaanottamaan meiltä kaupallista viestintää, käytämme salattuja henkilötietojasi (esim. sähköpostiosoitettasi, puhelinnumeroasi tai mobiilimainostunnustasi) lähettääksemme sinulle mainoksia verkkokanavilla (esim. sosiaalisen median tilillesi). Teemme tämän tietosuojaasi kunnioittavalla prosessilla käyttäen salattuja tietoja ja huolellisesti valittuja kumppaneita. Jaamme henkilötietojasi erityisesti kolmansille osapuolille, jotka tarjoavat meille räätälöityjä mainospalveluja, mukaan lukien sosiaalisen median alustat. Käytämme tällaisia palveluita täsmäyttääksemme hallussamme olevia salattuja tietoja niiden tietokannassa oleviin henkilötietoihin, jotta voimme luoda mukautettuja kohderyhmiä ja räätälöidä mainontaa kiinnostuksesi mukaan Internetissä, mukaan lukien sosiaalinen media, sovellettavan lainsäädännön sallimalla tavalla.</li>
    </ul>
    <br>Tämä auttaa meitä lähettämään sinulle merkityksellisempiä verkkomainoksia (tarjouksia, tietoa tuotteista ja innovaatioista) sekä vähemmän mainoksia, koska tiedämme lähettäneemme sinulle tietyn mainoksen, ja voimme siten sulkea sinut pois samasta aiheesta tapahtuvasta viestinnästä. Useimmat kuluttajamme arvostavat sitä, ettemme näytä heille mainoksia tuotteista, joista he eivät välitä tai joita olemme näyttäneet heille monta kertaa aiemmin, mutta jos olet eri mieltä, voit aina muuttaa asetuksiasi.
    <br>Voit aina muuttaa mieltäsi ja peruuttaa suostumuksesi tai kieltää käyttämästä tietojasi henkilökohtaiseen viestintään ja mainontaan, mukaan lukien mukautettujen kohderyhmien ja samankaltaisten kohderyhmien luominen, <a href='https://preferencecenter.pg.com/fi-fi/datarequests/' target='_blank'><strong><u>täällä</u></strong></a>. Lue tietosuojakäytäntömme</div>"
    >lisää*</p>.
    Lisätietoja tietojesi käsittelystä ja tietosuojaoikeuksistasi saat lukemalla <p class='modalOpener' data-text="**Arvostamme yksityisyyttäsi
    <div>
      <ul>
        <br><li>Tietosuojakäytäntömme sääntelee tietojesi keräämistä ja käyttöä.</li>
        <br><li>Tässä kerrotaan <a href='https://privacypolicy.pg.com/fi-FI/' target='_blank'><strong><u>miksi</u></strong></a> keräämme tietojasi, käsittelyn oikeusperustat ja henkilötietojesi säilytysajan.</li>
        <br><li>Sähköpostimme sisältävät pikseleitä, jotka auttavat P&G:tä ymmärtämään, oletko vuorovaikutuksessa niiden kanssa ja mikä sisältö kiinnosti sinua. Lue lisää <a href='https://privacypolicy.pg.com/fi-FI/' target='_blank'><strong><u>täältä</u></strong></a>. Voit <a href='https://preferencecenter.pg.com/fi-fi/datarequests/' target='_blank'><strong><u>peruuttaa</u></strong></a> sähköpostiviestintäohjelmiemme tilauksen milloin tahansa.</li>
        <br><li>Tietojasi käsitellään muussa maassa kuin siinä, jossa ne on kerätty, mukaan lukien Yhdysvallat. Lue lisää <a href='https://privacypolicy.pg.com/fi-FI/' target='_blank'><strong><u>täältä</u></strong></a>.</li>
        <br><li>Voit käyttää rekisteröityjen oikeuksia (voit tarkastella, korjata, poistaa, siirtää toiselle palveluntarjoajalle, vastustaa tai rajoittaa sinua koskevien henkilötietojen käsittelyä tai peruuttaa suostumuksesi) tai muuttaa asetuksiasi ja tilauksiasi napsauttamalla <a href='https://preferencecenter.pg.com/fi-fi/datarequests/' target='_blank'><strong><u>tästä</u></strong></a></li>
      </ul>
    </div>">täältä**</p>
    tai tutustumalla koko <a href='https://privacypolicy.pg.com/fi-FI/' target='_blank'><strong><u>tietosuojakäytäntöömme</u></strong></a>. Olet vähintään 18-vuotias ja hyväksyt <a href='https://termsandconditions.pg.com/fi-fi/' target='_blank'><strong><u>käyttöehtomme</u></strong></a>.</div>`,
  'Why do we collect this data?': `<div>
                                Olet rekisteröitymässä palvelun Braun käyttäjäksi. <a href="https://privacypolicy.pg.com/fi-FI#entities" target="_blank"><u>Procter & Gamble</u></a> on sen rekisterinpitäjä. Allekirjoittamalla hyväksyt seuraavat:
                                <br>
                                <br>
                                <b>Sähköpostimarkkinointi.</b> Haluat vastaanottaa sähköposteja, tarjouksia ja kuponkeja Braun-tuotemerkiltä ja muilta luotetuilta <a target='_blank' href='https://www.pg.com/brands'><u>P&G-tuotemerkeiltä</u></a>.
                                <br>
                                <br>
                                <b>Omat valintasi.</b> Voit käyttää <span class="modalOpener" data-text="Kuten tietosuojakäytännössämme todetaan, voit saada, korjata, poistaa tai siirtää tietojasi toiselle palveluntarjoajalle tai vastustaa tai rajoittaa meillä olevien henkilötietojen käsittelyä tai perua suostumuksesi.">tietoturvaoikeuksiasi</span> tai muuttaa asetuksiasi ja tilauksiasi napsauttamalla <a target='_blank' href='https://privacypolicy.pg.com/fi-FI#preferences'><u>tästä</u></a>.
                                <br>
                                <br>
                                <b>Ehdot ja tietosuojakäytäntö.</b> Olet vähintään 18-vuotias ja olet lukenut ja hyväksynyt P&G <a target='_blank' href='https://termsandconditions.pg.com/fi-fi/'><u>käyttöehdot ja tietosuojakäytännön</u></a> ja <a target='_blank' href='https://privacypolicy.pg.com/fi-FI'><u>tietosuojakäytännön</u></a>. Jotta voisimme mukauttaa viestintämme sinua kiinnostavien kohteiden mukaiseksi, <span class="modalOpener" data-text="Eräämme ja saatamme yhdistää tietoja, joita saamme sinulta, kun soitat meille tai käyt sivustoillamme tai käytät sovelluksiamme, kun käytät laitteitamme, kun tarkastelet verkkomainoksiamme, tai joita saamme kaupallisesti saatavilla olevista lähteistä.">yhdistämme yllä antamasi tiedot</span> muihin tietoihin, joita olemme keränneet sinusta monista muista lähteistä ja kolmansilta osapuolilta.
                              </div>`,

  // Guest registration page

  'Continue as a guest': 'Jatka vieraana',
  'and get your money back': 'ja saat rahasi takaisin',
  'This campaign is recommended by Braun - the online community with great product tests and offers.': 'Tätä kampanjaa suosittelee Braun - verkkoyhteisö, jossa on upeita tuotetestejä ja tarjouksia.',

  // Forgot Password page

  'Please fill in your email address, and we will send you a reset link.': 'Täytä sähköpostiosoitteesi, niin lähetämme sinulle nollauslinkin.', // Missing

  // Reset password page
  'Choose a new password': 'Valitse uusi salasana',
  'New password': 'Uusi salasana',
  'Enter your new password': 'Syötä uusi salasana',
  'Repeat new password': 'Vahvista salasana',
  'Enter your new password again': 'Syötä nuusi salasana uudelleen',

  // Receipt reupload page
  'Re-Upload receipt': 'Ladata kuitti uudelleen',

  // Product photo reupload page
  'Re-Upload product photo': 'Ladata tuotekuva uudelleen',

  // Iban thank you page

  'Thank you': 'Kiitos',
  'Thank you for resubmitting your bank details.': 'Kiitos, että lähetit pankkitietosi uudelleen.',
  'So nothing should stand in the way of a successful transfer.': 'Mikään ei siis saisi estää onnistunutta siirtoa.',
  "If you have any questions related to your bank details, please contact <a href='mailto:support@braun-moneyback.com'><b>support@braun-moneyback.com</b></a>.": "Jos sinulla on pankkitietoihin liittyviä kysymyksiä, ota yhteyttä osoitteeseen <a href='mailto:support@braun-moneyback.com'><b>support@braun-moneyback.com</b></a>.",
  "For other concerns, you can contact our support service at <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>.": "Muissa asioissa voit ottaa yhteyttä asiakaspalveluumme osoitteessa <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>.",

  // Expired page

  'Unfortunately the link is no longer valid!': 'Valitettavasti linkki ei ole enää voimassa!',
  "Please participate again or contact our support team: <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>": "Osallistu uudelleen tai ota yhteyttä asiakaspalveluumme: <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>",

  // Reclaim page
  'Ready': 'Valmis',
  'Thank you for the message.': 'Kiitos viestistä.',
  'We will check the reimbursement amount again and then inform you. The verification may take some time, so we ask for your patience': 'Tarkistamme hyvityssumman uudelleen ja ilmoitamme sinulle. Vahvistus voi kestää jonkin aikaa, joten pyydämme kärsivällisyyttäsi',

  // Reset password success page

  'Your password has been reset and changed.': 'Salasanasi on nollattu ja vaihdettu.',
  'You can now log in to the campaign website again. ': 'Voit nyt kirjautua kampanjasivustolle uudelleen.',
  'To do this, visit the desired campaign website.': 'Voit tehdä tämän siirtymällä halutulle kampanjasivustolle.',

  // Campaign is over page

  'The campaign period is over': 'Kampanja-aika on ohi',
  'Thank you for your interest in the campaign, unfortunately the campaign period is over.': 'Kiitos kiinnostuksestasi kampanjaa kohtaan, mutta valitettavasti kampanja-aika on päättynyt.',

  // DOI page

  'Thank you for your registration': 'Kiitos rekisteröinnistä',
  'You have successfully registered for our newsletter.': 'Olet onnistuneesti rekisteröitynyt uutiskirjeeseemme.',
  'From now on you will be one of the first to find out as soon as there are free product tests, competitions and inspiring articles.': 'Tästä lähtien kuulet ensimmäisten joukossa heti, kun tarjolla on ilmaisia tuotetestejä, kilpailuja ja inspiroivia artikkeleita.',

  // Thank you page

  'Thank you for your participation': 'Kiitos osallistumisestanne',
  'Your receipt and your data have been successfully sent to us and are now being checked.': 'Kuittisi ja tietosi on lähetetty meille onnistuneesti, ja ne tarkistetaan nyt.',
  'Reference number: ': 'Viitenumero: ',
  'As soon as we have checked your data, you will either receive a confirmation e-mail or an e-mail with queries if your data was incomplete or your proof of purchase could not be recorded. This can take up to 36 hours. We ask for your patience.': 'Heti kun olemme tarkistaneet tietosi, saat joko vahvistussähköpostin tai sähköpostiviestin, joka sisältää kysymyksiä, jos tietosi olivat puutteelliset tai ostotodistustasi ei voitu tallentaa. Tämä voi kestää jopa 36 tuntia. Pyydämme kärsivällisyyttä.',
  'After successfully checking the proof of purchase and all other data as well as succesfully receiving the product you would like to return, we will transfer the reimbursement amount to the account you specified within 6 weeks. If anything is unclear, we will contact you again by email.': 'Kun ostotodistus ja kaikki muut tiedot on tarkastettu onnistuneesti ja palautettavaksi haluamasi tuote on onnistuneesti vastaanotettu, siirrämme palautussumman ilmoittamallesi tilille 6 viikon kuluessa. Jos jokin asia on epäselvä, otamme sinuun uudelleen yhteyttä sähköpostitse.',
  'Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation. ': 'Älä unohda, että jos haluat palauttaa tuotteen, sinun on lähetettävä se takaisin palvelukeskuksemme osoitteeseen 10 päivän kuluessa osallistumisen vahvistamisesta.',
  'Copied to clipboard': 'Kopioitu leikepöydälle',

  // SMS page

  'Please confirm your identity': 'Vahvistakaa henkilöllisyytenne',
  'Please enter your mobile number in the field below. We will then send you a code to confirm your participation via SMS, which you can then enter on the next page.': 'Kirjoita matkapuhelinnumerosi alla olevaan kenttään. Tämän jälkeen lähetämme sinulle tekstiviestillä koodin, jolla vahvistat osallistumisesi ja jonka voit syöttää seuraavalla sivulla.',
  'In order to check your identity, we need your mobile phone number. The specification of the mobile number is required to verify your participation. The phone number you provide will only be used for one-time, advertising-free verification and will be deleted by us after the campaign.': 'Henkilöllisyytesi tarkistamiseksi tarvitsemme matkapuhelinnumerosi. Matkapuhelinnumeron määrittelyä tarvitaan osallistumisesi vahvistamiseksi. Ilmoittamaasi puhelinnumeroa käytetään vain kertaluonteiseen, mainosvapaaseen todentamiseen, ja me poistamme sen kampanjan jälkeen.',
  'Phone number': 'Matkapuhelinnumero',
  'Confirmation code': 'Vahvistuskoodi',
  'Enter your phone number': 'Syötä phone number',
  'Please enter the confirmation code that we sent you via SMS into the field.': 'Kirjoita kenttään vahvistuskoodi, jonka lähetimme sinulle tekstiviestillä.',
  'Change mobile number': 'Vaihda matkapuhelinnumero',

  // 404 page

  'Oops!!': 'Hups!!',
  'Something went wrong!': 'Jokin meni pieleen!',

  // Survey page
  'What were the main reasons for you to buy this product?': 'Mitkä olivat tärkeimmät syyt, jotka saivat sinut ostamaan tämän tuotteen?',
  'Please select as applicable; You have a multiple choice.': 'Valitse soveltuvin osin; Sinulla on useita vaihtoehtoja.',
  'You have a multiple choice.': 'Sinulla on useita vaihtoehtoja.',
  'Easy to use': 'Helppokäyttöisyys',
  'Design': 'Muotoilu',
  'Price / good deal': 'Hinta / hyvä tarjous',
  'Performance': 'Suorituskyky',
  'Other': 'Muu',
  'Why would you like to return the product?': 'Miksi haluaisit palauttaa tuotteen?',
  "It doesn't work as expected": 'Se ei toimi odotetulla tavalla',
  'Wrong item size or wrong product': 'Väärä tuotekoko tai väärä tuote',
  'Changed mind after Product Purchased': 'Muuttanut mielesi tuotteen ostamisen jälkeen',
  "Product didn't match the website or catalog Description": 'Tuote ei vastannut verkkosivuston tai luettelon kuvausta',
  'Found better product price elsewhere': 'Löysit paremman tuotteen hinnan muualta',
  'Product did not meet my expectations': 'Tuote ei vastannut odotuksiani',
  'Enter optional': 'Syötä valinnainen',

  // Return form page
  'Product name': 'Tuotteen nimi',
  'Product EAN Number': 'Tuotteen EAN- numero',
  'Product Serial Number': 'Tuotteen sarjanumero',
  'Product Price for Money-back': 'Tuotteen hinta varten rahat - takaisin',
  'Date of purchase': 'Ostopäivä',
  'Time of purchase': 'Ostoaika',
  'Retailer Name': 'Jälleenmyyjän nimi',
  'Receipt/Invoice ID': 'Kuitin / laskun tunnus',
  'Reference number of the participation': 'Osallistumisen viitenumero',
  'Enter product name': 'Kirjoita tuotteen nimi',
  'Enter Product EAN number': 'Syötä tuotteen EAN- numero',
  'Enter product serial number': 'Syötä tuotteen sarjanumero',
  'Enter product price for Money-back': 'Syötä tuotteen hinta rahat takaisin',
  'Enter Date of purchase': 'Syötä ostopäivämäärä',
  'Enter Time of purchase': 'Syötä ostoajankohta',
  'Enter retailer name': 'Syötä jälleenmyyjän nimi',
  'Enter Receipt/Invoice ID': 'Syötä kuitin / laskun tunnus',
  'Enter Reference number of the participation': 'Syötä osallistumisen viitenumero',

  // Legal Docs

  'website-link': 'https://se.braun.com/en',
  'home-link': '/fi',
  'terms-link': 'https://termsandconditions.pg.com/fi-fi/',
  'privacy-link': 'https://privacypolicy.pg.com/fi-FI',
  'preference-link': 'https://preferencecenter.pg.com/fi-fi/datarequests/',
  'sitemap-link': 'https://www.oralb.fi/fi-fi/sitemap',
  'impressum-link': '#',
  'Return form': 'Palautuslomake',
  'Print': 'Tulosta',
  'Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation.': 'Älä unohda, että jos haluat palauttaa tuotteen, sinun on lähetettävä se takaisin palvelukeskuksemme osoitteeseen 10 päivän kuluessa osallistumisen vahvistamisesta.',
  'Denmark': 'Tanska',
  'Norway': 'Norja',
  'Finland': 'Suomi',
  'Sweden': 'Ruotsi',
  // Validation
  'This file format is not supported. Only .jpeg, .png, .pdf and .tiff files are accepted.': 'Tätä tiedostomuotoa ei tueta. Vain .jpeg-, .png-, .pdf- ja .tiff-tiedostot hyväksytään.',
  'There is currently a problem with our website. Please try again in a few minutes.': 'Verkkosivustossamme on tällä hetkellä ongelma.Yritä uudelleen muutaman minuutin kuluttua.',
  'The file is too big. Only files up to 16 MB are supported.': 'Tiedosto on liian suuri.Vain enintään 16 Mt: n tiedostoja tuetaan.',
  'Oops. An error has occurred. Please try again later.': 'Hups. On tapahtunut virhe. Yritä myöhemmin uudelleen.',
  validation: {
    housenumber: 'Talon numero saa sisältää vain kirjaimia, välilyöntejä, vinoviivoja ja väliviivoja.',
    mandatory: 'Tämä kenttä on pakollinen.',
    required: "{field} on pakollinen kenttä",
    alpha: '{item} * voi sisältää vain aakkosmerkkejä.',
    iban: 'IBAN - Käytä osallistujamaiden voimassa olevaa IBAN-tilinumeroa.',
    postal: 'Postinumerossa on oltava {digit} numeroa.',
    bic: 'BIC-koodin on oltava oikeassa muodossa.',
    mobileNumber: 'Matkapuhelinnumerossa on oltava vähintään {digit} numeroa.',
    email: 'Sähköpostiosoitteen on oltava oikeassa muodossa.',
    customRequired: {
      'Mobile number': 'Matkapuhelinnumero on pakollinen kenttä',
      code: 'Vahvistuskoodi on pakollinen kenttä',
    },
  },
  'Please hide your personal data (name, surname, phone nr., address, etc.) on the receipt/invoice if you will have it before sending your package to Service Center.': 'Piilota henkilötietosi (nimi, sukunimi, puhelinnumero, osoite jne.) kuitista/laskusta, jos saat ne ennen paketin lähettämistä Palvelukeskukseen.',
  socialMedia: {
    fb: 'https://www.facebook.com/braun',
    yt: 'https://www.youtube.com/braun',
  },
}
