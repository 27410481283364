<template>
  <div class="wrapper">
    <div class="container">
      <img class="prod_image" src="@/assets/images/second_bg.svg" alt="image">
      <div class="steps_vertical">
        <div class="step">
          <img class="step-bg" src="/assets/stepBg.svg" alt="image">
          <img class="step-image first" src="/assets/step1.svg" alt="icon">
        <div class="step-text">Aktionsprodukt<br class="hidden-xs"> kaufen</div>
        </div>


        <img class="step-arrow" src="/assets/stepArrow.svg" alt="icon">

        <div class="step">
          <img class="step-bg" src="/assets/stepBg.svg" alt="image">
          <img class="step-image second" src="/assets/step2.svg" alt="icon">
        <div class="step-text">Kassenbeleg <br class="hidden-xs">hochladen</div>
        </div>


        <img class="step-arrow" src="/assets/stepArrow.svg" alt="icon">

        <div class="step">
          <img class="step-bg" src="/assets/stepBg.svg" alt="image">
          <img class="step-image third" src="/assets/step3.svg" alt="icon">
        <div class="step-text">Geld zurück <br class="hidden-xs">erhalten</div>
        </div>

      </div>
      <img class="prod_image mobile" src="@/assets/images/second_bg.svg" alt="image">
    </div>
  </div>
</template>

<script>
export default {
  name: "second-block"
}
</script>

<style scoped lang="scss">
.wrapper {
  position: relative;
  @media (max-width: 991px) {
    margin: 50px 0 30px 0;
  }
  @media (max-width: 575px) {
    margin: 0;
  }
}
.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  @media (max-width: 575px) {
    flex-direction: column;
  }
}
.prod_image {
  &.mobile {
    display: none;
  }
  @media (max-width: 1199px) {
    width: 80%;
  }
  @media (max-width: 575px) {
    display: none;
    &.mobile {
      display: initial;
    }
  }
  @media (max-width: 991px) {
    width: 60%;
  }
}
.steps_vertical {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 575px) {
    flex-direction: row;
  }
}
.step {
  position: relative;
  display: flex;
  flex-direction: column;
  @media (max-width: 575px) {
    width: 22%;
  }
}
.step-image {
  position: absolute;
  width: 30%;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  &.third {
    width: 37%;
  }
  @media (max-width: 1199px) {
    top: 38%;
    &.third {
      top: 43%;
    }
  }
  @media (max-width: 991px) and (min-width: 766px) {
    top: 41%;
    width: 30%;
  }
  @media (max-width: 575px) {
    top: 50%;
    width: 40%;
    &.second {
      top: 51%;
      width: 33%;
    }
    &.third {
      top: 50%;
    }
  }
}
.step-arrow {
  margin: 40px auto;
  @media (max-width: 991px) {
    margin: 20px auto;
  }
  transform: rotate(90deg);
  @media (max-width: 575px) {
    width: 10%;
    transform: rotate(0deg);
  }
}
.step-text {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  margin-top: 10px;
  font-weight: 600;
  color: var(--color2);
  @media (max-width: 575px) {
    margin-top: -30px;
    font-size: 12px;
    max-height: 30px;
  }
  @media (max-width: 400px) {
    font-size: 10px;
  }
}
  .hidden-xs {
    @media (min-width: 768px) {
      display: none;
    }
  }
</style>
