/*eslint comma-dangle: [2, "always"]*/
export default {
  // General
  'Next': 'Neste',
  'NEXT': 'NESTE',
  'SEND': 'SENDE',
  'Back to the homepage': 'Tilbake til hjemmesiden',
  'Back to the Braun website': 'Tilbake til Braun-nettstedet',
  'Back': 'Tilbake',
  'LOG IN': 'LOGG INN',
  'SIGN UP': 'MELDE SEG PÅ', // Missing
  '(optional)': '(valgfritt)', // Missing
  'Cookie Text': 'Cookiesamtykke',
  'or': 'eller',

  // Header
  'Skip to Main Content': 'Gå Til Hovedinnhold',
  'Terms and Conditions': 'Regler og vilkår',
  'FAQ': 'FAQ', // Missing

  // Footer
  'Footer Support Text': 'Vår supportavdeling er tilgjengelig frem til 15.12.2025: <a href="mailto:contact@braun-moneyback.com" ><span><b>contact@braun-moneyback.com</b></span></a>',
  'terms': 'Regler og vilkar',
  'Privacy': 'Personvern',
  'Preference': 'Mine data',
  'Impressum': 'Avtrykk',
  'Sitemap': 'Oversikt over sider',

  // Home Screen
  'START REFUND': 'Start refusjon',
  'Step 1': 'Steg 1',
  'Step 2': 'Steg 2',
  'Step 3': 'Steg 3',
  'Step 4': 'Steg 4',
  'Select your product': 'Velg produktet ditt',
  'Upload a receipt': 'Last opp kvitteringen',
  'Register': 'Registrere',
  'Get your money back': 'Få pengene tilbake',
  'bannerAltText': 'Helt fornøyd eller pengene tilbake!* Vilkår og betingelser gjelder',

  // Progress Bar

  'Select<br>product': 'Velg<br>produktet',
  'Upload<br>a receipt': 'Last opp<br>kvitteringen',
  'Get your<br>money back': 'Få pengene<br>tilbake',

  // Product Select Page
  'Scan the product': 'Skann produktet',
  'Type in or scan the barcode and serial number': 'Skriv inn eller skann strekkoden',
  'Please enter the serial number and barcode number of your Braun products, take a photo of your promotional product or upload it using <b>Drag & Drop</b>. Please make sure that the barcode information of your promotional product is clearly visible.': 'Vennligst skriv inn strekkodenummeret til Braun produktet ditt, ta et bilde av kampanjeproduktet ditt eller last det opp ved å <b>dra og slipp</b>. Sørg for at strekkodeinformasjonen til kampanjeproduktet ditt er godt synlig.',
  'Upload': 'Opplasting',
  'Upload<br>barcode image': 'Last opp<br>strekkodebilde',
  'Drag & Drop': 'Dra og slipp',
  'Scan': 'Skann',
  'Stop': 'Stoppe', // Missing
  'Scan<br>barcode image': 'Skann<br>strekkodebilde',
  '(the easiest)': '(den enkleste)',
  'Add': 'Legg',
  'Add<br>barcode number': 'Legg til strekkode',
  'Enter Barcode number': 'Skriv inn Strekkodenummerr',
  'Serial number': 'Serienummer',
  'The file format is not supported. It will only .jpeg, .png and .pdf file accepted.': 'Dette filformatet støttes ikke. Bare .jpeg-, .png-, .pdf- og .tiff filer godtas.',
  'The file is too large. Only files up to 16 MB are supported.': 'Filen er for stor. Der understøttes kun filer på op til 16 MB.',
  'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.': 'Desværre kan der ikke genkendes nogen gyldig stregkode. Prøv venligst at indtaste stregkoden i feltet ovenfor.',
  'Please turn on your camera': 'Tænd venligst for dit kamera',
  'Correct Barcode Number': 'Stregkodenummeret er gyldigt',
  'Wrong Barcode Number': 'Stregkodenummeret er ugyldigt',
  'You cannot enter more than one product.': 'Du kan ikke legge inn mer enn ett produkt.',

  // Receipt Upload Page

  'Upload receipt': 'Last opp kvittering',
  'Upload a picture of your proof of purchase in this step.': 'Last opp et bilde av kjøpsbeviset i dette trinnet.',
  'Upload your receipt photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.': 'Last opp kvitteringsbildet ditt ved hjelp av dra og slipp. Bare JPEG, PDF og PNG støttes. Maksimal filstørrelse: 16 MB.',
  'Please photograph your proof of purchase!': 'Vennligst ta bilde av kjøpsbeviset ditt!',
  'Make sure that the following information is clearly visible:': 'Kontroller at følgende informasjon er tydelig synlig:',
  'Dealer / Market name': 'En forhandler / marked',
  'Promotional product and purchase price': 'Kampanjeprodukt og kjøpesum',
  'Total of the proof of purchase and VAT': 'Summen av kjøpsbeviset og mva.',
  'Date and time of purchase': 'Dato og klokkeslett for kjøp',
  'Proof of purchase ID': 'Kjøpsbevis-ID',
  'Upload your receipt photo': 'Last opp kvitteringsbildet ditt',
  'Drag & Drop or click here': 'Dra og Slipp eller klikke her',
  'Choose a File': 'Velg en fil',
  'Choose Another': 'Velg en annen', // Missing
  'Crop': 'Beskjær',
  'Erase': 'Gni ut',
  'Move': 'Bevege', // Missing
  'Zoom In': 'Zoome inn',
  'Zoom Out': 'Zoome ut',
  'Please drag your receipt into the gray rectangle.': 'Dra kvitteringen inn i det grå rektangelet.',
  'You can erase private information with the razor tool.': 'Du kan slette privat informasjon med razor-verktøyet.',
  'If you get an error message, please take a better version of the receipt and upload it again.': 'Hvis du får en feilmelding, kan du ta en bedre versjon av kvitteringen og laste den opp på nytt.',
  'The receipt is processed automatically.This can take a minute.Please be patient.': 'Kvitteringen behandles automatisk.Dette kan ta litt tid.Vær tålmodig.',
  'Your receipt will now be uploaded and processed.': 'Kvitteringen blir nå lastet opp og behandlet.',

  // Product upload page

  'Upload product photo': 'Last opp produktbilde',
  'Please upload a photo of the product side on which the EAN barcode is clearly visible.': 'Vennligst last opp et bilde av produktsiden der EAN-strekkoden er tydelig synlig.',
  'Upload your product photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.': 'Last opp produktbildet ditt ved hjelp av dra og slipp. Bare JPEG, PDF og PNG støttes. Maksimal filstørrelse: 16 MB.',
  'Upload your product photo': 'Last opp produktbildet ditt',

  // Banking page
  'What is IBAN and where do I find it?': 'Hva er IBAN, og hvor finner jeg det?',
  "IBAN stands for International Bank Account Number. It's an internationally-agreed code made up of up to 34 letters and numbers that helps banks to process transfers around the world. You can usually find your IBAN number on the top right- hand side of your bank statement or in your online banking.If you can't locate your IBAN, you should be able to generate it online via your bank's internet banking service or by using an IBAN calculator tool.": `IBAN står for Internasjonalt bankkontonummer.Det er en internasjonalt avtalt kode bestående av opptil 34 bokstaver og tall som hjelper banker med å behandle overføringer over hele verden.
  <br>
  <br>
  Du kan vanligvis finne IBAN- nummeret ditt øverst til høyre på kontoutskriften din eller i nettbanken din.Hvis du ikke finner IBAN - en din, bør du kunne generere den på nettet via bankens nettbanktjeneste eller ved å bruke et IBAN - kalkulatorverktøy.`,
  'What is BIC and where do I find it?': 'Hva er BIC og hvor finner jeg det ?',
  "BIC stands for Bank Identifier Code. To send or receive money, any financial institution must know where to send the money and BIC is exactly what shows it. You can usually find your bank's BIC in your bank account statements.If you're using online banking, log into your digital bank account to easily view your bank statement or contact your bank so that they provide you your BIC.": `BIC står for Bankens Identifiseringskode..For å sende eller motta penger, må enhver finansinstitusjon vite hvor pengene skal sendes, og BIC er akkurat det som viser det.
  <br>
  <br>
  Du kan vanligvis finne bankens BIC i kontoutskriftene dine.Hvis du bruker nettbank, kan du logge inn på din digitale bankkonto for enkelt å se kontoutskriften din eller kontakte banken din slik at de gir deg din BIC.`,
  'Enter bank details': 'Angi bankdetaljer',
  'Please enter the first and last name of the account holder exactly as they were given at the bank.': 'Vennligst skriv inn for- og etternavnet til kontoinnehaveren nøyaktig slik de ble gitt i banken.',
  'Avoid changing the umlauts: <b>Müller or Mueller</b>; Avoid adding or leaving out hyphens: <b>Marie Luise or Marie-Luise</b>; Only enter the middle name if the account is also managed with it.': 'Unngå å endre tødlene: <b>Müller or Mueller</b>; Unngå å legge til eller utelate bindestreker: <b>Marie Luise or Marie-Luise</b>; Skriv bare inn mellomnavnet hvis kontoen også administreres med det.',
  'Enter your IBAN': 'Skriv inn IBAN ditt',
  'Enter your BIC': 'Skriv inn BIC ditt',
  'Enter your first name': 'Skriv inn fornavn ditt',
  'Enter your last name': 'Skriv inn etternavn ditt',
  'Account holder first name': 'Fornavn på kontoinnehaver',
  'Account holder last name': 'Etternavn for kontoinnehaver',
  'If the data does not exactly match the account details, in most cases the bank will charge back and make the process of an uncomplicated and quick refund more difficult.': 'Hvis dataene ikke samsvarer nøyaktig med kontodetaljene, vil banken i de fleste tilfeller belaste tilbake og gjøre prosessen med en ukomplisert og rask refusjon vanskeligere.',

  // Address page

  'Your address': 'Din adresse',
  'Streetname': 'Gatenavn',
  'House number': 'Nummer',
  'Postal code': 'Postnummer',
  'City': 'By',
  'Country': 'Land',
  'Enter your streetname': 'Skriv inn gatenavn ditt',
  'Enter the number of your house': 'Skriv inn nummeret til huset ditt',
  'Enter your postal code': 'Skriv inn postnummer ditt',
  'Enter a city you live': 'Gå inn i en by du bor i', // Missing
  'Choose a country': 'Velg et land', // Missing

  // Login page

  'Log in with an Braun account': 'Logg inn med en Braun-konto',
  'Log in with<br>Braun account': 'Logg inn med<br>Braun-konto',
  'Email address': 'E-postadresse',
  'Enter your email address': 'Skriv inn e-postadresse ditt',
  'Password': 'Passord',
  'Enter your password': 'Skriv inn passord ditt',
  'Forgot password?': 'Glemt passord?',
  "Don't have<br>an account yet?": "Har du ikke<br>en Braun-konto ennå?",
  'Continue<br>as a guest': 'Fortsett<br>som gjest',

  // Registration page

  'Registration form': 'Registreringsskjema',
  "Register now and don't miss a thing.": "Registrer deg nå og ikke gå glipp av noe.",
  'Braun informs you regularly about further "free test" campaigns and also offers exclusive free product tests every week.': 'Vi trenger følgende informasjon fra deg for å behandle betalingen. Disse brukes bare til å behandle Braun pengene-tilbake kampanjen.',
  'Personal information': 'Personopplysninger', // Missing
  'Title': 'Tittel',
  'Mrs.': 'Fru',
  'Mr.': 'Herr',
  'First name': 'Fornavn',
  'Last name': 'Etternavn',
  'Date of birth': 'Fødselsdato',
  'dd/mm/yyyy': 'dd/mm/åååå', // Missing
  'Account information': 'Informasjon om forretningsforbindelse', // Missing
  'E-mail': 'E-post',
  'Enter your email': 'Skriv inn E-post ditt',
  'Create a strong password': 'Opprette et sterkt passord', // Missing
  'I confirm that I am over 18 years old. I confirm the terms and conditions, privacy policy & Justsnap privacy policy.': 'Jeg bekrefter at jeg er over 18 år gammel. Jeg bekrefter <a href="https://termsandconditions.pg.com/no-no/" target="_blank"><u>vilkårene</u></a>, <a href="https://privacypolicy.pg.com/no-NO" target="_blank"><u>personvernreglene</u></a> og <a href="https://justsnap.de/Datenschutz/Data_Policy_DE.pdf" target="_blank"><u>Justsnap personvernregler</u></a>.',
  'I have read the Conditions of participation for this promotion and accept them.': 'Jeg har lest <a href="https://braun-moneyback.com/no/terms" target="_blank"><u>vilkårene</u></a> for deltakelse for denne kampanjen og akseptert dem.',
  'Always up-to-date with our newsletters. I want to receive emails, offers and coupons from Oral-B - the community for power women and super moms - and other trusted P&G brands.': `<strong>Hold kontakt med oss!</strong> Jeg samtykker til å motta personlig tilpassede meldinger angående tilbud, nyheter og andre kampanjeinitiativer fra Braun og andre <a href='https://us.pg.com/brands/' target='_blank'><strong><u>P&G-merker</u></strong></a> via e-post, postgang og online-kanaler. Jeg kan <a href='https://preferencecenter.pg.com/no-no/datarequests/' target='_blank'><strong><u>avmelde</u></strong></a> meg  når som helst.`,
  'Registration Notice': `<div style="font-size: 16px; font-family: 'Helvetica'; padding-left: 25px;">Procter & Gamble, den behandlingsansvarlige,vil behandle dine personopplysninger slik at du kan registrere deg for dette nettstedet, samhandle med dets tjenester og, avhengig av ditt samtykke, motta relevante kommersielle meldinger, inkludert personlig tilpassede annonser via medier på nett.
    Finn ut <p class='modalOpener' data-text="<div>*Finn ut mer
    <br>Relevant reklameinformasjon og personlig tilpassede nettannonser
    <br>Vi bestreber oss på å gjøre dine opplevelser med oss og P&G-relaterte merker så interessante, tilfredsstillende og personlig tilpasset som mulig. Vi vil forsøke å oppnå dette ved å utforme våre kommersielle meldinger med tanke på ting du liker, og unngå å sende deg forslag som ikke passer deg, eller å vise deg reklamer som du allerede har sett mange ganger. Hvordan vil vi gjøre det?
    <ul>
      <br><li>Kombinere data for bedre å skreddersy meldingene våre etter interessene dine. Vi kan forbedre dine registreringsdata og data som du deler med oss når du samhandler med tjenestene våre (merkepreferanser, klippekuponger, osv.), med kjennetegn, interesser, demografisk data hentet fra kommersielt tilgjengelige kilder eller andre tredjeparter. I tillegg, hvis du har godtatt våre målrettede reklameinformasjonskapsler eller lignende teknologier hos P&G, eller i noen tilfeller tredjeparts nettsteder eller apper, kan vi supplere dataene vi har fått gjennom våre målrettede reklameinformasjonskapsler med annen informasjon du gir oss når du oppretter en konto eller samhandler med tjenestene våre.</li>
    </ul>
    <br>Vi vil behandle disse dataene – også ved hjelp av automatiserte metoder – for personlig tilpasning av innholdet vårt og tilbudene våre via e-post, på nettet (for eksempel på tredjepartsplattformer, inkludert sosiale media-plattformer) og utenfor Internett (f.eks. via vanlig post) hvis du har gitt samtykke til å motta kommersielle meldinger fra oss.
    <ul>
      <br><li>Reklame basert på like profiler. Basert på våre legitime interesser, vil vi også analysere dine data for å identifisere nye kontakter som – med en profil som ligner din – kan være interessert i å høre fra oss. For eksempel kan vi og P&G-partnerselskapene våre laste opp din databehandlede e-postadresse til Facebook og be dem om å vise våre reklamer til personer som har lignende interesser som deg, også i andre land, basert på data de har om deg og andre personer.</li>
      <br><li>Bruk av dine personlige data til å kontakte deg via medier på nett. Hvis du har samtykket til å motta kommersiell kommunikasjon fra oss, vil vi bruke enkelte personopplysninger (som e-postadresse, telefonnummer eller din mobilreklame-ID) til å sende deg reklame via Internett (f.eks. på din sosiale mediekonto). Vi gjør dette gjennom en prosess som respekterer ditt personvern, ved hjelp av krypterte data og nøye utvalgte partnere. Nærmere bestemt deler vi dine personopplysninger med tredjeparter som tilbyr oss skreddersydde reklametjenester, inkludert sosiale medier. Vi bruker slike tjenester til å sammenligne anonymiserte opplysninger vi har med personopplysninger i deres database for å opprette tilpassede målgrupper og tilpasse reklame etter dine interesser på Internett, inkludert sosiale medier (i samsvar med aktuelle lover og regler).</li>
    </ul>
    <br>Dette vil hjelpe oss å sende deg mer relevante reklamer på nettet (tilbud, informasjon om produkter og innovasjoner), samt gi deg færre annonser, da vi allerede vil vite det når vi har vist deg en bestemt reklame og kan utelukke deg fra videre kommunikasjon om det samme. De fleste kundene våre setter pris på at vi ikke ønsker å vise dem reklamer for produkter de ikke er interessert i eller som de allerede har sett mange ganger før; men hvis du ikke ønsker dette, er det ingen problem: du kan alltid endre preferansene dine.
    <br>Du kan alltid endre mening og trekke tilbake ditt samtykke / avmelde deg fra bruken av dine data for personlig tilpassede meldinger og reklame, og for oppretting av egendefinerte målgrupper og lignende målgrupper <a href='https://preferencecenter.pg.com/no-no/datarequests/' target='_blank'><strong><u>her</u></strong></a>. Les vår personvernerklæring</div>"
    >mer*</p>.
    For mer informasjon angående behandlingen av dine data og dine personvernrettigheter, kan du lese <p class='modalOpener' data-text="**Vi bryr oss om personvernet ditt
    <div>
      <ul>
        <br><li>Personvernerklæringen vår styrer vår innsamling og bruk av dine data.</li>
        <br><li><a href='https://privacypolicy.pg.com/no-NO/' target='_blank'><strong><u>Her</u></strong></a> defineres hensikten bak vår innsamling av data, våre rettslige grunnlag for behandling og hvor lenge dine personopplysninger kan lagres.</li>
        <br><li>E-postene våre inneholder piksler som hjelper P&G med å forstå hvorvidt du samhandler med dem og hvilket innhold som virker interessant for deg. Les mer om dette <a href='https://privacypolicy.pg.com/no-NO/' target='_blank'><strong><u>her</u></strong></a>. Du kan <a href='https://preferencecenter.pg.com/no-no/datarequests/' target='_blank'><strong><u>avmelde</u></strong></a> deg fra våre programmer for e-post-kommunikasjon når som helst.</li>
        <br><li>Dine data kan bli behandlet i et annet land enn der de ble innhentet, inkludert USA. Lær mer om dette <a href='https://privacypolicy.pg.com/no-NO/' target='_blank'><strong><u>her</u></strong></a>.</li>
        <br><li>Du kan utøve dine rettigheter som registrert bruker (du har rett til å få innsyn i, rette, slette, overføre til en annen tjenesteleverandør, protestere mot eller begrense vår behandling av personopplysningene dine, eller trekke tilbake ditt samtykke) eller endre dine preferanser og abonnementer ved å klikke <a href='https://preferencecenter.pg.com/no-no/datarequests/' target='_blank'><strong><u>her</u></strong></a></li>
      </ul>
    </div>">her**</p>
    eller gå gjennom vår fullstendige <a href='https://privacypolicy.pg.com/no-NO/' target='_blank'><strong><u>personvernerklæring</u></strong></a>. Du har fylt 18 år og godtar våre <a href='https://termsandconditions.pg.com/no-no/' target='_blank'><strong><u>vilkår og betingelser</u></strong></a>.</div>`,
  'Why do we collect this data?': `<div>
  Du registrerer deg som en bruker av Braun som <a href="https://privacypolicy.pg.com/no-NO#entities" target="_blank"><u>Procter & Gamble</u></a> er behandlingsansvarlig for. Ved å registrere deg bekrefter du at:
                                <br>
                                <br>
                                <b>Påmelding til e-postmarkedsføring.</b>
                                <br>
                                Du gjerne vil motta e-poster, tilbud og kuponger fra Braun og andre betrodde <a target='_blank' href='https://www.pg.com/brands'><u>P&G-varemerker.</u></a>
                                <br>
                                <br>
                                <b>Dine valg.</b>
                                <br>
                                Du kan utøve dine <span class='modalOpener' data-text="Du kan, som forklart i vår personvernerklæring, få tilgang til, korrigere eller slette personopplysningene vi har om deg, overføre dem til en annen leverandør, og avslå eller begrense behandlingen av de opplysningene vi har om deg, eller trekke tilbake samtykket ditt.">personvernrettigheter</span> eller endre dine preferanser og abonnementer ved å klikke <a target='_blank' href='https://privacypolicy.pg.com/no-NO#preferences'><u>her</u></a>.
                                <br>
                                <br>
                                <b>Vilkår og personvernerklæring.</b>
                                <br>
                                Du er minst 18 år gammel og har lest og godtar P&G sine <a target='_blank' href='https://termsandconditions.pg.com/no-no/'><u>vilkår og betingelser</u></a> og <a target='_blank' href='https://privacypolicy.pg.com/no-NO'><u>personvernerklæring</u></a>. For bedre å tilpasse våre meldinger til dine interesser, vil vi kombinere de <span class='modalOpener' data-text="Vi samler inn og kan kombinere informasjon som vi innhenter fra kommersielt tilgjengelige kilder eller mottar fra deg når du ringer oss, besøker stedene eller appene våre, bruker enhetene våre eller ser på nettannonsene våre.">opplysninger</span> du oppgir ovenfor, med andre opplysninger vi kan ha innhentet om deg fra andre kilder og tredjeparter.
                              </div>`,


  // Guest registration page

  'Continue as a guest': 'Fortsett som gjest',
  'and get your money back': 'og få pengene tilbake',
  'This campaign is recommended by Braun - the online community with great product tests and offers.': 'Denne kampanjen anbefales av Braun - nettsamfunnet med gode produkttester og tilbud.',

  // Forgot Password page

  'Please fill in your email address, and we will send you a reset link.': 'Fyll ut e-postadressen din, så sender vi deg en tilbakestillingslenke.', // Missing

  // Reset password page
  'Choose a new password': 'Velg et nytt passord',
  'New password': 'Nytt passord',
  'Enter your new password': 'Skriv inn ditt nytt passord',
  'Repeat new password': 'Bekreft passord*',
  'Enter your new password again': 'Skriv inn ditt nytt passord igjen',

  // Receipt reupload page
  'Re-Upload receipt': 'Last opp kvitteringen på nytt',

  // Product photo reupload page
  'Re-Upload product photo': 'Last opp produktbilde på nytt',

  // Iban thank you page

  'Thank you': 'Takk skal du ha',
  'Thank you for resubmitting your bank details.': 'Takk for at du sendte inn bankopplysningene dine på nytt.',
  'So nothing should stand in the way of a successful transfer.': 'Så ingenting skal stå i veien for en vellykket overføring.',
  "If you have any questions related to your bank details, please contact <a href='mailto:support@braun-moneyback.com'><b>support@braun-moneyback.com</b></a>.": "Hvis du har spørsmål knyttet til bankdetaljene dine, vennligst kontakt <a href='mailto:support@braun-moneyback.com'><b>support@braun-moneyback.com</b></a>.",
  "For other concerns, you can contact our support service at <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>.": "For andre bekymringer kan du kontakte vår støttetjeneste på <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>.",

  // Expired page

  'Unfortunately the link is no longer valid!': 'Dessverre er lenken ikke lenger gyldig!',
  "Please participate again or contact our support team: <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>": "Delta igjen eller kontakt vårt supportteam: <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>",

  // Reclaim page
  'Ready': 'Klar',
  'Thank you for the message.': 'Takk for beskjeden.',
  'We will check the reimbursement amount again and then inform you. The verification may take some time, so we ask for your patience': 'Vi vil sjekke refusjonsbeløpet igjen og deretter informere deg. Verifiseringen kan ta litt tid, så vi ber om tålmodighet',

  // Reset password success page

  'Your password has been reset and changed.': 'Passordet er tilbakestilt og endret.',
  'You can now log in to the campaign website again. ': 'Du kan nå logge inn på kampanjenettstedet igjen.',
  'To do this, visit the desired campaign website.': 'For å gjøre dette, besøk ønsket kampanjenettsted.',

  // Campaign is over page

  'The campaign period is over': 'Kampanjeperioden er over',
  'Thank you for your interest in the campaign, unfortunately the campaign period is over.': 'Takk for din interesse for kampanjen, dessverre er kampanjeperioden over.',

  // DOI page

  'Thank you for your registration': 'Takk for registreringen',
  'You have successfully registered for our newsletter.': 'Du har registrert deg for vårt nyhetsbrev.',
  'From now on you will be one of the first to find out as soon as there are free product tests, competitions and inspiring articles.': 'Fra nå av vil du være en av de første til å finne ut så snart det er gratis produkttester, konkurranser og inspirerende artikler.',

  // Thank you page

  'Thank you for your participation': 'Takk for din deltakelse',
  'Your receipt and your data have been successfully sent to us and are now being checked.': 'Kvitteringen og dataene dine er sendt til oss og blir nå sjekket.',
  'Reference number: ': 'Referansenummer: ',
  'As soon as we have checked your data, you will either receive a confirmation e-mail or an e-mail with queries if your data was incomplete or your proof of purchase could not be recorded. This can take up to 36 hours. We ask for your patience.': 'Så snart vi har sjekket dataene dine, vil du enten motta en bekreftelses-e-post eller en e-post med spørsmål hvis dataene dine var ufullstendige eller kjøpsbeviset ditt ikke kunne registreres. Dette kan ta opptil 36 timer. Vi ber om tålmodighet.',
  'After successfully checking the proof of purchase and all other data as well as succesfully receiving the product you would like to return, we will transfer the reimbursement amount to the account you specified within 6 weeks. If anything is unclear, we will contact you again by email.': 'Etter å ha sjekket kjøpsbeviset og alle andre data, samt mottatt produktet du ønsker å returnere, vil vi overføre refusjonsbeløpet til kontoen du spesifiserte innen 6 uker. Hvis noe er uklart, vil vi kontakte deg igjen via e-post.',
  'Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation. ': 'Ikke glem at hvis du vil returnere produktet ditt, må du sende produktet tilbake til vår servicesenteradresse innen 10 dager etter at du har bekreftet din deltakelse.',
  'Copied to clipboard': 'Kopiert til utklippstavle',

  // SMS page

  'Please confirm your identity': 'Vennligst bekreft din identitet',
  'Please enter your mobile number in the field below. We will then send you a code to confirm your participation via SMS, which you can then enter on the next page.': 'Vennligst skriv inn mobilnummeret ditt i feltet nedenfor. Vi vil deretter sende deg en kode for å bekrefte din deltakelse via SMS, som du deretter kan skrive inn på neste side.',
  'In order to check your identity, we need your mobile phone number. The specification of the mobile number is required to verify your participation. The phone number you provide will only be used for one-time, advertising-free verification and will be deleted by us after the campaign.': ' For å sjekke identiteten din, trenger vi mobilnummeret ditt. Spesifikasjonen av mobilnummeret kreves for å bekrefte deltakelsen din. Telefonnummeret du oppgir, vil bare bli brukt til engangs, annonseringsfri bekreftelse og vil bli slettet av oss etter kampanjen.',
  'Phone number': 'Mobilnummer',
  'Confirmation code': 'Bekreftelseskode',
  'Enter your phone number': 'Skriv inn ditt phone number',
  'Please enter the confirmation code that we sent you via SMS into the field.': 'Vennligst skriv inn bekreftelseskoden som vi sendte deg via SMS i feltet.',
  'Change mobile number': 'Bytt mobilnummer',

  // 404 page

  'Oops!!': 'Oops!!',
  'Something went wrong!': 'Noe gikk galt!',

  // Survey page
  'What were the main reasons for you to buy this product?': 'Hva var hovedårsakene til at du kjøpte dette produktet?',
  'Please select as applicable; You have a multiple choice.': 'Velg etter behov; Du har et flervalg.',
  'You have a multiple choice.': 'Du har et flervalg.',
  'Easy to use': 'Enkel å bruke',
  'Design': 'Utforming',
  'Price / good deal': 'Pris / god avtale',
  'Performance': 'Ytelse',
  'Other': 'Annet',
  'Why would you like to return the product?': 'Hvorfor vil du returnere produktet?',
  "It doesn't work as expected": 'Det fungerer ikke som forventet',
  'Wrong item size or wrong product': 'Feil varestørrelse eller feil produkt',
  'Changed mind after Product Purchased': 'Endret tankene, etter kjøp av produktet',
  "Product didn't match the website or catalog Description": 'Produktet samsvarer ikke med webområdet eller katalogen Beskrivelse',
  'Found better product price elsewhere': 'Fant bedre produktpris andre steder',
  'Product did not meet my expectations': 'Produktet oppfylte ikke forventningene mine',
  'Enter optional': 'Angi valgfritt',

  // Return form page
  'Product name': 'Produktnavn',
  'Product EAN number': 'Produkt EAN- nummer',
  'Product serial number': 'Produkt Serienummer',
  'Product price for Money-back': 'Produktpris for pengene tilbake',
  'Date of purchase': 'Kjøpsdato',
  'Time of purchase': 'Tidspunkt for kjøp',
  'Retailer Name': 'Forhandlers navn',
  'Receipt/Invoice ID': 'Mottaks - /faktura-ID',
  'Reference number of the participation': 'Deltakelsen referansenummer',
  'Enter product name': 'Angi produktnavn',
  'Enter Product EAN number': 'Angi EAN- nummer for produkt',
  'Enter product serial number': 'Angi produktserienummer',
  'Enter product price for Money-back': 'Angi produktpris for pengene tilbake',
  'Enter Date of purchase': 'Angi kjøpsdato',
  'Enter Time of purchase': 'Angi kjøpstidspunkt',
  'Enter retailer name': 'Angi forhandlernavn',
  'Enter Receipt/Invoice ID': 'Angi mottaks - /faktura-ID',
  'Enter Reference number of the participation': 'Angi referansenummer for deltakelsen',

  // Legal Docs

  'website-link': 'https://se.braun.com/en',
  'home-link': '/no',
  'terms-link': 'https://termsandconditions.pg.com/no-no/',
  'privacy-link': 'https://privacypolicy.pg.com/no-NO',
  'preference-link': 'https://preferencecenter.pg.com/no-no/datarequests/',
  'sitemap-link': 'https://se.braun.com/en/sitemap',
  'impressum-link': '#',
  'Return form': 'Returskjema',
  'Print': 'Skrive ut',
  'Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation.': 'Ikke glem at hvis du vil returnere produktet ditt, må du sende produktet tilbake til vår servicesenteradresse innen 10 dager etter at du har bekreftet din deltakelse.',
  'Denmark': 'Danmark',
  'Norway': 'Norge',
  'Finland': 'Finland',
  'Sweden': 'Sverige',
  // Validation
  'This file format is not supported. Only .jpeg, .png, .pdf and .tiff files are accepted.': 'Dette filformatet støttes ikke. Bare .jpeg-, .png-, .pdf- og .tiff filer godtas.',
  'There is currently a problem with our website. Please try again in a few minutes.': 'Det er for øyeblikket et problem med nettstedet vårt.Prøv på nytt om noen minutter.',
  'The file is too big. Only files up to 16 MB are supported.': 'Filen er for stor.Bare filer på opptil 16 MB støttes.',
  'Oops. An error has occurred. Please try again later.': 'Oops. Det har oppstått en feil. Prøv på nytt senere.',
  validation: {
    housenumber: 'Husnummeret kan bare inneholde bokstaver, mellomrom, skråstreker og bindestreker.',
    mandatory: 'Dette feltet er obligatorisk.',
    required: "{field} er eit obligatorisk felt",
    alpha: '{item} * kan endast innehålla alfabetiska tecken.',
    iban: 'IBAN - Bruk en gyldig IBAN for de deltakende landene',
    postal: 'Postnummeret må bestå av {digit} sifre.',
    bic: 'BIC må ha riktig format.',
    mobileNumber: 'Mobiltelefonnummeret må inneholde minst {digit} sifre',
    email: 'E-postadressen må ha riktig format.',
    customRequired: {
      'Mobile number': 'Mobilnummer er et obligatorisk felt',
      code: 'Bekreftelseskode er et obligatorisk felt',
    },
  },
  'Please hide your personal data (name, surname, phone nr., address, etc.) on the receipt/invoice if you will have it before sending your package to Service Center.': 'Gjem dine personlige data (navn, etternavn, telefonnummer, adresse osv.) på kvitteringen/fakturaen hvis du vil ha det før du sender pakken til servicesenteret.',
  socialMedia: {
    fb: 'https://www.facebook.com/braun',
    yt: 'https://www.youtube.com/braun',
  },
}
