<template>
  <div class="">
    <ValidationProvider
      v-if="validation"
      :rules="name === 'postal-code' ? plz : validation"
      v-slot="{ errors }"
      :name="label"
    >
      <div class="textInputWrapper form-group">
        <label v-if="labelOnTop" :style="labelOnTopStyle" class="input-label">
          {{$t(label)}}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>{{$t('(optional)')}}</span>
          <span v-if="name === 'iban' || name === 'bic'" class="mx-3">
            <b-icon icon="exclamation-circle-fill" variant="dark" :id="name" alt="icon">
            </b-icon>
            <b-tooltip custom-class="text-black" :target="name" triggers="hover" variant="secondary" placement="topright">
              <b>{{ $t(toolTip.title) }}</b>
              <br>
              <span v-html="$t(toolTip.text)"></span>
            </b-tooltip>
          </span>
        </label>
        <input
          :style="styles"
          :type="inputType"
          class="form-control textInput"
          :class="{'is-invalid': errors.length}"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="$t(placeholder)"
          :maxlength="maxlength"
          :autocomplete="autocomplete"
          :aria-label="$t(placeholder)"
        />
        <label v-if="!labelOnTop && !noLabel" :for="id" class="floating-label" :class="{'slideUp': slideUp}">
          {{ $t(label) }}
        </label>
      </div>
      <div
        class="validation-error"
        v-if="errors.length"
      >{{errors[0]}}</div>
    </ValidationProvider>
    <div
      class="m-0 p-0"
      v-else
    >
      <div class="textInputWrapper form-group">
        <label v-if="labelOnTop" :style="labelOnTopStyle" class="input-label">
          {{$t(label)}}
          <span v-if="validation.includes('required')">*</span>
          <span v-else>{{$t('(optional)')}}</span>
        </label>
        <input
          :style="styles"
          :type="inputType"
          class="form-control textInput"
          :value="text == '' ? value : text"
          @input="updateValue($event.target.value)"
          ref="input"
          :name="name"
          :id="id"
          :placeholder="$t(placeholder)"
          :maxlength="maxlength"
          :autocomplete="autocomplete"
        />
        <label v-if="!labelOnTop && !noLabel" :for="id" class="floating-label" :class="{'slideUp': slideUp}">
          {{ $t(label) }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'

export default {
  name: "text-input",
  data () {
    return {
      plz: 'required|numeric'
    }
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    id: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    inputType: {
      type: String,
      default: "text"
    },
    label: {
      type: String,
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    text: {
      type: String,
      default: ""
    },
    validation: {
      type: String,
      required: false
    },
    value: {
      required: false
    },
    maxlength: {
      type: Number,
      required: false
    },
    styles: {
      type: Object,
      required: false,
      default: () => {}
    },
    slideUp:Boolean,
    labelOnTop: {
      type: Boolean,
      default: false
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    labelOnTopStyle: {
      type: Object,
      default: () => {}
    },
    isUpperCase: Boolean,
    toolTip: {
      type: Object,
      default: () => {
        return {}
      }
    },
    autocomplete:{
      type: String,
      required: false
    }
  },
  mounted () {
    if (this.name === 'postal-code') {
      this.$eventHub.$on('checkCountryForPLZ', this.updateValidation)
    }
  },
  methods: {
    updateValue (val) {
      let value = this.isUpperCase ? val.toUpperCase() : val;
      if (this.name === "iban") {
        value = value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim().toUpperCase();
      }
      this.$emit("component-updated", {
        text: value,
        slideUp:!!value
      });
      this.$emit("input", value);
    },
    updateValidation (val) {
      this.plz = val === 'Denmark' || val === 'Norway' ? 'required|numeric|postal:4' : 'required|numeric|postal:5'
    }
  },
  beforeDestroy () {
    if (this.name === 'plz') {
      this.$eventHub.$off('checkCountryForPLZ', this.updateValidation)
    }
  }
};
</script>

<style scoped lang="scss">
$medium: 800px;

.textInputWrapper .floating-label {
  position: absolute;
  top: 50%;
  left: 10px;
  padding-left: 7px;
  padding-right: 7px;
  transition: transform 0.1s;
  pointer-events: none;
  transform-origin: 0 100%;
  transform: translateY(-50%);
  font-size: 14px;
  line-height: 16px;
  color: #808080;
}

.slideUp {
  transform: translateY(-135%) scale(0.8) !important;
}
</style>
