<template>
  <div class="wrapper5points">
    <p class="heading">Bitte beachten Sie folgende Kernteilnahmebedinungen</p>
    <div class="grid-container" :class="{ 'grid-container-mobile' : mobile }">
      <template v-for="(value, idx) in points" >
        <p v-bind:key="idx" v-html="value" class="grid-item" :class="{ 'grid-item-mobile' : mobile , 'yellow-bg' : (idx + 1) == yellowBg }"></p>
      </template>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "fivePoints",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    grid: {
      type: Object,
      default: () => ({
        backgroundColor: "#fff"
      })
    },
    points: {
      type: Array
    },
    variables: Object,
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: Array,
      default: () => {
        return [];
      }
    },
    mobile: {
      type: Boolean,
      default: false
    },
    yellowBg: Number
  },
  data () {
    return {
      cssString: "",
      classString:""
    };
  },
  computed: {
    html () {
      let result = this.text;
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state.formData[variable];
          if(variable === "referenceNumber") {
            value = this.$store.getters.getReferenceNumber
          }
          result = result.replace(new RegExp(`{${variable}}`, "g"), value);
        }
      }
      return result;
    }
  },
  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      }
    },
    classes: {
      immediate: true,
      handler () {
        this.classes.forEach(cls=>{
          this.classString += cls+" ";
        })
      }
    }
  },
  methods: {
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000),
    triggerActions () {
      if (this.actions) {
        this.actions.forEach(action => {
          this.$store.dispatch(action, { val: 123 });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  outline: 0;
}

.wrapper5points {
  margin-bottom: 50px;
}

.heading {
  color: var(--color2);
  text-align: center;
  font: normal normal 600 32px/34px 'Plus Jakarta Sans', SemiBold;
  letter-spacing: 0px;
  opacity: 1;
  padding: 0px;

  @media (max-width: 800px) {font-size: 18px; line-height: 20px;}
  @media (max-width: 700px) {font-size: 16px; line-height: 18px;}
  @media (max-width: 600px) {font-size: 14px; line-height: 16px;}
  @media (max-width: 500px) {font-size: 12px; line-height: 13px;}
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  background-color: #f90099;
  padding: 15px;

  &-mobile {
    grid-template-columns: auto;
    background-color: transparent;
    border: 5px solid #f90099;
    padding: 0px;
  }
}

.grid-item {
  padding: 20px;
  margin: 0px;
  text-align: center;
  font: normal normal bold 20px/20px Lato;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;

  background-color: white;//#f900a3;

  border-left : 2px solid #8CC6FF;
  &:first-child {
    border-left: none;
    border-top: none;
  }

  &-mobile {
    font: normal normal 300 11px/13px 'Plus Jakarta Sans';
    text-align: center;
    letter-spacing: 0px;
    color: #1B1B1B;
    opacity: 1;
    background-color: transparent;

    border-top : 2px solid #8CC6FF;
    border-left: none;

    padding: 20px 0px;
    margin: 0px 10px;
  }

    @media (max-width: 1200px) {font-size: 18px; line-height: 18px;}
    @media (max-width: 1050px) {font-size: 16px; line-height: 16px;}

    @media (max-width: 991px) and (min-width: 800px) {font-size: 18px; line-height: 20px;}

    @media (max-width: 800px) {font-size: 18px; line-height: 20px;}
    @media (max-width: 700px) {font-size: 16px; line-height: 18px;}
    @media (max-width: 600px) {font-size: 14px; line-height: 16px;}
    @media (max-width: 500px) {font-size: 11px; line-height: 13px;}
}

.yellow-bg {
  background-color: yellow;
  font-weight: bold;
}
</style>
