<template>
<div class="d-flex flex-column align-items-center">

  <div :class="`product ${selected ? 'active' : ''} ${!product.selectable ? 'notSelectable' : ''}`" :style="cssString">
      <img :src="product.backgroundImage" class="product-image">
      <div :class="`isSelectedBox ${selected ? 'active' : ''}`">
          <transition-group name="fade" mode="out-in">
            <svg key="checked" v-if="selected" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="24" height="24" rx="12" :fill="colorSchema"/>
                <path d="M8 12.5L10.6667 15L16 9" stroke="white" stroke-width="1.5"/>
            </svg>

            <svg key="unchecked" v-else width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="white" stroke="#97999B"/>
            </svg>
          </transition-group>
      </div>
  </div>
      <div class="title">
      {{product.title}}

      </div>
</div>
</template>

<script>
export default {
  props: {
    product: {
      required: true,
      type: Object
    },
    selected: {
      required: true,
      type: Boolean
    },
    colorSchema: {
      required: true,
      type: String
    },
    cssString: String
  }
}
</script>

<style lang="scss" scoped>
.fade-enter-active, .fade-leave-active {
  opacity: 1;
  transition: .4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.title{
    width: 180px;
    text-align: center;
}
.product {
    height: 180px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 16px;
    margin-top:50px ;
    position: relative;
    background-size: cover;
    background: #FFFFFF;
    border: 1px solid #DDDFE1;
    box-sizing: border-box;
    border-radius: 4px;

    .product-image {
      width: 100%;
      object-fit: cover;
      padding: 30px 10px 8px 10px;
    }

    &.active, &:hover {
      border: 1px solid #8B8B8B;
      box-sizing: border-box;
      border-radius: 4px;
      transition: .4s ease-in-out
    }

    &.notSelectable {
        opacity: 0.6;
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
    }
    .isSelectedBox {
        border-radius: 5px;
        position: absolute;
        top: 16px;
        left: 16px;
        user-select: none;
        svg {
            position: absolute
        }
    }
}
</style>