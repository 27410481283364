<template>
  <button
    type="button"
    :class="[`btn ${fullWidth?'w-100':''}`, addClass ? addClass : '' , `col-sm-${grid.columns}`] "
    :style="cssString"
    :disabled="disabled || loading || (dependency !== 'full' ? hasSomeOfDependencies : hasUnresolvedDependencies)"
    @click="clicked"
    tabindex="0"
  >
    <div class="buttonText" :style="textCssString" v-if="!loading">
      <img v-if="addClass.includes('backButton')" src="/assets/svg/left-arrow.svg" alt="back">
      {{$t(text)}}
    </div>
    <div
      v-else
      class="spinner-border spinner-border-sm"
      :style="textCssString"
      role="status"
    >
      <span class="sr-only">Loading...</span>
    </div>
  </button>
</template>

<script>
export default {
  name: "button-component",
  data () {
    return {
      loading: false,
      cssString: "",
      textCssString: ""
    };
  },
  props: {
    requires: {
      type: Array
    },
    grid: {
      type: Object,
      required: false,
      default: () => {}
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "#000"
    },
    borderColor: {
      type: String,
      required: false,
      default: "#000"
    },
    textColor: {
      required: false,
      type: String,
      default: "#fff"
    },
    text: {
      required: false,
      type: String,
      default: "Button"
    },
    borderRadius: {
      required: false,
      type: String,
      default: "0"
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    enabledOn:{
      required: false,
      type:String,
      default:''
    },
    fullWidth: {
      type: Boolean
    },
    action: {
      type: [Object, Array]
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    textStyles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    dependency: {
      type: String,
      default: 'full'
    },
    addClass: {
      type: String
    }
  },
  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
        const txtkeys = Object.keys(this.textStyles);
        const txtvals = Object.values(this.textStyles);
        txtkeys.forEach((key, idx) => (this.textCssString += key + ":" + txtvals[idx]));
      }
    },
    enabledOn:{
      immediate:true,
      handler (val){
        if(!val || val ==''){
          return
        }
        return this.$store.state[val]
      }
    }
  },
  computed: {
    hasUnresolvedDependencies () {
      return this.$store.getters.hasUnresolvedDependencies(this.requires);
    },
    hasSomeOfDependencies () {
      return this.$store.getters.hasSomeOfDependencies(this.requires);
    }
  },
  methods: {
    async clicked () {
      if (!this.action) return;

      if(this.action.anchor){
        return window.location.href = this.$te(this.action.link) ? this.$t(this.action.link) : this.action.link
      }
      if (this.action.length > 0) {
        // console.log("inside actions loop::");
        for (let i = 0; i < this.action.length; i++) {
          const act = this.action[i];
          const fn = act.fn;
          const params = act.params;
          await this[fn](params, this);
        }
        return;
      }
      const fn = this.action.fn;
      const params = this.action.params;
      await this[fn](params, this);
      // const params = this.action.params.toString();
      // eval(`this.${this.action.fn}(params)`);
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  &:focus,
  &:active {
    outline: 1px solid #000 !important;
    box-shadow: none;
  }
}
.upload-btn {
  background-color: #5A5E5F;
}
.btn {
  color: #FBFBFB;
  border: 0px;
  text-align: center;
  background-color: var(--color2);
  vertical-align: middle;
  cursor: pointer;
  border-radius: var(--button-radius);
  transition: all 0.3s;
  font-size: 18px;
  font-weight: 700;
  min-height: 44px;

  @media (max-width: 450px) {
    .buttonText {
      font: normal normal 500 14px/18px 'Plus Jakarta Sans' !important;
    }
  }

  &-back {
    background-color: #fff;
    border: 1px solid var(--color1);
    color: var(--color1);
  }

  &-login {
    background-color: var(--color4);
  }

  &-guest {
    background-color: #fff;
    border: 1px solid var(--color4);
    color: var(--color4);
  }


   &:hover,

      &:focus {

        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.16);

      }

      &:active {

        border-color: #DEBA06;

      }

      &-min {

        @media (max-width: 574px) {

          font-size:  17px;

        }

      }
  &:disabled {
    background: #B2B2B2 !important;
    div {
      color: #808080 !important;
    }
  }
}
.long-text {
  @media (max-width: 450px) {
    .buttonText {
      font: normal normal 500 14px/18px 'Plus Jakarta Sans' !important;
    }
  }
}

.homePage {
  font: normal normal normal 20px/28px 'Plus Jakarta Sans';
  letter-spacing: 0px;
  color: #FBFBFB;
  opacity: 1;
}

@media (min-width: 991px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}
@media (max-width: 991px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

.customColor{
  color: #000 !important;
  background: #ea9e1c !important;
}
</style>
