<template>
<span></span>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: "noLogo",

  methods: {
    ...mapActions['setRemoveLogo']
  },

  mounted () {
    this.$store.commit( 'setRemoveLogo' ,  true)
  },

  beforeDestroy () {
    this.$store.commit( 'setRemoveLogo' ,  false)
  }
}
</script>