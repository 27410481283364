<template>
  <div>
    <div class="row">
      <div class="col-md-12 p-0">
        <barcodeScanner :texts="texts" :height="readerHeight" :width="readerWidth" :cameraTimeOut="cameraTimeOut" :maxProductCount="maxProductCount" :apiUrl="apiUrl" />
      </div>
    </div>
    <div class="d-flex buttonContainer col-lg-12 row">
        <buttonComponent
          :backButton="true"
          addClass="backButton absolute left"
          @click.native="backToHomeScreen"
          :text="$t('Back')"
          :grid= "{columns: 3}"
        />
        <buttonComponent
          addClass="primaryButton"
          :class="{'disabled': nextButtonDisabled}"
          :disabled="nextButtonDisabled"
          @click.native="checkIfOnlyOneProductSelected"
          :text="$t('NEXT')"
          :grid= "{columns: 3}"
        />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import buttonComponent from "@/components/renderers/button-component.vue";
import BarcodeScanner from './BarcodeScanner'
export default {
  name: 'barcode-scanner-oralb',
  components: {
    BarcodeScanner,
    buttonComponent
  },
  data () {
    return {
      tabValue: ["DETERGENT" , "SOFTENERS" , "BEADS"],
      popUpSeen: false
    }
  },
  computed: {
    ...mapState({
      list: state => state.scannedProducts,
      nextButtonDisabled: state => !state.scannedProducts.length,
      status: state => state.searchStatus,
      barcode: state => state.productCode
    }),
    showDisplayFull () {
      return !this.isMobileOrTablet(screen.width)
    }
  },
  props: {
    readerHeight: {
      type: Number
    },
    readerWidth: {
      type: Number
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    apiUrl: {
      type: String
    },
    successAction: {
      type: [Object, Array]
    },
    errorPageName: {
      type: String
    },
    cameraTimeOut: {
      type: Number
    },
    maxProductCount: Number
  },
  methods: {
    ...mapActions(["getBarCode", "searchProductByBarcode", "setErrorActivate" , "updateSearchStatus"]),
    sendProducts () {
      const apiUrl = "https://template-v2.justsnap.eu/api/submitProducts";

      this.list.forEach(item => {
        item.count = 1;
      })

      const formData = {
        selectedProducts: this.list
      };

      this.$store.dispatch("sendProducts", { formData, apiUrl }).then(r => {
        if (r.response) {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
          return;
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
    },

    backToHomeScreen () {
      this.$eventHub.$emit('goToPage-event', 'homepage')
    },

    checkIfOnlyOneProductSelected () {
      console.log(this.list)

      // if (this.list && this.list.length == 1 && !this.popUpSeen) {
      //   this.showModal("Sind Sie sicher, dass Sie nur mit einem Produkt teilnehmen möchten? Alle einzulösenden Lenor Produkte müssen auf einem gemeinsamen Bild abgebildet sein." , "custom")
      //   this.popUpSeen = true
      //   return
      // }

      this.sendProducts()
    }

  },
  destroyed () {
    // Set the error message to false
    this.setErrorActivate(false)
    this.$store.dispatch("findProducts", []);
    this.getBarCode('')
    this.updateSearchStatus(null)
  },
  mounted () {
    // Check if the search status error is true
    if (this.status === "not_found")
      this.setErrorActivate(true)
    else
      this.setErrorActivate(false)
  }
}
</script>

<style scoped lang="scss">
.product_list-relative_container {
  position: relative;
  height: 100%;
}
.product_list-absolute_container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
@media (max-width: 768px) {
  .product_list-absolute_container {
    position: static;
  }
}
.scroll {
  overflow-y: scroll;
}
.next-btn {
    background: var(--color2) 0% 0% no-repeat padding-box;
    border-radius: var(--button-radius);

    margin-bottom: 40px;

    font: normal normal 500 20px/24px 'Plus Jakarta Sans';
    letter-spacing: 0px;
    color: #FBFBFB;
    opacity: 1;
}
.next-btn.disabled {
    background: #E3E3E3 !important;
    border-color: #E3E3E3 !important;
    color: #6F6F6F;
}
.confirm-msg {
    background: #FFFFFF;
    border: 1px solid #DDDFE1;
    box-sizing: border-box;
    border-radius: 20px;


    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
}
@media screen and (max-width: 425px) {
  .next-btn,
  .next-btn.disabled {
    height: 48px;
    width: 100%;
  }
}

.searchModuleHeading {
  text-align: center;
  font: normal normal 500 20px/24px 'Plus Jakarta Sans', Medium;
  letter-spacing: 0px;
  color: #3D3D41;
  opacity: 1;
}

.buttonContainer {
  justify-content: center;
  padding: 140px 0;
  @media (max-width: 991px) {
    padding: 80px 0;
    justify-content: flex-end;
  }
 }
</style>
