<template>
  <ValidationObserver ref="form">
    <form class="row" :id="fieldId" :style="styles">
      <template v-for="field in fields">
        <componentRenderer
          :class="`col-lg-${field.properties.grid.columns}`"
          :field="field"
          v-on:component-updated="updated"
          v-bind:key="field.id"
        />
      </template>
      <!-- <button @click="submit">SUBMIT</button> -->
    </form>
  </ValidationObserver>
</template>
<script>
import componentRenderer from "@/components/component-renderer";
import { mapGetters } from "vuex";

export default {
  name: "form-component",
  components: { componentRenderer },
  props: {
    fieldId: String,
    requires: Array,
    successAction: {
      type: Object,
      default: () => null
    },
    apiUrl: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    styles: {
      type: Object,
      required: false,
      default: () => {
      }
    },
    keepInState: Array
  },
  computed: {
    ...mapGetters(["customerToken", "campaignId", "receiptToken", "getErrorImageList"])
  },
  methods: {
    clicked () {
      // console.log("FORM CLICKED", this);
    },
    clear () {
      this.fields.forEach(field => {
        if (field.fieldType === "text-input") {
          field.properties.text = "";
        } else if (field.fieldType === "select-input") {
          field.properties.text = "";
        } else if (field.fieldType === "radio-input") {
          field.properties.selectedValue = "";
        } else if (field.fieldType === "checkbox") {
          field.properties.checked = "";
        } else if (field.fieldType === "date-input") {
          field.properties.value = "";
        }
      });
    },
    getFieldData (fields, formData) {
      fields.forEach(field => {
        if (field.fieldType === "text-input") {
          const name = field.properties.name;
          const value = field.properties.text;
          formData[name] = name === "iban" ? value.replace(/\s/g, '') : value;
        } else if (field.fieldType === "select-input") {
          const name = field.properties.name;
          const value = field.properties.text;
          formData[name] = value;
        } else if (field.fieldType === "radio-input") {
          const name = field.properties.name;
          const value = field.properties.selectedValue;
          formData[name] = value;
        } else if (field.fieldType === "checkbox") {
          const name = field.properties.name;
          const value = field.properties.checked;
          formData[name] = value;
        } else if (field.fieldType === "date-input") {
          const name = field.properties.name;
          const value = field.properties.value;
          formData[name] = value;
        } else if (field.fieldType === "url-token") {
          const name = field.properties.name;
          const value = field.properties.text;
          formData[name] = value;
        } else if (field.fieldType === "checkbox-terms") {
          const name = field.properties.name;
          const value = field.properties.checked;
          formData[name] = value;
        } else if (field.properties.fields) {
          this.getFieldData(field.properties.fields, formData);
        }
      });
    },
    getFormData (formId, triggers) {
      if (this.fieldId === formId) {
        console.log("inside getFormData", this, formId, this.$refs.form);

        let formData = {};
        if (Object.keys(this.$route.query).length > 0) {
          formData = { ...this.$route.query }
        }
        const apiUrl = this.apiUrl;
        if (this.locale) {
          formData.lang = this.locale;
        }
        formData.customerToken = this.customerToken;
        formData.campaignId = this.campaignId;
        formData.receiptToken = this.receiptToken;
        this.getFieldData(this.fields, formData)
        let sendMail = triggers?.sendMail ? true : false

        this.$refs.form.validate().then(isValid => {
          if (isValid) {
            // console.log("SENDING FORM>>", formData);
            const presistentFormFields = {}
            if (this.keepInState) {
              this.keepInState.forEach(field => {
                presistentFormFields[field] = this.$store.state.formData[field]
              })
            }
            this.$store.commit("setField", {
              field: "formData",
              value: { ...presistentFormFields, ...formData }
            });
            if (!sendMail) {
              this.$store
                .dispatch("sendFormData", { formData, apiUrl, triggers })
                .then(r => {
                  if (r.consumerId !== undefined) {
                    this.$store.commit("setconsumerId", r.consumerId);
                  }
                  if (r.response && this.successAction) {
                    const fn = this.successAction.fn;
                    const params = this.successAction.params;
                    this[fn](params, this);
                    this.$eventHub.$emit("done-event-trigger", {
                      parent: this,
                      status: true
                    });
                  }
                  this.$eventHub.$emit("done-event-trigger", {
                    parent: this,
                    status: false,
                    message: r.message || "Unexpected Error",
                    invalid_fields: r.invalid_fields || []
                  });

                  if (r.response) {
                    // append response payload into formData
                    this.$store.commit("setField", {
                      field: "formData",
                      value: {
                        ...presistentFormFields,
                        ...formData,
                        ...r
                      }
                    });
                  }
                  if(r.referenceNumber !== undefined) {
                    this.$store.commit("setReferenceNumber", r.referenceNumber)
                  }
                })
                .catch(e => {
                  this.$eventHub.$emit("done-event-trigger", {
                    parent: this,
                    status: false
                  });
                });
            }
          } else {
            // this.scrollTo(`[id=${this.fieldId}`);
            // document.getElementById(this.fieldId).scrollIntoView();
            if (sendMail) return formData
            this.$eventHub.$emit("done-event-trigger", {
              parent: this,
              status: false
            });
          }
        });


        return formData
      }
    },
    updated (field, properties) {
      let updatedField = this._.find(this.fields, function (fld) {
        return fld.fieldId === field.fieldId;
      });
      updatedField.properties = { ...updatedField.properties, ...properties };
      // let updatedField = __.findDeep(this.fields, fld => fld === field.fieldId);
      // console.log(
      //   "component renderer on updated::",
      //   field,
      //   "properties",
      //   properties,
      //   "FOUND",
      //   updatedField
      // );

      // TODO
      // some validation will go here
      // in case if all fields are valid we are happy to set truthy status
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
    },
    sendEmail (data) {

      let formId = data.formId
      let imgArr = this.getErrorImageList
      let triggers = { sendMail: true }
      let dataForm = this.getFormData(formId, triggers);

      let firstName = dataForm.firstName
      let lastName = dataForm.lastName
      let email = dataForm.email

      // If Any input is empty
      if (imgArr[0] === "" || imgArr[1] === "" || firstName === "" || lastName === "" || email === "") {
        this.$eventHub.$emit("email-sent-trigger", {
          parent: this,
          status: false,
          message: "Lassen Sie kein Feld leer"
        });
        return
      }

      // Proccessing is done
      this.$eventHub.$emit("email-sent-trigger", {
        parent: this,
        status: true,
        success: true,
        message: data.message
      });

      return true
    }
  },
  mounted () {
    // console.log("mountedForm", this);

    this.$eventHub.$on("getFormData-event", this.getFormData);

    this.$eventHub.$on("sendmail-event", this.sendEmail)
  }
};
</script>
<style scoped lang="scss">
  .row {
    ::v-deep [disabled] {
      pointer-events: none;
    }
  }
</style>
