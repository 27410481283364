import Vue from 'vue'
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import dk from 'vee-validate/dist/locale/da.json';
import no from 'vee-validate/dist/locale/nn_NO.json';
import se from 'vee-validate/dist/locale/sv.json';
import fi from 'vee-validate/dist/locale/fi.json';
import { i18n } from './translate'

const ibantools = require('ibantools');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

extend('required', {
  ...rules['required'],
  message: (field) => {
    if (i18n.te(`validation.customRequired.${field}`)) {
      return i18n.t(`validation.customRequired.${field}`)
    }
    return i18n.t('validation.required', { field: i18n.t(field) });
  }
})

extend('hausnr', {
  validate: value => /^[a-zA-Z0-9-\s/]+$/.test(value),
  message: i18n.t('validation.housenumber')
});
extend('email', {
  ...rules['email'],
  message: () => i18n.t(`validation.email`)
})
extend('postal', {
  validate: (value, params) => {
    let digits = params[0]
    return value.length == digits
  },
  message: (value, params) => {
    let digits = params[0]
    return i18n.t('validation.postal', { digit: digits });
  }
})
extend('iban', {
  validate: (value) => {
    let allowedCountryCode = i18n.locale ? i18n.locale.toUpperCase() : 'NO'
    const countryCode = value.substring(0, 2)
    return (allowedCountryCode == countryCode) && ibantools.isValidIBAN(value.replace(/[^\dA-Z]/g, ''))
  },
  message: () => i18n.t(`validation.iban`)
});
extend('bic', {
  validate: value => !!ibantools.isValidBIC(value),
  message: () => i18n.t(`validation.bic`)
});
extend('namesWithHyphensAndSpaces', {
  validate: value => {
    return /^([a-zA-ZßäöüÄÖÜÅåÅåÆæØø]{1,}(\s|-))*[a-zA-ZßäöüÄÖÜÅåÅåÆæØø]{1,}$/.test(value);
  },
  message: (item) => i18n.t(`validation.alpha`, { item: i18n.t(item) })
});
extend('mobileNumber', {
  validate: (value, params) => {
    let country = params[0]
    if (country === '45' || country == '47')
      return value.length == 8;
    else if (country === '46')
      return value.length == 11;
    else
      return value.length == 10;
  },
  message: (value, params) => {
    let country = params[0]
    let digit = 10;
    if (country === '45' || country == '47')
      digit = 8
    else if (country === '46')
      digit = 11
    else
      digit = 10
    return i18n.t('validation.mobileNumber', { digit: digit });
  }
})

// let wihtCustomMessages = {
//   ...de.messages,
//   length: (input, { length }) => `${input} muss eine ${length}-stellige Zahl sein`
// }

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule] // copies rule configuration
    // message: wihtCustomMessages[rule] // assign message
  });
});


localize({
  en: {
    messages: en.messages
  },
  dk: {
    messages: dk.messages
  },
  se: {
    messages: se.messages
  },
  fi: {
    messages: fi.messages
  },
  no: {
    messages: no.messages
  }
});

localize(i18n.locale);

// A simple get/set interface to manage our locale in components.
// This is not reactive, so don't create any computed properties/watchers off it.
Object.defineProperty(Vue.prototype, "locale", {
  get() {
    return i18n.locale;
  },
  set(val) {
    i18n.locale = val;
    localize(val);
  }
});
