<template>
  <span></span>
</template>

<script>
export default {
  name: "segmenta-cookieHandler",
  props: {},
  mounted () {
    window.segmentaCookieHandler = () => {
      const _sfcc = 1;
      const _spcc = +window.OnetrustActiveGroups.includes("2") || 0;
      const _smcc =
        +window.OnetrustActiveGroups.includes("4") ||
        +window.OnetrustActiveGroups.includes("8") ||
        0;
      window.SEGMANTA__USER_METADATA = {
        _sfcc,
        _spcc,
        _smcc
      };
      console.log("Segmenta Cookie Handler triggered...")
    };
    window.segmentaCookieHandler();
  }
};
</script>
