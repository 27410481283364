<template>
  <header class="p-0">
    <div class="navbar-wrapper col-lg-12 m-0 p-0">
      <nav
        class="navbar navbar-expand-lg navbar-light p-0"
        :class="{'justify-content-end' : removeLogo , 'justify-content-between' : !removeLogo}"
      >
        <div class="navbar-brand m-0 p-0">
          <a
            v-if="!removeLogo"
            :href="$t('website-link')"
            target="_blank"
          >
            <img
              :src="logo.imageSource"
              :width="logo.width"
              :height="logo.height"
              :alt="logo.alternateText"
              role="link"
            />
          </a>
        </div>
        <div class="navbar-collapse expandedDiv" ref='expandedDiv' >
          <ul class="navbar-nav">
            <li
              class="navbarLinks"
              v-for="field in fields"
              :key="field.fieldId"
            >
              <componentRenderer v-bind:key="field.id" :field="field" class="underline" />
            </li>
          </ul>
        </div>
        <div class="expandButtonWrapper">
          <button
            class="navbar-toggler"
            type="button"
            @click="checkBoxClicked"
          >
            <svg width="20" height="20" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.661 11.385H14.57m-12.909-10H14.57 1.66Zm0 5H14.57 1.66Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
          </button>
          <language-dropdown v-if="isHomeScreen" :languages="languages"/>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
import componentRenderer from "@/components/component-renderer";
import { mapGetters } from "vuex";
import LanguageDropdown from './OralB-components/language-dropdown.vue';

export default {
  name: "navbar",
  components: { componentRenderer, LanguageDropdown },
  props: {
    showLine: {
      required: false,
      type: Boolean
    },
    borderColor: {
      type: String,
      default: "#CCE4FF"
    },
    backgroundColor: {
      required: false,
      type: String,
      default: "#fff"
    },
    title: {
      required: false,
      type: String,
      default: "Hello world"
    },
    logo: {
      required: true,
      type: Object
    },
    fields: {
      required: false,
      type: Array,
      default: () => []
    },
    color: {
      required: false,
      type: String,
      default: "#000"
    },
    languages: {
      type: Array
    }
  },
  mounted () {
    // this.initializeTabs();
    if (this.showLine) {
      this.$el.parentNode.style.borderBottom= `1px solid ${this.borderColor}`
    }
    this.$el.parentNode.style.backgroundColor= `${this.backgroundColor}`
  },
  computed: {
    removeLogo () {
      return this.$store.getters.removeLogo;
    },
    isHomeScreen () {
      const path = location.pathname; // path format "/countryCode/pageName"
      const pageName = path.split("/")[2] // [1] is country code, [2] is pageName
      if (!pageName) return true // if there's no pageName meaning we're on the home page, show the flag.
      return false // if pageName exists, hide the flag.
    }
  },
  methods: {
    checkBoxClicked () {
      const expandedDiv = this.$refs['expandedDiv'];
      expandedDiv.style.maxHeight = expandedDiv.style.maxHeight ? null : expandedDiv.scrollHeight + "px";
    }
  }
};
</script>

<style lang="scss" scoped>

.navbarLinks {
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 200px;
}

.underline {
  &:hover {
    text-decoration: underline;
  }
}

.navbar-wrapper {
  position: relative;
  min-height: 77px;
  display: flex;
  align-items: center;

  .navbar {
    width: 100%;
  }
}

.hr {
  margin-top: 8px;
  margin-bottom: 8px;
}
.navbar-collapse {
  flex-grow: initial;
}
.navbar-toggler {
    margin: 0 10px;
    border: none;
    &:focus {
      outline: none;
    }
}

.expandedDiv {
  @media (max-width: 991px) {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;
    -moz-transition: max-height 0.3s ease-out;; /* Firefox 4 */
    -webkit-transition: max-height 0.3s ease-out;;  /* Safari and Chrome */
    -o-transition: max-height 0.3s ease-out;;  /* Opera */
  }
}
.expandButtonWrapper {
  display: flex;
  flex-direction: row;
}
.navbar-brand {
  display: flex;
  flex: 1;
}
@media (max-width: 575px) {
  .navbar-brand > img {
    width: 145px !important;
  }
  .navbar-toggler {
    padding: 2px 6px;
  }
  .navbar-toggler-icon {
    width: 20px
  }
}

@media (max-width: 991px) {
  .expandButtonWrapper, .navbar-brand {
    order: -1
  }
  .navbarLinks {
    justify-content: flex-end;
    margin: 0 22px;
    font-size: 14px;
    border-bottom: 1px solid var(--color-6);
  }
}
</style>
