<template>
  <p :class="classString" :style="cssString" @input="updateComponent" v-html="html" @click="triggerActions"></p>
</template>

<script>
import debounce from "lodash/debounce";
export default {
  name: "paragraph",
  props: {
    text: {
      type: String,
      default: "Empty paragraph"
    },
    grid: {
      type: Object,
      default: () => ({
        backgroundColor: "#fff"
      })
    },
    actions: {
      type: Array
    },
    variables: Object,
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    classes: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data () {
    return {
      cssString: "",
      classString:""
    };
  },
  computed: {
    html () {
      let result = this.$t(this.text);
      if (this.variables) {
        for (const variable in this.variables) {
          let value = this.$store.state.formData[variable];
          if(variable === "referenceNumber") {
            value = this.$store.getters.getReferenceNumber
          }
          result = result.replace(new RegExp(`{${variable}}`, "g"), value);
        }
      }
      return result;
    }
  },
  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      }
    },
    classes: {
      immediate: true,
      handler () {
        this.classes.forEach(cls=>{
          this.classString += cls+" ";
        })
      }
    }
  },
  methods: {
    updateComponent: debounce(function (event) {
      this.$emit("component-updated", {
        text: event.target.innerText
      });
    }, 2000),
    triggerActions () {
      if (this.actions) {
        this.actions.forEach(action => {
          this.$store.dispatch(action, { val: 123 });
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  outline: 0;
}

.loginSubText {
  text-align: left;
  text-decoration: underline;
  font: normal normal 500 20px/24px Montserrat, Medium;
  letter-spacing: 0px;
  color: #000;
  opacity: 1;
  padding: 0px;
}

.stepCard {
  margin-top: 10px;

  color: #1A1111;
  text-align: left;
  font: normal normal normal 20px/28px 'Plus Jakarta Sans';
  letter-spacing: 0px;

  @media (max-width: 545px) {
    font-size: calc(100vw / 545 * 14 + 2px);
  }

}

@media (max-width: 991px) {
  .desktop-only {
    display: none !important;
  }
}

</style>
