<template>
  <div class="d-flex justify-content-center align-items-center products-container">
    <div class="row justify-content-center">
      <product
        v-for="product in products"
        :product="product"
        @click.native="itemClick(product)"
        :key="product.id"
        :selected="isSelected(product)"
        :colorSchema="colorSchema != '' ? colorSchema : '#8B8B8B'"
        :cssString="cssString"
        style
      />
    </div>
  </div>
</template>

<script>
import product from "./product";

export default {
  name: "product-selection",
  components: { product },
  props: {
    products: Array,
    apiUrl: String,
    successAction: Object,
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    maxSelectable: {
      required: false,
      type: [String, Number],
      default: -1
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    styles: {
      type: Object
    },
    selected:{
      type:[Array,Object],
      default:()=>[]
    },
    fieldId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      cssString: "",
      selectedItems: this.selected
    };
  },
  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      }
    }
  },
  methods: {
    clear () {
      this.selectedItems = [];
    },
    itemClick (product) {
      let maxSelectableItem = parseInt(this.maxSelectable);
      let isSelectedBefore = this.selectedItems.findIndex(selectedItem => {
        return selectedItem.id == product.id;
      });
      if (product.selectable) {
        if (isSelectedBefore != -1) {
          this.selectedItems.splice(isSelectedBefore, 1);
        } else {
          // This will be use if user selected before (NOT READY YET!)
          // this.selectedItems.forEach(selectedItem => {
          //   if(maxSelectableItem != -1 && selectedItem.selectable == false) {
          //     maxSelectableItem = maxSelectableItem + 1
          //   }
          // });
          if (
            maxSelectableItem != -1 &&
            this.selectedItems.length >= maxSelectableItem
          ) {
            return this.showModal(this.texts.maxSelectable);
          }
          this.selectedItems.push(product);
          this.$emit("component-updated", {
            selected: this.selectedItems
          });
        }
      }
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: !!this.selectedItems.length
      });
      // this.$emit("input", this.selectedItems);
      // console.log(this.selectedItems);
    },
    isSelected (product) {
      const isSelected = this.selectedItems.find(item => {
        return item.id == product.id;
      });
      return !!isSelected;
    },
    sendProducts () {
      // console.log(this.selectedItems.length,!this.selectedItems.length)
      if (!this.selectedItems.length) {
        // this.showModal("<div class='pb-5'>Bitte wählen Sie mindestens ein <p>produkt aus.</p></div>");
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false,
          message: `<div class='pb-5'>${this.texts.required}</div>`
        });
        return;
      }
      const apiUrl = this.apiUrl;
      const formData = {
        selectedProducts: this.selectedItems
      };
      this.$store.dispatch("sendProducts", { formData, apiUrl }).then(r => {
        if (r.response) {
          // console.log(this.successAction, this);
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
          return;
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false
        });
      });
      // console.log("inside send products", this.selectedItems);
    }
  },
  mounted () {
    /*
       add get products call to api and set the products to selectable or not.
    */
    this.$eventHub.$on("sendProducts-event", this.sendProducts);
  },
  beforeDestroy () {
    this.clear();
    this.$eventHub.$off("sendProducts-event", this.sendProducts);
  }
};
</script>

<style scoped>
.products-container {
  max-width: 800px;
}
</style>