/*eslint comma-dangle: [2, "always"]*/
export default {
  // General
  'Next': 'Nästa',
  'NEXT': 'NÄSTA',
  'SEND': 'SKICKA',
  'Back to the homepage': 'Tillbaka till hemsidan',
  'Back to the Braun website': 'Tillbaka till Braun:s webbplats',
  'Back': 'Tillbaka',
  'LOG IN': 'LOGGA IN',
  'SIGN UP': 'ANMÄLLA DIG', // Missing
  '(optional)': '(valfritt)', // Missing
  'Cookie Text': 'Cookiemedgivande',
  'or': 'eller',

  // Header
  'Skip to Main Content': 'Hoppa Till Huvudinnehållet',
  'Terms and Conditions': 'Bestämmelser och villkor',
  'FAQ': 'Vanliga Frågor', // Missing

  // Footer
  'Footer Support Text': 'Vår supportavdelning är tillgänglig fram till den 15.12.2025: <a href="mailto:contact@braun-moneyback.com" ><span><b>contact@braun-moneyback.com</b></span></a>',
  'terms': 'Bestämmelser och villkor',
  'Privacy': 'Integritetspolicy',
  'Preference': 'Min Data',
  'Impressum': 'Avtryck',
  'Sitemap': 'Sidan översikt',

  // Home Screen
  'START REFUND': 'Starta återbetalning',
  'Step 1': 'Steg 1',
  'Step 2': 'Steg 2',
  'Step 3': 'Steg 3',
  'Step 4': 'Steg 4',
  'Select your product': 'Välj din produkt',
  'Upload a receipt': 'Ladda upp ditt kvitto',
  'Register': 'Registrera',
  'Get your money back': 'Få tillbaka dina pengar',
  'bannerAltText': 'Helt nöjd eller få pengarna tillbaka!* Villkor och bestämmelser gäller',

  // Progress Bar

  'Select<br>product': 'Välj<br>produkt',
  'Upload<br>a receipt': 'Ladda upp<br>kvitto',
  'Get your<br>money back': 'Få tillbaka<br>dina pengar',

  // Product Select Page
  'Scan the product': 'Skanna produkten',
  'Type in or scan the barcode and serial number': 'Skriv in eller skanna streckkoden',
  'Please enter the serial number and barcode number of your Braun products, take a photo of your promotional product or upload it using <b>Drag & Drop</b>. Please make sure that the barcode information of your promotional product is clearly visible.': 'Ange streckkoden för din Braun produkt, ta ett foto av din reklamprodukt eller ladda upp den med hjälp av <b>dra & släpp</b>. Se till att informationen om streckkoden på din reklamprodukt syns tydligt.',
  'Upload': 'Ladda upp',
  'Upload<br>barcode image': 'Ladda upp<br>en streckkodsbild',
  'Drag & Drop': 'Dra och släpp',
  'Scan': 'Skanna',
  'Stop': 'Sluta', // missing
  'Scan<br>barcode image': 'Skanna<br>bilden av streckkoden',
  '(the easiest)': '(det enklaste)',
  'Add': 'Lägg til',
  'Add<br>barcode number': 'Lägg till streckkod',
  'Enter Barcode number': 'Ange Streckkodnummer',
  'Serial number': 'Serienummer',
  'The file format is not supported. It will only .jpeg, .png and .pdf file accepted.': 'Det här filformatet stöds inte. Endast .jpeg-, .png-, .pdf- och .tiff-filer accepteras.',
  'The file is too large. Only files up to 16 MB are supported.': 'Filen är för stor. Endast filer på upp till 16 MB stöds.',
  'Unfortunately no valid barcode can be recognized. Please try to enter the barcode in the field above.': 'Tyvärr kan ingen giltig streckkod kännas igen. Försök att skriva in streckkoden i fältet ovan.',
  'Please turn on your camera': 'Slå på kameran.',
  'Correct Barcode Number': 'Streckkodsnumret är giltigt',
  'Wrong Barcode Number': 'Streckkodnumret är ogiltigt',
  'You cannot enter more than one product.': 'Du kan inte ange mer än en produkt.',

  // Receipt Upload Page

  'Upload receipt': 'Ladda upp kvitto',
  'Upload a picture of your proof of purchase in this step.': 'Ladda upp en bild av ditt inköpsbevis i det här steget.',
  'Upload your receipt photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.': 'Ladda upp ditt kvittofoto med hjälp av dra och släpp. Endast JPEG, PDF och PNG stöds. Maximal filstorlek: 16 MB.',
  'Please photograph your proof of purchase!': 'Ta en bild av ditt inköpsbevis!',
  'Make sure that the following information is clearly visible:': 'Se till att följande information syns tydligt:',
  'Dealer / Market name': 'Återförsäljare / Marknad',
  'Promotional product and purchase price': 'Kampanjprodukt och inköpspris',
  'Total of the proof of purchase and VAT': 'Summan av inköpsbeviset och moms',
  'Date and time of purchase': 'Datum och tidpunkt för köpet',
  'Proof of purchase ID': 'Inköpsbevisets ID',
  'Upload your receipt photo': 'Ladda upp ditt kvittofoto',
  'Drag & Drop or click here': 'dra och släpp eller klicka här',
  'Choose a File': 'Välj en fil',
  'Choose Another': 'Välj en annan', // Missing
  'Crop': 'Beskär',
  'Erase': 'Radera ut',
  'Move': 'Flytta', // Missing
  'Zoom In': 'Zooma in',
  'Zoom Out': 'Zooma ut',
  'Please drag your receipt into the gray rectangle.': 'Vänligen dra ditt kvitto in i den grå rektangeln.',
  'You can erase private information with the razor tool.': 'Du kan radera privat information med razor-verktyg.',
  'If you get an error message, please take a better version of the receipt and upload it again.': 'Om du får ett felmeddelande kan du ta en bättre version av kvittot och ladda upp det igen.',
  'The receipt is processed automatically.This can take a minute.Please be patient.': 'Kvittot behandlas automatiskt.Detta kan ta en minut.Vänligen ha tålamod.',
  'Your receipt will now be uploaded and processed.': 'Ditt kvitto kommer nu att laddas upp och behandlas.',

  // Product upload page

  'Upload product photo': 'Ladda upp ett foto av produkten',
  'Please upload a photo of the product side on which the EAN barcode is clearly visible.': 'Vänligen ladda upp ett foto av produktsidan där EAN-streckkoden syns tydligt.',
  'Upload your product photo using Drag & Drop.Only JPEG, PDF and PNG are supported. Maximum file size: 16MB.': 'Ladda upp ditt produktfoto med hjälp av dra och släpp. Endast JPEG, PDF och PNG stöds. Maximal filstorlek: 16 MB.',
  'Upload your product photo': 'Ladda upp ditt produktfoto',

  // Banking page
  'What is IBAN and where do I find it?': 'Vad är IBAN och var hittar jag det?',
  "IBAN stands for International Bank Account Number. It's an internationally-agreed code made up of up to 34 letters and numbers that helps banks to process transfers around the world. You can usually find your IBAN number on the top right- hand side of your bank statement or in your online banking.If you can't locate your IBAN, you should be able to generate it online via your bank's internet banking service or by using an IBAN calculator tool.": `IBAN står för Internationellt Bankkontonummer.Det är en internationellt överenskommen kod som består av upp till 34 bokstäver och siffror och som hjälper bankerna att hantera överföringar över hela världen.
  <br>
  <br>
  Du kan vanligtvis hitta ditt IBAN- nummer uppe till höger på ditt kontoutdrag eller i din internetbank.Om du inte kan hitta ditt IBAN - nummer bör du kunna generera det online via din banks internetbank eller genom att använda ett IBAN - kalkylatorverktyg.`,
  'What is BIC and where do I find it?': 'Vad är BIC och var hittar jag det?',
  "BIC stands for Bank Identifier Code. To send or receive money, any financial institution must know where to send the money and BIC is exactly what shows it. You can usually find your bank's BIC in your bank account statements.If you're using online banking, log into your digital bank account to easily view your bank statement or contact your bank so that they provide you your BIC.": `BIC står för Bankidentifieringskod.För att skicka eller ta emot pengar måste alla finansinstitut veta vart pengarna ska skickas och BIC är exakt det som visar det.
  <br>
  <br>
  Du kan vanligtvis hitta din banks BIC i dina kontoutdrag.Om du använder internetbank kan du logga in på ditt digitala bankkonto för att enkelt se ditt kontoutdrag eller kontakta din bank så att de ger dig din BIC.`,
  'Enter bank details': 'Ange bankuppgifter',
  'Please enter the first and last name of the account holder exactly as they were given at the bank.': 'Vänligen ange kontoinnehavarens för- och efternamn exakt så som de angavs på banken.',
  'Avoid changing the umlauts: <b>Müller or Mueller</b>; Avoid adding or leaving out hyphens: <b>Marie Luise or Marie-Luise</b>; Only enter the middle name if the account is also managed with it.': 'Undvik att ändra umlauterna: <b>Müller or Mueller</b>; Undvik att lägga till eller utelämna bindestreck: <b>Marie Luise eller Marie-Luise</b>; Ange endast mellannamnet om kontot också förvaltas med det.',
  'Enter your IBAN': 'Ange ditt IBAN',
  'Enter your BIC': 'Ange ditt BIC',
  'Enter your first name': 'Ange ditt förnamn',
  'Enter your last name': 'Ange ditt efternamn',
  'Account holder first name': 'Kontoinnehavarens förnamn',
  'Account holder last name': 'Kontoinnehavarens efternamn',
  'If the data does not exactly match the account details, in most cases the bank will charge back and make the process of an uncomplicated and quick refund more difficult.': 'Om uppgifterna inte stämmer exakt med kontouppgifterna kommer banken i de flesta fall att debitera tillbaka och försvåra processen för en okomplicerad och snabb återbetalning.',

  // Address page

  'Your address': 'Din adress',
  'Streetname': 'Gatunamn',
  'House number': 'Nummer',
  'Postal code': 'Postnummer',
  'City': 'Stad',
  'Country': 'Land',
  'Enter your streetname': 'Ange ditt streetname',
  'Enter the number of your house': 'Ange nummeret på dit hus',
  'Enter your postal code': 'Ange ditt postnummer',
  'Enter a city you live': 'Ange en stad där du bor', // Missing
  'Choose a country': 'Välj ett land', // Missing

  // Login page

  'Log in with an Braun account': 'Logga in med ett Braun-konto',
  'Log in with<br>Braun account': 'Logga in med<br>Braun-konto',
  'Email address': 'E-postadress',
  'Enter your email address': 'Ange ditt e-postadress',
  'Password': 'Lösenord',
  'Enter your password': 'Ange ditt Lösenord',
  'Forgot password?': 'Glömt lösenord?',
  "Don't have<br>an account yet?": "Har du inget<br>Braun-konto ännu?",
  'Continue<br>as a guest': 'Fortsätt<br>som gäst',

  // Registration page

  'Registration form': 'Registreringsformulär',
  "Register now and don't miss a thing.": "Registrera dig nu så att du inte missar något.",
  'Braun informs you regularly about further "free test" campaigns and also offers exclusive free product tests every week.': 'Vi behöver följande information från dig för att kunna behandla betalningen. Dessa uppgifter används endast för att bearbeta kampanjen Braun pengarna-tillbaka.',
  'Personal information': 'Personlig information', // Missing
  'Title': 'Titel',
  'Mrs.': 'Fru',
  'Mr.': 'Herr',
  'First name': 'Förnamn',
  'Last name': 'Efternamn',
  'Date of birth': 'Födelsedatum',
  'dd/mm/yyyy': 'dd/mm/yyyy', // Missing
  'Account information': 'Kontoinformation', // Missing
  'E-mail': 'E-post',
  'Enter your email': 'Ange ditt e-post',
  'Create a strong password': 'Skapa ett starkt lösenord', // Missing
  'I confirm that I am over 18 years old. I confirm the terms and conditions, privacy policy & Justsnap privacy policy.': 'Jag bekräftar att jag har fyllt 18 år. Jag bekräftar <a href="https://termsandconditions.pg.com/se-se/" target="_blank"><u>villkoren</u></a>, <a href="https://privacypolicy.pg.com/sv-SE" target="_blank"><u>sekretesspolicyn</u></a> och <a href="https://justsnap.de/Datenschutz/Data_Policy_DE.pdf" target="_blank"><u>Justsnaps sekretesspolicy</u></a>.',
  'I have read the Conditions of participation for this promotion and accept them.': 'Jag har läst <a href="https://braun-moneyback.com/se/terms" target="_blank"><u>villkoren</u></a> för deltagande i denna kampanj och accepterar dem.',
  'Always up-to-date with our newsletters. I want to receive emails, offers and coupons from Oral-B - the community for power women and super moms - and other trusted P&G brands.': `<strong>Håll kontakten med oss!</strong> Jag samtycker till att ta emot personlig kommunikation angående erbjudanden, nyheter och andra marknadsföringsinitiativ från Braun och andra <a href='https://us.pg.com/brands/' target='_blank'><strong><u>P&G-varumärken</u></strong></a> via e-post och onlinekanaler. Jag kan <a href='https://preferencecenter.pg.com/sv-se/datarequests/' target='_blank'><strong><u>avregistrera</u></strong></a> mig  när som helst.`,
  'Registration Notice': `<div style="font-size: 16px; font-family: 'Helvetica'; padding-left: 25px;">Procter & Gamble kommer som personuppgiftsansvarigatt behandla dina uppgifter så att du kan registrera dig på denna webbplats, interagera med dess tjänster och, beroende på ditt samtycke, skicka relevant kommersiell kommunikation till dig, inklusive personliga annonser i onlinemedia.
    Ta reda på <p class='modalOpener' data-text="<div>*Ta reda på mer
    <br>Relevant reklaminformation och anpassade onlineannonser
    <br>Vår målsättning är att göra din resa med oss och med P&G:s familj av varumärken intressant, trevlig och personlig. Vi kommer att göra detta genom att försöka att designa vår kommersiella kommunikation kring det som du tycker om bäst och undvika att skicka förslag som inte faller dig i smaken eller att visa annonser som du redan har sett åtskilliga gånger. Hur uppnår vi detta mål?
    <ul>
      <br><li>Genom att kombinera data för att bättre anpassa vår kommunikation efter dina intressen. Baserat på vårt berättigade intresse kan vi utveckla dina registreringsuppgifter som du förser oss med när du interagerar med våra tjänster (varumärkespreferenser, klippta kuponger etc.) med attribut, intressen, demografiska uppgifter som erhållits från kommersiellt tillgängliga källor eller andra tredje parter. På liknande sätt, om du har accepterat våra riktade reklamcookies eller liknande P&G tekniker eller, i vissa fall tredje parts webbsajts eller appar, kan vi komplettera den information som erhålles genom våra målinriktade reklamcookies med annan information som du förser oss med när du utformar ett konto eller interagerar med våra tjänster.</li>
    </ul>
    <br>Vi kommer att behandla dessa uppgifter, inklusive dem som vi erhåller på automatiserade sätt, för att personifiera vårt innehåll och våra erbjudanden via e-post, online (t.ex. från tredje parts plattformar inklusive sociala medieplattformar) och offline (t.ex. via papperspost) om du har gett oss ditt samtycke till att ta emot kommersiell kommunikation från oss.
    <ul>
      <br><li>”Look-alike”-reklam. Baserat på vårt berättigade intresse kommer vi också att analysera dina personuppgifter för att identifiera nya kontakter, som har en profil som liknar din och som kan vara intresserade av att höra från oss. Exempelvis kan vi och våra dotterbolag ladda upp dina hashade e-postadress till Facebook och be Facebook att visa våra annonser till personer som har intressen som liknar dina, inklusive i andra länder, baserat på data som finns om dig och om andra människor.</li>
      <br><li>Användning av dina personuppgifter för att nå ut till dig i onlinemedia. Om du har samtyckt till att erhålla kommersiell kommunikation från oss, kommer vi att använda oss av dina hashade uppgifter (dvs. din e-postadress, ditt telefonnummer eller ditt mobila reklam-ID) för att skicka reklam till dig via onlinekanaler (t.ex. via ditt sociala mediekonto). Vi gör detta genom en process som respekterar din integritet med hjälp av krypterade uppgifter och noggrant utvalda partners. Speciellt kommer vi att dela dina personuppgifter med tredje part som tillhandahåller skräddarsydda reklamtjänster till oss inklusive sociala medieplattformar. Vi använder sådana tjänster för att matcha hashad information som vi har med personuppgifter i deras databas för att utforma anpassade målgrupper och skräddarsy reklam som passar dina intressen på Internet, inklusive sociala medier, i enlighet med tillämplig lag.</li>
    </ul>
    <br>Detta kommer att hjälpa oss att ge dig mer relevanta annonser online (erbjudanden, produktinformation och information om innovationer) samt att betjäna dig med färre annonser, eftersom vi vet att vi redan har tillställt dig en viss annons och därför kan utesluta dig från vidare kommunikation rörande samma ämne. De flesta av våra konsumenter uppskattar att vi inte vill visa dem annonser som de inte bryr sig om eller som vi har visat dem många gånger tidigare, men om du inte gör detta, är detta inte något problem eftersom du alltid kan ändra dina preferenser.
    <br>Du kan alltid ändra dig och återkalla ditt samtycke/välja bort användningen av dina uppgifter för personlig kommunikation och reklam inklusive utformningen av anpassade målgrupper och målgrupper som ser likadana ut <a href='https://preferencecenter.pg.com/sv-se/datarequests/' target='_blank'><strong><u>här</u></strong></a>. Läs vår Sekretesspolicy</div>"
    >mer*</p>.
    För mer information om behandlingen av dina personuppgifter och dina integritetsrättigheter, läs <p class='modalOpener' data-text="**Vi värdesätter din integritet
    <div>
      <ul>
        <br><li>Vår Sekretesspolicy styr vår insamling och användning av dina personuppgifter.</li>
        <br><li>Det är <a href='https://privacypolicy.pg.com/sv-SE/' target='_blank'><strong><u>därför</u></strong></a> som vi samlar in dina personuppgifter, som utgör vår legala grund för behandlingen och tiden under vilken dina personuppgifter kommer att förvaras.</li>
        <br><li>Våra e-postmeddelanden innehåller pixlar som hjälper P&G att förstå om du integrerar med dem och vilket innehåll som var intressant för dig. Läs mer <a href='https://privacypolicy.pg.com/sv-SE/' target='_blank'><strong><u>här</u></strong></a>. Du kan när som helst avregistrera <a href='https://preferencecenter.pg.com/sv-se/datarequests/' target='_blank'><strong><u>dig</u></strong></a> från våra program som berör e-postkommunikation.</li>
        <br><li>Dina personuppgifter kommer att behandlas i ett annat land än det där de samlades in, däribland USA. Läs mera <a href='https://privacypolicy.pg.com/sv-SE/' target='_blank'><strong><u>här</u></strong></a>.</li>
        <br><li>Du kan utöva dina rättigheter som registrerad (du få åtkomst till, korrigera, radera, överföra till en annan leverantör, invända mot eller begränsa vår behandling av de personuppgifter vi har om dig, eller dra tillbaka ditt samtycke) eller ändra dina preferenser och prenumerationer genom att klicka <a href='https://preferencecenter.pg.com/sv-se/datarequests/' target='_blank'><strong><u>här</u></strong></a></li>
      </ul>
    </div>">här**</p>
    eller ta del av vår fullständiga <a href='https://privacypolicy.pg.com/sv-SE/' target='_blank'><strong><u>sekretesspolicy</u></strong></a>. Du är minst 18 år gammal och accepterar <a href='https://termsandconditions.pg.com/se-se/' target='_blank'><strong><u>våra villkor</u></strong></a>.</div>`,
  'Why do we collect this data?': `<div>
                                Du registrerar dig som användare av Braun, för vilken <a href="https://privacypolicy.pg.com/sv-SE#entities" target="_blank"><u>Procter & Gamble</u></a> är registeransvarig. Genom att registrera dig bekräftar du följande:
                                <br>
                                <br>
                                <b>Medgivande för marknadsföring via e-post.</b> Du vill ta emot e-postmeddelanden, erbjudanden och kuponger från Braun och andra betrodda <a target='_blank' href='https://www.pg.com/brands'><u>P&G-varumärken</u></a>.
                                <br>
                                <br>
                                <b>Dina val.</b> Du kan utöva dina <span class="modalOpener" data-text="Som förklarats i vår sekretesspolicy får du skaffa åtkomst till, korrigera, radera, överföra till annan leverantör, invända mot eller begränsa vår hantering av de personuppgifter vi har om dig eller dra tillbaka ditt samtycke.">dataskyddsrättigheter</span> eller ändra dina inställningar och prenumerationer genom att klicka <a target='_blank' href='https://privacypolicy.pg.com/sv-SE#preferences'><u>här</u></a>.
                                <br>
                                <br>
                                <b>Användningsvillkor och Integritetspolicy.</b> Du har fyllt 18 år och har läst igenom och godkänt P&G:s <a target='_blank' href='https://termsandconditions.pg.com/se-se/'><u>Användningsvillkor</u></a> och <a target='_blank' href='https://privacypolicy.pg.com/sv-SE'><u>Integritetspolicy</u></a>. För att bättre kunna anpassa vår kommunikation till dig efter dina intressen kommer vi att <span class="modalOpener" data-text="Vi samlar in och kan kombinera information vi får från dig när du ringer till oss eller besöker våra webbplatser eller appar, när du använder våra enheter, när du tittar på våra annonser online eller när vi erhåller den från kommersiellt tillgängliga källor.">kombinera de uppgifter</span> du tillhandahåller ovan med andra data som vi kan ha samlat in om dig från olika källor och tredje parter.
                              </div>`,

  // Guest registration page

  'Continue as a guest': 'Fortsätt som gäst',
  'and get your money back': 'och få pengarna tillbaka',
  'This campaign is recommended by Braun - the online community with great product tests and offers.': 'Den här kampanjen rekommenderas av Braun - online community med bra produkttester och erbjudanden.',

  // Forgot Password page

  'Please fill in your email address, and we will send you a reset link.': 'Fyll i din e-postadress så skickar vi dig en länk för återställning av lösenordet.', // missing

  // Reset password page
  'Choose a new password': 'Välj ett nytt lösenord',
  'New password': 'Nytt lösenord',
  'Enter your new password': 'Ange ditt nytt lösenord',
  'Repeat new password': 'Bekräfta lösenordet',
  'Enter your new password again': 'Ange ditt nytt lösenord på nytt',

  // Receipt reupload page
  'Re-Upload receipt': 'Ladda upp ditt kvitto på nytt',

  // Product photo reupload page
  'Re-Upload product photo': 'Ladda upp produktfoto på nytt ',

  // Iban thank you page

  'Thank you': 'Tack',
  'Thank you for resubmitting your bank details.': 'Tack för att du skickade in dina bankuppgifter på nytt.',
  'So nothing should stand in the way of a successful transfer.': 'Inget ska alltså stå i vägen för en lyckad överföring.',
  "If you have any questions related to your bank details, please contact <a href='mailto:support@braun-moneyback.com'><b>support@braun-moneyback.com</b></a>.": "Om du har några frågor som rör dina bankuppgifter kan du kontakta <a href='mailto:support@braun-moneyback.com'><b>support@braun-moneyback.com</b></a>.",
  "For other concerns, you can contact our support service at <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>.": "För andra frågor kan du kontakta vår supporttjänst på <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>.", // mail inconsistency

  // Expired page

  'Unfortunately the link is no longer valid!': 'Tyvärr är länken inte längre giltig!',
  "Please participate again or contact our support team: <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>": " Vänligen delta igen eller kontakta vår support: <a href='mailto:contact@braun-moneyback.com'><b>contact@braun-moneyback.com</b></a>",

  // Reclaim page
  'Ready': 'Redo',
  'Thank you for the message.': 'Tack för meddelandet.',
  'We will check the reimbursement amount again and then inform you. The verification may take some time, so we ask for your patience': 'Vi kommer att kontrollera återbetalningsbeloppet igen och sedan informera dig. Kontrollen kan ta en viss tid, så vi ber om ditt tålamod.',

  // Reset password success page

  'Your password has been reset and changed.': 'Ditt lösenord har återställts och ändrats.',
  'You can now log in to the campaign website again. ': 'Du kan nu logga in på kampanjens webbplats igen.',
  'To do this, visit the desired campaign website.': 'För att göra detta, besök den önskade kampanjwebbplatsen.',

  // Campaign is over page

  'The campaign period is over': 'Kampanjperioden är över',
  'Thank you for your interest in the campaign, unfortunately the campaign period is over.': 'Tack för ditt intresse för kampanjen, men tyvärr är kampanjperioden över.',

  // DOI page

  'Thank you for your registration': 'Tack för din anmälan',
  'You have successfully registered for our newsletter.': 'Du har registrerat dig för vårt nyhetsbrev',
  'From now on you will be one of the first to find out as soon as there are free product tests, competitions and inspiring articles.': 'Från och med nu kommer du att vara en av de första att få reda på så snart det finns gratis produkttester, tävlingar och inspirerande artiklar.',

  // Thank you page

  'Thank you for your participation': 'Tack för ditt deltagande',
  'Your receipt and your data have been successfully sent to us and are now being checked.': 'Ditt kvitto och dina uppgifter har skickats till oss och kontrolleras nu.',
  'Reference number: ': 'Referensnummer: ',
  'As soon as we have checked your data, you will either receive a confirmation e-mail or an e-mail with queries if your data was incomplete or your proof of purchase could not be recorded. This can take up to 36 hours. We ask for your patience.': 'Så snart vi har kontrollerat dina uppgifter får du antingen ett bekräftelsemeddelande eller ett e-postmeddelande med frågor om dina uppgifter var ofullständiga eller om ditt inköpsbevis inte kunde registreras. Detta kan ta upp till 36 timmar. Vi ber om ditt tålamod.',
  'After successfully checking the proof of purchase and all other data as well as succesfully receiving the product you would like to return, we will transfer the reimbursement amount to the account you specified within 6 weeks. If anything is unclear, we will contact you again by email.': 'Efter att vi framgångsrikt kontrollerat köpebeviset och alla andra uppgifter samt efter att vi framgångsrikt mottagit den produkt du vill returnera, överför vi återbetalningsbeloppet till det konto du angett inom 6 veckor. Om något är oklart kontaktar vi dig igen via e-post.',
  'Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation. ': 'Glöm inte att om du vill returnera din produkt måste du skicka tillbaka produkten till adressen för vårt servicecenter inom 10 dagar efter din bekräftelse av ditt deltagande.',
  'Copied to clipboard': 'Kopieras till urklipp',

  // SMS page

  'Please confirm your identity': 'Vänligen bekräfta din identitet',
  'Please enter your mobile number in the field below. We will then send you a code to confirm your participation via SMS, which you can then enter on the next page.': 'Ange ditt mobilnummer i fältet nedan. Vi kommer sedan att skicka dig en kod för att bekräfta ditt deltagande via SMS, som du sedan kan ange på nästa sida.',
  'In order to check your identity, we need your mobile phone number. The specification of the mobile number is required to verify your participation. The phone number you provide will only be used for one-time, advertising-free verification and will be deleted by us after the campaign.': 'För att kunna kontrollera din identitet behöver vi ditt mobilnummer. Specificeringen av mobilnumret krävs för att verifiera ditt deltagande. Telefonnumret som du anger kommer endast att användas för en engångsverifiering utan reklam och kommer att raderas av oss efter kampanjen.',
  'Phone number': 'Mobilnummer',
  'Confirmation code': 'Bekräftelsekod',
  'Enter your phone number': 'Ange ditt mobilnummer',
  'Please enter the confirmation code that we sent you via SMS into the field.': 'Vänligen ange den bekräftelsekod som vi skickade dig via SMS i fältet.',
  'Change mobile number': 'Ändra mobilnummer',

  // 404 page

  'Oops!!': 'Oops!!',
  'Something went wrong!': 'Något gick fel!',

  // Survey page
  'What were the main reasons for you to buy this product?': 'Vilka var de viktigaste orsakerna till att du köpte den här produkten?',
  'Please select as applicable; You have a multiple choice.': 'Välj det som är tillämpligt; Du har flera valmöjligheter.',
  'You have a multiple choice.': 'Du har flera valmöjligheter.',
  'Easy to use': 'Lätt att använda',
  'Design': 'Design',
  'Price / good deal': 'Pris / bra erbjudande',
  'Performance': 'Prestanda',
  'Other': 'Annat',
  'Why would you like to return the product?': 'Varför vill du returnera produkten?',
  "It doesn't work as expected": 'Den fungerar inte som förväntat',
  'Wrong item size or wrong product': 'Fel storlek eller fel produkt',
  'Changed mind after Product Purchased': 'Har ändrat sig efter att produkten köpts',
  "Product didn't match the website or catalog Description": 'Produkten motsvarade inte beskrivningen på webbplatsen eller i katalogen.',
  'Found better product price elsewhere': 'Hittade ett bättre produktpris någon annanstans',
  'Product did not meet my expectations': 'Produkten motsvarade inte mina förväntningar',
  'Enter optional': 'Ange valfritt',

  // Return form page
  'Product name': 'Produktnamn',
  'Product EAN Number': 'Produkt EAN- nummer',
  'Product Serial Number': 'Produktens serienummer',
  'Product Price for Money-back': 'Produktpris för pengarna - tillbaka',
  'Date of purchase': 'Inköpsdatum',
  'Time of purchase': 'Inköpstid',
  'Retailer Name': 'Återförsäljarens namn',
  'Receipt/Invoice ID': 'Kvitto - /faktura-ID',
  'Reference number of the participation': 'Referensnummer för deltagandet',
  'Enter product name': ' Ange produktnamn',
  'Enter Product EAN number': 'Ange produktens EAN- nummer',
  'Enter product serial number': 'Ange produktens serienummer',
  'Enter product price for Money-back': 'Ange produktens pris för pengarna tillbaka',
  'Enter Date of purchase': 'Ange datum för inköp',
  'Enter Time of purchase': 'Ange tidpunkt för köpet',
  'Enter retailer name': 'Ange återförsäljarens namn',
  'Enter Receipt/Invoice ID': 'Ange kvitto / fakturanummer',
  'Enter Reference number of the participation': 'Ange referensnummer för deltagandet',

  // Legal Docs

  'website-link': 'https://se.braun.com/en',
  'home-link': '/se',
  'terms-link': 'https://termsandconditions.pg.com/se-se/',
  'privacy-link': 'https://privacypolicy.pg.com/sv-SE',
  'preference-link': 'https://preferencecenter.pg.com/sv-se/datarequests/',
  'sitemap-link': 'https://se.braun.com/en/sitemap',
  'impressum-link': '#',
  'Return form': 'Returformulär',
  'Print': 'Skriva ut',
  'Please do not forget, if you want to return your product you have to send the product back to our Service Center address within 10 days after your confirmation of your participation.': 'Glöm inte att om du vill returnera din produkt måste du skicka tillbaka produkten till adressen för vårt servicecenter inom 10 dagar efter din bekräftelse av ditt deltagande.',
  'Denmark': 'Danmark',
  'Norway': 'Norge',
  'Finland': 'Finland',
  'Sweden': 'Sverige',
  // Validation
  'This file format is not supported. Only .jpeg, .png, .pdf and .tiff files are accepted.': 'Det här filformatet stöds inte. Endast .jpeg-, .png-, .pdf- och .tiff-filer accepteras.',
  'There is currently a problem with our website. Please try again in a few minutes.': 'Det finns för närvarande ett problem med vår webbplats. Försök igen om några minuter.',
  'The file is too big. Only files up to 16 MB are supported.': 'Filen är för stor. Endast filer på upp till 16 MB stöds.',
  'Oops. An error has occurred. Please try again later.': 'Oops. Ett fel har inträffat. Försök igen senare.',
  validation: {
    housenumber: 'Husnumret får endast innehålla bokstäver, mellanslag, snedstreck och bindestreck.',
    mandatory: 'Det här fältet är obligatoriskt.',
    required: "Fältet {field} är obligatoriskt",
    alpha: '{item} * kan bare inneholde alfabetiske tegn.',
    iban: 'IBAN - Använd ett giltigt IBAN-nummer för de deltagande länderna.',
    postal: 'Postnumret måste bestå av {digit} siffror.',
    bic: 'BIC måste vara i rätt format.',
    mobileNumber: 'Mobiltelefonnumret måste innehålla minst {digit} siffror.',
    email: 'E-postadressen måste vara i rätt format.',
    customRequired: {
      'Mobile number': 'Mobilnummer är ett obligatoriskt fält',
      code: 'Verifieringskoden är ett obligatoriskt fält',
    },
  },
  'Please hide your personal data (name, surname, phone nr., address, etc.) on the receipt/invoice if you will have it before sending your package to Service Center.': 'Vänligen dölj dina personuppgifter (namn, efternamn, telefonnummer, adress etc.) på kvittot/fakturan om du har det innan du skickar ditt paket till Servicecenter.',
  socialMedia: {
    fb: 'https://www.facebook.com/braun',
    yt: 'https://www.youtube.com/braun',
  },
}
