<template>
  <a
    v-if="this.action"
    :class="cssClass"
    @click="handleClick"
    :target="target"
    :style="styles"
    v-html="$t(text)"
  ></a>
  <a v-else :class="cssClass" :href="url" :target="target" :style="styles" v-html="$t(text)"></a>
</template>

<script>
export default {
  name: "anchor",
  props: {
    text: {
      type: String,
      default: "Empty Link Text"
    },
    target: {
      required: false,
      type: String,
      default: "_self"
    },
    url: {
      required: false,
      type: String,
      default: "#"
    },
    cssClass: {
      required: false,
      type: String,
      default: ""
    },
    styles: {
      required: false,
      type: Object
    },
    action: {
      type: [Object, Array]
    }
  },
  methods: {
    handleClick () {
      if (this.action.length > 0) {
        // console.log("inside actions loop::");
        for (let i = 0; i < this.action.length; i++) {
          const act = this.action[i];
          const fn = act.fn;
          const params = act.params;
          this[fn](params, this);
        }
        return;
      }
      const fn = this.action.fn;
      const params = this.action.params;
      this[fn](params, this);
    }
  }
};
</script>

<style lang="scss" scoped>

.loginSubText {
  text-align: center;
  text-decoration: underline;
  font: normal normal 500 20px/24px Montserrat, Medium;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.headingLink {
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #fff !important;
  opacity: 1;

  @media (max-width: 991px) {
    font-size: 12px;
    line-height: 25px;
  }
}

.forgotPassword {
  font: normal normal 700 12px/15px 'Plus Jakarta Sans';
  letter-spacing: 0px;
  color: var(--text-color-3);
  opacity: 1;
}

</style>
