<template>
  <div class="steps">
    <div class="step">
      <div class="image-wrapper">
        <img class="step-bg" :src="`assets/${stepBg}`" alt="icon">
        <img class="step-image" :src="`assets/${step1icon}`" alt="icon">
      </div>
      <div class="step-title" v-text="step1text"/>
    </div>
    <img class="step-arrow" :src="`assets/${stepArrow}`" alt="icon">
    <div class="step">
      <div class="image-wrapper">
        <img class="step-bg" :src="`assets/${stepBg}`" alt="icon">
        <img class="step-image" :src="`assets/${step2icon}`" alt="icon">
      </div>
      <div class="step-title" v-text="step2text"/>
    </div>
    <img class="step-arrow" :src="`assets/${stepArrow}`" alt="icon">
    <div class="step">
      <div class="image-wrapper">
        <img class="step-bg" :src="`assets/${stepBg}`" alt="icon">
        <img class="step-image" :src="`assets/${step3icon}`" alt="icon">
      </div>
      <div class="step-title" v-text="step3text"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "steps-horizontal",
  props: {
    step1text: {
      type: String,
      default: 'Aktionsprodukt kaufen'
    },
    step1icon: {
      type: String,
      default: 'step1.svg'
    },
    step2text: {
      type: String,
      default: 'Kassenbeleg hochladen'
    },
    step2icon: {
      type: String,
      default: 'step2.svg'
    },
    step3text: {
      type: String,
      default: 'Geld zurück erhalten'
    },
    step3icon: {
      type: String,
      default: 'step3.svg'
    },
    stepBg: {
      type: String,
      default: 'stepBg.svg'
    },
    stepArrow: {
      type: String,
      default: 'stepArrow.svg'
    }
  }
}
</script>

<style lang="scss" scoped>
  .steps {
    position: absolute;
    justify-content: space-between;
    z-index: 1;
    display: flex;
    width: 100%;
    padding: 0 17.3%;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 1100px) {
      top: 28%;
    }
  }
  .step {
    width: 100%;
    max-width: 200px;
    position: relative;
  }
  .image-wrapper {
    width: 100%;
    position: relative;
  }
  .step-bg {
    width: 100%;
  }
  .step-image {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40%;
    transform: translate(-50%, -50%);
  }
  .step-title {
    font-family: 'Plus Jakarta Sans', "sans-serif";
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 20px;
    margin-top: 30px;
    text-align: center;
  }

  .step-arrow {
    display: flex;
    margin: -30px 60px 0;
  }

  @media (max-width: 1025px) {
    .steps {
      max-width: none;
      padding: 0 13.67%;
    }
    .step {
      width: 20%;
    }
    .step-arrow {
      width: 3.6%;
      margin: -50px 4.6% 0;
    }
    .step-image {
      width: 35%;
    }
  }

  @media (max-width: 767px) {
    .steps {
      top: 15%;
      padding: 0 10%;
    }
    .step {
      width: 22.7%;
    }
    .step-arrow {
      margin: -30px 0 0;
      width: 6%;
    }
    .step-image {
      width: 30%;
    }
    .step-title {
      margin-top: 10px;
      line-height: 10px;
      font-size: 10px;
    }
  }

  @media (max-width: 500px) {
    .step-arrow {
      width: 7%;
    }
  }
</style>
