<template>
    <button
        class="scroll-down-button"
        :class="className"
        :style="styles"
        @click="scrollTo(scrollToId, direction)"
        >
        <div class="icon-wrapper" :style="iconWrapperStyles">
            {{imageSource ? '' : '^'}}
            <img v-if="imageSource" :src="imageSource" alt="scrollArrow">
        </div>
    </button>
</template>
<script>
export default {
  name: "scroll-button",
  props: {
    styles: Object,
    iconWrapperStyles: Object,
    className: String,
    direction: {
      type: String,
      default: 'end'
    },
    scrollToId: {
      type: String,
      required: true
    },
    imageSource: String
  }
}
</script>
<style lang="scss">
.scroll-down-button {
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    line-height: 100%;
    border: none;
    height: 100%;
}
.scroll-down-button:focus {
    outline: none;
}
.icon-wrapper {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    img {
        height: 100%;
    }
}
</style>