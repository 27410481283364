<template>
  <div id="loader" class="loader-wrapper">
    <div class="scanner">
      <div class="invoice"></div>
      <div class="scanner-bar"></div>
    </div>
    <div class="text">
      <template
        v-for="(phrase , idx) in phrases"
      >
        <p :class="`desc_${idx+1}`" :id="`receiptPhrase${idx+1}`" v-bind:key="idx">{{$t(phrase)}}</p>
      </template>
      <!-- <p class="desc_1" id="receiptPhrase1">{{receiptPhrase1}}</p>
      <p class="desc_2" id="receiptPhrase2">{{receiptPhrase2}}</p> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'receipt-scan-loader',
  props: {
    phrases: {
      type: Array,
      default: () => {
        return [
          'Do I really need conditioner?',
          'What is the ideal water temperature?'
        ]
      }
    }
  },
  data () {
    return {
      // receiptPhrase1: this.phrases[0],
      // receiptPhrase2: this.phrases[1],
      phraseElements: [],
      counter: 2,
      repeatPhraseFunction: function (event) {
        this[event.target.id] = this.phrases[this.counter]
        if (this.counter === this.phrases.length - 1) {
          this.counter = 0
        } else {
          this.counter++
        }
      }
    }
  },
  mounted () {
    // this.phraseElements[0] = document.getElementById('receiptPhrase1');
    // this.phraseElements[1] = document.getElementById('receiptPhrase2');
    this.phrases.forEach((item, idx) => {
      this.phraseElements.push(document.getElementById(`receiptPhrase${idx+1}`))
    })
    if (this.phrases.length > 2) {
      this.phraseElements.forEach((element) => {
        element.addEventListener("webkitAnimationIteration", this.repeatPhraseFunction.bind(this));
        element.addEventListener("animationiteration", this.repeatPhraseFunction.bind(this));
      })
    }
  },
  beforeDestroy () {
    if (this.phrases.length > 2) {
      this.phraseElements.forEach((element) => {
        element.removeEventListener("webkitAnimationIteration", this.repeatPhraseFunction.bind(this));
        element.removeEventListener("animationiteration", this.repeatPhraseFunction.bind(this));
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .loader-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    background-color: rgb(255, 255, 255);
  }

  .scanner {
    width: 100%;
    max-width: 300px;
    height: 100px;
    position: relative;
    padding: 10px 5px;
    margin: 0 auto;
    box-shadow: inset 0 0 10px white;
    top: calc(50% - 100px);
  }

  .invoice {
    width: 100%;
    height: 100%;
    background-image: url('data:image/svg+xml;utf8,<svg width="152" height="41" viewBox="0 0 152 41" xmlns="http://www.w3.org/2000/svg"><g fill="none" fill-rule="evenodd"><path fill="#CECECE" d="M0 2.073v37.983h152V.506H0z"/><path fill="#FFFFFF" d="M152 39.127H.024v-8.195L0 20.857v-10.15l.024-9.136H152z"/><path fill="#CECECE" d="M146.106 8v20.258H147V8zm-3 0v20.258H144V8zm-4 0v20.258H140V8zm7 21v3.859H147V29zm-3 0v3.859H144V29zm-4 0v3.859H140V29zm-84.212-9v13.505H54V20zm43 0v13.505H97V20zm-46 0v13.505H51V20zm43 0v13.505H94V20zm-78-12v20.258H16V8zm45 0v20.258H61V8zm43 0v20.258H104V8zm-84 0v20.258H20V8zm4 4v11.576H24V12zm45 0v11.576H69V12zm43 0v11.576H112V12zm-88 17v3.859H24V29zm45 0v3.859H69V29zm43 0v3.859H112V29zm-96 0v3.859H16V29zm45 0v3.859H61V29zm43 0v3.859H104V29zm-84 0v3.859H20V29zm11-21v20.258H31V8zm46 0v20.258H77V8zm43 0v20.258H120V8zm-85 0v20.258H35V8zm45 0v20.258H80V8zm43 0v20.258H123V8zm-84 0v20.258H39V8zm45 0v20.258H84V8zm43 0v20.258H127V8zm-84 6v8.682H43V14zm-12 15v3.859H31V29zm46 0v3.859H77V29zm43 0v3.859H120V29zm-85 0v3.859H35V29zm45 0v3.859H80V29zm43 0v3.859H123V29zm-84 0v3.859H39V29zm45 0v3.859H84V29zm43 0v3.859H127V29zm-122-21v20.258H5V8zm3 0v20.258H8V8zm4 0v20.258H12V8zm-7 21v3.859H5V29zm3 0v3.859H8V29zm4 0v3.859H12V29z"/></g></svg>');
    background-size: cover;
    overflow: hidden;
    animation-name: move;
    animation-duration: 6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .scanner-bar {
    position: absolute;
    width: 100%;
    height: 5px;
    top: 100%;
    left: 0;
    background: #D62DA7;
    border-radius: 5px;
    padding-left: -5px;
    padding-right: -5px;
    box-shadow: 0 0 10px #D62DA7;
    animation-name: move-bar;
    animation-duration: 6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  .text {
    top: calc(60%);
    position: relative;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    height: 80px;
    @media (max-width: 500px) {
      height: 50px;
    }
  }

  .text p {
    position: absolute;
    bottom: 0;
    opacity: 0;
    transform: translate(-50%, -50%);
    left: 50%;
  }

  .desc_1 {
    animation-name: move-desc-1;
    animation-duration: 6s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }


  @keyframes move {
    0% {
      background-position-x: 230px;
    }
    45% {
      background-position-x: 82px;
    }
    50% {
      background-position-x: 82px;
    }
    95% {
      background-position-x: -66px;
    }
    100% {
      background-position-x: -66px;
    }
  }

  @keyframes move-bar {
    0% {
      top: 100%;
    }
    45% {
      top: 0;
    }
    50% {
      top: 0;
    }
    95% {
      top: 100%;
    }
    100% {
      top: 100%;
    }
  }

  @keyframes move-desc-1 {
    0% {
      opacity: 0;
      bottom: 0;
    }
    15% {
      opacity: 1;
      bottom: 100%;
    }
    50% {
      opacity: 1;
      bottom: 100%;
    }
    65% {
      opacity: 0;
      bottom: 0;
    }
    100% {
      opacity: 0;
      bottom: 0;
    }
  }
</style>
