<template>
  <div class="footerWrapper">
    <div class="footerBottom">
      <div class="container">

        <div class="row link-wrapper justify-content-between">
          <div class="col-4 col-md-2">
            <a :href="$t('website-link')" target="_blank">
              <img src="/assets/logo/braun_logo_footer.svg" alt="Braun logo" role="link" style="max-width: 100%;max-height: 50px">
            </a>
          </div>
          <div class="socialMediaWrapper col-6 text-right">
            <a v-if="$te('socialMedia.fb')" :href="$t('socialMedia.fb')" target="_blank" rel="noopener noreferrer" >
              <img src="/assets/svg/fb.svg" alt="Facebook">
            </a>
            <a v-if="$te('socialMedia.yt')" :href="$t('socialMedia.yt')" target="_blank" rel="noopener noreferrer">
              <img src="/assets/svg/yt.svg" alt="Youtube">
            </a>
            <a v-if="$te('socialMedia.ig')" :href="$t('socialMedia.ig')" target="_blank" rel="noopener noreferrer">
              <img src="/assets/svg/ig.svg" alt="Instagram">
            </a>
          </div>
        </div>
        <p class="pl-3 pt-3 underLogo" v-html="$t('Footer Support Text')" />
      </div>

      <div class="container">
        <ul class="pl-3 links bottom-links">
          <li v-if="$te('terms-link')">
            <a :href="$t('terms-link')" target="_blank">
              {{$t('terms')}}
            </a>
          </li>
          <li v-if="$te('privacy-link')">
            <a :href="$t('privacy-link')" target="_blank">
              {{$t('Privacy')}}
            </a>
          </li>
          <li v-if="$te('preference-link')">
            <a :href="$t('preference-link')" target="_blank">
              {{$t('Preference')}}
            </a>
          </li>
          <li v-if="$te('sitemap-link')">
            <a :href="$t('sitemap-link')" target="_blank">
              {{$t('Sitemap')}}
            </a>
          </li>
          <li class="copyright">
            <b>© 2024 Procter & Gamble</b>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer-component",
  methods: {
  }
};
</script>

<style lang="scss" scoped>

  $tablet : 991px;

  .footerWrapper {
    position: relative;
  }
  .footer-links {
    display: flex;
    position: absolute;
    top: 20px;
    right: 29vw;

    @media (max-width: $tablet) {
      top: 25px;
      right: 30px;
    }

    a {
      width: 30px;
      height: 30px;
      display: flex;
      margin-left: 10px;
      img {
        width: 100%;
      }
    }
  }
  .footer-social {
    display: flex;
    background: #e1e7ef;
    align-items: center;
  }
  .footerBottom {
    position: relative;
    color: #3D3D41;
    padding-top: 15px;
    border-top: 1px solid #ccc;
    background-color: var(--footer);

    @media (max-width: $tablet) {
      padding-top: 10px;
    }
  }
  .footer-title {
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;

    @media (max-width: 767px) {
      width: 100%;
    }

    &.top-title {
      color: #0a46bd;
    }
  }
  .icons-container {
    display: flex;
    align-items: center;
  }
  .thumbnails {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 0 0 20px;

    &__icon {
      a {
        display: block;
        padding: 10px 5px;
      }
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .links {
    margin: 0;
    padding: 0;
    list-style: none;
    a {
      display: block;
      text-align: center;
      word-break: normal;
      font: normal normal 400 14px/25px "Plus Jakarta Sans";
      letter-spacing: 0px;
      color: #3D3D41;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: #3D3D41;
      }
    }
    li {
      font-size: 12px;
      padding: 4px 0;
      font: normal normal 400 14px/25px "Plus Jakarta Sans";
    }

    .copyright {
      margin-bottom: 10px;
      @media (min-width: 992px) {
        flex: 1;
        text-align: right;
      }
      @media (max-width: 992px) {
        text-align: right;
      }
    }

    &.bottom-links {
      padding-bottom: 30px;
      @media (min-width: 768px) {
        display: flex;
        align-items: center;
        li {
          margin-right: 30px;
        }
      }
      &.second {
        li:first-child a {
          padding-left: 0 !important;
        }
      }
    }
  }

  .bottom-block {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
  }
  @media (max-width: 767px) {
    .links {
      font-size: 19px;
      a {
        text-align: left;
      }
    }
    .link-wrapper .links {
      margin-bottom: 20px;

    }
  }

.underLogo {
  font: normal normal 400 14px/25px "Plus Jakarta Sans";
  letter-spacing: 0px;
  color: #3D3D41;

  span {
    font-family: "Plus Jakarta Sans";
    font-weight: 500;
  }
}

.footer-circle-1 {

  height: 30px;
  width: 30px;

  position: absolute;
  top: 7vh;
  right: 60vw;

  border-radius: 50%;
  background: var(--color2)0% 0% no-repeat padding-box;
  mix-blend-mode: screen;
  opacity: 0.7;
}

.footer-circle-2 {

  height: 150px;
  width: 150px;

  position: absolute;
  bottom: -75px;
  right: 20vw;

  border-radius: 50%;
  background: var(--color2) 0% 0% no-repeat padding-box;
  mix-blend-mode: screen;
  opacity: 0.7;
}

.poweredWrapper {
  position: absolute;
  right: 26vw;
  top: 50px;

  @media (max-width: $tablet) {
    position: relative;
    top: -10px;
    left: 10px;
    justify-content: start;
  }

  width: 100;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 13px;
  .text {
    display: flex;
    align-items: center;
    padding-right: 10px;
    .brandName {
      color: #e83c3c;
      font-weight: bold;
      margin-left: 2px;
    }
  }
  .logo {
    width: 40px;
    height: 40px;
    margin-left: 5px;
  }
}


</style>
