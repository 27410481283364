<template>
  <div class="steps_container container">
    <ul class="steps_ul progressbar">
      <li
        v-for="(step, idx) in steps"
        :key="idx"
        class="step_action"
        :data-step="idx + 1"
        :class="activeIndex==idx?'active':''"
      >
        <div class="bgbg">
          <img v-if="activeIndex <= idx " :src="`/assets/steps/step${idx + 1}.svg`" alt="">
          <img v-else :src="`/assets/steps/step4.svg`" alt="">
        </div>
        <img src="/assets/steps/arrow.svg" v-if="idx + 1 < 3" class="desktop arrow" alt="">
        <img src="/assets/steps/arrow.svg" v-if="idx + 1 < 3" class="mobile arrow" alt="">
        <div class="step_text text-left" v-html="step.title">
          <br />.
        </div>
        <div class="inner_step_action"></div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "step-counter",
  props: {
    grid: {
      type: Object,
      default: () => {
        `backgroundColor: "#fff";
        `;
      }
    },
    steps: {
      type: Array,
      required: true
    },
    actions: {
      type: Object
    },
    activeIndex:{
      type:Number,
      required:false
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#000"
    }
  },
  computed: {
    computedSteps () {

      return this.steps.map((e, index) => {

        let title = e.title

        if (screen.width >= 800) return title;
        if (index !== 1 && screen.width <= 800 && screen.width >= 450) return title.replace("<br/>", "<br/><br/>")
        return title.replace("<br/>", "")

      })
    }
  }
};
</script>

<style lang="scss" scoped>
.steps_ul {
  padding: 0;
  margin: 0;
  text-align: center;
  margin: 0 auto;
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 32%;
  position: relative;
  text-align: center;
}
.steps {
  padding-top: 160px;
  padding-bottom: 110px;
}

.steps_container {
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: var(--page-max-width);
}

.steps_ul {
  padding: 0;
  margin: 0;
  text-align: center;
  margin: 0 auto;
}

.progressbar {
  counter-reset: step;
  position: relative;

  @media (max-width: 800px) {
    display: flex;
  }
}
.progressbar li {
  list-style: none;
  display: inline-block;
  width: 32%;
  position: relative;
  text-align: center;

  @media (max-width: 800px) {
    width: 33.3%;
  }
}

.progressbar li .step_text {
  margin-top: 10px;
  font: normal normal normal 20px/28px 'Plus Jakarta Sans';
  letter-spacing: 0px;
  color: #1A1111;
  text-align: center !important;
  // opacity: 0.2;

  @media (max-width: 991px) {
    display: none;
  }
}

.progressbar li.active .step_text {
    opacity: 1;
}


.step_action {
  .bgbg {
    font-size: 14px;
    width: 150px;
    height: 150px;
    border: 2px solid inherit;
    display: block;
    font-weight: 800;
    text-align: center;
    margin: 0px auto 0px auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    z-index: 2;
    padding-top: 4px;
    position: relative;
    // opacity: 0.2;


    img {
      width: 100%;
    }

    @media (max-width: 900px) {
      width: 50px;
      height: 50px;
    }
  }
  .stepNumber {
    width: 100%;
    height: 100%;
    display: inline-block;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 30px;
    color: #fff;
    font-weight: 600;
    z-index: 2;
  }
}
.active {
  font-size: 14px;
  border: 2px solid inherit;
  display: block;
  font-weight: 800;
  text-align: center;
  margin: 0px auto 0px auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  z-index: 2;
  opacity: 1;

  .arrow {
    opacity: 1;
  }
}
.active > div.bgbg {
  opacity: 1;
}
.progressbar li:last-child:after {
  display: none;
}

@media (max-width: 400px) {
  .steps_container {
    padding: 0 !important;
  }
  .step_text {
    font-size: 12px !important;
  }
}

.arrow {
  position: absolute;
  left: calc(100% - 75px);
  top: 75px;

  // opacity: 0.2;

  @media (max-width: 991px) {

    width: 40px;

    left: calc(100% - 20px);
    top: 25px;
  }
}

.mobile {display: none;}
@media (max-width: 991px) {
  .desktop {display: none;}
  .mobile {display: block;}
}
</style>