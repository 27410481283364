<template>
  <div class="poz_rel whiteCard product-upload" :class="className">
    <div class="dashedContainer">
      <div
        class="wrapper_file_upload"
        :class="{ highlight: highlight }"
        @dragover.prevent
        @drop.stop.prevent="handleDrop"
        @dragenter.stop.prevent="onDragEnter"
        @dragleave.stop.prevent="onDragLeave"
      >
        <label v-show="!selectedFile" class="file_upload">
          <input
            @change="onFilePicked"
            accept="image/*"
            class="inputfile"
            type="file"
            name="file"
            ref="file"
          />

          <!-- <img class="upload_img" src="../../assets/images/svg/fileUpload.svg" alt="" /> -->
          <div class="text1 color-black" v-html="$t(this.texts.receiptUpload1)"></div>
          <div class="text2 color-black" v-html="$t(this.texts.receiptUpload2)"></div>
        </label>
        <a
            href="#"
            v-if="!selectedFile"
            @click.prevent="$refs.file.click()"
            class="btn upload-btn secondaryButton btn_abs"
            >{{ $t(this.texts.choose) }}</a
          >
        <div v-show="selectedFile" class="row row-dop justify-content-center">
          <label class="wrapper_square_img">
            <img
              class="square-img img-fluid"
              ref="preview"
              src="../../assets/icons/placeholder.svg"
              alt
            />
            <div @click="removeImage" class="delete_block">
              <img class="delete_img" src="../../assets/icons/delete.svg" alt="Delete" />
            </div>
          </label>
          <a
            href="#"
            v-if="selectedFile"
            @click.prevent="$refs.file.click()"
            class="btn upload-btn btn_abs secondaryButton"
          >{{$t(this.texts.choose)}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "product-upload",
  props: {
    fieldId: String,
    apiUrl: String,
    successAction: {
      type: Object,
      required: true
    },
    value: {
      required: false
    },
    title: {
      type: String,
      default: "Laden Sie Ihr Foto hoch"
    },
    uploadText: {
      type: String,
      default: "Datei auswählen"
    },
    colorSchema: {
      required: false,
      type: String,
      default: "#8B8B8B"
    },
    tokenName: {
      required: false,
      type: String,
      default: "token"
    },
    reUpload: {
      required: false,
      type: Boolean
    },
    texts: {
      required: false,
      type: Object,
      default: () => {}
    },
    className: String
  },
  data () {
    return {
      selectedFile: null,
      uploadSectionHover: false,
      highlight: false
    };
  },
  components: {},
  methods: {
    clear () {
      this.selectedFile = null;
      this.setValue(null);
    },
    removeImage () {
      this.selectedFile = null;
      this.setValue(null);
      this.$refs.file.value = null;
    },
    onFilePicked (event) {
      const files = event.target.files || event.dataTransfer.files;
      this.handleFiles(files);
    },
    onDragEnter () {
      this.highlight = true;
    },
    onDragLeave () {
      this.highlight = false;
    },
    handleDrop (e) {
      let dt = e.dataTransfer;
      let files = dt.files;
      this.handleFiles(files);
    },
    handleFiles (files) {
      const maxfilesize = 1024 * 1024 * 16; // 1 Mb

      if (files && files[0]) {
        let filename = files[0].name;

        if (filename && filename.lastIndexOf(".") <= 0) {
          return;
        }
        if (files[0].size > maxfilesize) {
          this.showModal(this.$t(this.texts.fileSizeLimit));
          this.$refs.image.type = "";
          this.$refs.image.type = "file";
          return;
        }
        const extension = files[0].name.split(".").pop();
        const found = ["png", "jpg", "jpe", "jpeg"].filter(
          (ext) => ext === extension.toLowerCase()
        );
        if (!found.length) {
          this.showModal(this.$t(this.texts.wrongFormat));
          this.$refs.image.type = "";
          this.$refs.image.type = "file";
          return;
        }
        this.selectedFile = files[0];
        this.setPreview(this.selectedFile);
        this.setValue(this.selectedFile);
      }
    },
    setValue (value) {
      this.$emit("component-updated", {
        value
      });
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: !!value
      });
    },
    setPreview (file) {
      const reader = new FileReader();

      this.selectedFile = file;
      reader.onload = (e) => {
        this.$refs.preview.src = e.target.result;
      };

      reader.readAsDataURL(file);
    },
    sendProductPhoto (formId, triggers) {
      const formData = {
        image: this.selectedFile,
        apiUrl: this.apiUrl,
        receiptToken: this.$store.getters.receiptToken
      };

      if (this.reUpload) {
        formData["receiptToken"] = this.$route.query[this.tokenName];
        formData["customerToken"] = 1;
        // console.log("REUPLOAD",this.reUpload, formData)
      }

      this.$store.dispatch("uploadProductPhotos", formData).then((r) => {
        // console.log(this.successAction, this.response);
        if (r.response && this.successAction) {
          const fn = this.successAction.fn;
          const params = this.successAction.params;
          this[fn](params, this);
          this.$eventHub.$emit("done-event-trigger", {
            parent: this,
            status: true
          });
        }
        this.$eventHub.$emit("done-event-trigger", {
          parent: this,
          status: false,
          message: r.message || "Unexpected Error",
          invalid_fields: r.invalid_fields || []
        });
      });
    }
  },
  mounted () {
    if (this.value) {
      this.setPreview(this.value);
    }
    this.$eventHub.$on("sendProductPhotos-event", this.sendProductPhoto);
  },
  beforeDestroy () {
    this.$eventHub.$off("sendProductPhotos-event", this.sendProductPhoto);
  }
};
</script>

<style scoped lang="scss">
.product-upload {
  min-height: 529px;
  @media (max-width: 1024px) {
    min-height: 341px;
  }
}
.fade-enter-active,
.fade-leave-active {
  opacity: 1;
  transition: 0.4s;
}
.uploadIcon {
  position: absolute;
  top: 0;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.uploadDescription {
  font-size: 12px;
  line-height: 22px;
}
.receiptUploaderWrapper {
  border: 1px solid #dddfe1;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 240px;
  padding: 0;
  margin-bottom: 20px;
  width: 86%;
  .receiptScannerPlaceholder {
    color: #97999b;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.text-center {
  text-align: center !important;
}
.hidden {
  display: none;
}
.receipt-uploader {
  overflow: hidden;
}

.wrapper_square_img {
  position: relative;
  display: inline-block;
  width: 89%;
}
.delete_block {
  position: absolute;
  top: 0;
  right: 0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
    transition: transform .5s;
  }
}
.delete_block .delete_img {
  width: 16px;
}
.section-title {
  color: #474747;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.16px;
  text-align: center;
  margin-bottom: 30px;
}
.upload-canvas {
  box-sizing: border-box;
  height: 176px;
  border: 2px dashed #979797;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  transition: background-color 0.5s;
  cursor: pointer;

  &:hover {
    background-color: #fff;
  }

  .upload-text {
    color: #474747;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.16px;
  }

  .upload-icon {
    text-align: center;
  }
}
.upload-hint {
  margin-top: 14px;
  opacity: 0.64;
  color: #474747;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.14px;
  line-height: 16px;
}
.inputfile {
  display: none;
}
.wrapper_file_upload {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 54px;
  transition: background-color 300ms;
  box-sizing: border-box;

  @media (max-width: 767px) {
    padding-left: 25px;
    padding-right: 25px;
  }
  &_prog {
    border-style: solid;
  }
  .file_upload {
    width: 100%;
    text-align: left;
    cursor: pointer;
    padding-top: 40px;
    padding-bottom: 20px;
    .text {
      &1 {
        font: normal normal bold 24px/30px 'Plus Jakarta Sans';
        letter-spacing: 0px;
        color: var(--text-color-2);
        opacity: 1;
        padding-bottom: 15px;
      }

      &2 {
        font: normal normal normal 16px/25px 'Plus Jakarta Sans';
        letter-spacing: 0px;
        color: var(--text-color-3);
        opacity: 1;
      }
    //   line-height: 48px;
    //   @media (max-width: 767px) {
    //     line-height: 32px;
    //   }
    }
  }
}
.upload_img {
  width: 30%;
  margin-bottom: 50px;
}
.file_upload input[type="file"] {
  display: none;
}
.color-green {
  color: var(--color2) !important;
}
.btn {
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: all 0.3s;
  box-sizing: border-box;
  font-family: "'Plus Jakarta Sans'", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  padding: 0 15px;
  box-shadow: none !important;
  min-height: 54px;
  &.disabled {
    opacity: 0.3;
  }
  &-lg {
    max-width: 470px;
    width: 100%;
  }
  &-md {
    max-width: 226px;
    width: 100%;
  }
  &-min {
    @media (max-width: 574px) {
      font-size: 17px;
    }
  }
}
.btn_abs {
  border-width: 0px !important;
  position: absolute;
  margin: 0 auto;
  bottom: 30px;
  line-height: 0;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: normal normal bold 16px/20px 'Plus Jakarta Sans';
  color: #FFFFFF;
  &:active {
    border-width: 1px !important;
  }
}
.text.color-black {
  font-size: 13px;
}
.poz_rel {
  position: relative;
}
.container_sm {
  max-width: 710px !important;
}
.upload-btn {
  background-color: #5a5e5f;
  color: #fff;
}
</style>
