<template>
  <div class="stepCardWrapper">
    <div class="stepImage">
      <img :src="imageSource" alt="">
    </div>
    <div class="stepArrow">
      <img v-if="stepNumber <= 2" src="/assets/steps/arrow.svg" class="desktop" alt=""/>
      <img v-if="stepNumber <= 2" src="/assets/steps/arrow_mobile.svg" class="mobile" alt=""/>
    </div>

    <div class="paragraph">
      <p v-for="field in fields" :key="field.fieldId">
        <componentRenderer v-bind:key="field.id" :field="field" />
      </p>
    </div>
  </div>
</template>

<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "step-card",
  components: { componentRenderer },
  props: {
    fields: {
      required: false,
      type: Array,
      default: () => []
    },
    imageSource: {
      required: true,
      type: String
    },
    stepNumber: {
      required: true,
      type: [String, Number]
    },
    stepStyles: {
      required: false,
      type: Object
    }
  }
};
</script>

<style lang="scss" scoped>
.stepCardWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;

    .stepImage {
      position: relative;
      margin: 5px;
    }

    .stepNumber {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: #D8D8D8;
        font-weight: bold;
        height: 35px;
        width: 35px;
        font-size: 14px;
        line-height: 24px;
        position: absolute;
        right: -40px;
        top: -8px;
    }
    .paragraph {
        margin-top: 20px;
        font-weight: bold;
        font-size: 12px;
        @media (min-width: 768px) {
          font-size: inherit;
        }
    }
    .stepImage img {
      height: 74px;
      width: 74px;
      border-radius: 50%;
      @media (min-width: 768px) {
        height: 100px;
        width: 100px;
      }
      @media (min-width: 1025px) {
        height: 150px;
        width: 150px;
      }
    }
}
.stepCardWrapper:last-child {
  .stepImage:after {
    background-image: none;
  }
}

.stepArrow {
  position: absolute;
  left: calc(100% - 64px);
  top: calc(0px + 75px - 21px);

  @media (max-width: 1024px) {
    left: calc(100% - 50px);
    top: calc(0px + 50px - 21px)
  }

  @media (max-width: 991px) {
    left: calc(50% - 50px);
    top: calc(100% - 61px);
    z-index: 10;
  }


  img {
    width: 124px;
    height: 42px;

    @media (max-width: 1024px) {
      width: 100px;
    }

    @media (max-width: 991px) {
      transform: rotate(90deg);
      height: 30px;
    }

  }
}

.mobile {
  display: none;
}

@media (max-width: 991px) {
  .mobile {display: block;}
  .desktop {display: none;}
}

</style>
