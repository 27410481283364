<template>
  <div v-if="show" class="wrapper">
    <div class="layout">
      <div class="container">
        <div id="canvas-container" class="canvas-container">
          <image-drawer
                  :image="image"
                  :points="points"
                  :hideEraser="hideEraser"
                  :eraseColor="eraseColor"
                  :eraseSize="eraseSize"
                  :cropColor="cropColor"
                  :cropBackground="cropBackground"
                  :cropTitle="cropTitle"
                  :eraseTitle="eraseTitle"
                  :dragTitle="dragTitle"
                  @input="$emit('input', $event)"
                  :controlsBackground="controlsBackground"
          >
            <template slot="crop">
              <slot name="crop" />
            </template>
            <template slot="erase">
              <slot name="erase" />
            </template>
            <template slot="drag">
              <slot name="drag" />
            </template>
            <template slot="zoomIn">
              <slot name="zoomIn" />
            </template>
            <template slot="zoomOut">
              <slot name="zoomOut" />
            </template>
          </image-drawer>
          <receipt-scan-loader
                  v-if="isLoading"
                  :phrases="image ? phrases : cornerDetectionPhrases"
          ></receipt-scan-loader>
        </div>
      </div>
    </div>
    <div class="message" :class="{ error: isErrorMessage }" v-if="message">{{message}}</div>
  </div>
</template>

<script>
import axios from 'axios'
import ImageDrawer from './ImageDrawer'
import ReceiptScanLoader from './ReceiptScanLoader'

export default {
  name: 'receipt-scanner',
  components: {
    ReceiptScanLoader,
    ImageDrawer
  },
  props: {
    // main properties
    file: [File,Blob],
    apiUrl: String,
    showLoader: Boolean,
    show:Boolean,

    // options
    phrases: {
      type: Array,
      default: () => {
        return [
          "Ihr Beleg wird jetzt hochgeladen und bearbeitet.",
          "Der Beleg wird automatisch bearbeitet. Dies kann eine Minute dauern. Bitte haben Sie Geduld.",
          "Sollten Sie eine Fehlermeldung erhalten, nehmen Sie bitte eine bessere Version des Belegs auf und laden Sie ihn erneut hoch."
        ]
      }
    },
    cornerDetectionPhrases: {
      type: Array,
      default: () => {
        return [
          "Bitte passen Sie das graue Rechteck an den Kassenbeleg an.",
          "Bitte ziehen Sie Ihren Beleg in das graue Rechteck.",
          "Sie können private Informationen mit dem Radierer-Tool löschen."
        ]
      }
    },
    hideEraser: {
      type: Boolean,
      default: false
    },
    hideZoomer: {
      type: Boolean,
      default: false
    },
    skipCornerDetection: {
      type: Boolean,
      default: false
    },
    eraseColor: {
      type: String,
      default: '#ffffff'
    },
    eraseSize: {
      type: Number,
      default: 12
    },
    cropColor: {
      type: String,
      default: '#3A96F3'
    },
    cropBackground: {
      type: String,
      default: 'rgba(0,0,0,0.6)'
    },
    cropTitle: {
      type: String,
      default: 'Crop'
    },
    eraseTitle: {
      type: String,
      default: 'Erase'
    },
    dragTitle: {
      type: String,
      default: 'Drag'
    },
    controlsBackground: {
      type: String,
      default: '#4193d3'
    }
  },
  data () {
    return {
      message: '',
      isErrorMessage: false,
      isLoading: false,
      image: null,
      points: [],
      axiosCancelSource: null
    }
  },

  watch: {
    file (file) {
      if (!file) {
        if (this.image) {
          this.image = null
          this.points = null
        }
        if (this.axiosCancelSource) {
          this.axiosCancelSource.cancel('cancel')
        }
        if (this.isLoading) {
          this.isLoading = false
        }
      }
      this.onFileChanged(file)
    },
    showLoader (showLoader) {
      this.isLoading = showLoader
    }
  },

  methods: {
    onFileChanged () {
      if (!this.file) return

      if (
        this.file.type !== 'image/jpeg' &&
        this.file.type !== 'image/jpg' &&
        this.file.type !== 'image/png' &&
        this.file.type !== 'application/tif' &&
        this.file.type !== 'image/tiff' &&
        this.file.type !== 'application/pdf'
      ) {
        this.$emit('uploadError', { code: 'WRONG_EXTENSION' })
        return
      }
      //@kutluturk updated to get files up to 16 MB - New phones take huge photos
      if (this.file.size > 1024 * 1024 * 16) {
        this.$emit('uploadError', { code: 'FILE_TOO_BIG' })
        return
      }

      this.isLoading = true

      const formData = new FormData()
      formData.append('receipt', this.file)

      this.axiosCancelSource = axios.CancelToken.source()

      if (this.skipCornerDetection) {
        this.isLoading = false

        const img = new Image()
        const reader = new FileReader()

        reader.onload = (event) => {
          img.onload = () => {
            this.points = []
            this.image = img
          }
          img.src = event.target.result
        }
        reader.readAsDataURL(this.file)

        return
      }

      // if (!this.$store.state.receiptImage) {
      //
      // }
      axios.post(`${this.apiUrl}/getConvertedImageWithPoints`, formData, { cancelToken: this.axiosCancelSource.token })
        .then(resp => {
          this.isLoading = false
          this.axiosCancelSource = null
          if (resp.data.response === 'OK') {
            const img = new Image()
            img.addEventListener('load', () => {
              this.points = JSON.parse(resp.data.points)
              this.image = img
            });
            img.src = resp.data.image;

            if (this.message) {
              this.message = null
            }
            this.$emit('converted');
            this.$store.dispatch('onReceiptUploadSuccess', this.file);
          }
        })
        .catch(error => {
          this.isLoading = false;
          this.axiosCancelSource = null;
          this.$emit('uploadError', error.response ? error.response.data : {})
        })
    },

    showMessage (message, isError) {
      this.message = message
      this.isErrorMessage = !!isError
    }
  }
}
</script>

<style scoped>
  .canvas-container {
    position: relative;
  }

  .canvas-container img {
    position: absolute;
    top: 50%;
    left: 50%;
  }

  .container {
    padding: 5px;
    border: 1px solid #ccc;
  }

  .message {
    border: 1px solid #067a00;
    background-color: #bbffbd;
    color: #067a00;
    padding: 10px;
    border-radius: 5px;
    z-index: 100;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 25px;
    right: 25px;
    text-align: center;
  }

  .message.error {
    border: 1px solid #ff0000;
    background-color: #ffe2d9;
    color: #ff0000;
  }

  .wrapper {
    position: relative;
  }
</style>
