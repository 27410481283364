<template>
  <span></span>
</template>

<script>
export default {
  name: "dataLayer-event",
  props: {
    eventName: {
      type: String
    },
    fbEvent: {
      type: String
    },
    sendconsumerId: {
      type: Boolean
    },
    sendReceiptData: Boolean
  },
  mounted () {
    console.log("dataLayer event.... ",this.eventName || this.fbEvent)
    if (this.sendconsumerId && this.$store.getters.consumerId) {
      // eslint-disable-next-line
      PGdataLayer.user.id = this.$store.getters.consumerId;
      // setTimeout(()=>{
      console.log("Website login identification call....",this.$store.getters.consumerId)
      // eslint-disable-next-line
        dataLayer.push({
        'event': 'customEvent',
        'GAeventCategory': 'event_crm_action',
        'GAeventAction': 'event_profile_login_complete',
        'GAeventLabel': 'Log-in',
        'GAeventValue': 0,
        'GAeventNonInteraction': false
      });
      // },250)
      const trackData = {
        firstName: this.$store.state.formData.firstName,
        lastName: this.$store.state.formData.lastName,
        email: this.$store.state.formData.email,
        gender: this.$store.state.formData.gender,
        hausnr:this.$store.state.formData.hausnr,
        ort:this.$store.state.formData.ort,
        plz: this.$store.state.formData.plz,
        strasse: this.$store.state.formData.strasse
      }
      console.log("PGDatalayer consumer id set...",this.$store.getters.consumerId,"with data...", trackData)
      window.SEGMANTA__USER_METADATA = {
        userID: this.$store.getters.consumerId
      }
      // eslint-disable-next-line
      // if(analytics){
      // // eslint-disable-next-line
      //     analytics.identify(this.$store.getters.consumerId, trackData);
      //   }
    }else if (window.dataLayer && this.eventName ) {
      // eslint-disable-next-line
      dataLayer.push({
        event_action: this.eventName
      });
    }
    if (window.fbq && this.fbEvent) {
      // eslint-disable-next-line
      fbq("track", this.fbEvent);
    }
  }
};
</script>
