<template>
  <span></span>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "iban-reupload",
  beforeMount () {
    const customerToken = this.$route.query.customerToken;
    const campaignId = this.$store.state.campaignId
    if (customerToken)
      this.$store.dispatch("isUpdateIban", { customerToken, campaignId })
        .then(res => {

          // If Customer Token Not Found
          if (!res.response) {
            this.onUpdateIbanError()
            return
          }

          // Everything can proceed as normal
          this.displaySuccess()
          this.setCustomerToken(customerToken)
        })
        .catch(err => {
          this.onUpdateIbanError()
        })
    else {
      this.onUpdateIbanError()
    }

  },
  methods: {
    ...mapActions(['setCustomerToken']),
    onUpdateIbanError () {
      // Set a modal error and hide all other things
      // this.showModal('Die soeben angegebene IBAN hat im Rahmen unserer Aktion bereits erfolgreich teilgenommen. Bitte verwenden Sie eine andere IBAN!')
      location.href = "/404"
    }
  }
};
</script>
