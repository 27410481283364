/* eslint-disable */

<template>
  <div>
    <router-view />
    <button class="cookie_button" @click.prevent="oneTrustModal" v-html="$t('Cookie Text')"></button>
  </div>
</template>
<script>
const version = process.env.VUE_APP_VERSION;
import store from './store'
export default {
  created () {
    if (process.env.NODE_ENV === 'production') {
      console.log = function () {};
    }
    console.log("%cV" + version, "color:red; font-size:32px;");
  },
  methods: {
    oneTrustModal: function () {
      // eslint-disable-next-line no-undef
      Optanon.OnConsentChanged(() => {
        //if (window.segmentaCookieHandler) window.segmentaCookieHandler();
        if (!window.nsId && !window.nsIdPending && window.nsUrl) {
          window.nsIdPending = true;
          // eslint-disable-next-line no-undef
          fetchJsonp(window.nsUrl, {
            jsonpCallbackFunction: "neustar_response"
          })
            .then(function (response) {
              return response.json();
            })
            .then(function (json) {
              window.nsId = json.nsid;
            });

          if (window._agknEchoTag) {
            var a = window._agknEchoTag;
            a.setBpId("firstp");
            a.addEchoKeyValue("event", "page_landing");
            a.generateTag();
          }
        }
      });
      // eslint-disable-next-line
      Optanon.ToggleInfoDisplay();
    }
  },
  updated () {
    document.querySelectorAll('.modalOpener').forEach(x => {
      x.addEventListener('click', function () {
        store.commit('setModalOptions', {
          type: 'custom',
          message: this.dataset.text,
          isActive: true,
          closeButton: true
        })
      })
    })
  }
};
</script>
<style lang="scss">
@media (min-width: 1440px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1152px !important;
  }
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
@import "assets/styles/_application.scss";
#app {
  height: 100%;
  overflow: hidden;
  font-family: "Plus Jakarta Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
.segmanta-loader{margin:60px auto;font-size:10px;position:relative;text-indent:-9999em;border-top:1.1em solid rgba(86,164,255,.2);border-right:1.1em solid rgba(86,164,255,.2);border-bottom:1.1em solid rgba(86,164,255,.2);border-left:1.1em solid #56a4ff;-webkit-transform:translateZ(0);-ms-transform:translateZ(0);transform:translateZ(0);-webkit-animation:load8 1.1s infinite linear;animation:load8 1.1s infinite linear}.segmanta-loader,.segmanta-loader:after{border-radius:50%;width:10em;height:10em}@-webkit-keyframes load8{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}@keyframes load8{0%{-webkit-transform:rotate(0);transform:rotate(0)}100%{-webkit-transform:rotate(360deg);transform:rotate(360deg)}}
</style>
