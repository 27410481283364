<template>
  <div class="stepCounterWrapper"
    :style="cssVars">
      <div class="stepCounterContainer">
        <div
          :key="step.title"
          v-for="(step, idx) in steps"
        >
          <div
            class="stepItemsWrapper"
            :class="{'active': isActive(idx), 'done': isDone(idx)}"
          >
            <div class="stepCircle">
              <div class="stepInnerCircle">
                <img v-if="isDone(idx)" src="/assets/svg/checkMark.svg" alt="Checkmark Image"/>
                <span v-else class="stepTitle">{{idx+1}}</span>
              </div>
            </div>
            <span class="stepTitle" v-html="$t(step.title)"/>
          </div>
        </div>
        <div class="progressBarLine" />
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: "progress-bar",
  props: {
    currentInterval: {
      type: Number,
      default: 1
    },
    currentStep: {
      type: Number,
      default: 1
    },
    stepCount: {
      type: Number,
      default: 3
    },
    // Both colors support gradient
    primaryColor: {
      type: String,
      default: "#3B3328"
    },
    secondaryColor: {
      type: String,
      default: "#3B3328"
    },
    steps: {
      type: Array,
      default: () => [
        {
          title: "Select<br>product"
        },
        {
          title: "Upload<br>a receipt"
        },
        {
          title: "Register"
        },
        {
          title: "Get your<br>money back"
        }
      ]
    }
  },
  watch: {
    currentStepState (newStep, oldStep) {
      this.currentStep = newStep
    }
  },
  computed: {
    ...mapState({
      currentStepState: state => state.currentStep
    }),
    cssVars () {
      return {
        '--primary-color': this.primaryColor,
        '--secondary-color': this.secondaryColor,
        '--current-interval': this.currentInterval,
        '--interval-count': this.steps.length - 1,
        '--current-step': this.currentStep,
        '--step-count': this.stepCount

      }
    }
  },
  methods: {
    isActive (idx) {
      return this.currentInterval > idx;
    },
    isDone (idx) {
      return this.currentInterval - idx > 1;
    }
  }
};
</script>

<style lang="scss" scoped>
// You can customize this component by changing these variables

.stepCounterWrapper {
  --horizontalPadding: 140px;
  --stepCircleRadius: 20px;
  --stepItemsWrapperWidth: 100px;
  --verticalMargin: 110px;

  @media (max-width: 991px) {
    --horizontalPadding: 0px;
    --stepItemsWrapperWidth: 70px;
    --verticalMargin: 48px;
  }
}
$gapBetweenTextAndCircle: 5px;
$fontSize: 14px;
$borderWidth: 2px;

$lineThickness: 2px;
$activeLineThickness: 2px;
$activeLineRadius: 0px;

// Passing variables from vue to css with this trick:
$primaryColor: var(--primary-color);
$secondaryColor: var(--secondary-color);
$currentInterval: var(--current-interval);
$currentStep: var(--current-step);
$stepCount: var(--step-count);
$intervalCount: var(--interval-count);

// Calculated variables
$stepCircleDiameter: calc(var(--stepCircleRadius) * 2);
$stepInnerCircleDiameter: calc(#{$stepCircleDiameter} - #{$borderWidth} * 2);
$progressBarLeftPosition: calc(var(--horizontalPadding) + calc(var(--stepItemsWrapperWidth) / 2) + var(--stepCircleRadius));
$activeLineTopPosition: 1px - calc($activeLineThickness / 2);
$progressBarFullWidth: calc(100% - calc(var(--horizontalPadding) * 2) - var(--stepItemsWrapperWidth) - #{$stepCircleDiameter});
$intervalWidth: calc((100% - (#{$intervalCount} - 1) * #{$stepCircleDiameter}) / #{$intervalCount});
$intervalGap: calc(#{$intervalWidth} + #{$stepCircleDiameter});
$startingWidth: calc((#{$currentInterval} - 1) * #{$intervalGap});
$activeLineWidth: calc(#{$startingWidth} + #{$intervalWidth} * #{$currentStep} / #{$stepCount});

.stepCounterContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 var(--horizontalPadding);

  .stepItemsWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: var(--stepItemsWrapperWidth);

    .stepCircle {
      z-index: 1;
      width: $stepCircleDiameter;
      height: $stepCircleDiameter;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      margin-bottom: $gapBetweenTextAndCircle;
      background: $secondaryColor;
    }

    .stepInnerCircle {
      width: $stepInnerCircleDiameter;
      height: $stepInnerCircleDiameter;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #FAFCFF
    }

    .stepTitle {
      text-align: center;
      font-size: $fontSize;
      white-space: nowrap;
      font-weight: 500;
      background: $secondaryColor;
      -webkit-background-clip: text;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    &.active {
      .stepCircle {
        background: $primaryColor;
      }

      .stepInnerCircle {
        background-color: white;
      }

      .stepTitle {
        font-weight: 600;
        background: $primaryColor;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &.done {
      .stepInnerCircle {
        background-color: transparent;
      }
    }
  }
}

.progressBarLine {
  width: $progressBarFullWidth;
  height: $lineThickness;
  background: $secondaryColor;
  position: absolute;
  top: var(--stepCircleRadius);
  left: $progressBarLeftPosition;
  z-index: 0;

  &::before {
    content: '';
    transition: all 0.3s;
    position: absolute;
    width: $activeLineWidth;
    max-width: 100%;
    // min-width: 0;
    top: $activeLineTopPosition;
    height: $activeLineThickness;
    background: $primaryColor;
    border-bottom-right-radius: $activeLineRadius;
    border-top-right-radius: $activeLineRadius;
  }
}

.stepCounterWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  margin: var(--verticalMargin) 0;
}

</style>
