<template>
<div class="d-flex whiteCard" :class="className" :style="cssString">
    <div class="notDashedContainer">
      <div class="notDashedContainerInner" :style="innerContainerCssString">
        <div class="w-100 position-relative d-flex flex-wrap">
          <template v-for="field in fields">
            <componentRenderer
              :class="field.properties.grid ? `col-lg-${field.properties.grid.columns}`: ''"
              :field="field"
              v-bind:key="field.id"
              v-on:component-updated="updated"
            />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import componentRenderer from "@/components/component-renderer";

export default {
  name: "card-bucket",
  components: { componentRenderer },
  data () {
    return {
      cssString: "",
      innerContainerCssString: ""
    }
  },
  props: {
    fieldId: String,
    requires: Array,
    fields: {
      type: Array,
      required: true
    },
    grid: {
      type: Object
    },
    noRow: {
      type: Boolean,
      default: false
    },
    styles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    innerStyles: {
      type: Object,
      default: () => {
        return {};
      }
    },
    className: {
      type: String
    }
  },
  methods: {
    updated (field, properties) {
      let updatedField = this._.find(this.fields, function (fld) {
        return fld.fieldId === field.fieldId;
      });

      updatedField.properties = { ...updatedField.properties, ...properties };
      // let updatedField = __.findDeep(this.fields, fld => fld === field.fieldId);
      // console.log(
      //   "component renderer on updated::",
      //   field,
      //   "properties",
      //   properties,
      //   "FOUND",
      //   updatedField
      // );

      // TODO
      // some validation will go here
      // in case if all fields are valid we are happy to set truthy status
      this.$store.dispatch("setComponentStatus", {
        component: this.fieldId,
        status: true
      });
    }
  },

  watch: {
    styles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.styles);
        const vals = Object.values(this.styles);
        keys.forEach((key, idx) => (this.cssString += key + ":" + vals[idx]));
      }
    },
    innerStyles: {
      immediate: true,
      handler () {
        const keys = Object.keys(this.innerStyles);
        const vals = Object.values(this.innerStyles);
        keys.forEach((key, idx) => (this.innerContainerCssString += key + ":" + vals[idx]));
      }
    }
  }
};
</script>
<style scoped lang="scss">
@media (min-width: 992px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}
@media (max-width: 991px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }
}

</style>
