import EN from './files/en'
import DK from './files/dk'
import FI from './files/fi'
import NO from './files/no'
import SE from './files/se'

export default {
  en: EN,
  dk: DK,
  fi: FI,
  no: NO,
  se: SE
}
